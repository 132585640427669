
import { useStore } from '../../../../zustand/store';


const Default_color = ['#EF5350', '#EC407A', '#AB47BC', '#7E57C2', '#D4E157', '#FFEE58', '#FFA726', '#FF7043', '#8D6E63', '#78909C'];

const Pencil = () => {
    let store = useStore(state => state);
    let drawData = useStore(state => state.drawData);
    let activetab = useStore(state => state.activetab);
    const changeValue = (key, value) => {
      let data = {
        ...drawData,
        [key]: value,
      }
      store.updateStore("drawData", data)
    }
  
    const showLine = (size) => {
       if(size == 2){
        return "line1"
       } else if(size == 5){
        return "line2"
      } else if(size == 7){
        return "line3"
      } else if(size == 10){
        return "line4"
       }
    }
  
    return (
      <>
        <div className="p-25">
          <div className="cun_img_editor_side_layers">
            <h6>{activetab == "pencil" ? "Pencil" : "Line"} Settings</h6>
          </div>
  
          <div className="cun-pencilSetting-color">
          <label className="cun_image_editor_label pb-2"> Color </label>
          <div className="cun_input_wrapper_div pb-3">
            <div className="cun_color_picker_wrapper">
              <div className="cun_color_picker_toggle">
                <input type="color" value={drawData?.color} onChange={(e) => {
                  changeValue("color", e.target.value);
                }} />
                <span>{drawData.color}</span>
              </div>
            </div>
          </div>
          </div>
  
  
  
          <div className="cun-pencilSetting-size pb-2">
          <label className="cun_image_editor_label pb-2">Size </label>
          <div className="d-flex justify-content-between px_image_editor_main_box">
            <div className="cun_input_wrapper">
              <div className="ewp_input ewp_input_size">
                <select onChange={(e) => { changeValue("size", e.target.value) }} value={drawData.size} className="subcribe_site" >
                  <option value={2} disabled="">
                    1
                  </option>
                  <option value={5}>2</option>
                  <option value={7}>3</option>
                  <option value={10}>4</option>
                </select>
              </div>
              <div className='cun_editor_lines ms-3'>
              <div className={ showLine(drawData?.size)}></div>
           
              </div>
            </div>
          </div>
          </div>
          <div className="cun_image_editor py-2">
            <label className="cun_image_editor_label pb-2">Default Color </label>
            <div className="cun_image_editor_def_color">
              {Default_color.map((val, i) => {
                return (
                  <div key={i}
                    className="cun_image_editor_def_color_box"
                    onClick={() => {
                      changeValue("color", val);
                    }}
                    style={{ background: `${val}` }}
                  ></div>
                )
              })}
            </div>
          </div>
        </div>
      </>
    );
}

export default Pencil