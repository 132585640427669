
import { Helmet } from "react-helmet";
import { useStore } from "../zustand/store";
import { useNavigate } from 'react-router-dom';
import Sidebar from "../components/Sidebar";
import svg from "../components/svg";

const Dashboard = ({ pageTitle }) => {
    let store = useStore(state => state);

    let { role, scope = {} } = store.userData;
    const navigate = useNavigate();

    let AppsDataOption = [
        { navigatePath: "/builder", teamMemNavUrl: "/builder/projects", urlPath: `app.pixalab.ai/builder`, app: 'Builder', value: 'builder', icon: 'builderApp' },
        { navigatePath: "/scheduler", teamMemNavUrl: "/scheduler", urlPath: `app.pixalab.ai`, app: 'Scheduler', value: 'scheduler', icon: 'schedularApp' },
        { navigatePath: "/images", teamMemNavUrl: "/images/templates", urlPath: `app.pixalab.ai/images`, app: 'Images', value: 'images', icon: 'imagesApp' },
        { navigatePath: "/pixa-support", teamMemNavUrl: "/pixa-support/projects", urlPath: `app.pixalab.ai/pixa-support`, app: 'Pixa Support', value: 'support', icon: 'supportApp' }
    ]

    const AppsData = (role === "teamMember") ? AppsDataOption.filter(item => scope.hasOwnProperty(item.value)) : AppsDataOption;


    return (
        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>
            <div className="pxl_mainWrapper">
                <Sidebar />
                <div className="pxl-admin-dashboard-wrapper">
                    <div className="pxl_rightBar_assets">
                        {/* Count Card Widgets Section */}
                        <div className="pxl-flex-divide">
                            <div className="pxl-headingTop">
                                <h2>{pageTitle}</h2>
                            </div>
                            {/* <div className="sch-search-section pxl-dashboardSearch1">
                            <div className="sch-search-row sch-search-bg-color">
                                <input placeholder="Search Here..." type="text" />
                                <button type="button" className="sch-search-btn">
                                    <img src="/assets/images/scheduler/search.svg"/>
                                    </button>
                            </div>
                        </div> */}
                        </div>

                        {/* <div className="pxl-count-cards">
                            <div className="pxl_container">

                                <div className="pxl_grid">
                                    <div className="dash_head">
                                        <h3>Revenue</h3>
                                    </div>
                                    <div className="dash_grid pxl-dashboard-grid-curve">
                                        <div className="single_dash_box">
                                            <div className="dah_color_box color_1">
                                                <img src="/assets/images/today-img.png" alt="" />
                                            </div>
                                            <div className="pxl_dash_box_content">
                                                <h2>$154</h2>
                                                <p>Today</p>
                                            </div>
                                        </div>
                                        <div className="single_dash_box">
                                            <div className="dah_color_box color_2">
                                                <img src="/assets/images/thisWeek-img.png" alt="" />
                                            </div>
                                            <div className="pxl_dash_box_content">
                                                <h2>$154</h2>
                                                <p>This week</p>
                                            </div>
                                        </div>
                                        <div className="single_dash_box">
                                            <div className="dah_color_box color_3">
                                                <img src="/assets/images/thisYear-img.png" alt="" />
                                            </div>
                                            <div className="pxl_dash_box_content">
                                                <h2>$154</h2>
                                                <p>This Year</p>
                                            </div>
                                        </div>
                                        <div className="single_dash_box">
                                            <div className="dah_color_box color_4">
                                                <img src="/assets/images/thisMonth-img.png" alt="" />
                                            </div>
                                            <div className="pxl_dash_box_content">
                                                <h2>$154</h2>
                                                <p>This Month</p>
                                            </div>
                                        </div>
                                        <div className="single_dash_box">
                                            <div className="dah_color_box color_5">
                                                <img src="/assets/images/total-img.png" alt="" />
                                            </div>
                                            <div className="pxl_dash_box_content">
                                                <h2>$154</h2>
                                                <p>Total</p>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="pxl_revenueLeftBox">
                                    <div className="px_revenueBox">
                                        <p>Today : <span>$520</span></p>
                                        <p>This week : <span>$520</span></p>
                                        <p>This Month : <span>$520</span></p>
                                        <p>This Year : <span>$520</span></p>
                                        <p>Total: <span>$2080</span></p>
                                    </div>
                                    <div className="pxl_btnright_Dv">
                                        <a  className="pxl_btn">View All</a>
                                    </div>
                                </div> */}
                                {/* </div>
                            </div>
                        </div> */}



                        {/* Trending Templates Section */}
                        <div className="pxl-templates-wrapper pxl-dashboard-appmain-wrap mt-5">
                            <div className="pxl-container-fluid">
                                <div className="pxl-apps-heading">
                                    <h2 className="pxl-font-20-weight-700">Apps</h2>
                                </div>
                                <div className="pxl-dashboard-appWrap">

                                    {
                                        AppsData.map((val, index) => (<>
                                            {val.app != "Builder" &&
                                                <div className="" key={index} onClick={() => {
                                                    {
                                                        val.app != "Builder" && navigate(role === "teamMember" ? val.teamMemNavUrl : val.navigatePath);
                                                    }
                                                }}>
                                                    <div className={`pxl-dashboard-appbox pxl-dashboard-app-color-box${index}`}>
                                                        <div className="pxl-dashboard-box-left">
                                                            <div className="pxl-dashboard-app-img">
                                                                {svg.app[val.icon]}
                                                                {/* <img src="/assets/images/app-schedular-svg.svg"/> */}
                                                            </div>
                                                            <div className="pxl-dashboard-app-content">
                                                                <p>{val.app}</p>
                                                            </div>
                                                        </div>
                                                        <div className="pxl-dashboard-box-right">
                                                            {svg.app.dashboardAppSchedularSvg}
                                                        </div>
                                                    </div>

                                                </div>
                                            }
                                        </>
                                        ))
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="pxl-templates-wrapper pxl-dashboard-appmain-wrap pxl-upComing-box ">
                            <div className="pxl-container-fluid">
                                <div className="pxl-apps-heading">
                                    <h2 className="pxl-font-20-weight-700"><span className="mt-5"> Upcoming</span></h2>
                                </div>
                                <div className="pxl-dashboard-appWrap">
                                    {
                                        AppsData.map((val, index) => (
                                            <div className="" key={index} onClick={() => {
                                                {
                                                    val.app != "Builder" && navigate(role === "teamMember" ? val.teamMemNavUrl : val.navigatePath);
                                                }
                                            }}>
                                                {val.app == "Builder" &&
                                                    <div className={`pxl-dashboard-appbox pxl-dashboard-app-color-box${index}`}>
                                                        <div className="pxl-dashboard-box-left">
                                                            <div className="pxl-dashboard-app-img">
                                                                {svg.app[val.icon]}
                                                                </div>
                                                            <div className="pxl-dashboard-app-content">
                                                                <p>{val.app}</p>
                                                            </div>
                                                        </div>
                                                        <div className="pxl-dashboard-box-right">
                                                            {svg.app.dashboardAppSchedularSvg}
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        ))
                                    }

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>






        </>
    )
};

export default Dashboard;