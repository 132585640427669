import React, { useState, useEffect } from "react";

// const Canvas = dynamic(
//   () => import("../editor/index"),
//   {
//     ssr: false, 
//   }
// );

import { useStore } from "../zustand/store";
import { hitApi } from "../components/commonAction";
import { useParams } from "react-router-dom";
import Canvas from "./images/components/canvas";

let ImagePreview = () => {

  const { id } = useParams();
  const searchParams = new URLSearchParams(window?.location?.search);
  const type = searchParams.get('type');

  let store = useStore((state) => state);
  let filterdata = useStore(state => state.filterCanvas);
  let editorData = useStore((state) => state.editorData);
  editorData = editorData.filter(
    (d1) => d1.type == "text" || d1.type == "image"
  );


  const [load, setload] = useState(false);
  const [check, setcheck] = useState(false);
  const [fixSize, setSize] = useState({});
  const [imageSize1, setImageSize1] = useState("");
  const [bgColor , setbgColor] =useState("")

  const size = {
    Square: {
      width: " 650px",
      height: "650px",
    },
    Landscape: {
      width: "1156px",
      height: "650px",
    },
    Portrait: {
      width: "366px",
      height: "650px",
    },
  };

  const handleEditorSize = (sizeType) => {
    if (sizeType === "Portrait") {
      return "Portrait"
    } else if (sizeType === "Landscape") {
      return "Landscape"
    } else if (sizeType === "Square") {

      return "Square"
    } else if (sizeType === "Custom") {

      return "customratio"
    }
  }

  useEffect(() => {
    if (id) {
      getTemplatedata(id);
    }
    return () => {

    };
  }, [id]);



  const getTemplatedata = async (id) => {

    hitApi(
        {
            method: "GET",
            url: `image/my-designs/get-preview`,
            data: {
              target: id,
              type : type
            },
        },
      async (resp) => {

        
        let records = resp.data.records[0];
        if (records) {
          let data = resp.data.records[0].imageData;

            setbgColor(data.bgColor)
          let siz = data.layout;
          setImageSize1(data.layout);
 
          let d1 = size[siz];
          if (!d1) {
            setSize(data?.dimenstions || data?.dimension);
          } else {
            setSize(d1);
          }
       
          await store.updateStore("filterCanvas", data.filter);
          await store.updateStore(
            "editorData",
            data.data == undefined
              ? []
              : data.data.objects == undefined
                ? []
                : data.data.objects
          );

          setload(true);
        } else {
          setcheck(true);
        }

       
      }
    );
  };
  

  let CSS={
    width: parseInt(fixSize.width),
    height: parseInt(fixSize.height),
    overflow: "hidden",
    background:bgColor,
  }
  if( filterdata && Object.keys(filterdata).length !=0)
  {
    CSS={...CSS,
      ...filterdata.value
    }
  }

  return (
    <>
      {check == true ? (
        <>
          <h1>Page Not found</h1>
        </>
      ) : (
        <div className="ps_img_editor_main_box" style={{overflow: "hidden"}}>
          <div className="conatiner-fluid " style={CSS}>     
                  {load == true ? (
                      <Canvas layout={handleEditorSize(imageSize1)} dimension={fixSize} />
                  ) : (
                    ""
                  )}
            </div>
          </div>
      )}
    </>
  );
};

export default ImagePreview;
