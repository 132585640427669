import hljs from 'highlight.js/lib/core'; 
import 'highlight.js/styles/default.css'; 
import json from 'highlight.js/lib/languages/json'; 
import { useEffect, useRef } from 'react';

hljs.registerLanguage('json', json);

function ExampleResponse({data}) {

    const codeRef = useRef(null);

  useEffect(() => {
    if (codeRef.current) {
      hljs.highlightElement(codeRef.current);
    }
  }, []);

  const jsonString = typeof data == 'string' ? data : JSON.stringify(data, null, 2);

  return (
    <div className="code_Section">
      <pre>
        <code ref={codeRef} className="json">
          {jsonString}
        </code>
      </pre>
    </div>
  );
}

export default ExampleResponse