import { Helmet } from "react-helmet";
import svg from "../pixa-support/components/svg";


import { useLocation, useNavigate, Link } from 'react-router-dom';
import styles from "../pixa-support/Project.module.css"
import { useEffect, useRef, useState } from "react";
import { NoDataFound, commonToast, setMyState, hitApi, teamMemberRestrictionMess } from "../../components/commonAction";

import { common } from "../pixa-support/helper/Common";
import { Tooltip } from "@mui/material";
import { useStore } from "../../zustand/store";
import Skeleton from '@mui/material/Skeleton';
import { Modal } from 'react-bootstrap';
import ConfirmationPopup from '../../components/common/ConfirmationPopup';
import appConfig from "../../config/appConfig";
// import useUserStore from '@/store/userStore';



const Projects = ({ isTrash = false, displayButtons, action, updateAction, checkAll = false, chackAllAction = false ,pageTitle}) => {
    const navigate = useNavigate();
    let userData = useStore(state => state)['userData'];
    let { role, scope } = userData;

    const [name, setName] = useState('');
    const [changeBtn, setChangeBtn] = useState(false);
    const [projectList, setProjectList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [shownoData, setShowNoData] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showConfiModal, setShowConfiModal] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [logoURL, setLogoURL] = useState('');
    const [logoFile, setLogoFile] = useState('');
    const [faviconURL, setFaviconURL] = useState('');
    const [faviconFile, setFaviconFile] = useState('');
    const [keywords, setKeywords] = useState('');
    const [isError, setIsError] = useState(false);
    const [customerProject, setCustomerProject] = useState('');
    const [targetId, setTargetId] = useState();
    const [showRestorePopup, setShowRestorePopup] = useState(false);
    const [metaInfo, setMetaInfo] = useState('');
    const [isbtn, setIsbtn] = useState(false);
    const [cPage, setCPage] = useState(1);
    const [isSkeletonLoader, setIsSkeletonLoader] = useState(false);


    const containsBuilderWrite = (role === "teamMember") ? scope?.builder?.builder?.includes("write") : (role === "client") ? false : true;
    const containsBuilderDelete = (role === "teamMember") ? scope?.builder?.builder?.includes("delete") : (role === "client") ? false : true;

    const [state, setState] = useState({
        // limit: 12,
        // page: 1,
        // sort: 'createdAt=1',
        // keyword: '',
        // isLoading: false,
        // totalCount: 0,
        // loadData: false,
        isDataload: false,
        // totalRecord: '',
        // mediaType: 'image',
        removeMe: [],
        restoreMe: [],
        isLoad: false,
        btnLoader: false,
        localCheckAll: false,
        // selectAllActive: false,
        selectedPost: [],
        // draftList: [],
        selectedAction: ''
    });
    useEffect(() => {
        if (action == 'Delete') {

            setMyState(setState, { removeMe: state.selectedPost })
        } else if (action == 'Restore') {
            setMyState(setState, { restoreMe: state.selectedPost })
        }
    }, [action])

    useEffect(() => {
        if (isTrash) {
            if (checkAll) {
                setMyState(setState, { selectedPost: projectList });
                displayButtons(projectList.length);
            } else {
                setMyState(setState, { selectedPost: [] });
                displayButtons(0);
            }
        }
    }, [checkAll])

    useEffect(() => {
        if (isTrash) {
            if (state.projectList?.length > 0) {
                if (state.projectList?.length == state.selectedPost?.length) {
                    chackAllAction(true)
                } else {
                    chackAllAction(false)
                }
            }
        }
    }, [state.selectedPost])
    const handleSelectPost = (val, post) => {
        if (val) {
            setMyState(setState, { selectedPost: [...state.selectedPost, post] })
            isTrash && displayButtons([...state.selectedPost, post].length)
        } else {
            const arrCopy = Array.from(state.selectedPost);
            const objWithIdIndex = arrCopy.findIndex((obj) => obj._id === post._id);
            arrCopy.splice(objWithIdIndex, 1);
            setMyState(setState, { selectedPost: arrCopy })
            isTrash && displayButtons(arrCopy.length);
        }
    }

    const handleShow = () => {
        setIsbtn(false);
        setShowModal(true);
    }
    const handleClose = () => {
        setShowModal(false);
    }

    // const handleConfiShow = () => {
    //     setShowConfiModal(true);
    // }
    // const handleConfiClose = () => {
    //     setShowConfiModal(false);
    // }

    const fetchProjects = async (page, listPerPage = perPage, search = searchTerm, type) => {
        console.log("loadMore", type)
        if (type == 'loadMore') {
            // setProjectList(...projectList)
            setLoading(true)
        } else {
            // setProjectList("");
            setIsSkeletonLoader(true)
            // setMyState(setState, {...state, isSkeletonLoader:true,openCreatePopUp:false });
        }


        // console.log(page, listPerPage, type, perPage);
        let curPage = (type == 'loadMore') ? page + 1 : 1;
        //    setCPage(curPage)
        let data = {};
        if (isTrash) {
            // setLoading(true)
            data['isTrash'] = true
        }
        data['keyword'] = search;
        data['limit'] = listPerPage;
        data['page'] = curPage;

        // setLoading(true)
        hitApi({
            method: 'GET',
            url: 'page-builder/get-projects',
            data: data,
            loading: true,
            alert: false
        }, (resp) => {
            if (resp.status) {


                if (resp.data?.records.length == 0) {
                    setShowNoData(true);
                } else {
                    setShowNoData(false);
                }
                let newList = type == 'loadMore' ? [...projectList, ...resp.data.records] : resp.data.records;
                setProjectList(newList);
                setLoading(false);
                setIsSkeletonLoader(false);
                setTotalRows(resp?.data?.totalRecords);
                setIsError(false);

                // setMyState(setState, { draftList: resp.data.records, totalRecord: resp.data.totalRecords });
            } else {
                setIsError(true);
                setIsSkeletonLoader(false);
                setLoading(false);
                setShowNoData(true);
            }
        });

    };

    useEffect(() => {
        fetchProjects();
    }, []);

    const handleSearchKeyupEvent = async (e) => {
        let t = e.target;
        let searchTerm = t.value;
        setSearchTerm(searchTerm);
        if (e.keyCode === 13) {
            fetchProjects(1);
        }
    }
    let clearSearch = (e) => {
        setSearchTerm('');
        fetchProjects(1, perPage, '');
    }

    const saveMetaData = (e) => {
        e.preventDefault();
        if (!title) {
            commonToast('error', `Title is required!`);
            return false;
        }
        setChangeBtn(true);
        hitApi({
            method: 'PATCH',
            url: 'page-builder/update-project',
            data: {
                target: targetId,
                title: "meta builder",
                meta: {
                    title: title,
                    keywords: keywords
                }
            },
            loading: true
        }, (resp, err = null) => {
            if (resp.status === true) {
                setChangeBtn(false);
                fetchProjects(1);
                setShowModal(false);
            }
        });

    }
    /* create project end */

    // outside click menu close start
    // let i = 0;
    // const menuRef = useRef();
    // common.useOutsideClick(menuRef, () => {
    //     i++;
    //     if (i > 1) {
    //         // setMenuShow('');
    //     }
    // });

    // outside click menu close end
    // if (userData.role === -1 || isError) {
    //     return <Errorpage />
    // } else {

    useEffect(() => {
        document.body.classList.add('pxl-body-white');
        return () => {
          document.body.classList.remove('pxl-body-white');
        };
      }, []);
    return (
        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>
          <div className="pxlBuilder-mainBox">
          <div className="sch-container-fluid">
                <div className={`pxl-builder-projectWrapper pxlBuilderMain-wrapper `}>
                    {!isTrash && <div className='dash_head dash_head_topNone pxl-builder-project-heading'>

                        <h3>
                            {"Projects (" + totalRows + ")"}
                        </h3>


                        <div className="sch-search-section">
                            <div className={`sch-search-row sch-search-bg-color`}>
                                <input placeholder="Search Here..."
                                    type='text'
                                    value={searchTerm}
                                    onChange={e => {
                                        setSearchTerm(e.target.value)
                                    }}
                                    onKeyUp={handleSearchKeyupEvent}
                                />
                                <button type="button" className="sch-search-btn">
                                    <img src="/assets/images/scheduler/search.svg" />
                                </button>
                            </div>

                        </div>


                    </div>}

                    <div className={`${styles.xs_projects_wr} ${styles.xs_projectModule} `}>
                        {projectList && isSkeletonLoader ? <>
                            {
                                [1, 2, 3, 4].map(x => <div key={x}>
                                    <div className={styles.skeleton_uperbox} >
                                        <Skeleton animation="wave" variant="circular" sx={{ width: { xl: 90, md: 60, xs: 40 }, height: { xl: 90, md: 60, xs: 40 } }} />
                                        <div className={styles.skeleton_text}>
                                            <Skeleton variant="text" sx={{ width: { xl: 230, md: 100, xs: 90 } }} height={10} />
                                            <Skeleton variant="text" sx={{ width: { xl: 230, md: 100, xs: 90 } }} height={10} />
                                        </div>
                                    </div>
                                    <Skeleton animation="wave" variant="rectangular" height={143} />
                                </div>)}
                        </> :

                            (projectList.length > 0) && projectList.map((project, index) => (
                                <div key={project._id} data-index={index} className={`${styles.xs_projects_box} ${isTrash ? "pxl-builderTrash" : ''} ${state.selectedPost.some((obj) => obj._id === project._id) && 'active_post'}`} >


                                    {isTrash && <div className={`sch-custom-check-box cun-select ${styles.sch_hide_checkbox} ${isTrash ? '' : styles.sch_topSpace_project}`}
                                        style={{ opacity: state.selectedPost.length > 0 && 1 }}>
                                        <div className="form-group">

                                            <input type="checkbox"
                                                id={project._id}
                                                checked={state.selectedPost.some((obj) => obj._id === project._id)}
                                                onChange={(e) => handleSelectPost(e.target.checked, project)}
                                            />
                                            <label
                                                htmlFor={project._id}
                                            ></label>
                                        </div>
                                    </div>}
                                    <div className={styles.xs_project_name}>

                                        <div>
                                            <h3>{project.title}</h3>
                                            <p>Created - {common.dateFormatter(project.createdAt)}</p>
                                        </div>
                                        {/* </Link> */}
                                        <div className={styles.xs_project_icons_sec}>
                                            <ul>

                                                <li>
                                                    {!isTrash && <div className={styles.xs_project_menu_btns}>

                                                        {/* https://builder.pixalab.ai/editor/PROJECT_ID?t=USER_TOKEN */}
                                                        <Tooltip title={containsBuilderWrite ? "Edit Project" : teamMemberRestrictionMess(containsBuilderWrite)} placement="top" arrow>
                                                            {containsBuilderWrite ? <a href={appConfig.builderUrl + 'editor/' + project._id + '?t=' + userData.token} target="_blank">{svg.dt_edit_icon}</a> :
                                                                <a>{svg.dt_edit_icon} </a>
                                                            }
                                                            {/* onClick={() => navigate(`/pixa-support/edit-project?id=${project._id}`)} */}
                                                        </Tooltip>
                                                    </div>}
                                                </li>
                                                <li>
                                                    {!isTrash && <div className={styles.xs_project_menu_btns}><Tooltip title={containsBuilderWrite ? "Meta Settings" : teamMemberRestrictionMess(containsBuilderWrite)} placement="top" arrow>
                                                        <a onClick={() => containsBuilderWrite && navigate(`/builder/edit-project?id=${project._id}`)}>

                                                            {/* <a onClick={(e) => { */}
                                                            {/* // handleShow();
                                // setTargetId(project._id);
                                // setKeywords(project?.meta?.keywords ? project?.meta?.keywords : "");
                                // setMetaInfo(project?.meta?.title == undefined);
                                // setTitle(project?.meta?.title ? project?.meta?.title : ""); */}
                                                            {/* }}> */}
                                                            {svg.settingWhiteSvg}



                                                        </a>
                                                    </Tooltip></div>}
                                                </li>

                                                <li>
                                                    {isTrash && <div className={styles.xs_project_menu_btns}> <Tooltip title="Restore" placement="top" arrow>
                                                        <a onClick={() => {
                                                            // setShowRestorePopup(true);
                                                            // setTargetId(project._id)
                                                            setMyState(setState, { restoreMe: [project] })

                                                        }}>
                                                            {svg.tickets_restore}
                                                        </a>
                                                    </Tooltip></div>}

                                                </li>

                                                <li><div className={styles.xs_project_menu_btns}>
                                                    <Tooltip title={containsBuilderDelete ? "Delete" : teamMemberRestrictionMess(containsBuilderDelete)} placement="top" arrow>
                                                        <a onClick={(e) => {
                                                            if (containsBuilderDelete) {
                                                                // setShowConfiModal(true);
                                                                // setTargetId(project._id);
                                                                setMyState(setState, { removeMe: [project] })

                                                            }
                                                        }}>
                                                            {svg.deleteWhiteSvg}
                                                        </a>
                                                    </Tooltip></div>
                                                </li>



                                            </ul>

                                        </div>
                                    </div>
                                    {/* <span className={styles.xs_project_line}></span> */}
                                    <div className={`${styles.xs_project_details_main} ${isTrash ? "pxl-builderTrash-box" : ''}`}>
                                        <div className={styles.xs_project_details}>
                                            <div className={styles.xs_project_details_left}>
                                                <p>Meta title</p>
                                            </div>
                                            <div className={styles.xs_project_details_right_link}>
                                                {project.domain_name ?
                                                    <Link target="_blank" className={styles.support_link} href={project.domain_type === 1 ? 'https://' + project.domain_name : 'https://' + project.domain_name}>{project.domain_type === 1 ? project.domain_name : 'https://' + project.domain_name}</Link>
                                                    : <i style={{ color: '#F44336' }}><p>{project?.meta?.title ? project?.meta?.title : ""}</p></i>
                                                }
                                            </div>
                                        </div>
                                        <div className={styles.xs_project_details}>
                                            <div className={styles.xs_project_details_left}>
                                                {/* <p>Agents</p> */}
                                                <p>Keywords</p>
                                            </div>
                                            <div className={styles.xs_project_details_right}>
                                                <div className={styles.xs_project_avatar_list}>
                                                    <p>{project?.meta?.keywords ? project?.meta?.keywords : ""}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.xs_project_details}>
                                            <div className={styles.xs_project_details_left}>
                                                <p>Open Tickets</p>
                                            </div>
                                            <div className={styles.xs_project_details_right}>
                                                <p>{project.open_tickets_count}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }



                        {
                            loading ? [1, 2, 3, 4].map(x => <div key={x}>
                                <div className={styles.skeleton_uperbox} >
                                    <Skeleton animation="wave" variant="circular" sx={{ width: { xl: 90, md: 60, xs: 40 }, height: { xl: 90, md: 60, xs: 40 } }} />
                                    <div className={styles.skeleton_text}>
                                        <Skeleton variant="text" sx={{ width: { xl: 230, md: 100, xs: 90 } }} height={10} />
                                        <Skeleton variant="text" sx={{ width: { xl: 230, md: 100, xs: 90 } }} height={10} />
                                    </div>
                                </div>
                                <Skeleton animation="wave" variant="rectangular" height={143} />
                            </div>) : null
                        }

                    </div>

                    {shownoData ?
                        <>
                            <NoDataFound title={'No data found.'} bgRemove={true} />

                        </>
                        : null
                    }
                </div>
            </div>
          </div>
            {
                projectList?.length < totalRows &&
                <div className="loadmorebtn">
                    <a
                        className={`sch-loadmoreBtn ${projectList?.length >= totalRows ? 'pxl-button-disable' : ''}`}
                        onClick={() => {
                            fetchProjects(cPage, perPage, "", 'loadMore');
                        }}
                    >{loading ? <span>Loading...</span> : <span>Load More</span>}</a>
                </div>
            }
            <Modal
                show={showModal}
                onHide={handleClose}
                animation={false}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_mainWrapper "
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Meta Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=''>

                        <div className='proj_Setting_first_flex'>
                            <div className="pxl_input_feilds pxl-placeholder-color1">
                                <label>Title</label>
                                <input type="text" name="title" placeholder="Enter Title" onChange={(e) => setTitle(e.target.value)} value={title} />
                            </div>
                            <div className="pxl_input_feilds pxl-placeholder-color1">
                                <label>Keywords</label>
                                <input type="text" name="keywords" value={keywords} onChange={(e) => setKeywords(e.target.value)} placeholder="Enter Keywords" />
                            </div>
                            {/* <div className="pxl_input_feilds pxl-placeholder-color1">
                                <label>Description</label>
                                <input type="text" name="description" placeholder="Enter Description" value={description} onChange={(e) => setDescription(e.target.value)} />
                            </div> */}
                            {/* <div className="pxl_input_feilds">
                            <label>Logo ( max 250x80)</label>
                            <div className="pxl-custom-file-upload pxl-upload-file-profile">
                                <div className="pxl-custom-file-select">
                                
                                    {logoURL ? <div className='image_url_wrap'> <img src={logoURL} width={'60px'} />
                                     <a className="img_Cross" onClick={() => {
                                        setLogoURL('');
                                        setLogoFile('')
                                     }}>
                                            {svg.crossSvg1}
                                        </a></div>

                                        : <>
                                            <div className="pxl-custom-file-select-button" id="fileName">
                                                <img src="/assets/images/newpost/file-upload1.svg" alt="" />
                                            </div>
                                            <div className="pxl-custom-file-select-name" id="noFile">
                                                <span> <b>Upload</b> <span className='pxl-upload-file-text'>or drop your file here</span> </span>
                                            </div>
                                            <input type="file" name="chooseFile" id="chooseFile" onChange={(e) => uploadLogoImage(e)} />
                                        </>

                                    }

                                </div>
                            </div>
                        </div> */}
                            {/* <div className="pxl_input_feilds">
                            <label>Favicon ( max 32x32)</label>
                            <div className="pxl-custom-file-upload pxl-upload-file-profile">
                                <div className="pxl-custom-file-select">
                                    {faviconURL ? <div className='image_url_wrap'> <img src={faviconURL} width={'60px'} />
                                    <a className="img_Cross" onClick={() => {
                                        setFaviconFile('');
                                        setFaviconURL('')
                                     }}>
                                            {svg.crossSvg1}
                                        </a>
                                    </div> : <>
                                        <div className="pxl-custom-file-select-button" id="fileName">
                                            <img src="/assets/images/newpost/file-upload1.svg" alt="" />
                                        </div>
                                        <div className="pxl-custom-file-select-name" id="noFile">
                                            <span> <b>Upload</b> <span className='pxl-upload-file-text'>or drop your file here</span> </span>
                                        </div>
                                        <input type="file" name="chooseFile" id="chooseFile" onChange={(e) => uploadFaviconImage(e)} />
                                    </>}
                                </div>
                            </div>
                        </div> */}


                        </div>


                        <div className='save_btn'>
                            <button className="sch-btn" onClick={(e) => saveMetaData(e)} >{metaInfo ? !changeBtn ? 'Save' : 'Processing...' : !changeBtn ? 'Update' : 'Processing...'} </button>
                        </div>

                        {/* </form> */}
                    </div>

                </Modal.Body>

            </Modal>



            {/* <ConfirmationPopup
                shownPopup={showRestorePopup}
                closePopup={() => {
                    setShowRestorePopup(false)
                }}
                type={"project"}
                btnLoader={isbtn}
                title={`Are you sure you want to restore this project ?`}
                subTitle={'This item will be restore immediately.'}
                yesBtn={"Yes, Restore"}
                removeAction={() => {
                    let data = {}
                    data['target'] = targetId
                   
                    setIsbtn(true)

                    hitApi({
                        url: `page-builder/recover`,
                        method: "PATCH",
                        data: data,
                    }, (resp, err = null) => {
                        setShowRestorePopup(false);
                        fetchProjects();
                        setIsbtn(false)


                    })
                }}
            /> */}
            {/* Restore PopUp */}
            <ConfirmationPopup
                shownPopup={state.restoreMe.length}
                closePopup={() => {
                    setMyState(setState, {
                        restoreMe: [],
                        selectedPost: [],
                        localCheckAll: false,
                        selectedAction: ''
                    })
                    isTrash && updateAction('');
                    isTrash && displayButtons(0);
                }}
                type={"project"}
                title={`Are you sure you want to restore ${state.restoreMe.length} ${state.restoreMe.length > 1 ? "projects" : "project"} ?`}
                subTitle={'This item will be restore immediately.'}
                yesBtn={"Yes, Restore"}
                btnLoader={state.isDataload}

                removeAction={() => {
                    let data = {}
                    const idArray = state.restoreMe.map((item) => item._id);

                    data['target'] = idArray
                    setMyState(setState, { isDataload: true })

                    hitApi({
                        url: `page-builder/recover`,
                        method: "PATCH",
                        data: data,
                    }, (resp, err = null) => {
                        setMyState(setState, {
                            restoreMe: [],
                            selectedPost: [],
                            isDataload: false

                        })
                        isTrash && updateAction('');
                        isTrash && displayButtons(0);
                        if (resp.status) {
                            setShowRestorePopup(false)
                            fetchProjects();

                            setMyState(setState, { isLoad: !state.isLoad });
                        }
                    })
                }}
            />


            {/* Delete Pop Up */}
            <ConfirmationPopup
                shownPopup={state.removeMe.length}
                closePopup={() => {
                    setMyState(setState, {
                        removeMe: [],
                        selectedPost: [],
                        localCheckAll: false,
                        selectedAction: ''
                    })
                    isTrash && updateAction('');
                    isTrash && displayButtons(0);
                }}
                type={"project"}
                title={isTrash ? `Are you sure you want to delete ${state.removeMe.length} ${state.removeMe.length > 1 ? "projects" : "project"} ?` : `Do you want to move project to trash?`}
                subTitle={isTrash ? '' : 'Items can be restored from trash in next 15 days.'}
                yesBtn={isTrash ? "Yes, Delete" : "Yes, Move"}
                btnLoader={state.isDataload}

                removeAction={() => {
                    let data = {}
                    const idArray = state.removeMe.map((item) => item._id);
                    if (isTrash) {
                        data[`target`] = idArray.join(',');
                    } else {
                        data[`target`] = idArray
                    }
                    setMyState(setState, { isDataload: true })

                    hitApi({
                        url: isTrash ? `page-builder/remove-project` : `page-builder/move-to-trash`,
                        method: isTrash ? "DELETE" : "PATCH",
                        data: data,
                    }, (resp, err = null) => {
                        setMyState(setState, {
                            removeMe: [],
                            selectedPost: [],
                            isDataload: false
                        })
                        isTrash && updateAction('');
                        isTrash && displayButtons(0);
                        if (resp.status) {
                            fetchProjects();

                            setMyState(setState, { isLoad: !state.isLoad });
                        }
                    })
                }}
            />


        </>
    )

}

export default Projects;