import React, { useEffect, useState } from 'react'
import ExampleRequest from './ExampleRequest';
import ExampleResponse from './ExampleResponse';
import appConfig from '../../config/appConfig'
import { convertObjToQueryString, objectToFormData } from '../../components/commonAction'

function MiddleSection({ data, apiData }) {

    const [tryState, setTryState] = useState(false);
    const [sampleResp, setSampleResp] = useState({});

    useEffect(() => { 
        const handleScroll = () => { 
            const fileSections = document.querySelectorAll('.file-section');

            fileSections.forEach(section => {
                const rect = section.getBoundingClientRect();
                  
                if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
                    const fileId = section.getAttribute('id');
                    window.history.replaceState({}, '', `#${fileId}`);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    let tryApi = () => {
      
        let { body, url } = tryState;

        if (['get', "GET"].includes(tryState.method)) {
            if(Object.keys(tryState.body)[0] != ''){
                let qs = convertObjToQueryString(tryState.body);
                url = url + `?${qs}`;
            }
            
        } else {
            body = JSON.stringify(body);
        }
         
        if(tryState?.query){
            if(Object.keys(tryState.query)[0] != ''){
                let qs = convertObjToQueryString(tryState.query);
                url = url.includes('?') ?    url + `&${qs}`   :  url + `?${qs}`; 
            }
           
        }
       
           let  data = {
                headers: Object.keys(tryState.headers)[0] != '' ? tryState.headers : {},
                method: tryState.method,
                body
            }
       

        
        
        if(tryState?.isFormData){
            data.body = objectToFormData(tryState.body);
        }
            data.headers['Content-Type'] = tryState?.isFormData ? 'multipart/form-data' : "application/json"
       
 
        if (['get', "GET",'DELETE'].includes(tryState.method)){
            delete data.body
        }
       
            fetch(url, data)
                .then(res => res.json())
                .then(resp => {
                    setSampleResp({...sampleResp,[tryState.apiId]:resp});
                })
    }
   
    let apiHtml = (val, i, isEdit) => {
        return <div className='api-d-flex file-section' id={`${val?.apiId}`} key={i}>
            <div className='api_content_wrapper' key={i}>



                <h3 className='api_p-28 api_name'>{val.title} </h3>

                <div className='api_request_wrap api_p-28'>
                    <div>Request</div>
                    {
                        isEdit ? <>
                            <button className='api-btn api_cancel_btn' onClick={() => {
                                // setSampleResp(false);
                                setTryState(false);
                            }}>Cancel<span> </span> </button>
                            <button className='api-btn api_send_btn' onClick={e => tryApi()}>Send Request <span> </span></button>
                        </>
                            :
                            <button className='api-btn api_try_btn' onClick={() => {
                                let bodyData = {}, headerData = {},queryData = {};

                                if (val?.request?.body?.length) {
                                    val?.request?.body?.map(d => {
                                        bodyData[d.key] = '';
                                    })
                                }
                                if (val?.request?.query?.length) {
                                    val?.request?.query?.map(d => {
                                        queryData[d.key] = '';
                                    })
                                }
                                if (val?.request?.headers?.length) {
                                    val?.request?.headers?.map(d => {
                                        headerData[d.key] = d.value;
                                    })
                                }

                                setTryState({
                                    apiId: val.apiId,
                                    url: appConfig.apiUrl + val?.request?.url,
                                    method: val?.request?.method,
                                    body: bodyData,
                                    headers: headerData,
                                    query :queryData
                                });
                            }}>Try It Out<span> </span> </button>
                    }



                </div>

                <p className='api_method_type'> <span>{val?.request?.method}</span> <b>{val?.request?.url}</b> </p>
                {
                    val?.request?.headers?.length > 0 && val?.request?.headers[0].key && <>
                        <div className='api_p-28 api_head_body'><h5>Header</h5></div>
                        {
                            val?.request?.headers.map((headers, i) => <div className='api_content_text_wrap api_p-28  mb-5' key={i}>
                                <h5>{headers.key}<span>{headers.value}</span> </h5>

                                {isEdit && <input placeholder={headers.key} value={tryState?.body[headers.key]} onChange={e => {
                                    let ttt = { ...tryState };
                                    ttt.headers[headers.key] = e.target.value;
                                    setTryState({ ...ttt });
                                }} />}
                            </div>)
                        }
                    </>
                }

                  {
                    val?.request?.query?.length > 0 && val?.request?.query[0].key && <>
                        <div className='api_p-28 api_head_body'><h5>Query</h5></div>
                        {
                            val?.request?.query.map((query, i) => <div className='api_content_text_wrap api_p-28  mb-5' key={i}>
                                <h5>{query.key}<span>{query.example}</span> </h5>

                                {isEdit && <input placeholder={query.key} value={tryState?.query[query.key]} onChange={e => {
                                    let ttt = { ...tryState };
                                    ttt.query[query.key] = e.target.value;
                                    setTryState({ ...ttt });
                                }} />}
                                <div>Example : {query.description}</div>
                            </div>)
                        }
                    </>
                }

                {
                    val?.request?.body?.length > 0 &&  val?.request?.body[0].key && <>
                        <div className='api_p-28 api_head_body'><h5>Body</h5></div>

                        {
                            val?.request?.body.map((body, i) => <div className='api_content_text_wrap api_p-28  mb-5' key={i}>
                                <h5>
                                    {body.key}
                                    <span>{body.type}</span>
                                    <span>{body.require ? "( Required )" : "( Optional )"}</span>
                                </h5>
                                {isEdit ? body.type == 'file' ?    
                                 <input type='file' placeholder={body.key} className='mb-1'  onChange={e => {
                                    let tt = { ...tryState };
                                    tt.isFormData= true ;
                                    tt.body[body.key] = e.target.files[0];
                                    setTryState({ ...tt });
                                }} />
                                
                                :
                                <input type='text' placeholder={body.key} className='mb-1' value={tryState?.body[body.key]} onChange={e => {
                                    let tt = { ...tryState };
                                    tt.body[body.key] = e.target.value;
                                    setTryState({ ...tt });
                                }} /> : ''}
                                <div>Example : {body.description}</div>
                            </div>)
                        }
                    </>
                }

            </div>

            {/* RIght DArk Section */}
            <div className='api_right_wrapper'>
                <div className='seprator'></div>
                <div className='api_example_wrap'>
                    <div className='head'>Example Request</div>
                    <ExampleRequest data={val.request} />
                </div>

                <div className='api_example_wrap'>
                    <div className='head'>Example Response</div> 
                    <ExampleResponse data={sampleResp[val.apiId] ? sampleResp[val.apiId] : val.response}  />
                </div>
            </div>
        </div>
    }

    if (data?.length > 0) {
        return (<>
            {
                data.map((val, i) => {
                    if (val?.folders.length > 0) {

                        return <><div key={i} id={val.folderId}><MiddleSection data={val.folders} apiData={val.apis} /> </div>

                            {/* {
                            val.apis.length > 0 && val.apis.map((val, i) => {
                                let isEdit = tryState && tryState?.apiId == val.apiId;
                                return (
                                <>{apiHtml(val,i,isEdit)}</>)
                                }
                            )
                          } */}
                        </>

                    } else {
                        return (<>

                            {
                                apiData.map((val, i) => {
                                    let isEdit = tryState && tryState?.apiId == val.apiId;
                                    return (
                                        <>{apiHtml(val, i, isEdit)}</>)
                                }
                                )
                            }
                            <div className='api_content_main_heading'>{val.title}</div>
                            {
                                val.apis.map((val, i) => {
                                    let isEdit = tryState && tryState?.apiId == val.apiId;
                                    return (
                                        <>{apiHtml(val, i, isEdit)}</>)
                                }
                                )
                            }


                        </>
                        )

                    }
                })

            }
        </>)

    }

    if (apiData.length > 0) {
        return (<>
            {
                apiData.map((val, i) => {
                    let isEdit = tryState && tryState?.apiId == val.apiId;
                    return (
                        <>{apiHtml(val, i, isEdit)}</>)
                }
                )
            }
        </>
        )
    }
}

export default MiddleSection