import { useRef, useState } from "react";
import { assetsUrlPath } from "../../../components/commonAction";
import svg from "../../../components/svg";

const AudioPlayer = ({ media }) => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
   
    const togglePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const mouseLeave = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            }
            setIsPlaying(false);
        }
    }


    return (

        <div className="sch-audioWrpa" onMouseLeave={e => mouseLeave()}>
            <audio
                // controls
                ref={audioRef}
                src={assetsUrlPath(media.files.original.path)} alt=""
            />
            <span className={`sch-audioPlay ${isPlaying && 'sch-audioPause'}`}>
                <a onClick={togglePlayPause}>
                    {isPlaying ? svg.app.PauseSvg : svg.app.PlaySvg}
                </a>
            </span>
        </div>
    );
};
export default AudioPlayer;