import { useEffect, useState } from "react";
import { commonToast, hitApi } from "../../../components/commonAction";
import { useNavigate,Link ,useLocation} from 'react-router-dom';


function InstallScript({p_id,setActiveTabMenu}) {

  const [script, setScript] = useState('');
 

  useEffect(() => {
     getWidgetScript(p_id)
  }, [p_id])

  const getWidgetScript = (id) => {
      if (id) {
        hitApi({
          url: 'pixa-support/api/getProjectScript',
          method: "POST",
          data: {
            project_id: id
        },
          loading: true,
          alert: false
      }, (resp, err = null) => {
        if (resp.status === 1) {
          setScript(resp.url)
        
      }
      });
         
      }
  }

  const handleCopyScript = () => {
      navigator.clipboard.writeText(script);
      commonToast('success', `Script copied to clipboard!`);
      
  }


    // let script = `<div id="pixasupport" className="e0c3920c50ca16cbf1f37d2d2338d0b51a092cfca449f3e1c9d3637995c54097"> <script src="https://api.pixasupport.com/project_widget.js"></script></div>`
  return (
    <div className='sup_proj_setting_form install_script_wrap'>
        <h5>{`Place this script before the closing </body> tag on your website where you'd like your chat widget to appear for this project.`}</h5>
        <div className='sup_script_text'>
            <p>{script}</p>
        </div>
        <div className='pxlInstall-script-btns'>
        <button onClick={()=>{setActiveTabMenu('widget')}}   className="pxlCommon-btn">Back</button>
        <button className="pxlCommon-btn" onClick={handleCopyScript}>Copy To Clipboard</button>
        
        {/* <Link to={"/pixa-support/projects"}  className="pxlCommon-btn">Go to project</Link> */}
        </div>
       

    </div>
  )
}

export default InstallScript