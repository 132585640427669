
import { useState } from "react";
import schedulerSvg from "./schedulerSvg";
import { commonToast, hitApi, setMyState } from "../../../components/commonAction";

const AiImage = () => {
    const [state, setState] = useState({
        prompt: '',
        orientation: 'square',
        generatedImage: '',
        loading: false,
    })

    let orientationArray = [
        { path: '/assets/images/newpost/aspect-ratio1.svg', name: 'square' },
        { path: '/assets/images/newpost/aspect-ratio2.svg', name: 'landscape' },
        { path: '/assets/images/newpost/aspect-ratio3.svg', name: 'portrait' }
    ]

    const generateAIImage = () => {


        let { prompt, orientation } = state;

        if (!prompt) {
            commonToast('error', `Prompt is required.`);
            return false
        }
        setMyState(setState, { loading: true });
        hitApi({
            url: 'ai-services/generate-image',
            method: "POST",
            data: { prompt, orientation },
        }, (resp, err = null) => {
            setMyState(setState, { loading: false });
            if (resp.status) {
                setMyState(setState, { generatedImage: resp.data.image });

            }
        })
    }

    return (
        <div className="sch-ai-image-wrapper">
            <div className="sch-textareaSection">
                <h2 className="sch-textfont16">Create an image from text prompt</h2>
                <textarea placeholder="Describe What You Want To See" onChange={(e) => setMyState(setState, { prompt: e.target.value })}></textarea>
                <div className="sch-img-promt-sample">
                    <a><span>{schedulerSvg.app.prompt}</span>Prompt Sample</a>
                </div>
            </div>
            <div className="sch-aspect-ration">
                <h2 className="sch-textfont16">Aspect Ratio</h2>
                <ul>
                    {orientationArray.map((val,i) => (
                        <li key={i} onClick={() => setMyState(setState, { orientation: val.name })} className={state.orientation == val.name ? "sch-active-aiBox" : null}>
                            <a>
                                <img src={val.path} alt="" />
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="sch-ai-image-button">
                <button disabled={state.loading} onClick={generateAIImage} className="pxl_btn">{state.loading ? 'Processing...' : 'Generate AI Image'}</button>
            </div>

            {state.generatedImage && <div className="sch-aspect-ration">
                <ul>
                    <li>
                        <a>
                            <img src={state.generatedImage} alt="" />
                        </a>
                    </li>
                </ul>
            </div>}
        </div>
    )
}
export default AiImage;