import schedulerSvg from '../components/schedulerSvg';
const Title = ({title,onClick,linkTitle,uri, hasTitleLink, titleRight}) => {
  return (
    <>
     <div className="sch-title-wrap">
          <h2>{title}</h2>
          {
              hasTitleLink  &&
              <a href={uri} className="sch-title-link">
                {linkTitle}
                <span>{schedulerSvg.app.arrowRight}</span>
              </a>
          }
          <h3><a href={void(0)}>{titleRight} {schedulerSvg.app.arrowRight}</a></h3>
        </div>

    </>
  );
};
export default Title;