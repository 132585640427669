const PrivacyPolicy= ()=> {
   
    return <div className='cun-no-design-wrapper'>
        <div className="cun-noDesign-inner">
            {/* <img src="/assets/images/no-design-img.png" alt="" /> */}
            <h2>Privacy Policies</h2>
            <h2>Privacy Policies</h2>
            <div className="scun-noImg-btn">
                   Welcome to Template Bundle (the "Site").
                    We understand that privacy online is important to users of our Site,<br/> especially when conducting business. This statement governs our privacy policies with respect to those users of the Site ("Visitors") who visit without transacting business and Visitors who register to transact business on <br/>the Site and make use of the various services offered by Template Bundle (collectively, "Services") ("Authorized Customers").
            </div>
                    <h2>Personally Identifiable Information</h2>
            <div className="scun-noImg-btn">
                    refers to any information that identifies or can be used to identify, <br/>contact, or locate the person to whom such information pertains, including, but not limited to, name, address, phone number, fax number, email address, financial profiles, social security number, and credit card information.<br/> Personally Identifiable Information does not include information that is collected anonymously (that is, without identification of the individual user) or demographic information not connected to an identified individual.
               
            </div>
        </div>
    </div>
}
export default PrivacyPolicy;