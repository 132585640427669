import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import NotFound from "./404";
import Dashboard from "./Dashboard";
import MyLibrary from "./my-library";
import Profile from "./Profile";
import Social from "./Social";
import Autoresponder from "./Autoresponder";
import EmailVerification from "./EmailVerification";
import Login from "./Login";
import { GoogleOAuthProvider } from '@react-oauth/google';
import Test from '../pages/admin/test'
import Trash from "./Trash";
import HomeTest from "./test";

import AdminRouter from "./admin/AdminRouter";
import SchedulerRouter from "./scheduler/SchedulerRouter";
import PixaSupportRouter from "./pixa-support/pixaSupportRouter";
import ImagesRouter from "./images/ImagesRouter";
import ImagePreview from "./imagePreview";
import appConfig from "./../config/appConfig";
import APIEndPoint from "./api-endpoint";
import TeamMember from "./TeamMember";
import Client from "./Client";
import Brand from "./brand";
import TicketCreate from "./pixa-support/projectTickets/TicketCreate";
import BuilderRouter from "./pageBuilder/builderRouter";
import PrivateRoute from "./PrivateRoute";
import TermsConditions from "./TermsConditions";
import PrivacyPolicy from "./PrivacyPolicy";
// pixalab-ai/src/pages/
export default function MainRoutes() {
    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route path="/" element={<Login pageTitle={`${appConfig.appName} | Login`} />} />
                        <Route path="/login" element={<Login pageTitle={`${appConfig.appName} | Login`} />} />
                        <Route path="/email-verification/*" element={<EmailVerification pageTitle={`${appConfig.appName} | Email Verification`} />} />
                        <Route path="/terms-conditions" element={<TermsConditions pageTitle={`${appConfig.appName} | TermsConditions`} />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy pageTitle={`${appConfig.appName} | privacyPolicy`} />} />
                        
                        <Route path="/test" element={<HomeTest pageTitle={`${appConfig.appName} | Test`}  />} />

                        <Route path="/dashboard" element={<PrivateRoute allowedRoles={['user','admin','teamMember',"client"]}> <Dashboard  pageTitle={`${appConfig.appName} | Dashboard`}/></PrivateRoute>} />
                        <Route path="/my-library" element={<PrivateRoute allowedRoles={['user','admin']}> <MyLibrary  pageTitle={`${appConfig.appName} | My Library`}/></PrivateRoute>} />
                        <Route path="/team-members" element={<PrivateRoute allowedRoles={['user','admin']}> <TeamMember  pageTitle={`${appConfig.appName} | Team Member`}/></PrivateRoute>} />
                        <Route path="/Client" element={<PrivateRoute allowedRoles={['user','admin']}> <Client  pageTitle={`${appConfig.appName} | Client`}/></PrivateRoute>} />
                        <Route path="/brand" element={<PrivateRoute allowedRoles={['user','admin']}> <Brand  pageTitle={`${appConfig.appName} | Brand`}/></PrivateRoute>} />
                        <Route path="/create-ticket/:id" element={<PrivateRoute allowedRoles={['user','admin']}> <TicketCreate  pageTitle={`${appConfig.appName} | Ticket Create`}/></PrivateRoute>} />
                        <Route path="/trash" element={<PrivateRoute allowedRoles={['user','admin']}> <Trash  pageTitle={`${appConfig.appName} | Trash`}/></PrivateRoute>} />
                        <Route path="/account-settings" element={<PrivateRoute allowedRoles={['user','admin','teamMember',"client"]}> <Profile  pageTitle={`${appConfig.appName} | Profile`}/></PrivateRoute>} />
                        <Route path="/social" element={<PrivateRoute allowedRoles={['user','admin']}> <Social  pageTitle={`${appConfig.appName} | Social Accounts`}/></PrivateRoute>} />
                        <Route path="/autoresponder" element={<PrivateRoute allowedRoles={['user','admin']}> <Autoresponder  pageTitle={`${appConfig.appName} | AR Connections`}/></PrivateRoute>} />
                        
                        <Route path="/admin/*" element={<PrivateRoute allowedRoles={['user','admin']}> <AdminRouter/></PrivateRoute>} />
                        <Route path="/admin/test" element={<PrivateRoute allowedRoles={['user','admin']}> <Test/></PrivateRoute>} />
                        <Route path="/scheduler/*" element={<PrivateRoute allowedRoles={['user','admin','teamMember',"client"]}> <SchedulerRouter/></PrivateRoute>} />
                        <Route path="/pixa-support/*" element={<PrivateRoute allowedRoles={['user','admin','teamMember',"client"]}> <PixaSupportRouter/></PrivateRoute>} />
                        {/* <Route path="/builder/*" element={<PrivateRoute allowedRoles={['user','admin','teamMember',"client"]}> <BuilderRouter/></PrivateRoute>} /> */}
                        <Route path="/api-endpoint" element={<PrivateRoute allowedRoles={['user','admin']}> <APIEndPoint/></PrivateRoute>} />
                        <Route path="/images/*" element={<PrivateRoute allowedRoles={['user','admin','teamMember',"client"]}> <ImagesRouter/></PrivateRoute>} />
                        <Route path="/imagePreview/:id" element={<ImagePreview/>} />
                        <Route path="*" element={<PrivateRoute allowedRoles={['user','admin']}> <NotFound pageTitle={`${appConfig.appLogo} | 404`}/></PrivateRoute>} />
                        
                        {/* <Route path="/dashboard" element={<Dashboard pageTitle={`${appConfig.appName} | Dashboard`}  />} /> */}
                        {/* <Route path="/my-library" element={<MyLibrary pageTitle={`${appConfig.appName} | My Library`}  />} /> */}
                        {/* <Route path="/team-members" element={<TeamMember pageTitle={`${appConfig.appName} | Team Member`}  />} /> */}
                        {/* <Route path="/Client" element={<Client pageTitle={`${appConfig.appName} | Client`}  />} /> */}
                        {/* <Route path="/brand" element={<Brand pageTitle={`${appConfig.appName} | Brand`}  />} /> */}
                        
                        {/* <Route path="/create-ticket/:id" element={<TicketCreate pageTitle={`${appConfig.appName} | Ticket Create`}  />} /> */}

                        {/* <Route path="/trash" element={<Trash pageTitle={`${appConfig    .appName} | Trash`}  />} /> */}
 
                        {/* <Route path="/account-settings" element={<Profile pageTitle={`${appConfig.appName} | Profile`}/>}/>
                        <Route path="/social" element={<Social pageTitle={`${appConfig.appName} | Social Accounts`}/>}/>
                        <Route path="/autoresponder" element={<Autoresponder pageTitle={`${appConfig.appName} | AR Connections`}/>}/> */}


                        
                        {/* <Route path="/admin/*" element={<AdminRouter />} /> */}
                        {/* <Route path="/admin/test" element={<Test />} /> */}
                        
                        {/* <Route path="/scheduler/*" element={<SchedulerRouter />} /> */}

                        {/* <Route path="/pixa-support/*" element={<PixaSupportRouter />} /> */}

                        {/* <Route path="/builder/*" element={<BuilderRouter />} /> */}
                        
                        {/* <Route path="/api-endpoint" element={<APIEndPoint />} /> */}

                        {/* <Route path="/images/*" element={<ImagesRouter />} /> */}
                        
                        {/* <Route path="/imagePreview/:id" element={<ImagePreview />} /> */}
                        
                        {/* <Route path="*" element={<NotFound pageTitle={`${appConfig.appLogo} | 404`}  />} /> */}
                        
                    </Route>
                </Routes>
            </BrowserRouter>
        </GoogleOAuthProvider>
    );
}
