import {  Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import moment from "moment";
import _ from "underscore";


import { useStore } from "../../zustand/store";
// import './css/calendar.css'

import React, { useState, useEffect, useRef } from 'react'
import { setMyState, hitApi, Loader, useOutsideClick, assetsUrlPath } from "../../components/commonAction";
import svg from "../../components/svg";
import { PreviewPost } from "./components/SchedulePopUp";
import ConfirmationPopup from "../../components/common/ConfirmationPopup";

const Calendar = ({ pageTitle }) => {

    const events = [
        { title: 'Meeting', start: new Date() }
    ]

    let store = useStore(state => state),
        schedulerStore = store?.scheduler;
    let { role ,scope} = store.userData;
    const containsWrite = ( role === "teamMember") ?   scope?.scheduler?.schedule.includes("write") :(role === 'client') ? false : true;
    const allowDelete = ( role === "teamMember") ?   scope?.scheduler?.schedule.includes("delete") : (role === 'client') ? false : true;


    let storeCalendarDate = schedulerStore?.calendarDate;

   
    const menuRef = useRef(null);

    const calendarRef = useRef(null);
    const [state, setState] = useState({
        postArray: [],
        scheduledPosts: [],
        windowSize: {
            width: window.innerWidth,
            height: window.innerHeight,
        },
        startDate: '',
        endDate: '',
        removeMe: '',
        removeItemDate: '',
        isLoad: false,
        activeInd: 0,
        activedate: false,
        activeId: '',
        previewPopUp: false,
        previewData: {},
        dateIndexValue: {},
        activeSubMenu: false,
        isLoading: false
    })

    useOutsideClick(menuRef, () => { setMyState(setState, { activeSubMenu: false }) });

    useEffect(() => {

        const handleResize = () => {
            setMyState(setState, {
                width: window.innerWidth,
                height: window.innerHeight,
            })
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        if (state.startDate) {
            getAllPost()
        }
    }, [state.startDate, state.isLoad])

    useEffect(() => {
        someMethod()

    }, [])

    const getAllPost = () => {
        if (!state.startDate) return
        setMyState(setState, {
            isLoading: true
        })

        hitApi({
            method: 'GET',
            url: `scheduler/post/get-post`,
            data: {
                limit: 'all',
                startDate: state.startDate,
                endDate: state.endDate,
            }
        }, (resp, error) => {
            setMyState(setState, {
                isLoading: false
            })

            if (!error) {
                let eventList = []
                resp.data.records.map((value, index) => {
                    eventList.push(value)
                })

                let eventDataList = showEvents(eventList);

                setMyState(setState, {
                    scheduledPosts: eventDataList
                })
            }
        })
    }

    useEffect(() => {
        document.body.classList.add('pxl-body-white');
        return () => {
          document.body.classList.remove('pxl-body-white');
        };
      }, []);

    const showEvents = (eventsData) => {
        if (eventsData.length === 0) return;
        let eventList = []

        eventsData.map((val, i) => {
            let event = {
                id: val._id,
                title: val?.title,
                mediaUrl: val?.mediaUrl,
                start: val.postDate,
                scheduleDate: val.scheduleDate,
                postOn: val.postOn,
                accounts: val.accounts,
                text: val?.caption,
                timezone: val?.timeZone,
                status: val.status,
                successData: val?.successData
            }
            eventList.push(event);
        })


        setMyState(setState, {
            postArray: eventList
        });

        var groups = _.groupBy(eventList, function (value) {
            return moment(value.start).format("YYYY-MM-DD");
        });
        let dataList = []
        var index = 0;
        for (const key in groups) {
            let data = {
                id: index + 1,
                start: key,
                obj: groups[key]
            }
            dataList.push(data)
            index++
        }
        return dataList
    }


    const renderEventContent = (eventInfo) => {
        let dataLength = eventInfo?.event?._def?.extendedProps?.obj?.length;

        let multiplePost = [];
        let activePost;
        let isPrevDisable;
        let isNextDisable;
        let Index = 0;
        let date;

        const obj = [
            { width: 3000, marLeft: 0, trans: 240 },
            { width: 1700, marLeft: '12px', trans: 202 },
            { width: 1500, marLeft: '2px', trans: 188 },
            { width: 1300, marLeft: '0', trans: 170 },
            { width: 1200, marLeft: '0', trans: 158 },
            { width: 1145, marLeft: '0', trans: 148 },
        ];

        let activeObj = obj.filter(data => data.width >= window.innerWidth).reduce((min, obj) => (obj.width < min.width ? obj : min))


        eventInfo?.event?._def?.extendedProps?.obj.map((val, i) => { 

            activePost = state.activedate ? state.activedate == val.start ? state.activeInd == i : i == 0 : i == 0;
            isPrevDisable = state.activedate ? state.activedate == val.start ? state.activeInd == 0 : state.dateIndexValue.hasOwnProperty(val.start) ? state.dateIndexValue[val.start] == 0 : true : state.removeItemDate ? "" : true;
            isNextDisable = state.activedate ? state.activedate == val.start ? state.activeInd ? (state.activeInd + 1) == dataLength : false : state.dateIndexValue.hasOwnProperty(val.start) ? state.dateIndexValue[val.start] == dataLength - 1 : false : state.removeItemDate ? "" : false;
            Index = i;
            date = val.start;
            const currentDate = new Date();

            let isEditShow = new Date(val.scheduleDate) > currentDate;

            multiplePost.push(

                <div className={`pl_calender_post_box ${activePost ? 'slide_active' : (state.activeInd)}`} style={{ marginLeft: activeObj?.marLeft }}>
                    <div className="slider" >
                        <div className="pl_cal_img_div">
                            {val.mediaUrl && val.mediaUrl.length > 0 && <img src={val.mediaUrl[0]?.mediaType === "image" ? val.mediaUrl[0].original.thumb ? assetsUrlPath(val.mediaUrl[0].original.thumb) : assetsUrlPath(val.mediaUrl[0].original.path) : val.mediaUrl[0].original.thumb ? assetsUrlPath(val.mediaUrl[0].original.thumb) : '/images/defaultVideoThumb.png'} />}
                        </div>

                        <div className="cal_post_dot_wrap">
                            <div className={val.mediaUrl && val.mediaUrl.length > 0 ? "cun-caption-oneline" : 'cun-caption-threeLine'} >
                                {/* <h6>{val.mediaUrl && val.mediaUrl.length > 0 ? val.text.length > 40 ? `${val.text.slice(0, 40)}...` : val.text : val.text.length > 250 ? `${val.text.slice(0, 250)}...` : val.text}  </h6> */}
                                <h6>{val.text}</h6>
                            </div>
                            <div className="sch-templateMenu" style={{right:'3px'}}>
                                <div className={`sch-menuSection ${state.activeSubMenu == val.id ? 'sch-menuOpen' : ''}`} >

                                    <div className='three_dot_bg' onClick={() => setMyState(setState, { activeSubMenu: state.activeSubMenu == val.id ? false : val.id })} ref={menuRef}><img src="/assets/images/three-dot.svg" alt="" /></div>
                                    <div className={`sch-cal-submenubox`} style={{ transform: 'translateX(-12px)',bottom:val.mediaUrl && val.mediaUrl.length > 0 ? '' :'auto' }}>
                                        <ul>
                                            <li>
                                                <a onClick={() => setMyState(setState, { previewPopUp: true, previewData: val })}   >
                                                    <span> {svg.app.previewCal}</span>Preview
                                                </a>
                                            </li>
                                            {(val.status == 'initialize' && isEditShow && containsWrite) && <li>
                                                <Link to={`/scheduler/edit-schedule-post/${val.id}`}> <span><img src="/assets/images/edit-svg.svg" alt="" /></span>Edit </Link>
                                            </li>}
                                            {allowDelete && <li>
                                                <a onClick={() => setMyState(setState, { removeMe: [val], removeItemDate: date })}    >
                                                    <span> <img src="/assets/images/delet-svg.svg" alt="" /> </span>Delete
                                                </a>
                                            </li>}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="pl_calender_options">{svg.app.menu_icon}

                            {/* <div className="pl_calender_options_div">

                                {val.postOn && val.postOn.length > 0 &&
                                    <div className="pxl-icon-tooltip cun-tooltipIcon">
                                        <a>
                                            <span className="pxl-tooltip-text">Preview</span>
                                        </a>
                                        <div onClick={() => setMyState(setState, { previewPopUp: true, previewData: val })} >
                                            {svg.app.previewCal}
                                        </div>
                                    </div>}


                                {(val.status == 'initialize' && isEditShow) && <div className="pxl-icon-tooltip cun-tooltipIcon">
                                    <a>
                                        <span className="pxl-tooltip-text">Edit</span>
                                    </a>
                                    <div><Link to={`/scheduler/edit-schedule-post/${val.id}`}> {svg.app.editCal}  </Link></div>
                                </div>}

                                <div className="pxl-icon-tooltip cun-tooltipIcon">
                                    <a>
                                        <span className="pxl-tooltip-text">Delete</span>
                                    </a>
                                    <div className="pl_calendar_delete" onClick={() => setMyState(setState, { removeMe: [val], removeItemDate: date })}> {svg.app.deleteIcon}  </div>
                                </div>
                            </div> */}


                            <div className="cun-calendar-social">
                                {
                                    val.postOn && val.postOn.length > 0 && val.postOn.map((val,i) => <span key={i}>{svg.app.socialAccount[val]}</span>)
                                }

                            </div>
                        </div>
                    </div>
                </div>

            )
        });



        return <div className="pl_slider"  >
            {(dataLength > 1) && <div  className="pl_calender_slider">
                <button className="pl_calender_prev"
                    style={{ opacity: isPrevDisable ? '50%' : '1' }}
                    disabled={isPrevDisable}
                    onClick={() => {
                        let updatedObj = state.dateIndexValue;
                        if (state.activedate != date) {
                            if (state.dateIndexValue.hasOwnProperty(date)) {
                                updatedObj[state.activedate] = state.activeInd;
                            } else {
                                updatedObj = { ...updatedObj, [state.activedate]: state.activeInd }
                            }
                        }
                        setMyState(setState, { dateIndexValue: updatedObj, activeInd: state.activedate == date ? state.activeInd - 1 : Index - 1, activedate: date, removeItemDate: false })
                    }}
                >{svg.app.prevIcon}
                </button>

                <button className="pl_calender_next"
                    style={{ opacity: isNextDisable ? '50%' : '1' }}
                    disabled={isNextDisable}
                    onClick={() => {
                        let updatedObj = state.dateIndexValue;
                        if (state.activedate != date) {
                            if (state.dateIndexValue.hasOwnProperty(date)) {
                                updatedObj[state.activedate] = state.activeInd;
                            } else {
                                updatedObj = { ...updatedObj, [state.activedate]: state.activeInd }
                            }
                        }
                        setMyState(setState, { dateIndexValue: updatedObj, activeInd: state.activedate == date ? state.activeInd + 1 : 1, activedate: date, removeItemDate: false })
                    }}
                >{svg.app.nextIcon}
                </button>
            </div>}
            {/* 240 */}

            <div className="pl_cal_post_flex" style={{
                transitionDuration: '.5s',
                transitionDelay: '0ms',
                transform: `translate3d(-${state.activedate == date ? state.activeInd * activeObj?.trans : state.removeItemDate ? '0' : ''}px, 0px, 0px)`,
            }}>
                {multiplePost}
            </div>
            <div className="pl_navigation-button">

                {
                    (dataLength > 1) && [...Array(dataLength)].map((item, i) => <span key={i} className={`pl_dot ${(state.activedate == date ? state.activeInd == i : state.dateIndexValue.hasOwnProperty(date) ? state.dateIndexValue[date] == i : i == 0) ? 'pl_dot_active' : ''}`}
                        onClick={() => {
                            let updatedObj = state.dateIndexValue;
                            if (state.activedate != date) {
                                if (state.dateIndexValue.hasOwnProperty(date)) {
                                    updatedObj[date] = i;
                                } else {
                                    updatedObj = { ...updatedObj, [date]: i }
                                }
                            }
                            setMyState(setState, {
                                activeInd: i, activedate: date,
                                dateIndexValue: updatedObj
                            })
                        }}
                    >
                    </span>)
                }
            </div>
        </div>;
    }



    const renderEventContentMobile = (eventInfo) => {

        let eventData = eventInfo.event._def;
       
        const currentDate = new Date();

        let isEditShow = new Date(eventData?.extendedProps?.scheduleDate) > currentDate;
        return <> <div className="pl_calender_post_box">
   
            {eventData?.extendedProps.mediaUrl && eventData?.extendedProps.mediaUrl?.length > 0 && <div className="pl_cal_img_div">
                <img src={eventData?.extendedProps.mediaUrl[0].mediaType === 'image' ?  eventData?.extendedProps.mediaUrl[0].original.thumb ? assetsUrlPath(eventData?.extendedProps.mediaUrl[0].original.thumb) : assetsUrlPath(eventData?.extendedProps.mediaUrl[0].original.path) : eventData?.extendedProps.mediaUrl[0].original.thumb ? assetsUrlPath(eventData?.extendedProps.mediaUrl[0].original.thumb) : '/images/defaultVideoThumb.png' } />
            </div>
            }

            <div className="cal_post_dot_wrap">
                <div className={eventData?.extendedProps.mediaUrl && eventData?.extendedProps.mediaUrl?.length > 0 ? "cun-caption-oneline" : 'cun-caption-threeLine'} >
                    <h6>{eventData?.extendedProps?.text}</h6> 
                </div>

                <div className="sch-templateMenu" style={{right:'3px'}}>
                        <div className={`sch-menuSection ${state.activeSubMenu == eventData?.publicId ? 'sch-menuOpen' : ''}`} >

                            <div className='three_dot_bg' onClick={() => setMyState(setState, { activeSubMenu: state.activeSubMenu == eventData?.publicId ? false : eventData?.publicId })} ref={menuRef}><img src="/assets/images/three-dot.svg" alt="" /></div>
                            <div className={`sch-cal-submenubox`} style={{ transform: 'translateX(-12px)'}}>
                                <ul>
                                    {eventData?.extendedProps?.postOn && eventData?.extendedProps?.postOn.length > 0 && <li>
                                        <a onClick={() => setMyState(setState, { previewPopUp: true, previewData: { title: eventData?.title, text: eventData?.extendedProps.text, mediaUrl: eventData?.extendedProps.mediaUrl, postOn: eventData?.extendedProps?.postOn, successData: eventData?.extendedProps?.successData } })}  >
                                            <span> {svg.app.previewCal}</span>Preview
                                        </a>
                                    </li>}
                                    {(eventData?.extendedProps?.status == 'initialize' && isEditShow && containsWrite) && <li>
                                        <Link to={`/scheduler/edit-schedule-post/${eventData.publicId}`}> <span><img src="/assets/images/edit-svg.svg" alt="" /></span>Edit </Link>
                                    </li>}
                                   {allowDelete && <li>
                                        <a onClick={() => setMyState(setState, { removeMe: [{ id: eventData?.publicId }] })} >
                                            <span> <img src="/assets/images/delet-svg.svg" alt="" /> </span>Delete
                                        </a>
                                    </li>}
                                </ul>
                            </div>
                        </div>
                    </div>
            </div>

            <div className="pl_calender_options">{svg.app.menu_icon}
                {/* <div className="pl_calender_options_div">
                    {eventData?.extendedProps?.postOn && eventData?.extendedProps?.postOn.length > 0 &&
                        <div className="pxl-icon-tooltip cun-tooltipIcon">
                            <a>
                                <span className="pxl-tooltip-text">Preview</span>
                            </a>
                            <div onClick={() => setMyState(setState, { previewPopUp: true, previewData: { title: eventData?.title, text: eventData?.extendedProps.text, mediaUrl: eventData?.extendedProps.mediaUrl, postOn: eventData?.extendedProps?.postOn, successData: eventData?.extendedProps?.successData } })} > {svg.app.previewCal}</div>
                        </div>}

                    {(eventData?.extendedProps?.status == 'initialize' && isEditShow) &&
                        <div className="pxl-icon-tooltip cun-tooltipIcon">
                            <a>
                                <span className="pxl-tooltip-text">Edit</span>
                            </a>
                            <Link to={`/scheduler/edit-schedule-post/${eventData.publicId}`}> {svg.app.editCal}  </Link>
                        </div>
                    }

                    <div className="pxl-icon-tooltip cun-tooltipIcon">
                        <a>
                            <span className="pxl-tooltip-text">Delete</span>
                        </a>
                        <div className="pl_calendar_delete" onClick={() => setMyState(setState, { removeMe: [{ id: eventData?.publicId }] })}> {svg.app.deleteIcon}  </div>
                    </div>

                </div> */}



                {
                    eventData?.extendedProps?.postOn && eventData?.extendedProps?.postOn.length > 0 && eventData?.extendedProps?.postOn.map((val,i) => <span key={i}>{svg.app.socialAccount[val]}</span>)
                }
            </div>

        </div >
        </>
    }


    const handleSize = (event) => {
        let contentAPi = event.view.calendar;

        if (window.innerWidth < 1000) {
            contentAPi.changeView('listMonth');
        } else {
            contentAPi.changeView('dayGridMonth');
        }
    }

    const someMethod = (event) => {
        let calendarApi = calendarRef?.current?.getApi()
        if (storeCalendarDate) {
            setTimeout(() => {
                calendarApi.gotoDate(storeCalendarDate)
            }, [1000])
            store.updateStore("scheduler", {
                ...schedulerStore,
                calendarDate: ''
            })
        }
    }

    function daysIntoYear(date) {
        let day = (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
        let year = moment(date).format("yyyy");
        let uniqueId = `${year}-${day}`
        return uniqueId
    }

    const showDay = (info, create) => {
        const element = create('span', { id: "fc-day-span-" + daysIntoYear(info.date) }, info.dayNumberText);
        return element;
    }

    const modifyDayCell = (info) => {
        let today = moment(new Date).format('yyyy-MM-DD'),
            clmDate = moment(info.date).format('yyyy-MM-DD');
            // day = daysIntoYear(info.date);
            if(containsWrite){
                if (today <= clmDate) {
                    let dyDiv = document.createElement('div')
                    let button = document.createElement('a')
                    button.setAttribute('href', `/scheduler/new-post?date=${clmDate}`)
                    button.setAttribute('style', 'border: none;border-radius: 100%;background: linear-gradient(90deg, #6F10B9 8.52%, #C02a93 110%);color:#fff;width: 22px;height: 22px;display: flex;justify-content: center;align-items: center;font-size: 20px;padding-top:0px')
                    button.innerHTML = '+'
        
                    // let span = `<span className="rz_tooltipEle">Add New Post</span>`
                    // dyDiv.innerHTML = span
                    dyDiv.setAttribute('class', "ps_calendar_event_btn")
                    dyDiv.setAttribute('style', "position: absolute; right: 5px; bottom: 5px; z-index: 9;");
                    dyDiv.append(( role != "teamMember")?button:'');
                    document.querySelector(`[data-date="${clmDate}"] .fc-daygrid-day-frame`).append(dyDiv);
                }
            }
        
    }


    const handleChangeMonth = (data) => {
        let startD = moment(data.startStr).format("YYYY-MM-DD")
        let endD = moment(data.endStr).format("YYYY-MM-DD")
        setMyState(setState, {
            startDate: startD,
            endDate: endD,
        })
    }

    return (
        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>

            {/* Main Wrapper  */}
           <div className="pxl-calenderMain-wrapper-box">
           <div className="sch-container-fluid schCalender-Wrapper">
                    <FullCalendar
                        // customButtons={{
                        //     myCustomButton: {
                        //         text: "Add event",
                        //         click: () => {
                        // setIsEventModal(true)
                        //         }
                        //     }
                        // }}
                        headerToolbar={{
                            left: "prev,next",
                            // center: state.windowSize.width > 991 ? "" : "myCustomButton",
                            right: "title"
                        }}
                        ref={calendarRef}
                        themeSystem="Simplex"
                        allDayText={"All Day"}
                        allDaySlot={true}
                        defaultAllDay={false}
                        displayEventTime={true}
                        displayEventEnd={false}

                        // events={state.postArray}
                        events={window.innerWidth > 1000 ? state.scheduledPosts : state.postArray}
                        eventContent={window.innerWidth > 1000 ? renderEventContent : renderEventContentMobile}

                        initialView={window.innerWidth > 1000 ? "dayGridMonth" : "listMonth"}
                        plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin, listPlugin]}
                        windowResize={handleSize}
                        selectable={true}
                        selectMirror={true}
                        timeZone={"GMT"}
                        dayCellContent={showDay}
                        dayCellDidMount={modifyDayCell}
                        datesSet={handleChangeMonth}
                    />
                </div>
           </div>

            {
                state.isLoading && <Loader />
            }

            <PreviewPost
                shownPopup={state.previewPopUp}
                closePopup={() => {
                    setMyState(setState, {
                        previewPopUp: false
                    })
                }}
                data={{ title: state.previewData.title, caption: state.previewData.text, mediaUrl: state.previewData.mediaUrl, accounts: state.previewData.postOn, successData: state.previewData?.successData }}
            />

            <ConfirmationPopup
                shownPopup={state.removeMe.length}
                closePopup={() => {
                    setMyState(setState, {
                        removeMe: [],
                    })

                }}
                type={"post"}
                title={`Do you want to move this post to trash?`}
                subTitle={'Items can be restored from trash in next 15 days.'}
                yesBtn={"Yes, Move"}
                removeAction={() => {
                    let data = {};

                    const idArray = state.removeMe.map((item) => item.id);

                    data['target'] = idArray

                    hitApi({
                        url: 'scheduler/post/move-to-trash',
                        method: "PATCH",
                        data: data,
                    }, (resp, err = null) => {
                        // let DummydateIndexValue = state.dateIndexValue;
                        //  delete DummydateIndexValue[state.removeItemDate];
                        setMyState(setState, {
                            removeMe: [],
                            activedate: false,
                            activeInd: 0,
                            dateIndexValue: {}
                        })

                        if (resp.status) {
                            getAllPost()
                        }
                    })
                }}
            />
        </>
    )
};

export default Calendar;