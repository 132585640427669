import svg from '../pixa-support/components/svg';
import ColorPicker from '../pixa-support/components/ColorPicker';
import { Modal } from "react-bootstrap";
import { useEffect, useState } from 'react';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { commonToast, assetsUrlPath, hitApi } from '../../components/commonAction';
// import { callAPI } from '../helper/API';
// /pages/pixa-support/components/ProjectSettingTab.js


function SeoSetting({ setActiveTabMenu , p_id, projName, r_Resdata, t_seoTitle, d_seoDescription, f_faviconFile, l_logoFile, a_author, k_keyword }) {
    const [openAgentPopUp, setOpenAgentPopUp] = useState(false);
    const animatedComponents = makeAnimated();
    const [isError, setIsError] = useState(false);
    const [projectName, setProjectName] = useState(projName);

    const [seoTitle, setSeoTitle] = useState('');
    const [seoDescription, setSeoDescription] = useState('');
    const [filterAgent, setFilterAgent] = useState([]);
    const [allAgents, setAllagents] = useState([]);

    const [seoKeyword, setSeoKeyword] = useState([]);
    const [seoKeywordInput, setSeoKeywordInput] = useState('')
    const [seoKeywords, setSeoKeywords] = useState('')
    const [seoAuthor, setSeoAuthor] = useState('')


    const [agentsList, setAgentsList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [agentIds, setAgentIds] = useState([]);
    const [shownoData, setShowNoData] = useState(false);

    const [logoURL, setLogoURL] = useState('');
    const [logoFile, setLogoFile] = useState('');

    const [faviconURL, setFaviconURL] = useState('');
    const [faviconFile, setFaviconFile] = useState('');

    const [loginImageURL, setLoginImageURL] = useState('');
    const [loginImageFile, setLoginImageFile] = useState('');
    const [signUpContent, setSignUpContent] = useState('');
    const [signUpHeading, setSignUpHeading] = useState('');

    const [btnLoading, setBtnLoading] = useState(false)
    const [btnDisable, setBtnDisable] = useState(false)

    const [colorTheme, setColorTheme] = useState('#039df4');


    const img_path = `${process.env.s3URL}usercontent/`;
    // const userData = useSelector(store => store.userData);
    // const dispatch = useDispatch();
    useEffect(() => {
        setSeoTitle(t_seoTitle);
        setSeoDescription(d_seoDescription);
        setLogoFile(l_logoFile);
        setFaviconFile(f_faviconFile);
        setSeoAuthor(a_author)
        setSeoKeywords(k_keyword)
    }, [t_seoTitle, d_seoDescription, f_faviconFile, l_logoFile, a_author, k_keyword]);


    // useEffect(() => {
    //     // if (userData.role === 1 ) {
    //     fetchProjectsDetail(p_id);
    //     fetchAgents();
    //     // }
    // }, [p_id]);


    // const fetchProjectsDetail = (id) => {
    //     if (id) {
    //         hitApi({
    //             url: 'page-builder/get-projects',
    //             method: "POST",
    //             data: {
    //                 project_id: id,
    //             },
    //             loading: true,
    //             alert: false,
    //         }, (resp, err = null) => {
    //             if (resp.status === 1) {
    //                 // dispatch(projectNameSetting(resp.data.project_name))
    //                 setProjectName(resp.data.project_name);
    //                 setSeoTitle(resp.data.seo_title ? resp.data.seo_title : '');
    //                 setSeoDescription(resp.data.seo_description ? resp.data.seo_description : '');
    //                 setSeoKeyword(resp.data.seo_keyword ? (resp.data.seo_keyword.split(',')) : []);
    //                 setColorTheme(resp.data.color_theme ? resp.data.color_theme : '#039df4');
    //                 // setLogoURL(resp.data.project_logo ? img_path + `project/${router.query.p_id}/` + resp.data.project_logo : '');
    //                 // setFaviconURL(resp.data.project_favicon ? img_path + `project/${router.query.p_id}/` + resp.data.project_favicon : '');
    //                 // setSignUpContent(resp.data.signup_content ? resp.data.signup_content : '');
    //                 // setLoginImageURL(resp.data.login_image ? img_path + `project/${router.query.p_id}/`+ resp.data.login_image  :'')
    //                 const aids = [];
    //                 if (resp.data.agent_id) {
    //                     resp.data.agent_id.map(item => {
    //                         aids.push(item._id);
    //                     });
    //                 }
    //                 setAgentIds(aids);
    //                 setIsError(false)
    //             } else {
    //                 setIsError(true)
    //             }
    //         });

    //     }
    // }

    const saveSeoSetting = (e) => {
        e.preventDefault();
        setBtnDisable(true)


        if (!seoTitle) {
            commonToast('error', `Title is required!`);
            return false;
        } else if (!seoDescription) {
            commonToast('error', `Description is required!`);
            return false;
        } else if (!seoKeywords) {
            commonToast('error', `Keyword is required!`);
            return false;
        } else if (!seoAuthor) {
            commonToast('error', ` Author is required!`);
            return false;
        } else if (!logoFile) {
            commonToast('error', `Logo is required!`);
            return false;
        } else if (!faviconFile) {
            commonToast('error', `Favicon is required!`);
            return false;
        } else {
            setBtnLoading(true)
            if (p_id) {

                const formData = new FormData();
                formData.append('target', p_id);
                formData.append('title', seoTitle);
                formData.append('description', seoDescription);
                formData.append('keywords', seoKeywords);
                formData.append('author', seoAuthor);
                formData.append('logo', logoFile);
                formData.append('favicon', faviconFile);
                hitApi({
                    url: 'page-builder/update-project',
                    method: "PATCH",
                    data: formData,
                    loading: true,
                    alert: true,
                    isFormData: true
                }, (resp, err = null) => {
                    setBtnLoading(false)
                    setBtnDisable(false)
                    setActiveTabMenu("Support")
                    // if (resp.status === 1) fetchProjectsDetail(resp.data._id);
                });

            }
        }
    }

    const uploadLogoImage = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type.includes('image/')) {
                const url = URL.createObjectURL(file);
                setLogoURL(url);
                setLogoFile(file);
            } else {
                commonToast('error', `Upload valid image! Only PNG and JPG are allowed!`);
            }
        }
    }
    const uploadFaviconImage = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type.includes('image/')) {
                const url = URL.createObjectURL(file);
                setFaviconURL(url);
                setFaviconFile(file);
            } else {
                commonToast('error', `Upload valid image! Only PNG and JPG are allowed!`);
            }
        }

    }
    const uploadLoginImage = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type.includes('image/')) {
                const url = URL.createObjectURL(file);
                setLoginImageURL(url);
                setLoginImageFile(file);
            } else {
                commonToast('error', `Upload valid image! Only PNG and JPG are allowed!`);
            }
        }

    }

    const addAgents = () => {
        let filterdata = allAgents.filter(val => { return agentIds.find(id => id.value == val._id) });
        setFilterAgent(filterdata);
        setOpenAgentPopUp(false);
    }
    useEffect(() => {
        document.body.classList.add('pxl-body-white');
        return () => {
            document.body.classList.remove('pxl-body-white');
        };
    }, []);
    return (
        <>


            <div className='sup_proj_setting_form pxl-seoSetting-wrap'>
                <div className='pxl-project-setting-inner-wrap'>
                    <h4 className='proj_main_head'>SEO Settings</h4>

                    <div className='proj_Setting_first_flex'>
                        <div className="pxl_input_feilds pxl-placeholder-color1">
                            <label>Title <span className="pxl-star-red">*</span></label>
                            <input type="text" name="title" placeholder="Enter Title" onChange={(e) => setSeoTitle(e.target.value)} value={seoTitle || ''} />
                        </div>
                        <div className="pxl_input_feilds pxl-placeholder-color1">
                            <label>Description <span className="pxl-star-red">*</span></label>
                            <input type="text" name="description" placeholder="Enter Description" value={seoDescription || ''} onChange={(e) => setSeoDescription(e.target.value)} />
                        </div>
                        <div className="pxl_input_feilds pxl-placeholder-color1">
                            <label>Keywords  <span className="pxl-star-red">*</span></label>
                            <input type="text" name="Keywords" placeholder="Enter Keywords" value={seoKeywords || ''} onChange={(e) => setSeoKeywords(e.target.value)} />
                        </div>
                        <div className="pxl_input_feilds pxl-placeholder-color1">
                            <label>Author  <span className="pxl-star-red">*</span></label>
                            <input type="text" name="Author" placeholder="Enter Author" value={seoAuthor || ''} onChange={(e) => setSeoAuthor(e.target.value)} />
                        </div>

                        <div className="pxl_input_feilds">
                            <label>Logo <span className="pxl-star-red">*</span>
                                {/* ( max 250x80) */}
                            </label>
                            <div className="pxl-custom-file-upload pxl-upload-file-profile pxl-seo-uploadFile">
                                <div className="pxl-custom-file-select">
                                    {console.log("logoURL", logoFile)}
                                    {logoURL ? <div className='image_url_wrap'> <img src={logoURL} width={'60px'} />
                                        <a className="img_Cross" onClick={() => {
                                            setLogoURL('');
                                            setLogoFile('')
                                        }}>
                                            {svg.crossSvg1}
                                        </a></div>

                                        : logoFile == '' || logoFile == undefined ? <>
                                            <div className="pxl-custom-file-select-button" id="fileName">

                                                <img src="/assets/images/newpost/file-upload1.svg" alt="" />
                                                {/* <img src={assetsUrlPath(logoFile)} alt="" /> */}
                                            </div>
                                            <div className="pxl-custom-file-select-name" id="noFile">
                                                <span> <b>Upload</b> <span className='pxl-upload-file-text'>or drop your file here</span> </span>
                                            </div>
                                            <input type="file" name="chooseFile" id="chooseFile" onChange={(e) => uploadLogoImage(e)} />
                                        </> : <>
                                            <div className="pxl-custom-file-select-button" id="fileName">

                                                {/* <img src="/assets/images/newpost/file-upload1.svg" alt="" /> */}
                                                <img src={assetsUrlPath(logoFile)} alt="" />
                                            </div>
                                            <div className="pxl-custom-file-select-name" id="noFile">
                                                <span> <b>Upload</b> <span className='pxl-upload-file-text'>or drop your file here</span> </span>
                                            </div>
                                            <input type="file" name="chooseFile" id="chooseFile" onChange={(e) => uploadLogoImage(e)} />
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="pxl_input_feilds">
                            <label>Favicon <span className="pxl-star-red">*</span>
                                {/* ( max 32x32) */}
                            </label>
                            <div className="pxl-custom-file-upload pxl-upload-file-profile pxl-seo-uploadFile">
                                <div className="pxl-custom-file-select">
                                    {faviconURL ? <div className='image_url_wrap'> <img src={faviconURL} width={'60px'} />
                                        <a className="img_Cross" onClick={() => {
                                            setFaviconFile('');
                                            setFaviconURL('')
                                        }}>
                                            {svg.crossSvg1}
                                        </a>
                                    </div> : faviconFile == '' || faviconFile == undefined ? <>
                                        <div className="pxl-custom-file-select-button" id="fileName">
                                            {/* <img src={assetsUrlPath(faviconFile)} alt="" /> */}
                                            <img src="/assets/images/newpost/file-upload1.svg" alt="" />

                                        </div>
                                        <div className="pxl-custom-file-select-name" id="noFile">
                                            <span> <b>Upload</b> <span className='pxl-upload-file-text'>or drop your file here</span> </span>
                                        </div>
                                        <input type="file" name="chooseFile" id="chooseFile" onChange={(e) => uploadFaviconImage(e)} />
                                    </> : <>
                                        <div className="pxl-custom-file-select-button" id="fileName">
                                            <img src={assetsUrlPath(faviconFile)} alt="" />
                                            {/* <img src="/assets/images/newpost/file-upload1.svg" alt="" /> */}

                                        </div>
                                        <div className="pxl-custom-file-select-name" id="noFile">
                                            <span> <b>Upload</b> <span className='pxl-upload-file-text'>or drop your file here</span> </span>
                                        </div>
                                        <input type="file" name="chooseFile" id="chooseFile" onChange={(e) => uploadFaviconImage(e)} />
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pxl-supportBtn-assignBack' >
                <button className="pxlCommon-btn " onClick={() => { setActiveTabMenu('domain') }}> Back</button>
                    <button className="pxlCommon-btn" disabled={btnLoading} onClick={(e) => { saveSeoSetting(e) }}> {btnLoading ? "Processing..." : 'Update & Continue '}</button>
                  

                </div>
            </div>

            <Modal
                show={openAgentPopUp} onHide={() => setOpenAgentPopUp(false)} animation={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className='pxl-model-header'>
                            <h2 className='modal-title'>Add New Agents</h2>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="form-group">
                            <label htmlFor="exampleFormControlSelect2">Select Agents</label>
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                value={agentIds}
                                isMulti
                                options={agentsList}
                                onChange={(option) => setAgentIds(option)}
                            />
                        </div>
                        <div>
                            <button type="submit" className="pxl_btn" onClick={addAgents}>Continue</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default SeoSetting