import { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';

const NoPage = () => {
    let appPath = useLocation().pathname.split('/').filter(Boolean)[0];
    let currentPath = useLocation().pathname.replace(`/${appPath}`, '');
   useEffect(()=>{
    if(!currentPath.includes('builder'))
    {
        window.location.href="/dashboard"; 
    }
   },[currentPath])
 
    return (
        <>
            <p>404 page not found </p>
        </>
    )
};

export default NoPage;