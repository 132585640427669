
import { useLocation, useNavigate } from 'react-router-dom';
import styles from "../Project.module.css"
import { useStore } from "../../../zustand/store";
import { useEffect, useState } from 'react';
import Select from "react-select";
import { commonToast, hitApi,validations } from '../../../components/commonAction';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Tooltip } from "@mui/material";

const TicketCreate = () => {
  
    const navigate = useNavigate();


    const [btnLoader, setBtnLoader] = useState(false);

    const [subject, setSubject] = useState('');
  

    const [statusList, setStatusList] = useState([]);

    const [selectedStatus, setSelectedStatus] = useState('');


    const [typeList, setTypeList] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [priorityList, setPriorityList] = useState([]);
    const [selectedPriority, setSelectedPriority] = useState('');

    const [email, setEmail] = useState('');
    const [attachmentFileURL, setAttachmentFileURL] = useState([]);
    const [attachFile, setAttachFile] = useState([]);
    const [editorData, setEditorData] = useState('');


    let currentPath = useLocation().pathname.replace('/pixa-support/create-ticket/', '');
    const project_id = currentPath;

    

    let userData = useStore(state => state)['userData'];
    // const attachmentFile = (event) => {
    //     const file = event.target.files[0];
    //     if (file) {
    //         if (file.type.includes('image/')) {
    //             const url = URL.createObjectURL(file);
    //             setAttachmentFileURL(current => [...current, url]);
    //             setAttachFile(current => [...current, file]);
    //         } else {
    //             commonToast('error', 'Upload valid image!', 'Only PNG and JPG are allowed!');
    //         }
    //     }
    // }

    const attachmentFile = (event) => {
        const files = Array.from(event.target.files);
        files.forEach(file => {
          if (file.type.includes('image/')) {
            const url = URL.createObjectURL(file);
            setAttachmentFileURL(current => [...current, url]);
            setAttachFile(current => [...current, file]);
          } else {
            commonToast('Only PNG and JPG are allowed!');
          }
        });
      };


    useEffect(() => {
        fetchTicketPriority();
        fetchTicketStatus();
        fetchTicketTypes();
    }, [])


    const fetchTicketStatus = async () => {
        hitApi({
            url: 'pixa-support/api/getAllTicketStatus',
            method: 'POST',
            data: {
                userId: (userData.role === 'user') ? userData.userId : userData.agency_id,
            },
            alert: false,
        }, (resp, err = null) => {
            if (resp.status === 1) {
                let data = resp.data.length > 0 ? resp.data.filter((val)=>val.status === 1).map(val => ({ value: val._id, label: val.name })) : [];
                data.unshift({ label: 'Select Ticket Status', value: '' });
                setStatusList(data);
            }
        });

    };

    const fetchTicketTypes = async () => {
        hitApi({
            url: 'pixa-support/api/getAllTicketTypes',
            method: 'POST',
            data: {
                userId: (userData.role === 'user') ? userData.userId : userData.agency_id,
            },
            alert: false,
        }, (resp, err = null) => {
            if (resp.status === 1) {
                let data = resp.data.length > 0 ? resp.data.map(val => ({ value: val._id, label: val.name })) : [];
                data.unshift({ label: 'Select Ticket Type', value: '' });
                setTypeList(data);
            }
        });

    };


    const fetchTicketPriority = async () => {
        hitApi({
            url: 'pixa-support/api/getAllTicketPriority',
            method: 'POST',
            data: {
                userId: userData.role === 'user' ? userData.userId : userData.agency_id,
            },
            alert: false,
        }, (resp, err = null) => {
            if (resp.status === 1) {
                setPriorityList(resp.data);
            }
        });
    }
    // useEffect(() => {
    //     if (project_id) {
    //         setProjectId(project_id)
    //         hitApi({
    //             url: 'pixa-support/api/project_details',
    //             method: "POST",
    //             data: {
    //                 project_id: project_id,
    //             },
    //             loading: true,
    //             alert: false,
    //         }, (resp, err = null) => {
    //             if (resp.status) {
    //                 if (resp.data.mail) {
    //                     setSelectedOption((resp.data?.mail_type != undefined) ? resp.data?.mail_type : 'forwarder')
    //                     setSupportName(resp?.data?.mailName)
    //                     setSupportMail(resp?.data?.mail);
    //                     setVerify(resp?.data?.mailVerified);
    //                     setSMTPHost(resp?.data?.smtpHost)
    //                     setSMTPPort(resp?.data?.smtpPort)
    //                     setSMTPUsername(resp?.data?.smtpUsername)
    //                     setSMTPPassword(resp?.data?.smtpPassword)
    //                     setSSL(resp?.data?.ssl)
    //                     setStatus(1);
    //                 }
    //             }
    //         });

    //     }
    // }, [project_id])



    const createTicketSubmit = () => {
       
        if (!email) {
            commonToast('error', `Customer Email is required!`);
            return false;
        }

        
        if (!subject) {
            commonToast('error', `Issue title is required!`);
            return false;
        }

        if (selectedStatus == '') {
            commonToast('error', `Status is required.`);
            return false;
        }
        if (selectedType == '') {
            commonToast('error', `Type is required.`);
            return false;
        }
        if (selectedPriority == '') {
            commonToast('error', `Priority is required.`);
            return false;
        }
        const formData = new FormData();

        formData.append('status_id', selectedStatus.value);
        formData.append('userId', (userData.role === 'user') ? userData.userId : userData.agency_id);
        formData.append('project_id', project_id);
        formData.append('type_id', selectedType.value);
        formData.append('subject', subject);
        formData.append('description', editorData);
        formData.append('priority_id', selectedPriority);
        formData.append('customer_email', email);
        for (let i = 0; i < attachFile.length; i++) {
            formData.append('attachments', attachFile[i]);
        }
        // if (userData.role === process.env.roles.customer) {
        //     formData.delete("status_id");
        //     formData.delete("customer_email");
        // }
        if(validations(email, 'email') ){
            setBtnLoader(true);
            hitApi({
                url: 'pixa-support/api/create_ticket',
                method: "POST",
                data: formData,
                loading: true,
                alert: true,
                isFormData: true
            }, (resp) => {
                setBtnLoader(false);
                if (resp.status === 1) {
                    // setStatus(1);
                    navigate(`/pixa-support/projects/${currentPath}`) ; 
                    // window.location.href=`/pixa-support/projects/${currentPath}`
                }
            });
        }
       

    }
   
    return (

        <>

            <div className='proj_mail_set_form pxl-createTicket-wrapper'>
                <div className='sup_proj_setting_form pxl-mail-setting-wrap'>
                    <h4 className='proj_main_head'>Create Ticket</h4>
                    <>
                        <div className='pxl-ticket-create-wrap'>
                            <div className="pxl-ticket-create-row12">
                                <div className="pxl_input_feilds pxl-placeholder-color1 pxl-ticket-input-bottom-space">
                                    <label> Customer Email <span className="pxl-star-red">*</span></label>
                                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="xs_input" placeholder="Enter Customer Email" />

                                    {/* <input type="text" name="" placeholder="Enter Customer Email" value={cEName} onChange={(e) => setCEName(e.target.value)} /> */}
                                </div>
                                <div className="pxl_input_feilds pxl-placeholder-color1 pxl-ticket-input-bottom-space">
                                    <label>Issue title <span className="pxl-star-red">*</span></label>
                                    <input type="text" className="xs_input" value={subject} onChange={(e) => setSubject(e.target.value)} placeholder="Enter Issue Title" />
                                    {/* <input type="text" name="" placeholder="Enter Issue title" value={iTitle} onChange={(e) => setITitle(e.target.value)} /> */}
                                </div>
                            </div>
                            <div className="pxl-ticket-status-type">
                                <div className="pxl_input_feilds pxl_input_feilds_placeholder pxl-placeholder-color1 pxl-ticket-input-bottom-space">
                                    <label>Status <span className="pxl-star-red">*</span></label>
                                  
                                    <Select
                                       
                                        value={selectedStatus}
                                       
                                        options={statusList}
                                        onChange={(option) => setSelectedStatus(option)}
                                    />
                                  
                                </div>
                                <div className="pxl_input_feilds pxl-placeholder-color1 pxl_input_feilds_placeholder pxl-ticket-input-bottom-space">
                                    <label htmlFor="exampleFormControlSelect2">Type <span className="pxl-star-red">*</span></label>
                                    <Select
                                        value={selectedType}
                                        options={typeList}
                                        onChange={(option) => setSelectedType(option)}
                                        
                                    />
                                   
                                </div>
                            </div>
                            <div className='pxl-create-ticket-radio-box'>
                            <div className="cun-radiobox-wrapper">
                                    <div className="pxl_input_feilds pxl-placeholder-color1">
                                        <label>Priority <span className="pxl-star-red">*</span></label>
                                    </div>
                                    {priorityList.map((option, i) => (
                                        <div key={i} className="cun-custom-radioBtn">
                                            <input type="radio" id={option._id} value={selectedPriority} checked={selectedPriority === option._id} name={'media_type'} onChange={(e) => setSelectedPriority(option._id)} />
                                            <label htmlFor={option._id} style={{ textTransform: 'capitalize' }}>{option.name}</label>
                                        </div>
                                    ))
                                    }
                                   
                                </div>
                            </div>
                        </div>
                       <div className="pxl-create-ticketBox">
                       <div className="pxl_input_feilds pxl-placeholder-color1 pxl-ticket-editor pxl-ticket-input-bottom-space">
                            <label>Message <span className="pxl-star-red">*</span></label>
                            <CKEditor
                                editor={ClassicEditor}
                                data={editorData}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setEditorData(data);
                                }}
                            />
                            
                        </div>
                        <div className="pxl-uploadAttachment">
                            <label>
                                Attachment
                            </label>

                            <div className="pxl-custom-file-upload pxl-upload-file-profile pxl-ticket-file-uplod">
                                <div className="pxl-custom-file-select">
                                    <div className="pxl-custom-file-select-button" id="fileName">
                                        {/* <img src="/assets/images/newpost/file-upload1.svg" alt="" /> */}
                                        <p>+</p>
                                    </div>
                                    <div className="pxl-custom-file-select-name" id="noFile">
                                        {/* <span>Upload</span> */}
                                        {/* {state.profile ? <span>{state.profile.name}</span> : <span> <b>Upload</b> <span className='pxl-upload-file-text'>or drop your file here</span> </span>} */}
                                    </div>
                                    <input type="file" name="chooseFile" id="chooseFile"
                                    multiple
                                        onChange={(e) => { attachmentFile(e); e.target.value = ''; }}

                                    />
                                    
                                  
                                </div>
                                {attachmentFileURL ?
                                        attachmentFileURL.map(url => <div className={styles.Ticket_attach_file_box} key={url}>
                                            <label>
                                                <div className={styles.Ticket_file_preview}>
                                                    <img src={url} alt="" />
                                                </div>
                                            </label>

                                            <Tooltip title="Remove" placement="top" arrow>
                                                <span className="xs_remove_icon" onClick={() => {

                                                    setAttachmentFileURL(current =>
                                                        current.filter(data => {
                                                            return data !== url;
                                                        }))
                                                    let indexToRemove = attachmentFileURL.indexOf(url);
                                                    setAttachFile(current =>
                                                        current.filter(data => {
                                                            return attachFile.indexOf(data) !== indexToRemove;
                                                        }))

                                                }}>x</span>
                                            </Tooltip>

                                        </div>) : null
                                    }
                            </div>
                        </div>
                       </div>

                       <div className="pxl-ticketBtn">
                       <button className="sch-btn-iconLeft" onClick={(e) => createTicketSubmit(e)} disabled={btnLoader}><span><img src="/assets/images/plus-svg.svg"/></span>{btnLoader ? "Processing..." : 'Create Ticket'}</button>
                       </div>
                     
                    </>
                </div>
            </div>

        </>

    )
}

export default TicketCreate
