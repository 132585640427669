import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import svg from '../svg';
import { assetsUrlPath, commonToast, hitApi,fieldsValidations } from '../commonAction';
import Cookies from "js-cookie";
import { useStore } from "../../zustand/store";

function CreateBrandPopUp({
    openModal,
    handleClose,
    type,
    callBrand,
    editRecords
}) {

    const [title, setTitle] = useState('');
    const [iconUrl, setIconUrl] = useState();
    const [iconFile, setIconFile] = useState('');
    const [btnLoader, setBtnLoader] = useState(false);
    // const [success, setSuccess] = useState(false);
    let store = useStore(state => state);
    let { currentBrandId, role, scope } = store.userData;
    let success=false
    useEffect(() => {
        setTitle(editRecords ? editRecords.title : '');
        setIconUrl(editRecords ? assetsUrlPath(editRecords.icon?.original.path) : '');
    }, [editRecords])

let F_name;
    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = fieldsValidations(title,F_name="Title")   
        if(isValid){   
        let url, method;
        if (type == 'firstcreate' || type == 'create') {
            url = 'brand/add-brand';
            method = 'POST';

        } else {
            url = 'brand/update-brand';
            method = 'PATCH';
        }

        if(!title.trim()) {
            commonToast('error', ` Brand name is required.`);
            return false
        } else if(!iconFile && !iconUrl) {
            commonToast('error', `Logo is required.`);
            return false
        }
        const formData = new FormData();
        formData.append('title', title);
        iconFile && formData.append('icon', iconFile);

        if (type === 'edit') formData.append("target", editRecords._id);
        setBtnLoader(true)
        hitApi({
            url: url,
            method: method,
            data: formData,
            alert: true,
            isFormData: true
        }, (resp, err = null) => {

            setBtnLoader(false)
            success=true;
            if (resp.status) {
                success=true;
                if(type == 'firstcreate'){
                    Cookies.set('authToken', resp?.data?.token, { expires: +process.env.REACT_APP_TOKEN_LIFE })
                    store.updateStore("userData", { ...store.userData, currentBrandId:resp.data.currentBrandId,token:resp?.data?.token});
                }
                (type !== 'firstcreate') && callBrand();
                closepopup()
            }
        });
    }

    }


    const uploadImage = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type.includes('image/')) {
                const url = URL.createObjectURL(file);
                setIconUrl(url);
                setIconFile(file);
            } else {
                commonToast('error', `Only PNG and JPG are allowed!`);

            }
        }

    }
    const closepopup = () => {
        let goAhead = type === 'firstcreate' ? success : true;
        if (goAhead) {
            setBtnLoader(false)
            // setSuccess(false)
            handleClose(false);
            setTitle('');
            setIconFile('');
            setIconUrl('');
            (type !== 'firstcreate') && callBrand();
        }    

    }

    return (
        <>
            <Modal
                show={openModal} onHide={closepopup} animation={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv "
            >
                <Modal.Header closeButton={type != 'firstcreate'}>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {(type === 'edit') ? 'Update Brand' : 'Create Brand'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit} className='create_brand'>
                        {type == 'firstcreate' && <p className='brand_note'> <b>Note</b> : It appears that you have not created a brand yet. Please create a brand to proceed. </p>}
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Brand Name <span className="pxl-star-red">*</span></label>
                            <input type="text" className="form-control" minLength={3} maxLength={20} value={title} placeholder="Enter Brand Title " onChange={(e) => setTitle(e.target.value)} />
                        </div>
                        <div className="pxl_input_feilds mb-4">
                            <label>Logo  <span className="pxl-star-red">*</span></label>
                            <div className="pxl-custom-file-upload pxl-upload-file-profile pxl-uploadFile-height">
                                <div className="pxl-custom-file-select">
                                    {
                                        iconUrl ? <div className='image_url_wrap'> <img src={iconUrl} width={'60px'} />
                                            <a className="img_Cross" onClick={() => {
                                                setIconFile('');
                                                setIconUrl('')
                                            }}>  {svg.app.crossSvg1}</a>
                                        </div> : <>
                                            <div className="pxl-custom-file-select-button" id="fileName">
                                                <img src="/assets/images/newpost/file-upload1.svg" alt="" />
                                            </div>
                                            <div className="pxl-custom-file-select-name" id="noFile">
                                                <span> <b>Upload</b> <span className='pxl-upload-file-text'>or drop your file here</span> </span>
                                            </div>
                                        </>
                                    }

                                    <input type="file" name="chooseFile" id="chooseFile" onChange={(e) => uploadImage(e)} />
                                </div>
                            </div>
                        </div>
                        <button disabled={btnLoader} type="submit" className="pxl_btn">{btnLoader ? 'Processing...' : (type === 'edit') ? "Update" : "Create"}</button>
                    </form>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default CreateBrandPopUp