import { useState, useEffect } from 'react'
import { commonToast, hitApi } from '../../components/commonAction';
import { useStore } from '../../zustand/store';
import appConfig from '../../config/appConfig';
import svg from '../pixa-support/components/svg';
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import makeAnimated from 'react-select/animated';

function DomainSetting({ p_id, d_domain,setActiveTabMenu }) {
    const [activeType, setActiveTab] = useState('Sub Domain');
    let store = useStore(state => state);
    const animatedComponents = makeAnimated();
    let userData = store.userData
    const [domainRadioActive, setDomainRadioActive] = useState('sub_domain');
    const [domainName, setDomainName] = useState('');
    const [customDomainName, setCustomDomainName] = useState('');
    const [currDomain, setCurrDomain] = useState('');

    const [isVerify, setIsVerify] = useState(true)
    const [isConnected, setIsConnected] = useState(false)
    const [isEdit, setIsEdit] = useState(false)

    const [isError, setIsError] = useState(false);
    const [showPoints, setShowPoints] = useState(true)
    const [showCustomDomIpt, setCustomDomIpt] = useState(true);
    const [isDomainWaiting, setIsDomainWaiting] = useState(true);
    const [showModelPopup, setShowModelPopUp] = useState(false);
    const [seoDomain, setSeoDomain] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);

    const regDomainName = new RegExp(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/)

    useEffect(() => {
        setSeoDomain(d_domain)
    }, [d_domain])

    // const fetchDomain = (method, url, data) => {

    //     hitApi({
    //         url: url,
    //         method: method,
    //         data: data,
    //         alert: false
    //     }, (resp) => {
    //         if (resp.status === 1) {
    //             if (resp.data.domain_type) {
    //                 (resp.data.domain_type == 1) ? setDomainRadioActive('sub_domain') : setDomainRadioActive('custom_domain');
    //                 if (resp.data.domain_type === 1) {
    //                     if (resp.data.domain_name) setDomainName(resp.data.domain_name.split('.')[0]);
    //                     // setIsVerify(true)
    //                     setIsConnected(false);
    //                     setCustomDomIpt(true);
    //                     setCurrDomain(resp.data.domain_name);
    //                 } else {
    //                     setCustomDomIpt(false);
    //                     setCustomDomainName(resp.data.domain_name);
    //                     setCurrDomain(resp.data.domain_name);
    //                     if (resp.data.domain_verified) {
    //                         setIsConnected(true);

    //                         setIsDomainWaiting(false);
    //                     } else {

    //                         setIsConnected(true);

    //                         setIsDomainWaiting(true);
    //                     }
    //                 }
    //             }
    //             setIsEdit(false)
    //             setIsError(false)
    //         } else {
    //             setIsError(true);
    //         }
    //     });

    // }

    // const getDomain = () => {
    //     let url, data;
    //     // if (domainfor != 'project') {
    //     url = 'pixa-support/api/getUserDetails?user_id=' + userData.userId;
    //     fetchDomain('GET', url, '')
    //     // } else {
    //     //     url = 'pixa-support/api/project_details';
    //     //     data = { project_id: p_id, };
    //     //     if (p_id) fetchDomain('POST', url, data)
    //     // }
    // }


    // const addDomain = (e) => {
    //     e.preventDefault();
    //     if (activeType === 'Sub Domain') {

    //         const urlname = new URL(appConfig.appUrl);
    //         const isDomainName = regDomainName.test(domainName);
    //         if (!domainName) {
    //             commonToast('error', `Field can not be empty!`);
    //             return false;
    //         } else if (isDomainName) {
    //             commonToast('error', `Enter valid domain name!`);
    //             return false;
    //         } else {
    //             let url, data;
    //             // if (domainfor != 'project') {
    //             // url = 'pixa-support/api/update_agency_domain';
    //             // data = {
    //             //     'domain_name': domainName + "." + urlname.hostname,
    //             //     'domain_type': 1
    //             // }

    //             // } else {
    //             url = 'page-builder/update-domain';
    //             data = {
    //                 'domain_name': domainName + "." + urlname.hostname,
    //                 'domain_type': 1,
    //                 project_id: p_id,
    //                 domain: seoDomain
    //             };

    //             // }
    //             hitApi({
    //                 url: url,
    //                 method: 'POST',
    //                 data: data,
    //                 alert: true
    //             }, (resp) => {
    //                 if (resp.status === 1) {

    //                     getDomain();
    //                     setCustomDomainName('');
    //                     setCurrDomain(resp.data.domain_name.split('.')[0])
    //                 }
    //             });

    //         }
    //     }
    //     if (activeType === 'Custom Domain') {
    //         if (currDomain != customDomainName) {
    //             const customdomainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    //             // const isDomainName = validator.isURL(customDomainName, { require_protocol: false });
    //             const isCusDomainName = customdomainRegex.test(customDomainName);

    //             if (!customDomainName) {
    //                 commonToast('error', `Field can not be empty!`);
    //                 return false;
    //                 // } 
    //                 // else if (!isDomainName) {
    //                 //     commonToast('error', `Enter valid domain name!`);
    //                 //     return false;
    //             } else if (!isCusDomainName) {
    //                 commonToast('error', `Enter valid domain name!`);
    //                 return false;
    //             } else {
    //                 // setShowVerifyTable(true)
    //                 let url, data;
    //                 // if (domainfor != 'project') {
    //                 //     url = 'pixa-support/api/update_agency_domain';
    //                 //     data = {
    //                 //         'domain_name': customDomainName,
    //                 //         'domain_type': 2
    //                 //     }
    //                 // } else {
    //                 url = 'pixa-support/api/update_Project_Domain';
    //                 data = {
    //                     'domain_name': customDomainName,
    //                     'domain_type': 2,
    //                     project_id: p_id,
    //                     user_id: userData.userId
    //                 };

    //                 // }
    //                 hitApi({
    //                     url: url,
    //                     method: 'POST',
    //                     data: data,
    //                     alert: true
    //                 }, (resp) => {
    //                     if (resp.status == 1) {
    //                         setDomainName('')
    //                         setCustomDomIpt(false)
    //                         setCurrDomain(resp.data.domain_name)
    //                         setIsConnected(true);
    //                         setIsDomainWaiting(true)

    //                     }
    //                 });

    //             }
    //         }
    //     }
    // }

    const addDomain = (e) => {
        e.preventDefault();

        if (!seoDomain) {
            commonToast('error', `Domain is required!`);
        } else if (!regDomainName.test(seoDomain)) {
            commonToast('error', `Domain is invalid`);
        } else {
            setBtnLoading(true)
            if (p_id) {
                hitApi({
                    url: 'page-builder/update-domain',
                    method: "PATCH",
                    data: {
                        'target': p_id,
                        'domain': seoDomain
                    },
                    loading: true,
                    alert: true,
                }, (resp, err = null) => {
                    setBtnLoading(false)
                    if (resp.status === 1) {
                        setShowModelPopUp(false)
                        setTimeout(()=>{
                            setActiveTabMenu('project')
                        },2000)

                    }
                });

            }
        }
    }

    // const VerifyCustomDOmain = () => {

    //     fetch(`https://${customDomainName}/`, { mode: 'no-cors' }).then(async result => {
    //         let data = { 'custom_domain': customDomainName, };
    //         // if (domainfor != 'project') {
    //         //     data["agency_id"] = userData.userId
    //         // } else {
    //         data["project_id"] = p_id
    //         // }
    //         hitApi({
    //             url: 'pixa-support/api/Verify_custom_domain',
    //             method: 'POST',
    //             data: data,
    //             alert: true
    //         }, (resp) => {
    //             if (resp.status == 1) {
    //                 setIsConnected(true)
    //                 setIsDomainWaiting(false)
    //             }
    //         });

    //     }).catch(err => {
    //         setIsConnected(true)

    //         setIsDomainWaiting(true)
    //         commonToast('error', `Domain verification is pending!`);
    //     })
    // }

    // const radioBtnSubDomain = () => {

    //     setDomainRadioActive('sub_domain');
    //     if (currDomain == customDomainName && customDomainName) {

    //         setIsConnected(true);
    //         setCustomDomIpt(false)

    //     }
    // }

    useEffect(() => {
        document.body.classList.add('pxl-body-white');
        return () => {
          document.body.classList.remove('pxl-body-white');
        };
      }, []);
    return (
        <div className='sup_proj_setting_form install_script_wrap pxl-supportProject-wrap'>
            <div className="pxl-doaminAdd-input ">
                <label>Domain Settings <span className="pxl-star-red">*</span></label>
               <div className="pxl_input_feilds pxl-placeholder-color1">
               <input type="text" name="title" placeholder="Enter Domain " onChange={(e) => setSeoDomain(e.target.value)}
                    value={seoDomain ||''} />
                    <div className="pxl-addDomain-btn">
                    <button className='sch-btn' onClick={(e) => addDomain(e)}> {d_domain == ''||d_domain==undefined ? btnLoading ? "Processing..." : "Add Domain" : btnLoading ? "Processing..." : "Update Domain"}</button>
                    </div>
               </div>
            </div>
             
            <div className="pxl-domain-instructions">
                <ul>
                    <li><span>01.</span> Please use top-level domains only (sub-domains won’t work).</li>
                    <li>
                        <span>02.</span>Please enter your domain above without any www, http or https (domain.com for example).
                    </li>
                    <li>
                        <span>03.</span>Enter your domain name for example "mydomain.com" (without the quotation marks) and click the "Add Domain" button.
                    </li>
                    <li>
                        <span>04.</span>Copy this IP address for the A Record: 3.235.6.190
                    </li>
                    <li>
                        <span>05.</span>Log into your domain registrar account and find the option to add Host Records, which can usually be found in the Advanced DNS section. If you're struggling to find this, perform a Google search for "[Domain Registrar Here] how to change host records".
                    </li>
                    <li>
                        <span>06.</span>Select your type as "A Record".
                    </li>
                    <li>
                        <span>07.</span>Set Host as "@".
                    </li>
                    <li>
                        <span>08.</span>Paste the copied IP Address from step 4 into the value field.
                    </li>
                    <li>
                        <span>09.</span>Leave TTL as Automatic or 14400.
                    </li>
                    <li>
                        <span>10.</span>Click Save and you're done.
                    </li>
                    <li><b>Note:</b> It can take up to 48-72 hours to reflect.</li>
                </ul>

            </div>


            {/* <Modal
                show={showModelPopup} onHide={() => setShowModelPopUp(false)} animation={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv"
            >


                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className='pxl-model-header'>
                            <h2 className='modal-title'>Domain Validation</h2>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="pxl-domain-input">

                        <InputGroup>
                            <Form.Control
                                placeholder="Enter Domain Name"
                                aria-label="Recipient's username with two button addons"
                                onChange={(e) => setSeoDomain(e.target.value)}
                                value={seoDomain}
                            />
                            <Button variant="outline-secondary" onClick={(e) => addDomain(e)} className='pxl_btn'>{d_domain == '' ? btnLoading ? "Processing..." : "Add Domain" : btnLoading ? "Processing..." : "Update Domain"}</Button>
                        </InputGroup>
                        <div className="pxl-domain-instructions">
                            <ul>
                                <li><span>01.</span> Please use top-level domains only (sub-domains won’t work).</li>
                                <li>
                                    <span>02.</span>Please enter your domain above without any www, http or https (domain.com for example).
                                </li>
                                <li>
                                    <span>03.</span>Enter your domain name for example "mydomain.com" (without the quotation marks) and click the "Add Domain" button.
                                </li>
                                <li>
                                    <span>04.</span>Copy this IP address for the A Record: 3.235.6.190
                                </li>
                                <li>
                                    <span>05.</span>Log into your domain registrar account and find the option to add Host Records, which can usually be found in the Advanced DNS section. If you're struggling to find this, perform a Google search for "[Domain Registrar Here] how to change host records".
                                </li>
                                <li>
                                    <span>06.</span>Select your type as "A Record".
                                </li>
                                <li>
                                    <span>07.</span>Set Host as "@".
                                </li>
                                <li>
                                    <span>08.</span>Paste the copied IP Address from step 4 into the value field.
                                </li>
                                <li>
                                    <span>09.</span>Leave TTL as Automatic or 14400.
                                </li>
                                <li>
                                    <span>10.</span>Click Save and you're done.
                                </li>
                                <li><b>Note:</b> It can take up to 48-72 hours to reflect.</li>
                            </ul>
                           
                        </div>
                    </div>
                </Modal.Body>
            </Modal> */}

        </div>


    )
}

export default DomainSetting