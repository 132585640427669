import React, { useEffect, useState } from 'react'
import appConfig from '../../config/appConfig'
import SingleApiComp from './SingleApiComp'

function LeftLayout({ data, handleFilter }) {

    const [openFolder, setOpenFolder] = useState([]);
    const [activeMenu, setAtiveMenu] = useState('1');
    const [activeApiMenu, setAtiveApi] = useState('1');
    const [keyWordSearch, setKeywordSearch] = useState('');
    let [parentIdslist, setparentIdsList] = useState({});

    function findParentIds(data, parentIds ) {
       
        if (Array.isArray(data)) {
            arrayPar(data,parentIds)
            return
        }
        if (data.apis.length > 0) { 
          
            data.apis.forEach(element => {
                setparentIdsList(prev =>({...prev,[element.apiId] : parentIds}));
            });

        }
        if (data.folders.length > 0) {  
            !parentIds.includes(data.folderId) && parentIds.push(data.folderId);
            findParentIds(data.folders, parentIds)
        }

    }

    const arrayPar = (data,parentIds) => {
        data.forEach(val =>{
            parentIds && !parentIds.includes(val.folderId) && parentIds.push(val.folderId);
           findParentIds(val, parentIds ? parentIds : [])
        } )
    }

    useEffect(() => {
        arrayPar(data)
    }, [data])

    const handleOpenFolder = (val) => {
        if (openFolder?.includes(val)) {
            setOpenFolder(curr => curr.filter(cur => cur !== val))
        } else {
            setOpenFolder(curr => [...curr, val]);
            window.history.pushState({}, '', `#${val}`);
            setAtiveApi(val)

            const fileSection = document.querySelector(`[id="${val}"]`);
            if (fileSection) {
                fileSection.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            let id = window.location.hash.substring(1);
            if (id != activeMenu) {
                setAtiveMenu(id);
                if(parentIdslist[id]) setOpenFolder(parentIdslist[id])
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [window.location.hash])

 

    return (
        <div>
            <div className="api_logo_wrap">
                <a className="pxl_mainLogo">
                    <img src={appConfig.appLogo} alt="Logo" width={'165px'} />
                </a>
            </div>

            <div className="sch-search-section">
                <div className={`sch-search-row sch-search-bg-color`}>
                    <input placeholder="Search API" value={keyWordSearch}
                        type='text' onChange={(e) => setKeywordSearch(e.target.value)}
                        onKeyUp={async e => {
                            e.preventDefault();
                            if (e.key === 'Enter') {
                                handleFilter(keyWordSearch)
                            }
                        }}
                    />
                    <button type="button" className="sch-search-btn">
                        <img src="/assets/images/scheduler/search.svg" />
                    </button>
                </div>
            </div>

            <div className='pt-3'>
                <div className='single_Comp_api_wrap'>
                    <p style={{ color: '#fff' }} onClick={() => handleOpenFolder('1')} className={activeMenu == 1 ? 'activefile' : ''}>Introduction</p>
                </div>
                {
                    data.map((val, i) => <SingleApiComp key={i} apiRecord={val} openFolder={openFolder} setOpenFolder={handleOpenFolder} activeMenu={activeMenu} setAtiveMenu={(id) => setAtiveMenu(id)} />)
                }
            </div>

        </div>
    )
}

export default LeftLayout