
import { Helmet } from "react-helmet";

import svg from "../components/svg";
import Sidebar from "../components/Sidebar";
import { useEffect, useState } from "react";
import { NoDataFoundDataTable,NoDataFound ,Pagination, commonToast, hitApi, setMyState, skeletonLoader, validations } from "../components/commonAction";
import { Modal } from 'react-bootstrap';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { Tooltip } from "@mui/material";


const TeamMember = ({ pageTitle }) => {


    const [state, setState] = useState({
        data: [],
        limit: 10,
        page: 1,
        keyword: '',
        sort: 'createdAt=-1',
        role: '',
        status: 3,
        totalRecords: '',
        userLoading: false,
        clearKeyword: false,
    });
    const [user, setUser] = useState({
        name: '',
        email: '',
        password: '',
        role: '',
        target: '',
        confirmPass: '',
        status: '',
        isEdit: false
    })
    const [btnLoading, setBtnLoading] = useState(false);
    const [selectedScope, setSelectedScope] = useState([]);
    const [checkedScope, setCheckedScope] = useState({});

    let objScope = [
        // { value: 'builder', label: 'Builder', accessOf: ['builder'] },
    { value: 'scheduler', label: 'Scheduler', accessOf: ['schedule'] },
    { value: 'images', label: 'Images', accessOf: ['images'] },
    { value: 'support', label: 'Support', accessOf: ['add project', 'ticket reply'] }];
    const [gtoggle, setGtoggle] = useState(1);

    useEffect(() => {
        getUserList();
    }, [state.page, state.status, state.role, state.clearKeyword])

    const clearSearch = () => {
        setMyState(setState, { ...state, clearKeyword: true, keyword: '' });
    }

    const getUserList = () => {
        setMyState(setState, { ...state, userLoading: true });
        let { limit, page, keyword, sort, role, status } = state;
        let data = { limit, page: keyword ? 1 : page, keyword, sort, role, };
        if (status != 3) {
            data['status'] = status;
        }

        hitApi({
            url: `team-member/member-list`,
            method: "GET",
            data: data
        }, (resp) => {
            if (resp.status) {
                const response = resp.data.records;

                setMyState(setState, {
                    ...state,
                    // limit: response.length,
                    data: response,
                    userLoading: false,
                    totalRecords: resp.data.totalRecords
                });
            }
        });
    }

    const emptyUserState = () => {
        setMyState(setUser, {
            name: '', email: '', password: '', role: '', target: '', status: '', isEdit: false
        })
    }


    const [showModal, setShowModal] = useState(false);
    const handleClose = () => {
        setShowModal(false);
        setMyState(setUser, {
            name: '', email: '', password: '', role: '', target: '', isEdit: false, confirmPass: ''
        });
        setCheckedScope({});
        setSelectedScope([]);
    }


    const createUser = (e) => {
        e.preventDefault();
        let haveScope = checkedScope ? Object.keys(checkedScope).length : false;
        if (user.name && user.email && user.password && haveScope) {
            let isValid = validations(user.email, 'email') && validations(user?.password, 'password');
            if (isValid) {
                setBtnLoading(true);
                hitApi({
                    url: 'team-member/add-member',
                    method: "POST",
                    data: { ...user, scope: checkedScope }
                }, (resp) => {
                    setBtnLoading(false);
                    if (resp.status) {
                        handleClose();
                        getUserList();
                        emptyUserState();
                    }
                });
            }
        } else {
            const emptyField = !user.name ? 'Name' : !user.email ? 'Email' : !user.password ? 'Password' : 'Scope';
            commonToast('error', `${emptyField} is required.`);
        }
    }

    const updateUser = (e) => {
        e.preventDefault();
        let haveScope = checkedScope ? Object.keys(checkedScope).length > 0 &&
            Object.keys(checkedScope).every(key => checkedScope[key]?.[Object.keys(checkedScope[key]).length > 0 ? Object.keys(checkedScope[key])[0] : '']?.length > 0) : false;
        console.log("haveScope", haveScope, checkedScope)

        let isValid = true;
        if (!user.name) {
            commonToast('error', `Name is required.`);
            return false;
        }
        if (!((user.password && user.confirmPass) || (!user.password && !user.confirmPass))) {
            commonToast('error', `${!user.password ? 'Password' : 'Confirm Password'} is required.`);
            return false;
        }
        if (!haveScope) {
            commonToast('error', `Scope is required.`);
            return false;
        }
        let data = {
            name: user.name, target: user.target, role: user.role, status: user.status, scope: checkedScope
        }
        if (user.password && user.confirmPass) {
            if (user.password != user.confirmPass) {
                commonToast('error', `Password and confirm password should be same.`);
                return false;
            }
            isValid = validations(user?.password, 'password');
            data['password'] = user.password;
            data['confirmPassword'] = user.confirmPass;
        }


        if (isValid) {
            setBtnLoading(true);
            hitApi({
                url: 'team-member/update-member',
                method: "PATCH",
                data: data
            }, (resp, err = null) => {
                setBtnLoading(false);
                if (resp.status) {
                    handleClose();
                    getUserList();
                    emptyUserState();
                }
            });
        }
    }
    const updatestatus = (e, item) => {
        e.preventDefault();
        let data = {
            target: item?._id,
            status: e.target.checked ? 1 : 2
        }
        setBtnLoading(true);
        hitApi({
            url: 'team-member/update-member',
            method: "PATCH",
            data: data
        }, (resp, err = null) => {
            setBtnLoading(false);
            if (resp.status) {
                handleClose();
                getUserList();
                emptyUserState();
            }
        });
    }


    const handleScopeCheckBox = (value, type, scope, access) => {
        setCheckedScope((prevCheckedScope) => {

            const newCheckedScope = prevCheckedScope ? JSON.parse(JSON.stringify(prevCheckedScope)) : { ...prevCheckedScope };


            if (value) {
                newCheckedScope[scope] = {
                    ...newCheckedScope[scope],
                    [access]: newCheckedScope[scope]?.[access]
                        ? [...newCheckedScope[scope][access], type]
                        : [type],
                };
            } else {

                if (newCheckedScope[scope] && newCheckedScope[scope][access]) {
                    newCheckedScope[scope][access] = newCheckedScope[scope][access].filter((t) => t !== type);

                    if (newCheckedScope[scope][access].length === 0) {
                        delete newCheckedScope[scope][access];
                    }

                    if (Object.keys(newCheckedScope[scope]).length === 0) {
                        delete newCheckedScope[scope];
                    }
                }
            }

            return newCheckedScope;
        });
    };

    let animatedComponents = makeAnimated();

    let cntStart = ((state.page - 1) * state.limit) + 1, cnt = cntStart;


    const handleShow = (item) => {
        setShowModal(true)
        if (item) {
            setMyState(setUser, {
                name: item.name, email: item.email, password: '', role: item.role, target: item._id, isEdit: true, status: item.status
            });
            let resultArray = [];

            item?.scope && Object.keys(item?.scope).forEach(key => {

                let matchingObj = objScope.find(obj => obj.value === key);
                if (matchingObj) {
                    resultArray.push(matchingObj);
                }
            });

            setSelectedScope(resultArray);
            setCheckedScope(item?.scope);
        }
    };


    const updateScopeSelect = (option) => {
        setSelectedScope(option);
        setCheckedScope((prevCheckedScope) => {
            const newCheckedScope = prevCheckedScope ? JSON.parse(JSON.stringify(prevCheckedScope)) : { ...prevCheckedScope };
            console.log(newCheckedScope, "newCheckedScope");
            const filteredScope = Object.keys(newCheckedScope).filter((key) => {
                return option.some((selected) => selected.value === key);
            }).reduce((acc, key) => {
                acc[key] = newCheckedScope[key];
                return acc;
            }, {});
            option.forEach((selectedOption) => {
                if (!filteredScope[selectedOption.value]) {
                    filteredScope[selectedOption.value] = {};
                    selectedOption.accessOf.forEach((access)=> {
                        filteredScope[selectedOption.value][access] = ["read"];
                    });
                }
            });
            return filteredScope;
        });
    };
    return (
        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>
            <div className="pxl_mainWrapper">
                <Sidebar />
                <div className="pxl-teamMember-wrapper">
                    <div className="pxl_rightBar_assets pxl-bg-back-color-wrap">
                        {/* Count Card Widgets Section */}
                        <div className="pxl_pageTitle">
                            <h2>{pageTitle}</h2>
                        </div>

                        <div className="pxl-count-cards">
                            <div className="pxl_container">
                                <div className="pxl_grid">
                                    <div className="pxl_tableBox_filter pxlTeamMemberHead">
                                        <div className="pxl_tableData">
                                            <h5>Team Members ({state.totalRecords})</h5>
                                        </div>
                                        <div className="pxl_filterFeilds cun-users-resheader">

                                            <div className="sch-search-section">
                                                <div className="sch-search-row sch-search-bg-color-white">
                                                    <input type="text" value={state.keyword} placeholder="Search Member"
                                                        onChange={(e) => setMyState(setState, {
                                                            keyword: e.target.value,
                                                            clearKeyword: false
                                                        })}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
                                                                getUserList()
                                                            }
                                                        }}
                                                    />
                                                    <button type="button" className="sch-search-btn">
                                                        {!state.keyword ? <span> <img src="/assets/images/scheduler/search.svg" /> </span> : <span onClick={clearSearch}><img src="/assets/images/scheduler/search.svg" /></span>}

                                                    </button>
                                                </div>
                                            </div>
                                            <div className="pxl-temMember-add-btn" onClick={() => handleShow()}>
                                                <button className="sch-btn-iconLeft ">
                                                    <span><img src="/assets/images/plus-svg.svg" /></ span>Add New Member
                                                </button>
                                            </div>
                                            {/* <a href={void (0)} className="pxl_btn" onClick={() => handleShow()}>Add new Member</a> */}
                                        </div>
                                    </div>
                                    <div className="pxl_table table-responsive">
                                        {

                                            <table className="pxl_table">
                                                {state.data.length!='0'?<thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Scope</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>:""}
                                                <tbody>
                                                    {!state.userLoading ?
                                                        // state.data.length > 0 ?
                                                            state.data.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td><p>{state.page == 1 ? (index + 1) : (index + 1) + ((state.page - 1) * state.limit)}</p></td>
                                                                        <td><p className="pxlName">{item.name}</p></td>
                                                                        <td><p>{item.email}</p></td>
                                                                        <td>
                                                                            <div className="pxl_planHolder_table pxl-teamMember-plans">
                                                                                {
                                                                                    item.scope ? Object.keys(item.scope).map((key, i) => <span key={i} className="pxl_plans">{key}</span>) : " - "
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <Tooltip title={item?.status === 1 ? 'Active' : 'Inactive'} placement="top" arrow>
                                                                                <div className="xs_switch">
                                                                                    <input
                                                                                        id={'userChk_' + index}
                                                                                        type="checkbox"
                                                                                        value={item.status}
                                                                                        defaultChecked={item.status === 1 ? true : false}
                                                                                        onClick={(e) => { updatestatus(e, item) }}
                                                                                    /><label htmlFor={'userChk_' + index}><span className="xs_switch_icon"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </Tooltip>
                                                                        </td>

                                                                        <td>
                                                                            <div className="pxl_tableIcon_holder pxl-clientTooltip-edit">
                                                                                <a href={void (0)} className="pxl_tableIcon cun-iconTooltip" onClick={() => handleShow(item)}>{svg.app.editIcon}
                                                                                    <div className="cun-show-tooltip">
                                                                                        <p>Edit</p>
                                                                                    </div>
                                                                                </a>

                                                                            </div>
                                                                        </td>

                                                                    </tr>
                                                                )
                                                            })

                                                            // :
                                                            // (state.keyword) ? <NoDataFoundDataTable column={8} statement="You don't have member with this name." /> : <NoDataFoundDataTable column={8} statement="No member found." />
                                                        :
                                                        <tr ><td colSpan={8}>{skeletonLoader('table')}</td></tr>
                                                    }
            {(!state.userLoading && state.data.length === 0) && <NoDataFound title={`You haven't added any members yet. Feel free to add a new member by clicking the "Add New Member" button`} bgRemove={true} />}



                                                </tbody>
                                            </table>

                                        }
                                    </div>


                                    {state.data.length!='0'?<Pagination
                                        type="User"
                                        dataRange={{
                                            start: state.totalRecords ? cntStart : 0,
                                            end: state.totalRecords ? cntStart + state.data.length - 1 : 0,
                                        }}
                                        currentPage={state.page}
                                        totalRecords={state.totalRecords}
                                        perPage={state.limit}
                                        isLoading={state.userLoading}
                                        onClick={(pageNum) => {
                                            setMyState(setState, {
                                                page: pageNum,
                                            });
                                        }}
                                    />:""}
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={showModal} onHide={handleClose} animation={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv pxlTeamMember-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {user.isEdit ? "Edit Member" : "Add New Member"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="" action="" onSubmit={user.isEdit ? updateUser : createUser}>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1"> Name <span className="pxl-star-red">*</span></label>
                            <input type="text" className="form-control" value={user.name} placeholder="Enter Name" onChange={(e) => setMyState(setUser, {
                                name: e.target.value
                            })} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Email Address <span className="pxl-star-red">*</span></label>
                            <input type="email" className="form-control" value={user.email} disabled={user.isEdit} placeholder="Enter Email" onChange={(e) => setMyState(setUser, {
                                email: e.target.value
                            })} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Password {!user.isEdit && <span className="pxl-star-red">*</span>}</label>
                            <input type="password" className="form-control" value={user.password} placeholder="Enter Password" onChange={(e) => setMyState(setUser, {
                                password: e.target.value
                            })} />
                        </div>
                        {user.isEdit && <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Confirm Password</label>
                            <input type="password" className="form-control" value={user.confirmPass} placeholder="Enter Confirm Password" onChange={(e) => setMyState(setUser, {
                                confirmPass: e.target.value
                            })} />
                        </div>}

                        <div className="form-group scope">
                            <label htmlFor="exampleFormControlSelect2">Select Scope <span className="pxl-star-red">*</span></label>
                            {/* <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                // defaultValue={[colourOptions[4], colourOptions[5]]}
                                value={selectedScope}
                                isMulti
                                options={objScope}
                                onChange={(option) => setSelectedScope(option)}
                            /> */}
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                // defaultValue={[colourOptions[4], colourOptions[5]]}

                                value={selectedScope}
                                isMulti
                                options={objScope}
                                onChange={(option) => updateScopeSelect(option)}
                            />
                        </div>

                        {
                            selectedScope.map((scope) => (
                                <div className={`pxl_scope_wrapper pxlAddnew-member-field ${scope.label}_newMember-popup`} key={scope.value}>
                                    <h3>{scope.label}</h3>
                                    {
                                        scope.accessOf.map((val, i) => <div key={i} className="pxl_fields">
                                            <p className="pxlText-capital">{val} {`-`}</p>

                                            <div className="pxl-memberWrap">
                                                <div className="check_box_wrap">
                                                    <div className="cun-AllChecknoxSelect">

                                                        <div className="sch-custom-check-box ">
                                                            <div className="form-group">
                                                                <input type="checkbox" id={`${scope.label + i}_read`}
                                                                    // checked={checkedScope ? checkedScope[scope.value] ? checkedScope[scope.value][val]?.includes('read') : false : false}
                                                                    disabled
                                                                    checked={true}
                                                                    onChange={(e) => handleScopeCheckBox(e.target.checked, 'read', scope.value, val)} />
                                                                <label htmlFor={`${scope.label + i}_read`}>

                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    Read
                                                </div>

                                                <div className="check_box_wrap">
                                                    <div className="cun-AllChecknoxSelect">
                                                        <div className="sch-custom-check-box ">
                                                            <div className="form-group">
                                                                <input type="checkbox" id={`${scope.label + i}_write`}
                                                                    checked={checkedScope ? checkedScope[scope.value] ? checkedScope[scope.value][val]?.includes('write') : false : false}
                                                                    onChange={(e) => handleScopeCheckBox(e.target.checked, 'write', scope.value, val)} />
                                                                <label htmlFor={`${scope.label + i}_write`}>

                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    Write
                                                </div>
                                                <div className="check_box_wrap">
                                                    <div className="cun-AllChecknoxSelect">
                                                        <div className="sch-custom-check-box ">
                                                            <div className="form-group">
                                                                <input type="checkbox" id={`${scope.label + i}_delete`}
                                                                    checked={checkedScope ? checkedScope[scope.value] ? checkedScope[scope.value][val]?.includes('delete') : false : false}
                                                                    onChange={(e) => handleScopeCheckBox(e.target.checked, 'delete', scope.value, val)} />
                                                                <label htmlFor={`${scope.label + i}_delete`}>

                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    Delete
                                                </div>
                                            </div>
                                        </div>)
                                    }

                                </div>
                            ))
                        }




                        {/* <div className="sch-statusCheckbox sch-relativeClass">
                            {user.isEdit && <div className="sch-custom-check-box">
                                <form>
                                    <div className="form-group">
                                        <input type="checkbox" checked={user.status} className="form-check-input" id="exampleCheck1" onClick={(e) => setMyState(setUser, {
                                            status: e.target.checked
                                        })} />
                                        <label className="form-check-label" htmlFor="exampleCheck1">Status</label>
                                    </div>
                                </form>
                            </div>}
                        </div> */}

                        <button type="submit" className="pxl_btn"> {btnLoading ? "Processing..." : user.isEdit ? "Update" : 'Submit'}</button>
                    </form>
                </Modal.Body>

            </Modal>

        </>
    )
};

export default TeamMember;