
import Projects from "./projects";
import appConfig from "./../../config/appConfig";
import { Routes, Route } from "react-router-dom";
import BuilderSetting from "./BuilderSetting";
 
// import PrivateRoute from "../PrivateRoute"


const BuilderRouter = () => {
   
return (
    <>
        <Routes>
            {/* <Route path="/" element={<Projects pageTitle={`${appConfig.appName} | Dashboard`} />} /> 
            <Route path="/projects" element={<Projects pageTitle={`${appConfig.appName} | Projects`} />} /> 
            <Route path="/edit-project/:id?" element={<BuilderSetting pageTitle={`${appConfig.appName} | Project`} />} /> */}
            {/* <Route path="/" element={<PrivateRoute allowedRoles={['user','admin','teamMember',"client"]}> <Projects  pageTitle={`${appConfig.appName} | Dashboard`}/></PrivateRoute>} />
            <Route path="/projects" element={<PrivateRoute allowedRoles={['user','admin','teamMember',"client"]}> <Projects  pageTitle={`${appConfig.appName} | Projects`}/></PrivateRoute>} />
            <Route path="/edit-project/:id?" element={<PrivateRoute allowedRoles={['user','admin','teamMember',"client"]}> <BuilderSetting  pageTitle={`${appConfig.appName} | Project`}/></PrivateRoute>} /> */}


        </Routes>
    </>
)
};

export default BuilderRouter;