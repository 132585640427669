

import svg from './svg';
import ColorPicker from './ColorPicker';
import { Modal } from "react-bootstrap";
import { useEffect, useState } from 'react';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { commonToast, hitApi } from '../../../components/commonAction';
// import { callAPI } from '../helper/API';


function ProjectSettingTab({ p_id, projName,setActiveTabMenu }) {

    const [openAgentPopUp, setOpenAgentPopUp] = useState(false);
    const animatedComponents = makeAnimated();
    const colourOptions = [
        { value: 'Select Project', label: 'Select Project', color: '#0052CC', isDisabled: true },
        { value: 'PixelNX', label: 'PixelNX', color: '#00B8D9', isFixed: true },
        { value: 'OverLap AI', label: 'OverLap AI', color: '#0052CC', isFixed: true },
        { value: 'VidExplo AI', label: 'VidExplo AI', color: '#5243AA' },
        { value: 'wpdefense', label: 'wpdefense', color: '#FF5630', isFixed: true },
    ];
    const [isError, setIsError] = useState(false);
    const [projectName, setProjectName] = useState(projName);
    const [seoTitle, setSeoTitle] = useState('');
    const [seoDescription, setSeoDescription] = useState('');
    const [filterAgent, setFilterAgent] = useState([]);
    const [allAgents, setAllagents] = useState([]);

    const [seoKeyword, setSeoKeyword] = useState([]);
    const [seoKeywordInput, setSeoKeywordInput] = useState('')

    const [agentsList, setAgentsList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [agentIds, setAgentIds] = useState([]);
    const [shownoData, setShowNoData] = useState(false);

    const [logoURL, setLogoURL] = useState('');
    const [logoFile, setLogoFile] = useState('');

    const [faviconURL, setFaviconURL] = useState('');
    const [faviconFile, setFaviconFile] = useState('');

    const [loginImageURL, setLoginImageURL] = useState('');
    const [loginImageFile, setLoginImageFile] = useState('');
    const [signUpContent, setSignUpContent] = useState('');
    const [signUpHeading, setSignUpHeading] = useState('');

    const[ btnLoading, setBtnLoading]=useState(false)
    const [addAgentPopupStatus, setAddAgentPopupStatus] = useState(false);

    const [colorTheme, setColorTheme] = useState('#039df4');

    const img_path = `${process.env.s3URL}usercontent/`;
    // const userData = useSelector(store => store.userData);
    // const dispatch = useDispatch();
    // console.log("colorTheme",colorTheme)

    useEffect(() => { 
        // if (userData.role === 1 ) {
        fetchProjectsDetail(p_id);
        fetchAgents();
        // }
    }, [p_id]);


    const fetchProjectsDetail = (id) => {
        if (id) {
            hitApi({
                url: 'pixa-support/api/project_details',
                method: "POST",
                data: {
                    project_id: id,
                },
                loading: true,
                alert: false,
            }, (resp, err = null) => {
                if (resp.status === 1) {
                    // dispatch(projectNameSetting(resp.data.project_name))
                    setProjectName(resp.data.project_name);
                    setSeoTitle(resp.data.seo_title ? resp.data.seo_title : '');
                    setSeoDescription(resp.data.seo_description ? resp.data.seo_description : '');
                    setSeoKeyword(resp.data.seo_keyword ? (resp.data.seo_keyword.split(',')) : []);
                    setColorTheme(resp?.data?.color_theme ? resp?.data?.color_theme : '#039df4');
                    // setLogoURL(resp.data.project_logo ? img_path + `project/${router.query.p_id}/` + resp.data.project_logo : '');
                    // setFaviconURL(resp.data.project_favicon ? img_path + `project/${router.query.p_id}/` + resp.data.project_favicon : '');
                    // setSignUpContent(resp.data.signup_content ? resp.data.signup_content : '');
                    // setLoginImageURL(resp.data.login_image ? img_path + `project/${router.query.p_id}/`+ resp.data.login_image  :'')
    console.log("colorTheme",resp.data.color_theme)
                    
                    const aids = [];
                    if (resp.data.agent_id) {
                        resp.data.agent_id.map(item => {
                            aids.push(item._id);
                        });
                    }
                    setAgentIds(aids);
                    setIsError(false)
                } else {
                    setIsError(true)
                }
            });

        }
    }

    const saveProject = (e) => {

    
        if (!projectName) {
            commonToast('error', `Project name is required!`);
            return false;
        // }

        //  else if (!seoTitle) {
        //     commonToast('error', `Project title is required!`);
        //     return false;
        // } else if (!seoDescription) {
        //     commonToast('error', `Project description is required!`);
        //     return false;
        // } 
        // else if (seoKeyword.length == 0) {
        //     commonToast('error', `Tags are required!`);
        //     return false;
        // } else if (!logoFile && !logoURL) {
        //     commonToast('error', `Project logo is required!`);
        //     return false;
        // } else if (!faviconFile && !faviconURL) {
        //     commonToast('error', `Project favicon is required!`);
        //     return false;
        } else {
            setBtnLoading(true)
        if (p_id) { 

        const formData = new FormData();
        let agent_id = agentIds.map(id => id.value)
        // formData.append('agent_id', JSON.stringify(agent_id));
        // formData.append('color_theme', colorTheme);
        formData.append('project_id', p_id);
        formData.append('project_name', projectName);
        // formData.append('seo_description', seoDescription);
        // formData.append('seo_keyword', seoKeyword ? seoKeyword.join() : '');
        // formData.append('seo_title', seoTitle);
        // if (loginImageFile) formData.append('login_image', loginImageFile);
        // if (signUpContent) formData.append('signup_content', signUpContent);

        // formData.append('project_logo', logoFile);
        // formData.append('project_favicon', faviconFile);
        hitApi({
            url: 'pixa-support/api/update_project_details',
            method: "POST",
            data: formData,
            loading: true,
            alert: true,
            isFormData: true
        }, (resp, err = null) => {
            setBtnLoading(false)
            if (resp.status === 1) fetchProjectsDetail(resp.data._id);
            setActiveTabMenu('mail')
        });

        }
        }
    }

    /* agent start */
    const fetchAgents = async (search = searchTerm) => {
        let data = { role: 'user' };

        hitApi({
            url: `team-member/member-list`,
            method: "GET",
            data: data
        }, (resp) => {
            if (resp.status) {
                const response = resp.data.records;
                if (resp.data.length === 0) {
                    setShowNoData(true);
                } else {
                    setShowNoData(false);
                }
                setAllagents(response);
                let data = response.map(val => ({ value: val._id, label: val.name }));
                console.log(data, "data");
                setAgentsList(data);

            }
        });
       

    };

  
    const deleteAgent = (id) => {
        if (id) {
            setAgentIds(prev => prev.filter(item => item.value != id));
            setFilterAgent(prev=>prev.filter(item => item._id != id))
        }
    }
  

    const uploadLogoImage = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type.includes('image/')) {
                const url = URL.createObjectURL(file);
                setLogoURL(url);
                setLogoFile(file);
            } else {
                commonToast('error', `Upload valid image! Only PNG and JPG are allowed!`);
            }
        }
    }
    const uploadFaviconImage = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type.includes('image/')) {
                const url = URL.createObjectURL(file);
                setFaviconURL(url);
                setFaviconFile(file);
            } else {
                commonToast('error', `Upload valid image! Only PNG and JPG are allowed!`);
            }
        }

    }
    const uploadLoginImage = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type.includes('image/')) {
                const url = URL.createObjectURL(file);
                setLoginImageURL(url);
                setLoginImageFile(file);
            } else {
                commonToast('error', `Upload valid image! Only PNG and JPG are allowed!`);
            }
        }

    }

    /* SEO Tag add start */
    const addSeoTag = (e) => {
        e.preventDefault();
        if (seoKeywordInput) {
            const newList = [...seoKeyword];
            newList.push(seoKeywordInput);
            setSeoKeyword(newList);
            setSeoKeywordInput('');
        }
    }
    /* SEO Tag add end */

    /* SEO Tag Delete start */
    const handleSeoTagDelete = (index) => {
        const newList = [...seoKeyword];
        newList.splice(index, 1);
        setSeoKeyword(newList);
    }
    /* SEO Tag Delete end */

    const addAgents = () => {
        let filterdata = allAgents.filter(val => { return agentIds.find(id => id.value == val._id) });
        setFilterAgent(filterdata);
        setOpenAgentPopUp(false);
    }

    return (
        <>


            <div className='sup_proj_setting_form pxl-pixasupport-projectSetting-wrap'>
                {/* <form onSubmit={saveProject}> */}
                <div className='pxl-project-setting-inner-wrap'>
                    <h4 className='proj_main_head'>Project Settings</h4>

                    <div className='proj_Setting_first_flex'>
                        <div className="pxl_input_feilds pxl-placeholder-color1">
                            <label>Project Name <span className="pxl-star-red">*</span></label>
                            <input type="text" name="projectname" value={projectName} onChange={(e) => setProjectName(e.target.value)} placeholder="Enter Projct Name" />
                        </div>
                        {/* <div className="pxl_input_feilds pxl-placeholder-color1">
                            <label>Title <span className="pxl-star-red">*</span></label>
                            <input type="text" name="title" placeholder="Enter Title" onChange={(e) => setSeoTitle(e.target.value)} value={seoTitle} />
                        </div>
                        <div className="pxl_input_feilds pxl-placeholder-color1">
                            <label>Description <span className="pxl-star-red">*</span></label>
                            <input type="text" name="description" placeholder="Enter Description" value={seoDescription} onChange={(e) => setSeoDescription(e.target.value)} />
                        </div> */}
                        {/* <div className='pxl_input_feilds'>
                            <label>Tags <span className="pxl-star-red">*</span></label>
                            <div className='pxl-tokenfield'>
                                <div className='pxl-tokenfield-inner'>
                                    {seoKeyword.length > 0 && seoKeyword.map((tag, index) => (
                                        <div className='pxl-tokenfield-child' key={index}>
                                            <p> {tag} <span><a onClick={() => handleSeoTagDelete(index)}>{svg.crossSvg1}</a></span></p>
                                        </div>
                                    ))}

                                </div>
                                <form onSubmit={(e) => addSeoTag(e)}>
                                    <div className='pxl-tokenfield-input pxl-placeholder-color1'>
                                        <input type='text' placeholder='Type and press enter to add tags' value={seoKeywordInput} onChange={(e) => setSeoKeywordInput(e.target.value)} />
                                    </div>
                                </form>
                            </div>
                        </div> */}
                        {/* <div className="pxl_input_feilds">
                            <label>Logo <span className="pxl-star-red">*</span> ( max 250x80) </label>
                            <div className="pxl-custom-file-upload pxl-upload-file-profile">
                                <div className="pxl-custom-file-select">
                                
                                    {logoURL ? <div className='image_url_wrap'> <img src={logoURL} width={'60px'} />
                                     <a className="img_Cross" onClick={() => {
                                        setLogoURL('');
                                        setLogoFile('')
                                     }}>
                                            {svg.crossSvg1}
                                        </a></div>

                                        : <>
                                            <div className="pxl-custom-file-select-button" id="fileName">
                                                <img src="/assets/images/newpost/file-upload1.svg" alt="" />
                                            </div>
                                            <div className="pxl-custom-file-select-name" id="noFile">
                                                <span> <b>Upload</b> <span className='pxl-upload-file-text'>or drop your file here</span> </span>
                                            </div>
                                            <input type="file" name="chooseFile" id="chooseFile" onChange={(e) => uploadLogoImage(e)} />
                                        </>

                                    }

                                </div>
                            </div>
                        </div> */}
                        {/* <div className="pxl_input_feilds">
                            <label>Favicon <span className="pxl-star-red">*</span> ( max 32x32)</label>
                            <div className="pxl-custom-file-upload pxl-upload-file-profile">
                                <div className="pxl-custom-file-select">
                                    {faviconURL ? <div className='image_url_wrap'> <img src={faviconURL} width={'60px'} />
                                    <a className="img_Cross" onClick={() => {
                                        setFaviconFile('');
                                        setFaviconURL('')
                                     }}>
                                            {svg.crossSvg1}
                                        </a>
                                    </div> : <>
                                        <div className="pxl-custom-file-select-button" id="fileName">
                                            <img src="/assets/images/newpost/file-upload1.svg" alt="" />
                                        </div>
                                        <div className="pxl-custom-file-select-name" id="noFile">
                                            <span> <b>Upload</b> <span className='pxl-upload-file-text'>or drop your file here</span> </span>
                                        </div>
                                        <input type="file" name="chooseFile" id="chooseFile" onChange={(e) => uploadFaviconImage(e)} />
                                    </>}
                                </div>
                            </div>
                        </div> */}

                        {/* <div className="pxl_input_feilds sup_proj_Color">
                            <label>Color Theme</label>
                            <ColorPicker colorVal={colorTheme} handleColorTheme={(color) => setColorTheme(color)} />
                        </div> */}
                    </div>
                </div>

                {/* <div className='lower_proj_settings'>
                    <div className='flex1 pxl-login-page-content'>
                        <h4 >Login Page Content Settings</h4>
                        <div className='proj_Setting_first_flex'>
                            <div className="pxl_input_feilds flex1 pxl-login-page-img-upload">
                                <label>Login Page Image (max 460x350)</label>
                                <div className="pxl-custom-file-upload pxl-upload-file-profile">
                                    <div className="pxl-custom-file-select">
                                        {loginImageURL ? <div style={{ width: '60px', }}> <img src={loginImageURL} width={'100%'} />
                                        <a className="img_Cross" onClick={() => {
                                            setLoginImageFile('');
                                            setLoginImageURL('')
                                        }}>
                                            {svg.crossSvg1}
                                        </a>
                                        </div> : <>
                                            <div className="pxl-custom-file-select-button" id="fileName">
                                                <img src="/assets/images/newpost/file-upload1.svg" alt="" />
                                            </div>
                                            <div className="pxl-custom-file-select-name" id="noFile">
                                                <span> <b>Upload</b> <span className='pxl-upload-file-text'>or drop your file here</span> </span>
                                            </div>
                                            <input type="file" name="chooseFile" id="chooseFile" onChange={(e) => uploadLoginImage(e)} />
                                        </>}
                                    </div>
                                </div>
                            </div>
                            <div className='col-flex-side flex1'>

                                <div className="pxl_input_feilds pxl-placeholder-color1">
                                    <label>SignUp Heading</label>
                                    <input type="text" name="heading" onChange={(e) => setSignUpHeading(e.target.value)} placeholder="Enter SignUp Heading" value={signUpHeading} />
                                </div>
                                <div className="pxl_input_feilds pxl-placeholder-color1">
                                    <label>SignUp Content</label>
                                    <input type="text" name="Content" onChange={(e) => setSignUpContent(e.target.value)} placeholder="Enter SignUp Content" value={signUpContent} />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='flex1'>
                        <div className='proj_head_Flex pxl-agent-head'>
                            <h4 className='proj_main_head'>Add Agent(s)</h4>
                           <div className='pxl-add-new-agent-btn'>
                                <button className="sch-btn-iconLeft " onClick={() => setOpenAgentPopUp(true)}>
                                    <span>
                                        <img src="/assets/images/plus-svg.svg" />
                                    </span>
                                    Add New Agent
                                </button>
                           </div>
                        </div>


                        <div className='agent_wrap'>
                            {filterAgent.length > 0 ?
                                <div className='agents_list_wrap'>
                                    {filterAgent.map((agent, index) => <div className='single_agent_wrap' key={index}>
                                        <div className='flex_box_img_contet'>
                                            <div className='img_box'>
                                                <img src='/images/img1.jpg' />
                                            </div>
                                            <div className='agent_detail'>
                                                <h5>{agent.name}</h5>
                                                <p>{agent.email}</p>
                                            </div>
                                        </div>
                                        <div className='agent_del' onClick={()=>deleteAgent(agent._id)}>{svg.agent_del_red}</div>
                                    </div>)}
                                </div> : <div className='sch-no-data-found-wrapper'>
                                    <div className='sch-no-data-inner'  style={{padding:'25px'}}>
                                        <img src='/images/support_no_data.png' />
                                        <h4>No Agent selected</h4>
                                    </div>

                                </div>
                            }
                        </div>

                    </div>
                </div> */}
                <div className='save_btn'>
                    <button className="pxlCommon-btn" onClick={() => {saveProject()} }>  {btnLoading ? "Processing..." : 'Save & Continue'}</button>
                    {/* <button className="sch-btn-iconLeft " onClick={() => {setActiveTabMenu('')} }> Back</button> */}
                </div>
                

                {/* </form> */}
            </div>

            <Modal
                show={openAgentPopUp} onHide={() => setOpenAgentPopUp(false)} animation={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv"
            >


                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className='pxl-model-header'>
                            <h2 className='modal-title'>Add New Agents</h2>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="form-group">
                            <label htmlFor="exampleFormControlSelect2">Select Agents</label>
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                // defaultValue={[colourOptions[4], colourOptions[5]]}
                                value={agentIds}
                                isMulti
                                options={agentsList}
                                onChange={(option) => setAgentIds(option)}
                            />
                        </div>
                        <div>
                            <button type="submit" className="pxl_btn" onClick={addAgents}>Continue</button>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default ProjectSettingTab