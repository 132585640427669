import React, { useEffect, useState } from 'react'
import svg from '../svg';
import { commonToast, hitApi } from '../../../../components/commonAction';
import { Modal } from 'react-bootstrap';
import ConfirmationPopup from '../../../../components/common/ConfirmationPopup';

function FeatureSection({ p_id, features }) {
    const [openPopUp, setOpenPopUp] = useState(false);

    const [isEdit, setIsEdit] = useState(false);

    // FOr Delete and Edit
    const [featureID, setFeatureID] = useState('');
    const [deletePopUp, setDeletePopUp] = useState(false);

    const [heading, setHeading] = useState('');
    const [description, setDescription] = useState('');
    const [featureUrl, setFeatureUrl] = useState('');
    const [featureFile, setFeatureFile] = useState('');

    const [featuresList, setFeaturesList] = useState([]);
    const img_path = `${process.env.s3URL}usercontent/`;


    useEffect(() => {
        if (features) {
            setFeaturesList(features);
        }
    }, [features])


    const handleAddfeature = () => {
        setOpenPopUp(true);
        setIsEdit(false);
        setHeading('')
        setDescription('')
        setFeatureUrl('')
        setFeatureFile('')
    }

    const getAllFeatures = () => {
        if (p_id) {
            hitApi({
                url: 'pixa-support/getLandingPage',
                method: "POST",
                data: {
                    project_id: p_id
                },
                alert: false,
            }, (resp, err = null) => {
                if (resp.status === 1) {
                    if (resp.featurePage) {
                        setFeaturesList(resp.featurePage);
                    }
                }
            });
        }
    }

    const PopupCloseHandler = () => {
        setOpenPopUp(false)
    }

    const uploadImage = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type.includes('image/')) {
                const url = URL.createObjectURL(file);
                setFeatureUrl(url);
                setFeatureFile(file);
            } else {
                commonToast('error', `Only PNG and JPG are allowed!`);

            }
        }

    }

    const handleEditFeature = (feature) => {
        setOpenPopUp(true);
        setIsEdit(true);
        setFeatureID(feature._id)
        setHeading(feature.heading);
        setDescription(feature.description);
        setFeatureUrl(img_path + `LandingPage/${feature.agency_id}/` + feature.feature_image);
    }

    const addFeatureFormSubmit = (e) => {
        e.preventDefault();

        if (!heading || !description) {
            commonToast('error', `Field can not be empty!`);
            return false;
        } else {

            const formData = new FormData();
            formData.append('heading', heading);
            formData.append('description', description);
            formData.append('featurepage_id', featureID);
            formData.append('project_id', p_id);
            if (featureFile) formData.append('feature_image', featureFile);

            if (!isEdit) formData.delete("featurepage_id");
            hitApi({
                url: 'pixa-support/api/manageFeaturePage',
                method: "POST",
                data: formData,
                loading: true,
                alert: true,
                isFormData: true
            }, (resp, err = null) => {
                if (resp.status === 1) {
                    getAllFeatures();
                    setOpenPopUp(false);
                    setHeading('');
                    setDescription('');
                    setFeatureUrl('');
                }
            });
        }
    }


    const handleDelete = (id) => {
        setDeletePopUp(true);
        setFeatureID(id);
    }


    return (
        <>
            <div className='mail_back_color_box'>

                {
                    featuresList.length == 0 ? <div className='sch-no-data-found-wrapper'>
                        <div className='sch-no-data-inner'>
                            <img src='/images/support_no_data.png' />
                            <h4>No Results Found</h4>
                            <p>We can not find any features for the this agency</p>
                            <div className='btn_center_add'>
                                <button className="sch-btn" style={{ gap: '6px' }} onClick={() => setOpenPopUp(true)}>
                                    <span>
                                        <img src="/assets/images/plus-svg.svg" />
                                    </span>
                                    Add Features
                                </button>
                            </div>

                        </div>
                    </div>

                        :
                        <>
                            {
                                featuresList.map((feature,index) => (
                                    <div className="support_single_feature_wrap" key={index}>


                                        <div className="single_agent_wrap">
                                            <div className="flex_box_img_contet">
                                                <div className="img_box">
                                                    <img src={feature.feature_image} />
                                                </div>
                                                <div className="feat_detail">
                                                    <h5>{feature.heading}</h5>
                                                    <p>{feature.description}</p>
                                                </div>
                                            </div>
                                            <div className="land_Action_edit" onClick={() => handleEditFeature(feature)}>
                                                {svg.edit_icon}
                                            </div>
                                            <div className="land_action_del" onClick={() => handleDelete(feature._id)}>
                                                {svg.delete_red}
                                            </div>
                                        </div>
                                    </div>
                                ))


                            }
                            <div className='btn_center_add'>
                                <button className="sch-btn" style={{ gap: '6px' }} onClick={handleAddfeature}>
                                    <span>
                                        <img src="/assets/images/plus-svg.svg" />
                                    </span>
                                    Add Features
                                </button>
                            </div>

                        </>



                }





            </div>
            <Modal
                show={openPopUp} onHide={() => setOpenPopUp(false)} animation={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv"
            >


                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className='pxl-model-header'>
                            <h2 className='modal-title'>Create New Feature</h2>
                        </div>
                    </Modal.Title>
                </Modal.Header>


                <form action="" className='sup_proj_setting_form model'
                    onSubmit={addFeatureFormSubmit}
                >
                    <div className="pxl_input_feilds mb-3">
                        <label>Heading</label>
                        <input id="" type="text" name="" placeholder="Enter Heading" value={heading} onChange={(e) => setHeading(e.target.value)} />
                    </div>
                    <div className="pxl_input_feilds mb-3">
                        <label>Sub Heading</label>
                        <textarea id="" type="text" name={description} placeholder="Enter Sub Heading" onChange={(e) => setDescription(e.target.value)} />
                    </div>

                    <div className="pxl_input_feilds mb-4">
                        <label>Banner</label>
                        <div className="pxl-custom-file-upload pxl-upload-file-profile">
                            <div className="pxl-custom-file-select">
                                {
                                    featureUrl ? <div className='image_url_wrap'> <img src={featureUrl} width={'60px'} />
                                        <a className="img_Cross" onClick={() => {
                                            setFeatureFile('');
                                            setFeatureUrl('')
                                        }}>  {svg.crossSvg1}</a>
                                    </div> : <>
                                        <div className="pxl-custom-file-select-button" id="fileName">
                                            <img src="/assets/images/newpost/file-upload1.svg" alt="" />
                                        </div>
                                        <div className="pxl-custom-file-select-name" id="noFile">
                                            <span> <b>Upload</b> or drop your file here </span>
                                        </div>
                                    </>
                                }

                                <input type="file" name="chooseFile" id="chooseFile" onChange={(e) => uploadImage(e)} />
                            </div>
                        </div>
                    </div>

                    <button type="submit" className="pxl_btn"> Add
                        {/* {btnLoading ? "Processing..." : 'Continue'} */}
                    </button>
                </form>

            </Modal>


            {/* Delete Pop Up */}
            <ConfirmationPopup
                shownPopup={deletePopUp}
                closePopup={() => {
                    setDeletePopUp(false);
                    setFeatureID('')
                }}
                type={"feature"}
                removeAction={() => {
                    hitApi({
                        url: 'pixa-support/api/deletePage',
                        method: "POST",
                        data: {
                            feature_id: featureID,
                        },
                        loading: true,
                        alert: true,
                    }, (resp, err = null) => {
                        if (resp.status === 1) {
                            getAllFeatures();
                            setDeletePopUp(false);
                            setFeatureID('');
                        }
                    });

                }}
            />
        </>
    )
}

export default FeatureSection