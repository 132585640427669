
import {Helmet} from "react-helmet";
import Sidebar from "../components/Sidebar"; 
import MyLibComponent from "./../components/common/myLibrary/MylibraryComponant"; 

const Dashboard = ({pageTitle}) => {
    
    
    return (
        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>
            <div className="pxl_mainWrapper">
                <Sidebar/>
                <div className="pxl-myLibraryPage-wrapper">
                <div className="pxl_rightBar_assets">
                    {/* Count Card Widgets Section */} 
                  
                    <div className="pxl_pageTitle">
                        <h2>{pageTitle}</h2>
                    </div>
                  
                    <div className="pxl-count-cards">
                        <div className="pxl_container">
                            <MyLibComponent 
                                title="My Library"
                                dataPerPage="25"
                                baseClass="fullPage"
                                onClickAct={(path) => {
                                    console.log(path);
                                }}
                            />
                        </div>
                    </div>
                    </div>
                </div>
            </div>

            
        </>
    )
};

export default Dashboard;