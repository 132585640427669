import { useEffect, useRef, useState } from "react"
import { NoDataFound, assetsUrlPath, hitApi, setMyState, skeletonLoader } from '../../../components/commonAction'
import schedulerSvg from "./schedulerSvg"
import svg from "../../../components/svg"
import AudioPlayer from "./AudioPlayer"


const NewpostData = [
    {
        id: 1,
        img: '/assets/images/newpost/img-newpost.png',
    },
]



const PixaStock = ({ baseClass = '', onClickAct = () => { }, mediaType = null }) => {

    const [state, setState] = useState({
        limit: 12,
        page: 1,
        keyword: '',
        sort: 'createdAt=-1',
        status: '',
        totalRecords: 0,
        assetTypeId: '',
        assetTypeTitle: '',
        subCategoryId: '',
        subCategoryTitle: "",
        mediaCountShow: 3,
        mediaId: '',
        activeSection: 'category',
        playMedia: null,
        mediaLoader: false,
        audioPlay: '',
        assetsTypeList: [],
        allAssetsList: [],
        subCategoryList: [],
        allSubCatList: [],
        mediaList: []
    });

    useEffect(() => {
        getAssetsType();
    }, [state.assetTypeId])

    useEffect(() => {
        getMedia();
        var myDiv = document.getElementById('pixastock_div');
        if (myDiv) {
            myDiv.scrollTop = 0;
        }

    }, [state.subCategoryId])

    const getAssetsType = () => {
        let data = {}
        if (state.keyword.trim()) {
            data = { keyword: state.keyword.trim() }
        }

        if (state.assetTypeId) {
            data = { ...data, categoryId: state.assetTypeId }
        } else {
            setMyState(setState, { assetTypeId: '', });
        }

        if (mediaType) {
            data.mediaType = mediaType;
        }

        setMyState(setState, { categoryLoading: true });

        hitApi({
            url: 'pixa-stock/get-assets-by-category',
            method: "GET",
            data: data
        }, (resp, err = null) => {
            setMyState(setState, { categoryLoading: false });
            if (resp.status) {
                let filterList = resp.data.filter(obj => obj.media.length > 0);
                setMyState(setState, { assetsTypeList: filterList });
            }
        });
    }


    const getMedia = () => {
        // setMyState(setState, { mediaLoader: true });
        let { page, sort, keyword } = state;

        if (state.subCategoryId) {
            setMyState(setState, { mediaLoader: true });
            hitApi({
                url: 'pixa-stock/get-pixa-stock-media',
                method: "GET",
                data: { limit: 'all', page, keyword, sort, category: state.assetTypeId, subCategory: state.subCategoryId }
            }, (resp, err = null) => {
                setMyState(setState, { mediaLoader: false });
                if (resp.status) {
                    const subcategoryMap = { _id: state.subCategoryId, title: state.subCategoryTitle };
                    subcategoryMap['media'] = resp.data.records
                    setMyState(setState, { assetsTypeList: [subcategoryMap] });
                }
            });
        }
    }

    const handleSearch = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
            if (state.subCategoryId) {
                getMedia();
            } else {
                getAssetsType(state.assetTypeId, state.subCategoryId);
            }

        }
    }

    return (
        <>
            <div className={`${baseClass}`}>
                <div className="sch-search-section">
                    <div className={`sch-search-row sch-search-bg-color`}>
                        <input placeholder="Search Stock Media"
                            type='text' onChange={(e) => setMyState(setState, { keyword: e.target.value })}
                            onKeyDown={(e) => handleSearch(e)}
                        />
                        <button type="button" className="sch-search-btn">
                            <img src="/assets/images/scheduler/search.svg" />
                        </button>
                    </div>
                </div>


                {state.categoryLoading ? [...Array(2)].map((i,index) => <span key={index} >{skeletonLoader('pixastock',"","","",index)}</span>) : 
                <div className="sch-post-img-main" >
                    {state.assetTypeId && <div className='sch-backBtn'>
                        <a onClick={() => {
                            if (state.subCategoryId) {

                                setMyState(setState, { subCategoryId: "" });
                                getAssetsType()
                            } else {

                                setMyState(setState, { assetTypeId: '', assetTypeTitle: '', subCategoryId: "" });
                            }

                            // getAssetsType()
                        }} ><span>{schedulerSvg.app.leftArrow}{state.assetTypeTitle}</span>
                        </a>
                    </div>}
                    {state.mediaLoader ? skeletonLoader('pixastock', 15,"","") : state.assetsTypeList.length > 0 ? state.assetsTypeList.map((subCat) => {
                        if (subCat.media.length != 0) {
                            return (
                               
                                    <div className="sch-post-img-main" key={subCat._id}>

                                        <div className="sch-post-heading-flex" key={subCat._id}>
                                            <h2>{subCat.title}</h2>

                                            {(state.subCategoryId && state.assetTypeId) ? null : subCat.media.length > 3 &&
                                                <h2><a onClick={() => {
                                                    if (state.assetTypeId) {
                                                        setMyState(setState, {
                                                            subCategoryId: subCat._id,
                                                            subCategoryTitle: subCat.title,
                                                        });
                                                        // getMedia(state.assetTypeId , subCat._id)
                                                    } else {
                                                        setMyState(setState, {
                                                            assetTypeId: subCat._id,
                                                            assetTypeTitle: subCat.title
                                                        })
                                                    }
                                                }
                                                }>View All</a></h2>
                                            }
                                        </div>
                                        <div className="sch-post-images">
                                            {
                                                subCat.media.length > 0 ? subCat.media.slice(0, state.subCategoryId ? subCat.media.length : 3).map((media) => {
                                                    let thumbObj = {
                                                        image: media?.files?.original?.thumb || media?.files?.original?.path,
                                                        video: media?.files?.original?.thumb || '/assets/images/newpost/video-newpost.png',
                                                        audio: '/assets/images/newpost/audio-newpost.png',
                                                    }

                                                    return (
                                                        <div className="sch-post-image-item" key={media._id}
                                                            onMouseEnter={e => {
                                                                if (media.mediaType != 'image') {
                                                                    setMyState(setState, {
                                                                        playMedia: media._id
                                                                    })
                                                                }
                                                            }}>


                                                            <div className="pxl-ImgBox" onClick={e => (media.mediaType != 'audio') && onClickAct({
                                                                id: media._id,
                                                                mediaType: media.mediaType,
                                                                thumb: media?.files?.original?.thumb ? assetsUrlPath(media?.files?.original?.thumb) : '',
                                                                path: assetsUrlPath(media?.files?.original?.path),
                                                                original: media?.files?.original
                                                            })}>
                                                                {
                                                                    state.playMedia == media._id ?
                                                                        (
                                                                            media.mediaType == 'audio' ? <AudioPlayer media={media} />

                                                                                :
                                                                                <video
                                                                                    preload="none"
                                                                                    src={assetsUrlPath(media.files.original.path)}
                                                                                    autoPlay={true}
                                                                                    muted={true}
                                                                                    playsInline disablePictureInPicture
                                                                                    onMouseLeave={e => {
                                                                                        setMyState(setState, {
                                                                                            playMedia: null
                                                                                        })
                                                                                    }}
                                                                                />
                                                                        )
                                                                        :
                                                                        <>
                                                                            <img
                                                                                src={assetsUrlPath(thumbObj[media.mediaType])} alt={media.title}
                                                                            />
                                                                            {(media.mediaType == 'video') && <span className={`sch-audioPlay`}>
                                                                                <a onClick={() => setMyState(setState, {
                                                                                    playMedia: media._id
                                                                                })}>
                                                                                    {state.playMedia != media._id && svg.app.PlaySvg}
                                                                                </a>
                                                                            </span>}
                                                                        </>


                                                                }
                                                            </div>

                                                        </div>
                                                    )

                                                }
                                                )
                                                    :
                                                    <div>No media found.</div>
                                            }

                                        </div>
                                    </div>
                               

                            )
                        } else {

                            return <NoDataFound title={state.keyword ? `You don't have asset with this name.` : 'No asset found.'} />
                        }
                    }
                    )
                        : 
                            <NoDataFound title={state.keyword ? `You don't have asset with this name.` : 'No asset found.'} />
                        

                    }
                </div>
                    // : null
                }
            </div>
        </>
    )
}
export default PixaStock;

