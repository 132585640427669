import React, { useEffect, useRef, useState } from 'react'
// import PropTypes from 'prop-types'
import {emojiObject} from './emojiObjList';
import './emojibox.css';
import svg from '../svg';
import {Helmet} from "react-helmet";
import { useOutsideClick } from '../commonAction';

function EmojiBox({ targetInputId , parentEmojiBoxClass, icon = "😃", boxPlacement = "top" }) {
    const [keyword, setKeyword] = useState('')
    const [emojiTitle, setEmojiTitle] = useState(null);
    const [emojiArray, setEmojiArray] = useState([]);
    const [filteredEmoji, setFilteredEmoji] = useState([]);
    let [showEmojiBox, setShowEmojiBox] = useState(false);
    let emojiPsrentElement = document.getElementsByClassName(parentEmojiBoxClass);
    // element.style.backgroundColor = 'red';
    var input = document.querySelector (targetInputId); 

    useEffect(() => {
        let emojiAry = [];
        emojiObject.map(d => {
            emojiAry = [...emojiAry, ...d.list];
        })
        setEmojiArray(emojiAry); 
    }, []);

    useEffect(() => {
        if(keyword != ''){
            // keyword = keyword.toLocaleLowerCase()
            let ttt = emojiArray.filter(dd => {
                let title = dd.title.toLocaleLowerCase();
                if(title.includes(keyword)){
                    return dd;
                }
            })
            setFilteredEmoji(ttt);
        }
    }, [keyword]);
    
    const scrollToElement = (index, title) => {
        setEmojiTitle(title);
        let t = document.querySelector(`[data-index="${index}"]`);
        t.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    let addEmoji = (icon) => {
        handleButtonClick(icon);
    }

    function handleButtonClick(icon) {

        var inputValue = input.value;
     
        // For the sake of simplicity, we're going to pull the insertable text right
        // out of the DOM structure. Fun fact, using .textContent will pull the emoji
        // glyph that was generated by HTML entities.
        var insertToken = icon;
         
        // NOTE: Even after the input is blurred, it appears to retain its last-known
        // selection properties. As such, we don't have to track those through the
        // life-cycle of the page - we can just reference them at any time.
        var insertTokenAt = input.selectionEnd;
        // After we insert the text, we're going to want to re-focus the input.
        // However, we're going to want to advance the selection such that it starts
        // just after the inserted text.
        var nextSelectionEnd = ( insertTokenAt + insertToken.length );

        // Insert the text at the given location within the input.
        input.value = ( inputValue.slice( 0, insertTokenAt ) + insertToken + inputValue.slice( insertTokenAt ) );
        // Advance the text selection to just after the inserted text.
        input.selectionStart = nextSelectionEnd;
        input.selectionEnd = nextSelectionEnd;
        input.focus();
    }
    
    const menuRef = useRef();
    const emojiBox = useRef();
    
    function useOutside(emojiBox,menuRef, handler) {
        useEffect(
            () => {
                const listener = (event) => {
                    if (!emojiBox.current || emojiBox.current.contains(event.target) || !menuRef.current || menuRef.current.contains(event.target) ) {
                        return;
                    }
                    handler(event);
                };
                document.addEventListener("mousedown", listener);
                document.addEventListener("touchstart", listener);
                return () => {
                    document.removeEventListener("mousedown", listener);
                    document.removeEventListener("touchstart", listener);
                };
            },
    
            [emojiBox,menuRef, handler]
        );
    }

    useOutside(emojiBox,menuRef,() => setShowEmojiBox(false));



    return (
        <>
            <Helmet >
            <style> 
                {`.${parentEmojiBoxClass}{
                    position: relative;
                    z-index: 91;
                    
                }`}
            </style>
            </Helmet>
            <a onClick={() => setShowEmojiBox(!showEmojiBox)} ref={menuRef}>{icon}</a>
            <div className='emoj_mainwrapper' style={{ display : !showEmojiBox ? "none" : ''}} placement={boxPlacement} ref={emojiBox}>
                <div className='emoj_innerBox'>
                    <div className='emoj_searchBox'>
                        <form className='emoj_searchForm'>
                            <input className='emoj_input' type='search' placeholder='Search emoji' onKeyUp={e => setKeyword(e.target.value.toLocaleLowerCase())} />
                            {/* <span className='emoj_searchIcon'>{svg.app.searchIcon}</span> */}
                        </form>
                        <div className='emoj_scrollableBox'>
                            <div className='emoj_sectionsScroll'>
                                {
                                    keyword == '' && emojiObject.map((d, i) => {
                                        return <div key={i} data-index={i}>
                                            <p title={d.title} href={void(0)} className='emoj_title'>{d.title}</p>
                                            <ul>
                                                {
                                                    d.list.map((dd , index) => {
                                                        return <li key={index}>
                                                            <a onClick={()  => addEmoji(dd.icon)} title={dd.title} className='emoj_icons'>{dd.icon}</a>
                                                        </li>
                                                    })
                                                }
                                                
                                            </ul>
                                        </div>
                                    })
                                }
                                
                                {
                                    keyword != '' ?
                                        filteredEmoji.length ?
                                            <ul>
                                                {
                                                    filteredEmoji.map((d, index) => {
                                                        return <li key={index}>
                                                            <a onClick={()  => addEmoji(d.icon)} title={d.title} className='emoj_icons'>{d.icon}</a>
                                                        </li>
                                                    })
                                                }
                                                
                                            </ul>
                                        :<p>Emoji not found.</p>
                                    :<></>
                                }
                            </div>
                            <div>
                                <ul className="emoj_tabIcons">
                                    {
                                        keyword == '' && emojiObject.map((d, i) => {
                                            return <li  idkey={i} key={i} className={emojiTitle == d.title ? 'isEmojiActive' : ''}>
                                                <a title={d.title} className='emoj_icons' onClick={()=>scrollToElement(i, d.title) }>{d.icon}</a>
                                            </li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmojiBox;
