import { useState,useEffect } from 'react'
import svg from './svg';
import ColorPicker from './ColorPicker';
import { commonToast, hitApi, setMyState } from '../../../components/commonAction';
import WidgetPreview from './WidgetPreview';
import { useStore } from '../../../zustand/store';

function WidgetSetting({p_id,setActiveTabMenu}) {

    const [title, setTitle] = useState('Widget Title');
    const [isError, setIsError] = useState(false);
    let store = useStore(state => state);
  
     let { userId ,role } = store.userData;

    // Login Screen Content State 
    const [heading, setHeading] = useState('Chat with us')
    const [subHeading, setSubHeading] = useState('Fill the detail below to get started.');

    const [nameLabel, setNameLabel] = useState('Full Name')
    const [namePlaceholder, setNamePlaceholder] = useState('Enter your name')
    const [emailLabel, setEmailLabel] = useState('Full Email')
    const [emailPlaceholder, setEmailPlaceholder] = useState('Enter your email')
    const [buttonText, setButtonText] = useState('Submit')

    // Error message 
    const [emptyMess, setEmptyMess] = useState('Field can not be empty')
    const [invalidEmailMess, setInvalidEmailMess] = useState('Email not valid')

    const [positionRadioActive, setPositionRadioActive] = useState('left')
    const [widgetTypeRadioActive, setWidgetTypeRadioActive] = useState('bubble')
    const [colorTheme, setColorTheme] = useState('#8417AF');
    const [projectId, setProjectId] = useState('');
    const[btnLoader,setBtnLoader] = useState(false)


    const combinedProps = {
        title: title, heading: heading, subHeading: subHeading, nameLabel: nameLabel, namePlaceholder: namePlaceholder, emailLabel: emailLabel, emailPlaceholder: emailPlaceholder,
        buttonText: buttonText, emptyMess: emptyMess, invalidEmailMess: invalidEmailMess, position: positionRadioActive, widgetType: widgetTypeRadioActive, colorTheme: colorTheme, projectId: projectId
    };

    useEffect(() => {
         getWidget(p_id);
    }, [p_id])

    const getWidget = (id) => {
        if (id) {
            hitApi({
                url: 'pixa-support/api/getWidget',
                method: "POST",
                data: {
                    project_id: id
                },
                // loading: true,
                alert: false,
            }, (resp, err = null) => {
                if (resp.status === 1) {
                    if(resp.data){
                        setProjectId(resp.data.project_id)
                        setTitle(resp.data.widget_title);
                        setHeading(resp.data.heading);
                        setSubHeading(resp.data.sub_heading);
                        setNameLabel(resp.data.name_label);
                        setNamePlaceholder(resp.data.name_placeholder);
                        setEmailLabel(resp.data.email_label);
                        setEmailPlaceholder(resp.data.email_placeholder);
                        setButtonText(resp.data.button_text);
                        setEmptyMess(resp.data.empty_error);
                        setInvalidEmailMess(resp.data.invalid_error);
                        setPositionRadioActive(resp.data.position);
                        setWidgetTypeRadioActive(resp.data.widget_type);
                        setColorTheme(resp.data.color_theme);
                        if(resp.data.color_theme){
                            document.documentElement.style.setProperty('--pixalab-widget-color', resp.data.color_theme) ;
                        }else{console.log("sadsadsad");
                            document.documentElement.style.setProperty('--pixalab-widget-color', '#8417AF') ;
                        }
                        setIsError(false)
                    }else{
                        document.documentElement.style.setProperty('--pixalab-widget-color', '#8417AF') ;
                    }
                }else{
                    setIsError(true)
                }
            });
           
        }
    }
   

    const saveWidget = (e) => {
        const emptyTitle = !title 
        const emptyHeading = !heading  ;
        const emptySubHeading = !subHeading ;
        const emptynameLabel = !nameLabel;
        const emptynamePlaceholder = !namePlaceholder;
        const emptyemailLabel = !emailLabel ;
        const emptyemailPlaceholder = !emailPlaceholder ;
        const emptybuttonText = !buttonText;
        const emptyemptyMess = !emptyMess;
        const emptyinvalidEmailMess = !invalidEmailMess ;

        if (emptyTitle || emptyHeading || emptySubHeading || emptynameLabel || emptynamePlaceholder || emptyemailLabel || emptyemailPlaceholder || emptybuttonText || emptyemptyMess || emptyinvalidEmailMess) {
            commonToast('error', `Field can not be empty!`);
            return false;
        } else {
            const data ={
                project_id:p_id,
                widget_title:title,
                widget_type:widgetTypeRadioActive,
                position:positionRadioActive,
                heading:heading,
                sub_heading:subHeading,
                name_label:nameLabel,
                name_placeholder:namePlaceholder,
                email_label:emailLabel,
                email_placeholder:emailPlaceholder,
                button_text:buttonText,
                color_theme:colorTheme,
                empty_error:emptyMess,
                invalid_error:invalidEmailMess
            }
            setBtnLoader(true)
              hitApi({
                url: 'pixa-support/api/widgetSetting',
                method: "POST",
                data: data,
                loading: true,
                
            }, (resp, err = null) => {
                setBtnLoader(false)
                if (resp.status === 1) {
                   setTimeout(()=>{
                    setActiveTabMenu('script')
                   },2000)
                }
            });
        }

  
    }

   
    return (
        <div className='widget_section_wrap'>
            <div className='pxl-widgetWrap pxl-widgetWrapBox'>
                <div className='sup_proj_setting_form widget_app_wrap pxl-widget-wrap'>
                    <h4 className='proj_main_head'>Widget Appearance</h4>
                    <div className='proj_Setting_first_flex'>

                        <div className="pxl_input_feilds pxl-placeholder-color1">
                            <label>Widget Title</label>
                            <input type="text" name="" placeholder="Enter Widget Title" value={title} onChange={(e) => setTitle(e.target.value)}/>
                        </div>
                    </div>
                    <div className='widget_login_Sec pxl-widget-head2'>
                        <h4>Login screen contents</h4>
                        <div className='proj_Setting_first_flex pxl-widgetSetting-flex'>

                            <div className="pxl_input_feilds mb-3 pxl-placeholder-color1">
                                <label>Heading</label>
                                <input type="text" name="" placeholder="Enter Heading" value={heading} onChange={(e) => setHeading(e.target.value)} />
                            </div>
                            <div className="pxl_input_feilds mb-3 pxl-placeholder-color1">
                                <label>Sub Heading</label>
                                <input type="text" name="" placeholder="Enter Sub Heading"  value={subHeading} onChange={(e) => setSubHeading(e.target.value)} />
                            </div>
                            <div className="pxl_input_feilds mb-3 pxl-placeholder-color1" >
                                <label>Name field label</label>
                                <input type="text" name="" placeholder="Enter Name Field Label" value={nameLabel} onChange={(e) => setNameLabel(e.target.value)}  />
                            </div>
                            <div className="pxl_input_feilds mb-3 pxl-placeholder-color1">
                                <label>Name field placeholder</label>
                                <input type="text" name="" placeholder="Enter Name Field Placeholder" value={namePlaceholder} onChange={(e) => setNamePlaceholder(e.target.value)} />
                            </div>
                            <div className="pxl_input_feilds mb-3 pxl-placeholder-color1">
                                <label>Email field label</label>
                                <input type="text" name="" placeholder="Enter Email Field Label"  value={emailLabel} onChange={(e) => setEmailLabel(e.target.value)} />
                            </div>
                            <div className="pxl_input_feilds mb-3 pxl-placeholder-color1">
                                <label>Email field placeholder</label>
                                <input type="text" name="" placeholder="Enter Email Field Placeholder" value={emailPlaceholder} onChange={(e) => setEmailPlaceholder(e.target.value)} />
                            </div>
                            <div className="pxl_input_feilds mb-3 pxl-placeholder-color1">
                                <label>Button text</label>
                                <input type="text" name="" placeholder="Enter Button Text" value={buttonText} onChange={(e) => setButtonText(e.target.value)}/>
                            </div>

                        </div>
                    </div>

                    <div className='widget_login_Sec pxl-widget-head3'>
                        <h4>Error message</h4>
                        <div className='proj_Setting_first_flex'>
                            <div className="pxl_input_feilds mb-3 pxl-placeholder-color1">
                                <label>Empty field error message</label>
                                <input type="text" name="" placeholder="Enter Empty Field Error Message" value={emptyMess} onChange={(e) => setEmptyMess(e.target.value)}  />
                            </div>
                            <div className="pxl_input_feilds mb-3 pxl-placeholder-color1">
                                <label>Invalid email error message</label>
                                <input type="text" name="" placeholder="Enter Invalid email error Message" value={invalidEmailMess} onChange={(e) => setInvalidEmailMess(e.target.value)}/>
                            </div>
                        </div>
                    </div>

                    <div className='widget_login_Sec pxl-widget-head4 pxl-widget-type'>
                        <h4>Widget Type</h4>
                        <div className='proj_Setting_first_flex'>
                            <div className='max-402'>
                                <div className='support_widget_type_wrap'>
                                    <div className="pxl_input_feilds mb-3 ">
                                        <label>Bubble </label>
                                        <div className={`widget_type_btn ${widgetTypeRadioActive == 'bubble' ? 'active' : ''}`} onClick={() => setWidgetTypeRadioActive('bubble')}>
                                            {/* <div className='widget_bubble'>{svg.chat}</div> */}
                                            <div className='widget_bubble'>
                                             <img src="/assets/images/chat1-img.png" alt="" />
                                            </div>
                                            <div className='pxl-widget-buble-text'>
                                                <h2>bubble</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pxl_input_feilds mb-3">
                                        <label>Button</label>
                                        <div className={`widget_type_btn ${widgetTypeRadioActive == 'button' ? 'active' : ''}`} onClick={() =>setWidgetTypeRadioActive('button')}>
                                            <div className='widget_btn'><img src="/assets/images/chat01.png" alt="" /> chat with us</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='max-402'>
                                <div className="pxl_input_feilds sup_proj_Color">
                                    <label>Color Theme</label>
            
                                    <ColorPicker  colorVal={colorTheme} handleColorTheme={(color) =>{ 
                                        setColorTheme(color);
                                        document.documentElement.style.setProperty('--pixalab-widget-color', color)}}/>
                                </div>
                            </div>
                            <div className='widget_login_Sec pxl-widget-position'>
                            <h4>Widget Position</h4>
                            <div className="cun-radiobox-wrapper">
                                {['left', 'right'].map((option, i) => (
                                    <div key={i} className="cun-custom-radioBtn">
                                        <input type="radio" id={option}
                                            value={positionRadioActive}
                                            checked={option == positionRadioActive}
                                            name={'position'} onChange={(e) =>setPositionRadioActive(option)} />
                                        <label style={{textTransform:'capitalize'}} htmlFor={option}>{option}</label>
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                        </div>
                        
                    </div>


                    <div className='pxl-widget-appearance-btn'>
                    <button className="pxlCommon-btn" onClick={() => setActiveTabMenu('mail')}>Back</button>
                    <button disabled={btnLoader} className="pxlCommon-btn" onClick={(e) => saveWidget(e)}> {btnLoader ? 'Processsing...' : 'Save & Continue'}</button>
                  
                    </div>

                </div>
            </div>
            <div className='widget_prev_wrap'>
                <WidgetPreview combinedProps={combinedProps} colorVal={colorTheme} />
            </div>
        </div>
    )
}

export default WidgetSetting