import React from "react";
import svg from "../editorSvg";

function MyModal({ children, shown, close }) {
  return shown ? (
    <>
        <div className="pxl-customModel">
    <div
      className="modal-backdrop pxl-editor-customModel"
      onClick={() => {
        close();
      }}
    >
      <div
        className="modal-content"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <a className="rz_closeIcon" onClick={close}><img src="/assets/images/popup-cross1.svg" alt=""/></a>
        {children}
      </div>
    </div>
    </div>




    
    </>







  ) : null;
}

export default MyModal;