import { useLocation, Link, useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import EmojiBox from '../../components/emoji/EmojiBox'
import schedulerSvg from "./components/schedulerSvg";
import  { useEffect, useState } from "react";
import { hitApi, setMyState, skeletonLoader, dateFormatter, Loader, commonToast } from "../../components/commonAction";

import SocialAccount from "./components/SocialAccountComponant";
import AiImage from "./components/AiImageComponant";
import ThirdPartyMedia from "../../components/common/thirdPartyMedia/Media";
import PixaStock from "./components/PixaStockComponant";
import Mylibrary from "../../components/common/myLibrary/MylibraryComponant";
import SchedulePopup, { PreviewPost, GenerateAiContent } from "./components/SchedulePopUp";
import svg from "../../components/svg";
import moment from "moment";
import { useStore } from "../../zustand/store";


const Dashboard = ({ pageTitle }) => {



    const [myLibraryToggle, setmyLibraryToggle] = useState(false);

    const [state, setState] = useState({
        keys: '',
        limit: '5',
        page: 1,
        keyword: '',
        searchImage: '',
        sort: 'createdAt=-1',
        mediaType: '',
        totalRecords: '',
        postContentLoading: false,
        title: '',
        caption: '',
        capTxt: '',
        offset: undefined,
        playMedia: false,
        scheduleDate: '',
        selectedDateTime: '',
        timeZone: '',
        timeZoneOffset: '',
        mediaUrl: [],
        previewPopUp: false,
        schedulePopUp: false,
        library: [],
        libraryFile: '',
        BtnLoader: '',
        canScheduleDate: true,
        accountsList: [],
        aiContentPopup: false,
        selectedAccounts: {},
        postOn: [],
        activeTab: ''
    })

    const { id } = useParams();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const date = params.get('date');
    const navigate = useNavigate();
    const parts = location.pathname.split("/");
    const locationPath = parts[2];
    let store = useStore((state) => state);

    useEffect(() => {
        if (id) {
            if (locationPath == 'edit-schedule-post') {
                getSchedulePostData();
            } else {
                getdraftData();
            }
        }
        if (date) {
            const currentDate = new Date(date);
            currentDate.setHours(currentDate.getHours() + 1)
            let dateformat = dateFormatter(currentDate);
            setMyState(setState, { scheduleDate: dateformat });
        }
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setMyState(setState, {timeZone: timeZone });
    }, [])

        useEffect(()=>{
            if(locationPath == 'new-post' && !date){
                setMyState(setState, {
                    title: "",
                    caption: "",
                    scheduleDate: "",
                    mediaUrl: [],
                    selectedAccounts: {},
                    timeZone: '',
                    postOn: [],
                    playMedia: false
                });
            }
        },[locationPath])

    const getdraftData = () => {

        setMyState(setState, { postContentLoading: true });

        hitApi({
            url: 'scheduler/draft/get-draft',
            method: "GET",
            data: { target: id },
        }, (resp, err = null) => {
            setMyState(setState, { postContentLoading: false });
            if (resp.status) {
                let draft = resp.data.records?.[0];
                setMyState(setState, {
                    title: draft.title,
                    caption: draft.caption,
                    scheduleDate: '',
                    mediaUrl: draft.mediaUrl,

                    playMedia: false
                });
            }
        });
    }


    const getSchedulePostData = () => {
        setMyState(setState, { postContentLoading: true });
        hitApi({
            url: 'scheduler/post/get-post',
            method: "GET",
            data: { target: id },
        }, (resp, err = null) => {
            setMyState(setState, { postContentLoading: false });
            if (resp.status) {
                let scheduleData = resp.data.records?.[0];
                // const currentDate = new Date();
                if (scheduleData.status == 'initialize') {
                    setMyState(setState, { canScheduleDate: true });
                } else {
                    setMyState(setState, { canScheduleDate: false });
                }
                // if (new Date(scheduleData.scheduleDate) > currentDate) {
                //     setMyState(setState, { canScheduleDate: true });

                // } else {
                //     setMyState(setState, { canScheduleDate: false });
                // }
                setMyState(setState, {
                    title: scheduleData.title,
                    caption: scheduleData.caption,
                    scheduleDate: scheduleData.scheduleDate,
                    mediaUrl: scheduleData.mediaUrl,
                    selectedAccounts: scheduleData.accounts,
                    timeZone: scheduleData.timeZone,
                    postOn: scheduleData.postOn,
                    playMedia: false
                });
            }
        });
    }


    function validationCheck(type) {


        if (!state.title.trim()) {
            commonToast('error', 'Title is required.')
            return false;
        }
        else if (!state.caption.trim()) {
            commonToast('error', 'Caption is required.')
            return false;
        } else if (!state.scheduleDate && type != 'draft' && type != 'postNow') {
            commonToast('error', 'Schedule Date is required.')
            return false;
        }
        else if (state?.postOn?.length == 0 && type != 'draft') {
            commonToast('error', 'Select atleast one social account.')
            return false;
        } else if (!state.timeZone && type != 'draft' && type != 'postNow') {
            commonToast('error', 'TimeZone is required.')
            return false;
        }

        if (id && !state.canScheduleDate) {
            return false
        }
        return true
    }

    const toggleHandle = (val) => {
        if (val) {
            setmyLibraryToggle(false);
        }
    }

    const handleSchedulePost = () => {


        if (validationCheck()) {
            let data = {
                title: state.title, caption: state.caption, scheduleDate: !state.selectedDateTime ? state.scheduleDate : moment(state.selectedDateTime).utcOffset(state.timeZoneOffset).utc().format(), "accounts": state.selectedAccounts, timeZone: state.timeZone, postDate: state.scheduleDate,
                "postType": "schedule", postOn: state.postOn,
            };
            if (state.mediaUrl?.length > 0) {
                data['mediaUrl'] = state.mediaUrl
            }
            if (id) {
                data['target'] = id
            }

            setMyState(setState, { BtnLoader: 'schedule' });
            hitApi({
                url: id ? 'scheduler/post/update-post' : 'scheduler/post/schedule-post',
                method: id ? "PATCH" : "POST",
                data: data,
            }, (resp, err = null) => {
                setMyState(setState, { BtnLoader: '', schedulePopUp: false });
                if (resp.status) {
                    navigate('/scheduler/calendar', { replace: true });
                    setMyState(setState, { title: '', caption: '', scheduleDate: '', mediaUrl: [], timeZone: '', selectedAccounts: {}, postOn: [] });
                }
            });
        }
    }

    const handlePostNow = () => {

        const date = new Date();
        const formattedDate = dateFormatter(date);
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // const offset = time.getTimezoneOffset();

        if (validationCheck('postNow')) {
            let data = {
                title: state.title, caption: state.caption, "accounts": state.selectedAccounts, timeZone: timeZone, postDate: formattedDate, scheduleDate: formattedDate,
                "postType": "now", postOn: state.postOn
            };
            if (state.mediaUrl?.length > 0) {
                data['mediaUrl'] = state.mediaUrl
            }
            if (id) {
                data['target'] = id
            }

            setMyState(setState, { BtnLoader: 'post' });
            hitApi({
                url: 'scheduler/post/schedule-post',
                method: "POST",
                data: data,
            }, (resp, err = null) => {
                setMyState(setState, { BtnLoader: '' });
                if (resp.status) {
                    setMyState(setState, { title: '', caption: '', scheduleDate: '', mediaUrl: [], timeZone: '', selectedAccounts: {}, postOn: [] });
                    navigate('/scheduler/calendar', { replace: true });
                }
            });
        }
    }

    const addToDraft = () => {
        if (validationCheck('draft')) {
            let data = {
                title: state.title, caption: state.caption,
            };
            if (state.mediaUrl?.length > 0 || id) {
                data['mediaUrl'] = state.mediaUrl
            }
            if (id) {
                data['target'] = id
            }
            setMyState(setState, { BtnLoader: 'draft' });
            hitApi({
                url: id ? 'scheduler/draft/update-draft' : 'scheduler/draft/add-to-draft',
                method: id ? "PATCH" : "POST",
                data: data,
            }, (resp, err = null) => {
                setMyState(setState, { BtnLoader: '' });
                if (resp.status) {

                    navigate('/scheduler/draft', { replace: true })

                    setMyState(setState, {
                        title: '',
                        caption: '',
                        scheduleDate: '',
                        mediaUrl: []
                    });
                }
            });
        }
    }

    const scheduleDateHandle = (key, value, offset) => {
        if (key == 'date') {
            if(value){
                const date = new Date(value);
                const formattedDate = dateFormatter(date);
                setMyState(setState, { scheduleDate: formattedDate, selectedDateTime: value })
            }else{
                setMyState(setState, { scheduleDate: '', selectedDateTime: '' })
            }
           
        } else if (key == 'timezone') {
            setMyState(setState, { timeZone: value, timeZoneOffset: offset })
        }
    }


    const generateContent = () => {
        if (!state.caption) {
            commonToast('error', 'Caption is required.')
            return false;
        }
        hitApi({
            url: 'ai-services/generate-content',
            method: "POST",
            data: { prompt: state.caption },
        }, (resp, err = null) => {
            if (resp.status) {
                setMyState(setState, { caption: resp.data.content });
            }
        });
    }

    let insertTag = (cls) => {

        const selection = window.getSelection().getRangeAt(0);

        let selectedParent = selection.commonAncestorContainer.parentElement;


        let mainParent = selectedParent;
        if (selectedParent.classList.contains(cls)) {
            var text = document.createTextNode(selectedParent.textContent);
            mainParent = selectedParent.parentElement;
            mainParent.insertBefore(text, selectedParent);
            mainParent.removeChild(selectedParent);
            mainParent.normalize();
        }
        else {
            const span = document.createElement("span");
            span.classList.toggle(cls);
            span.appendChild(selection.extractContents());
            //selection.surroundContents(span);
            selection.insertNode(span);
            mainParent.normalize();
        }

        //selection is set to body after clicking button for some reason
        //https://stackoverflow.com/questions/3169786/clear-text-selection-with-javascript
        if (window.getSelection) {
            if (window.getSelection().empty) {  // Chrome
                window.getSelection().empty();
            } else if (window.getSelection().removeAllRanges) {  // Firefox
                window.getSelection().removeAllRanges();
            }
        } else if (document.selection) {  // IE?
            document.selection.empty();
        }

    }

    let manageTextByAi = (type) => {

        setMyState(setState, {
            aiContentPopup: true
        })
    }


    const addToPost = (aiGeneratedContent) => {
        setMyState(setState, { caption: aiGeneratedContent, aiContentPopup: false });
    }


    const handleCheckbox = (sourceUpdate, updatedlist) => {

        setMyState(setState, { selectedAccounts: updatedlist, postOn: sourceUpdate });
    };

    let showPreview = state.caption.trim() || (Array.isArray(state.mediaUrl) && state.mediaUrl.length);
    useEffect(() => {
        document.body.classList.add('pxl-body-white');
        return () => {
          document.body.classList.remove('pxl-body-white');
        };
      }, []);
    return (

        <>

            {store.pageLoading && <Loader />}
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>

            {/* Main Wrapper  */}

           <div className="pxl-schduler-newPost-wrapper">
           <div className="sch-container-fluid">
                <div className={`sch-crete-post-inner ${myLibraryToggle ? 'openLibrarySidebar' : 'closeLibrarySidebar'}`}>
                    <div className="sch-ceate-left-wrapper">
                        <SocialAccount selectedAccounts={state.selectedAccounts} postOn={state.postOn} handleCheckbox={handleCheckbox} myLibraryToggle={myLibraryToggle} toggleHandle={toggleHandle} />

                        <div className="sch-post-center">

                            <h2 className="sch-tab-heading">Create New Post</h2>

                            {
                                state.postContentLoading ? <div className="sch-post-title-input"> {skeletonLoader('list', 1, '230px')}</div>
                                    :
                                    <div className="sch-post-title-input">
                                        <label>Title <span className="pxl-star-red">*</span></label>
                                       <div className="pxl-postTitle-input">
                                       <input type="text" placeholder="Enter Post Title" name="title" value={state.title} onChange={(e) => setMyState(setState, { title: e.target.value })} />
                                       </div>
                                    </div>
                            }




                            <div className="sch-post-area-box">
                                {state.postContentLoading ? skeletonLoader('asset', 1, '838px', '368px') :
                                    <div className="sch-post-area-box-inner">
                                        <div className="sch-post-content-box">
                                            <div className="sch-post-content">
                                                <textarea
                                                    className="sch-postTextarea"
                                                    placeholder="Enter Your Caption Here"
                                                    id="postContent"
                                                    value={state.caption}
                                                    onChange={(e) => setMyState(setState, {
                                                        caption: e.target.value,
                                                        capTxt: e.target.value,
                                                    })}
                                                    onBlur={() => {
                                                        let captionVal = document.querySelector("#postContent");
                                                        setMyState(setState, {
                                                            caption: captionVal.value,

                                                        })
                                                    }}
                                                ></textarea>
                                                {/* <ContentEditable
                                                    className="sch-postTextarea"
                                                    // innerRef={this.contentEditable}
                                                    html={state.caption} // innerHTML of the editable div
                                                    disabled={false}       // use true to disable editing
                                                    onChange={(e) => {
                                                       
                                                        setMyState(setState, {
                                                            caption: e.target.value,
                                                            capTxt: document.getElementsByClassName('sch-postTextarea')[0].textContent
                                                        })
                                                    }} // handle innerHTML change
                                                    tagName='article'
                                                /> */}
                                            </div>
                                            {/* <div className="sch-color-picker-svg">
                                                <a onClick={e => {
                                                    if (state?.capTxt?.trim() == '') {
                                                        manageTextByAi('newContent')
                                                    }
                                                }}>
                                                    {schedulerSvg.app.colorPicker}
                                                </a>
                                                {
                                                    state?.capTxt?.trim() != '' &&
                                                    <div className="sch-color-picker-dropedown">
                                                        <ul>
                                                            <li>
                                                                <a onClick={e => generateContent('completeText')}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 256 256" className="u-margin-right-5"><path d="M112,40a8,8,0,0,0-8,8V64H24A16,16,0,0,0,8,80v96a16,16,0,0,0,16,16h80v16a8,8,0,0,0,16,0V48A8,8,0,0,0,112,40ZM24,176V80h80v96ZM248,80v96a16,16,0,0,1-16,16H144a8,8,0,0,1,0-16h88V80H144a8,8,0,0,1,0-16h88A16,16,0,0,1,248,80ZM88,112a8,8,0,0,1-8,8H72v24a8,8,0,0,1-16,0V120H48a8,8,0,0,1,0-16H80A8,8,0,0,1,88,112Z"></path></svg>
                                                                    <span>Complete the text</span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a onClick={e => manageTextByAi('newContent')}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 256 256" className="u-margin-right-5"><path d="M88,104a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,104Zm8,40h64a8,8,0,0,0,0-16H96a8,8,0,0,0,0,16ZM232,56V184a16,16,0,0,1-16,16H156.53l-14.84,24.29a16,16,0,0,1-27.41-.06L99.47,200H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56Zm-16,0H40V184H99.47a16.08,16.08,0,0,1,13.7,7.73L128,216l14.82-24.32A16.07,16.07,0,0,1,156.53,184H216Z"></path></svg>
                                                                    <span>Generate new text</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                }
                                            </div> */}
                                        </div>
                                        <div className="sch-post-bottom">
                                            <div className="sch-post-schedule-area">
                                                <ul className="sch-footer-post-ul2">
                                                    <li className="emojiBoxParent">
                                                        <EmojiBox
                                                            targetInputId="#postContent"
                                                            parentEmojiBoxclassName={"emojiBoxParent"}
                                                            icon={schedulerSvg.app.smilyFace}
                                                            boxPlacement={"left"}
                                                        />
                                                    </li>
                                                    <li className="sch-aiText cun-socialIcon">
                                                        <a onClick={e => {
                                                            if (state?.capTxt?.trim() == '') {
                                                                manageTextByAi('newContent')
                                                            }
                                                        }}>
                                                            <span>
                                                                <img src="/assets/images/aiText.svg" alt="" />
                                                            </span>
                                                            AI Text
                                                        </a>
                                                        {/* <divclassName="cun-show-tooltip"><p>Ai text</p></divclassName=> */}
                                                        {
                                                            state?.capTxt?.trim() != '' &&
                                                            <div  className="cun-show-tooltip"> 
                                                            {/* <div className="sch-color-picker-dropedown"> */}
                                                                <ul>
                                                                    <li>
                                                                        <a onClick={e => generateContent('completeText')}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 256 256" className="u-margin-right-5"><path d="M112,40a8,8,0,0,0-8,8V64H24A16,16,0,0,0,8,80v96a16,16,0,0,0,16,16h80v16a8,8,0,0,0,16,0V48A8,8,0,0,0,112,40ZM24,176V80h80v96ZM248,80v96a16,16,0,0,1-16,16H144a8,8,0,0,1,0-16h88V80H144a8,8,0,0,1,0-16h88A16,16,0,0,1,248,80ZM88,112a8,8,0,0,1-8,8H72v24a8,8,0,0,1-16,0V120H48a8,8,0,0,1,0-16H80A8,8,0,0,1,88,112Z"></path></svg>
                                                                            <span>Complete the text</span>
                                                                        </a>
                                                                    </li>
                                                                    <li>
                                                                        <a onClick={e => manageTextByAi('newContent')}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 256 256" className="u-margin-right-5"><path d="M88,104a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,104Zm8,40h64a8,8,0,0,0,0-16H96a8,8,0,0,0,0,16ZM232,56V184a16,16,0,0,1-16,16H156.53l-14.84,24.29a16,16,0,0,1-27.41-.06L99.47,200H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56Zm-16,0H40V184H99.47a16.08,16.08,0,0,1,13.7,7.73L128,216l14.82-24.32A16.07,16.07,0,0,1,156.53,184H216Z"></path></svg>
                                                                            <span>Generate new text</span>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            {/* </div> */}
                                                            </div>
                                                        }
                                                    </li>
                                                    <li className="sch-aiText cun-socialIcon">
                                                        <a onClick={() => setmyLibraryToggle(!myLibraryToggle)}>
                                                            <span>
                                                                <img src="/assets/images/imageEditor.svg" alt="" />
                                                            </span>
                                                            Media
                                                        </a>
                                                        {/* <div className="cun-show-tooltip"><p>Media </p></div> */}
                                                    </li>
                                                    {/* <li>
                                                        <a onClick={e => insertTag("sch-bold")}>
                                                            {schedulerSvg.app.boldText}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={e => insertTag("sch-italic")}>
                                                            <img src="/assets/images/newpost/italian-svg.svg" />
                                                        </a>
                                                    </li> */}

                                                </ul>
                                                <div className="sch-select-schedule-date">
                                                    {/* <div className="sch-post-button sch-hashtags">
                                                    <a><img src="/assets/images/newpost/hashtag.png" /><span>hashtags</span></a>
                                                </div> */}
                                                   {state.scheduleDate && <h2>
                                                    <img src="/assets/images/newpost/date.svg"/>
                                                        {state.scheduleDate ? new Date(state.scheduleDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) : 'Select Schedule date'}
                                                    </h2>}
                                                    {/* {state.scheduleDate && <div className="schScheduleDate" >
                                                        <a href={void (0)} className="sch-btn"><img src="/assets/images/newpost/date.svg" /><span>{state.scheduleDate ? new Date(state.scheduleDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) : 'Select Schedule date'}</span></a>
                                                    </div>} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                            <div className="sch-postUploadImg">
                                {Array.isArray(state.mediaUrl) && state.mediaUrl.map((media, index) => ( 
                                    <div key={index} className="sch-postImgInner"
                                        onMouseEnter={e => {
                                            if (media.mediaType != 'image') {
                                                setMyState(setState, {
                                                    playMedia: media.id
                                                })
                                            }
                                        }}
                                    >
                                        {
                                            state.playMedia == media.id ?
                                                <video
                                                    preload="none"
                                                    src={media.path}
                                                    autoPlay={true}
                                                    muted={true}
                                                    playsInline disablePictureInPicture
                                                    onMouseLeave={e => {
                                                        setMyState(setState, {
                                                            playMedia: null
                                                        })
                                                    }}
                                                />
                                                :
                                                <img
                                                    src={media?.mediaType === "image" ?  media.thumb || media.path : media.thumb || '/images/defaultVideoThumb.png'}
                                                />
                                        }

                                        <a className="schCross" onClick={() => {
                                            let newArray = state.mediaUrl.filter(obj => obj.id !== media.id);
                                            setMyState(setState, { mediaUrl: newArray })
                                        }}>
                                            {svg.app.crossSvgWhite}
                                        </a>
                                    </div>
                                ))
                                }

                            </div>

                            <div className="sch-post-bottom-btns">
                                {(locationPath == 'edit-draft' || locationPath == 'new-post' || locationPath == 'edit-schedule-post') && <button disabled={(state.BtnLoader == 'post') || (!state.canScheduleDate)} className={`pxlCommon-btn ${!state.canScheduleDate && "pxl-button-disable"}`}
                                    onClick={handlePostNow}><span>{state.BtnLoader == 'post' ? 'Processing...' : 'Post Now'}</span></button>}

                                {(locationPath == 'edit-draft' || locationPath == 'new-post') && <button className="pxlCommon-btn" disabled={(state.BtnLoader == 'draft') || (!state.canScheduleDate)}
                                    onClick={addToDraft}> <span>{state.BtnLoader == 'draft' ? 'Processing...' : id ? 'Update Draft' : 'Save as Draft'}</span></button>}


                                {(locationPath == 'edit-schedule-post' || locationPath == 'new-post') && <button disabled={(state.BtnLoader == 'schedule') || (!state.canScheduleDate)} className={`pxlCommon-btn ${!state.canScheduleDate && "pxl-button-disable"}`} onClick={() => { state.canScheduleDate && setMyState(setState, { schedulePopUp: true }) }}
                                // onClick={handleSchedulePost}
                                >
                                    <span>{state.BtnLoader == 'schedule' ? 'Processing...' : locationPath == 'edit-schedule-post' ? 'Update Schedule Post' : 'Schedule Post'}</span>
                                </button>}

                                <div className="cun-socialIcon pxl-previewPost-disable">
                                    <a>
                                        <button className={`sch-common-btn-white ${!showPreview && 'pxl-button-disable'}`} onClick={() => { showPreview && setMyState(setState, { previewPopUp: true }) }}><span>
                                            {schedulerSvg.app.eyeSvg}</span>Preview Post</button>
                                        {!showPreview && <div className="cun-show-tooltip">
                                            <p>Fill all details to preview</p>
                                        </div>}
                                    </a>
                                    <div className="cun-show-tooltip"><p>Add either a caption or media to preview the post.</p></div>
                                </div>





                            </div>
                        </div>
                    </div>
                    <div className={`sch-post-right`}>
                        <div className="sch-post-arrow-responsive">
                            <a onClick={() => setmyLibraryToggle(!myLibraryToggle)}>{schedulerSvg.app.Arrow}</a>
                        </div>
                        <div className={`sch-right-inner `}>
                            <div className="sch-library-tab">
                                <Tabs defaultActiveKey="tab-pixastock" id="justify-tab-example" onSelect={(key) => setMyState(setState, { activeTab: key })} transition={false}>
                                    <Tab eventKey="tab-pixastock" title="PixaStock">
                                        <div className="sch-library-tab-inner" id="pixastock_div">
                                            <PixaStock
                                                baseClass="sortPage"
                                                mediaType="image,video"
                                                onClickAct={(data) => {
                                                    state.canScheduleDate && setMyState(setState, {
                                                        mediaUrl: [data]
                                                    })
                                                    // Array.isArray(state.mediaUrl) ? !state.mediaUrl.some(item => item.id === data.id) && setMyState(setState, { mediaUrl: [...state.mediaUrl, data] }) : setMyState(setState, {
                                                    //     mediaUrl: [data]
                                                    // })
                                                }}
                                            />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="tab-ai-image" title="AI Image">
                                        <div className="sch-library-tab-inner">
                                            <AiImage />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="tab-apps" title="Apps">
                                        <div className="sch-library-tab-inner">
                                            <ThirdPartyMedia
                                                baseClass="sortPage"
                                                onClickAct={(data) => {
                                                    state.canScheduleDate && setMyState(setState, {
                                                        mediaUrl: [{
                                                            id: data.id,
                                                            mediaType: "image",
                                                            path: data.path,
                                                            thumb: data.thumb

                                                        }]

                                                    })

                                                }}
                                            />
                                        </div>
                                    </Tab>
                                    <Tab eventKey="tab-my-library" title="My Library">
                                        <div className="sch-library-tab-inner" id="scrollableDiv">
                                            {state.activeTab == 'tab-my-library' &&
                                                <Mylibrary
                                                    mediaType="image,video"
                                                    dataPerPage='25'
                                                    baseClass="sortPage"
                                                    onClickAct={(data) => {
                                                        state.canScheduleDate && setMyState(setState, {
                                                            mediaUrl: [data]
                                                        })
                                                        // Array.isArray(state.mediaUrl) ? !state.mediaUrl.some(item => item.id === data.id) && setMyState(setState, {
                                                        //     mediaUrl: [...state.mediaUrl, data]
                                                        // }) : setMyState(setState, {
                                                        //     mediaUrl: [data]
                                                        // })
                                                    }}
                                                />}
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           </div>



            <PreviewPost
                shownPopup={state.previewPopUp}
                closePopup={() => {
                    setMyState(setState, {
                        previewPopUp: false
                    })
                }}
                data={{ title: state.title, caption: state.caption, mediaUrl: state.mediaUrl }}
            />


            <GenerateAiContent
                shownPopup={state.aiContentPopup}
                closePopup={() => {
                    setMyState(setState, {
                        aiContentPopup: false
                    })
                }}
                addToPost={addToPost}
            />


            <SchedulePopup
                shownPopup={state.schedulePopUp}
                closePopup={() => {
                    setMyState(setState, {
                        schedulePopUp: false
                    })
                }}
                type={"Schedule Post"}
                title={`Schedule Post`}
                handleValue={scheduleDateHandle}
                scheduleDate={state.scheduleDate}
                timezone={state.timeZone}
                subTitle={'Choose the Date and Time for Scheduling'}
                handleSchedule={() => handleSchedulePost()}
                btnLoader={state.BtnLoader == 'schedule'}
                isEdit={locationPath == 'edit-schedule-post'}
            />

        </>
    )
};

export default Dashboard;