import React from 'react'

function SingleApiComp({apiRecord, openFolder, setOpenFolder, activeMenu,setAtiveMenu}) {

    const scrollFun = (id) =>{
        window.history.pushState({}, '', `#${id}`);                              
        const fileSection = document.querySelector(`[id="${id}"]`);
        if (fileSection) {
          fileSection.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <>
            <div className='single_Comp_api_wrap'>
                
                <p style={{color: apiRecord?.folders?.length > 0 || apiRecord.apis ? '#fff' : ''}} onClick={()=>{
                   setOpenFolder(apiRecord?.folderId);
                }} className={activeMenu == apiRecord.folderId ? 'activefile' : ''}>{apiRecord.title}</p>
                {
                    apiRecord?.folders?.length > 0 && openFolder?.length > 0 && openFolder?.includes(apiRecord?.folderId) && apiRecord?.folders.map((val, i) => <SingleApiComp key={i} apiRecord={val} openFolder={openFolder} setOpenFolder={setOpenFolder} activeMenu={activeMenu} setAtiveMenu={(id)=>setAtiveMenu(id)}/> )
                }

                {
                 openFolder?.length > 0 && openFolder?.includes(apiRecord?.folderId) && apiRecord?.apis?.length ?
                            apiRecord?.apis?.map((val, i) => 
                                <div className='single_Comp_api_wrap' key={i}>
                                    <p  onClick={()=>{
                                           setAtiveMenu(val.apiId);
                                         scrollFun(val.apiId)
                                    }} className={activeMenu == val.apiId ? 'activefile' : ''}>{val.title}</p>
                                </div>
                        ) 
                    :<></>
                }
            </div>

            
        </>
    )
}

export default SingleApiComp