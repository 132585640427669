let schedulerSvg = {};
schedulerSvg.app = {};

schedulerSvg.app.userIcon= (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"className="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
);
schedulerSvg.app.logoutIcon= (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"className="feather feather-power"><path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path><line x1="12" y1="2" x2="12" y2="12"></line></svg>
);
schedulerSvg.app.arrowRight= (
    <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.831035 1.00416L0.442817 1.3369C0.321576 1.44162 0.254784 1.58095 0.254784 1.72986C0.254784 1.87868 0.321576 2.01817 0.442817 2.12289L4.84096 5.91862L0.437937 9.71873C0.316697 9.82329 0.25 9.96278 0.25 10.1116C0.25 10.2604 0.316697 10.4 0.437937 10.5046L0.823762 10.8375C1.07466 11.0542 1.48336 11.0542 1.73426 10.8375L6.99544 6.31298C7.11659 6.20842 7.20194 6.0691 7.20194 5.91895L7.20194 5.91722C7.20194 5.76831 7.11649 5.62899 6.99544 5.52443L1.74852 1.00416C1.62737 0.899436 1.46106 0.841954 1.28863 0.841789C1.1161 0.841789 0.952083 0.899436 0.831035 1.00416Z" fill="currentColor"/>
    </svg>
);
schedulerSvg.app.prompt=(
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
  <path d="M10.6534 2.18663C10.9444 2.23848 11.2126 2.37829 11.4217 2.58723C11.6308 2.79616 11.7708 3.06417 11.8229 3.35514C11.8918 3.74627 12.4761 3.7497 12.545 3.35514C12.5971 3.06424 12.7371 2.79627 12.9461 2.58735C13.1551 2.37842 13.4231 2.23857 13.714 2.18663C14.1052 2.11769 14.1076 1.53392 13.714 1.464C13.423 1.41203 13.155 1.2721 12.946 1.06308C12.7369 0.854064 12.597 0.585995 12.545 0.295002C12.4756 -0.0971107 11.8918 -0.0995552 11.8229 0.295002C11.7709 0.586063 11.6309 0.85418 11.4218 1.06321C11.2127 1.27223 10.9445 1.41212 10.6534 1.464C10.2608 1.53294 10.2598 2.11671 10.6534 2.18663ZM13.714 7.57548C13.3622 7.51273 13.038 7.34357 12.7852 7.09082C12.5325 6.83808 12.3633 6.5139 12.3006 6.16202C12.2311 5.76942 11.6474 5.76746 11.5784 6.16202C11.5157 6.51397 11.3464 6.8382 11.0936 7.09095C10.8407 7.3437 10.5165 7.51282 10.1645 7.57548C9.77824 7.64344 9.76553 8.22721 10.1645 8.2981C10.5164 8.36074 10.8406 8.52978 11.0935 8.78244C11.3463 9.0351 11.5156 9.35921 11.5784 9.71108C11.6469 10.1027 12.2316 10.1051 12.3006 9.71108C12.3634 9.35928 12.5326 9.03521 12.7854 8.78256C13.0381 8.52991 13.3622 8.36083 13.714 8.2981C14.1071 8.22868 14.1081 7.6454 13.714 7.57548ZM0.392973 11.369C0.141317 11.6214 0 11.9633 0 12.3197C0 12.6761 0.141317 13.018 0.392973 13.2704L0.738638 13.6161C1.24516 14.1226 2.12228 14.1333 2.64053 13.6161L8.02938 8.22672L5.78232 5.98014L0.392973 11.369ZM10.7052 4.98813C10.7063 4.81191 10.6726 4.63721 10.6061 4.47403C10.5396 4.31085 10.4415 4.1624 10.3175 4.03718L9.97186 3.69152C9.71952 3.43974 9.37762 3.29834 9.02116 3.29834C8.66469 3.29834 8.32279 3.43974 8.07045 3.69152L6.30057 5.4614L8.54764 7.70847L10.3175 5.93858C10.4414 5.81339 10.5394 5.66502 10.6059 5.50193C10.6725 5.33885 10.7062 5.16426 10.7052 4.98813Z" fill="white"/>
  <path d="M10.6534 2.18663C10.9444 2.23848 11.2126 2.37829 11.4217 2.58723C11.6308 2.79616 11.7708 3.06417 11.8229 3.35514C11.8918 3.74627 12.4761 3.7497 12.545 3.35514C12.5971 3.06424 12.7371 2.79627 12.9461 2.58735C13.1551 2.37842 13.4231 2.23857 13.714 2.18663C14.1052 2.11769 14.1076 1.53392 13.714 1.464C13.423 1.41203 13.155 1.2721 12.946 1.06308C12.7369 0.854064 12.597 0.585995 12.545 0.295002C12.4756 -0.0971107 11.8918 -0.0995552 11.8229 0.295002C11.7709 0.586063 11.6309 0.85418 11.4218 1.06321C11.2127 1.27223 10.9445 1.41212 10.6534 1.464C10.2608 1.53294 10.2598 2.11671 10.6534 2.18663ZM13.714 7.57548C13.3622 7.51273 13.038 7.34357 12.7852 7.09082C12.5325 6.83808 12.3633 6.5139 12.3006 6.16202C12.2311 5.76942 11.6474 5.76746 11.5784 6.16202C11.5157 6.51397 11.3464 6.8382 11.0936 7.09095C10.8407 7.3437 10.5165 7.51282 10.1645 7.57548C9.77824 7.64344 9.76553 8.22721 10.1645 8.2981C10.5164 8.36074 10.8406 8.52978 11.0935 8.78244C11.3463 9.0351 11.5156 9.35921 11.5784 9.71108C11.6469 10.1027 12.2316 10.1051 12.3006 9.71108C12.3634 9.35928 12.5326 9.03521 12.7854 8.78256C13.0381 8.52991 13.3622 8.36083 13.714 8.2981C14.1071 8.22868 14.1081 7.6454 13.714 7.57548ZM0.392973 11.369C0.141317 11.6214 0 11.9633 0 12.3197C0 12.6761 0.141317 13.018 0.392973 13.2704L0.738638 13.6161C1.24516 14.1226 2.12228 14.1333 2.64053 13.6161L8.02938 8.22672L5.78232 5.98014L0.392973 11.369ZM10.7052 4.98813C10.7063 4.81191 10.6726 4.63721 10.6061 4.47403C10.5396 4.31085 10.4415 4.1624 10.3175 4.03718L9.97186 3.69152C9.71952 3.43974 9.37762 3.29834 9.02116 3.29834C8.66469 3.29834 8.32279 3.43974 8.07045 3.69152L6.30057 5.4614L8.54764 7.70847L10.3175 5.93858C10.4414 5.81339 10.5394 5.66502 10.6059 5.50193C10.6725 5.33885 10.7062 5.16426 10.7052 4.98813Z" fill="url(#paint0_linear_776_433)"/>
  <path d="M13.1616 5.12551C13.3641 5.12551 13.5282 4.96134 13.5282 4.75882C13.5282 4.55631 13.3641 4.39214 13.1616 4.39214C12.959 4.39214 12.7949 4.55631 12.7949 4.75882C12.7949 4.96134 12.959 5.12551 13.1616 5.12551Z" fill="white"/>
  <path d="M13.1616 5.12551C13.3641 5.12551 13.5282 4.96134 13.5282 4.75882C13.5282 4.55631 13.3641 4.39214 13.1616 4.39214C12.959 4.39214 12.7949 4.55631 12.7949 4.75882C12.7949 4.96134 12.959 5.12551 13.1616 5.12551Z" fill="url(#paint1_linear_776_433)"/>
  <path d="M8.7613 1.70309C8.96381 1.70309 9.12799 1.53891 9.12799 1.3364C9.12799 1.13388 8.96381 0.969709 8.7613 0.969709C8.55878 0.969709 8.39461 1.13388 8.39461 1.3364C8.39461 1.53891 8.55878 1.70309 8.7613 1.70309Z" fill="white"/>
  <path d="M8.7613 1.70309C8.96381 1.70309 9.12799 1.53891 9.12799 1.3364C9.12799 1.13388 8.96381 0.969709 8.7613 0.969709C8.55878 0.969709 8.39461 1.13388 8.39461 1.3364C8.39461 1.53891 8.55878 1.70309 8.7613 1.70309Z" fill="url(#paint2_linear_776_433)"/>
  <path d="M3.34751 2.67897C3.76482 2.72021 4.15495 2.90478 4.45152 3.20125C4.74808 3.49773 4.93277 3.88781 4.97414 4.30511C5.01569 4.73193 5.66204 4.73487 5.70409 4.30511C5.74542 3.88796 5.92997 3.498 6.22633 3.20154C6.5227 2.90508 6.91261 2.72043 7.32974 2.67897C7.75412 2.6379 7.7595 1.99106 7.32974 1.94901C6.91261 1.90755 6.5227 1.7229 6.22633 1.42644C5.92997 1.12998 5.74542 0.740021 5.70409 0.32287C5.66351 -0.0956437 5.01667 -0.113245 4.97414 0.32287C4.93278 0.740096 4.74817 1.13011 4.4517 1.42658C4.15524 1.72305 3.76522 1.90766 3.34799 1.94901C2.93241 1.98959 2.90943 2.63643 3.34751 2.67897Z" fill="white"/>
  <path d="M3.34751 2.67897C3.76482 2.72021 4.15495 2.90478 4.45152 3.20125C4.74808 3.49773 4.93277 3.88781 4.97414 4.30511C5.01569 4.73193 5.66204 4.73487 5.70409 4.30511C5.74542 3.88796 5.92997 3.498 6.22633 3.20154C6.5227 2.90508 6.91261 2.72043 7.32974 2.67897C7.75412 2.6379 7.7595 1.99106 7.32974 1.94901C6.91261 1.90755 6.5227 1.7229 6.22633 1.42644C5.92997 1.12998 5.74542 0.740021 5.70409 0.32287C5.66351 -0.0956437 5.01667 -0.113245 4.97414 0.32287C4.93278 0.740096 4.74817 1.13011 4.4517 1.42658C4.15524 1.72305 3.76522 1.90766 3.34799 1.94901C2.93241 1.98959 2.90943 2.63643 3.34751 2.67897Z" fill="url(#paint3_linear_776_433)"/>
  <defs>
    <linearGradient id="paint0_linear_776_433" x1="1.15076" y1="4.17315" x2="15.5812" y2="4.37775" gradientUnits="userSpaceOnUse">
      <stop stopColor="#6F10B9"/>
      <stop offset="1" stopColor="#C02A93"/>
    </linearGradient>
    <linearGradient id="paint1_linear_776_433" x1="1.15076" y1="4.17315" x2="15.5812" y2="4.37775" gradientUnits="userSpaceOnUse">
      <stop stopColor="#6F10B9"/>
      <stop offset="1" stopColor="#C02A93"/>
    </linearGradient>
    <linearGradient id="paint2_linear_776_433" x1="1.15076" y1="4.17315" x2="15.5812" y2="4.37775" gradientUnits="userSpaceOnUse">
      <stop stopColor="#6F10B9"/>
      <stop offset="1" stopColor="#C02A93"/>
    </linearGradient>
    <linearGradient id="paint3_linear_776_433" x1="1.15076" y1="4.17315" x2="15.5812" y2="4.37775" gradientUnits="userSpaceOnUse">
      <stop stopColor="#6F10B9"/>
      <stop offset="1" stopColor="#C02A93"/>
    </linearGradient>
  </defs>
</svg>
);
schedulerSvg.app.facebook = (
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M16.2791 32.5581H8.13953C5.9808 32.5581 3.91047 31.7006 2.38401 30.1741C0.857556 28.6477 0 26.5773 0 24.4186V8.13953C0 5.9808 0.857556 3.91047 2.38401 2.38401C3.91047 0.857556 5.9808 0 8.13953 0H24.4186C26.5773 0 28.6477 0.857556 30.1741 2.38401C31.7006 3.91047 32.5581 5.9808 32.5581 8.13953V24.4186C32.5581 26.5773 31.7006 28.6477 30.1741 30.1741C28.6477 31.7006 26.5773 32.5581 24.4186 32.5581H21.3663C21.3663 31.8835 21.0983 31.2366 20.6213 30.7595C20.1443 30.2825 19.4973 30.0145 18.8227 30.0145C18.1481 30.0145 17.5011 30.2825 17.0241 30.7595C16.5471 31.2366 16.2791 31.8835 16.2791 32.5581Z" fill="#3764B9"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.2616 9.15698H24.4186C25.5933 9.15845 26.7237 8.70834 27.5758 7.89975C28.428 7.09117 28.9367 5.98598 28.9968 4.8128C29.0569 3.63961 28.6638 2.48821 27.8988 1.59676C27.1338 0.705302 26.0553 0.14201 24.8866 0.0234012C24.731 0.0147529 24.5768 0 24.4186 0H8.13953C5.9808 0 3.91047 0.857556 2.38401 2.38401C0.857556 3.91047 0 5.9808 0 8.13953V24.4186C0 20.371 1.60792 16.4891 4.47003 13.627C7.33214 10.7649 11.214 9.15698 15.2616 9.15698Z" fill="#507DD2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M24.4186 16.2791C22.8088 16.2791 21.2351 16.7564 19.8965 17.6508C18.558 18.5452 17.5147 19.8164 16.8987 21.3037C16.2826 22.791 16.1214 24.4276 16.4355 26.0065C16.7495 27.5855 17.5247 29.0358 18.6631 30.1741C19.8014 31.3125 21.2517 32.0877 22.8307 32.4017C24.4096 32.7158 26.0462 32.5546 27.5335 31.9386C29.0208 31.3225 30.292 30.2792 31.1864 28.9407C32.0808 27.6021 32.5581 26.0285 32.5581 24.4186V8.13953C32.5581 10.2983 31.7006 12.3686 30.1741 13.8951C28.6477 15.4215 26.5773 16.2791 24.4186 16.2791Z" fill="#1E4BA0"/>
<path fillRule="evenodd" clipRule="evenodd" d="M26.4535 9.15698C26.4535 9.42682 26.3463 9.68561 26.1555 9.87642C25.9647 10.0672 25.7059 10.1744 25.436 10.1744H22.3837C22.1139 10.1744 21.8551 10.2816 21.6643 10.4724C21.4735 10.6632 21.3663 10.922 21.3663 11.1919V15.2616H25.2122C25.3627 15.2616 25.5114 15.2951 25.6474 15.3594C25.7834 15.4238 25.9035 15.5176 25.9989 15.634C26.0944 15.7504 26.1628 15.8865 26.1992 16.0326C26.2357 16.1786 26.2393 16.3309 26.2098 16.4785L25.5993 19.5308C25.5532 19.7615 25.4287 19.969 25.2468 20.1182C25.0649 20.2673 24.837 20.3488 24.6017 20.3488H21.3663V32.5581H16.2791V20.3488H13.2267C12.9569 20.3488 12.6981 20.2416 12.5073 20.0508C12.3165 19.86 12.2093 19.6012 12.2093 19.3314V16.2791C12.2093 16.0092 12.3165 15.7504 12.5073 15.5596C12.6981 15.3688 12.9569 15.2616 13.2267 15.2616H16.2791V11.1919C16.2791 9.57281 16.9222 8.02006 18.0671 6.87522C19.2119 5.73038 20.7647 5.08721 22.3837 5.08721H25.436C25.7059 5.08721 25.9647 5.1944 26.1555 5.38521C26.3463 5.57602 26.4535 5.83481 26.4535 6.10465V9.15698Z" fill="white"/>
</svg>
);
schedulerSvg.app.smilyFace = (
<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z" fill="url(#paint0_linear_524_229)"/>
<path d="M8.54328 12.5995H19.4573C20.294 12.5995 20.9732 13.2787 20.9732 14.1154V14.1422C20.9732 17.9758 17.8609 21.088 14.0273 21.088H14.0005C10.1522 21.088 7.02789 17.9637 7.02789 14.1154C7.02789 13.2787 7.70711 12.5995 8.54383 12.5995H8.54328Z" fill="url(#paint1_radial_524_229)"/>
<path d="M18.3094 19.6115C17.1292 20.5362 15.6428 21.088 14.0268 21.088H14C12.3763 21.088 10.8828 20.5335 9.69773 19.6027C10.6706 18.2848 12.2358 17.4295 14 17.4295C15.7642 17.4295 17.3365 18.288 18.3094 19.6115Z" fill="url(#paint2_linear_524_229)"/>
<path d="M20.2147 12.8029V13.2059C20.2147 14.21 19.4004 15.0248 18.3958 15.0248H9.60422C8.59961 15.0248 7.78531 14.21 7.78531 13.2059V12.8029C8.00844 12.6738 8.26711 12.6 8.54328 12.6H19.4573C19.7329 12.6 19.9921 12.6738 20.2152 12.8029H20.2147Z" fill="url(#paint3_linear_524_229)"/>
<path d="M16.4019 9.25039C16.2296 9.25039 16.0557 9.20391 15.8982 9.10656C15.4487 8.8282 15.3109 8.23758 15.5887 7.78805C15.732 7.55672 17.0417 5.52727 18.906 5.52727C20.7703 5.52727 22.0801 7.55672 22.2234 7.78805C22.5017 8.23758 22.3628 8.82711 21.9133 9.10547C21.4643 9.38328 20.8759 9.24547 20.5975 8.79758C20.3197 8.35461 19.5409 7.44078 18.9066 7.44078C18.2722 7.44078 17.4934 8.35461 17.2156 8.79758C17.1299 8.93614 17.0103 9.05049 16.8679 9.12975C16.7256 9.209 16.5653 9.25053 16.4024 9.25039H16.4019Z" fill="url(#paint4_radial_524_229)"/>
<path d="M6.58984 9.25039C6.41758 9.25039 6.24367 9.20391 6.08617 9.10656C5.63664 8.8282 5.49883 8.23758 5.77664 7.78805C5.91992 7.55672 7.22969 5.52727 9.09398 5.52727C10.9583 5.52727 12.268 7.55672 12.4113 7.78805C12.6897 8.23758 12.5508 8.82711 12.1012 9.10547C11.6517 9.38383 11.0622 9.24492 10.7838 8.79539C10.5077 8.35516 9.72891 7.44133 9.09453 7.44133C8.46016 7.44133 7.68141 8.35516 7.40359 8.79813C7.31792 8.93669 7.19822 9.05104 7.05588 9.13029C6.91355 9.20955 6.7533 9.25108 6.59039 9.25094L6.58984 9.25039Z" fill="url(#paint5_radial_524_229)"/>
<defs>
<linearGradient id="paint0_linear_524_229" x1="4.10047" y1="4.10047" x2="23.8995" y2="23.8995" gradientUnits="userSpaceOnUse">
<stop offset="0.1" stopColor="#FFF1AB"/>
<stop offset="0.4" stopColor="#FFDA24"/>
<stop offset="0.51" stopColor="#FFC81D"/>
<stop offset="0.74" stopColor="#FF9A0C"/>
<stop offset="0.9" stopColor="#FF7700"/>
</linearGradient>
<radialGradient id="paint1_radial_524_229" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.9989 14) scale(11.5887 19.0383)">
<stop stopColor="#614444"/>
<stop offset="0.38" stopColor="#583C3C"/>
<stop offset="1" stopColor="#402828"/>
</radialGradient>
<linearGradient id="paint2_linear_524_229" x1="13.9991" y1="28" x2="13.9991" y2="0" gradientUnits="userSpaceOnUse">
<stop stopColor="#FF6A00"/>
<stop offset="0.99" stopColor="#FFA227"/>
</linearGradient>
<linearGradient id="paint3_linear_524_229" x1="13.9994" y1="28" x2="13.9994" y2="-0.00631499" gradientUnits="userSpaceOnUse">
<stop offset="0.65" stopColor="white"/>
<stop offset="0.75" stopColor="#F6F6F6"/>
<stop offset="0.9" stopColor="#DDDDDD"/>
<stop offset="1" stopColor="#CCCCCC"/>
</linearGradient>
<radialGradient id="paint4_radial_524_229" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.9998 14) scale(11.2406 20.8972)">
<stop stopColor="#705454"/>
<stop offset="0.27" stopColor="#674C4C"/>
<stop offset="0.71" stopColor="#4E3838"/>
<stop offset="1" stopColor="#3B2828"/>
</radialGradient>
<radialGradient id="paint5_radial_524_229" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(9.094 0.0273468) scale(2.77867)">
<stop stopColor="#705454"/>
<stop offset="0.27" stopColor="#674C4C"/>
<stop offset="0.71" stopColor="#4E3838"/>
<stop offset="1" stopColor="#3B2828"/>
</radialGradient>
</defs>
</svg>

);
schedulerSvg.app.colorPicker = (
<svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.7411 3.43613C17.1984 3.51761 17.6197 3.73732 17.9483 4.06564C18.2769 4.39396 18.497 4.81512 18.5788 5.27236C18.6872 5.887 19.6053 5.89238 19.7136 5.27236C19.7954 4.81523 20.0154 4.39415 20.3438 4.06583C20.6723 3.73752 21.0935 3.51775 21.5506 3.43613C22.1653 3.3278 22.1691 2.41045 21.5506 2.30058C21.0934 2.2189 20.6721 1.99902 20.3436 1.67056C20.0152 1.3421 19.7953 0.92085 19.7136 0.463574C19.6045 -0.152602 18.6872 -0.156444 18.5788 0.463574C18.4971 0.920956 18.2771 1.34228 17.9485 1.67075C17.6199 1.99923 17.1985 2.21905 16.7411 2.30058C16.1241 2.40891 16.1226 3.32626 16.7411 3.43613ZM21.5506 11.9043C20.9977 11.8057 20.4882 11.5399 20.0911 11.1427C19.6939 10.7456 19.4281 10.2361 19.3295 9.68317C19.2204 9.06623 18.303 9.06315 18.1947 9.68317C18.096 10.2362 17.8301 10.7457 17.4328 11.1429C17.0355 11.5401 16.5259 11.8059 15.9728 11.9043C15.3658 12.0111 15.3458 12.9285 15.9728 13.0399C16.5258 13.1383 17.0353 13.4039 17.4326 13.801C17.8299 14.198 18.0959 14.7073 18.1947 15.2603C18.3023 15.8757 19.2211 15.8795 19.3295 15.2603C19.4282 14.7074 19.6941 14.1982 20.0913 13.8012C20.4884 13.4041 20.9978 13.1384 21.5506 13.0399C22.1683 12.9308 22.1699 12.0142 21.5506 11.9043ZM0.617529 17.8656C0.222069 18.2622 0 18.7994 0 19.3595C0 19.9196 0.222069 20.4569 0.617529 20.8535L1.16072 21.3967C1.95668 22.1926 3.33501 22.2095 4.1494 21.3967L12.6176 12.9277L9.0865 9.39737L0.617529 17.8656ZM16.8225 7.83849C16.8242 7.56157 16.7713 7.28704 16.6668 7.03061C16.5622 6.77419 16.4081 6.54091 16.2132 6.34414L15.6701 5.80095C15.2735 5.40531 14.7363 5.18311 14.1761 5.18311C13.6159 5.18311 13.0787 5.40531 12.6821 5.80095L9.9009 8.5822L13.432 12.1133L16.2132 9.33206C16.4079 9.13533 16.562 8.90217 16.6665 8.6459C16.771 8.38962 16.824 8.11526 16.8225 7.83849Z" fill="black"/>
<path d="M16.7411 3.43613C17.1984 3.51761 17.6197 3.73732 17.9483 4.06564C18.2769 4.39396 18.497 4.81512 18.5788 5.27236C18.6872 5.887 19.6053 5.89238 19.7136 5.27236C19.7954 4.81523 20.0154 4.39415 20.3438 4.06583C20.6723 3.73752 21.0935 3.51775 21.5506 3.43613C22.1653 3.3278 22.1691 2.41045 21.5506 2.30058C21.0934 2.2189 20.6721 1.99902 20.3436 1.67056C20.0152 1.3421 19.7953 0.92085 19.7136 0.463574C19.6045 -0.152602 18.6872 -0.156444 18.5788 0.463574C18.4971 0.920956 18.2771 1.34228 17.9485 1.67075C17.6199 1.99923 17.1985 2.21905 16.7411 2.30058C16.1241 2.40891 16.1226 3.32626 16.7411 3.43613ZM21.5506 11.9043C20.9977 11.8057 20.4882 11.5399 20.0911 11.1427C19.6939 10.7456 19.4281 10.2361 19.3295 9.68317C19.2204 9.06623 18.303 9.06315 18.1947 9.68317C18.096 10.2362 17.8301 10.7457 17.4328 11.1429C17.0355 11.5401 16.5259 11.8059 15.9728 11.9043C15.3658 12.0111 15.3458 12.9285 15.9728 13.0399C16.5258 13.1383 17.0353 13.4039 17.4326 13.801C17.8299 14.198 18.0959 14.7073 18.1947 15.2603C18.3023 15.8757 19.2211 15.8795 19.3295 15.2603C19.4282 14.7074 19.6941 14.1982 20.0913 13.8012C20.4884 13.4041 20.9978 13.1384 21.5506 13.0399C22.1683 12.9308 22.1699 12.0142 21.5506 11.9043ZM0.617529 17.8656C0.222069 18.2622 0 18.7994 0 19.3595C0 19.9196 0.222069 20.4569 0.617529 20.8535L1.16072 21.3967C1.95668 22.1926 3.33501 22.2095 4.1494 21.3967L12.6176 12.9277L9.0865 9.39737L0.617529 17.8656ZM16.8225 7.83849C16.8242 7.56157 16.7713 7.28704 16.6668 7.03061C16.5622 6.77419 16.4081 6.54091 16.2132 6.34414L15.6701 5.80095C15.2735 5.40531 14.7363 5.18311 14.1761 5.18311C13.6159 5.18311 13.0787 5.40531 12.6821 5.80095L9.9009 8.5822L13.432 12.1133L16.2132 9.33206C16.4079 9.13533 16.562 8.90217 16.6665 8.6459C16.771 8.38962 16.824 8.11526 16.8225 7.83849Z" fill="url(#paint0_linear_524_278)"/>
<path d="M20.6824 8.05438C21.0007 8.05438 21.2587 7.79639 21.2587 7.47815C21.2587 7.15991 21.0007 6.90193 20.6824 6.90193C20.3642 6.90193 20.1062 7.15991 20.1062 7.47815C20.1062 7.79639 20.3642 8.05438 20.6824 8.05438Z" fill="black"/>
<path d="M20.6824 8.05438C21.0007 8.05438 21.2587 7.79639 21.2587 7.47815C21.2587 7.15991 21.0007 6.90193 20.6824 6.90193C20.3642 6.90193 20.1062 7.15991 20.1062 7.47815C20.1062 7.79639 20.3642 8.05438 20.6824 8.05438Z" fill="url(#paint1_linear_524_278)"/>
<path d="M13.7678 2.67628C14.086 2.67628 14.344 2.41829 14.344 2.10005C14.344 1.78181 14.086 1.52383 13.7678 1.52383C13.4495 1.52383 13.1915 1.78181 13.1915 2.10005C13.1915 2.41829 13.4495 2.67628 13.7678 2.67628Z" fill="black"/>
<path d="M13.7678 2.67628C14.086 2.67628 14.344 2.41829 14.344 2.10005C14.344 1.78181 14.086 1.52383 13.7678 1.52383C13.4495 1.52383 13.1915 1.78181 13.1915 2.10005C13.1915 2.41829 13.4495 2.67628 13.7678 2.67628Z" fill="url(#paint2_linear_524_278)"/>
<path d="M5.26037 4.2098C5.91614 4.27462 6.52921 4.56465 6.99524 5.03054C7.46127 5.49644 7.75149 6.10941 7.8165 6.76517C7.8818 7.4359 8.8975 7.44051 8.96357 6.76517C9.02852 6.10965 9.31852 5.49686 9.78424 5.03099C10.25 4.56513 10.8627 4.27495 11.5182 4.2098C12.1851 4.14527 12.1935 3.12881 11.5182 3.06273C10.8627 2.99758 10.25 2.70741 9.78424 2.24154C9.31852 1.77568 9.02852 1.16289 8.96357 0.507368C8.8998 -0.150297 7.88334 -0.177956 7.8165 0.507368C7.75152 1.16301 7.46142 1.77589 6.99554 2.24177C6.52966 2.70765 5.91677 2.99775 5.26113 3.06273C4.60808 3.1265 4.57197 4.14296 5.26037 4.2098Z" fill="black"/>
<path d="M5.26037 4.2098C5.91614 4.27462 6.52921 4.56465 6.99524 5.03054C7.46127 5.49644 7.75149 6.10941 7.8165 6.76517C7.8818 7.4359 8.8975 7.44051 8.96357 6.76517C9.02852 6.10965 9.31852 5.49686 9.78424 5.03099C10.25 4.56513 10.8627 4.27495 11.5182 4.2098C12.1851 4.14527 12.1935 3.12881 11.5182 3.06273C10.8627 2.99758 10.25 2.70741 9.78424 2.24154C9.31852 1.77568 9.02852 1.16289 8.96357 0.507368C8.8998 -0.150297 7.88334 -0.177956 7.8165 0.507368C7.75152 1.16301 7.46142 1.77589 6.99554 2.24177C6.52966 2.70765 5.91677 2.99775 5.26113 3.06273C4.60808 3.1265 4.57197 4.14296 5.26037 4.2098Z" fill="url(#paint3_linear_524_278)"/>
<defs>
<linearGradient id="paint0_linear_524_278" x1="1.80833" y1="6.55781" x2="24.4847" y2="6.87932" gradientUnits="userSpaceOnUse">
<stop stopColor="#6F10B9"/>
<stop offset="1" stopColor="#C02A93"/>
</linearGradient>
<linearGradient id="paint1_linear_524_278" x1="1.80833" y1="6.55781" x2="24.4847" y2="6.87932" gradientUnits="userSpaceOnUse">
<stop stopColor="#6F10B9"/>
<stop offset="1" stopColor="#C02A93"/>
</linearGradient>
<linearGradient id="paint2_linear_524_278" x1="1.80833" y1="6.55781" x2="24.4847" y2="6.87932" gradientUnits="userSpaceOnUse">
<stop stopColor="#6F10B9"/>
<stop offset="1" stopColor="#C02A93"/>
</linearGradient>
<linearGradient id="paint3_linear_524_278" x1="1.80833" y1="6.55781" x2="24.4847" y2="6.87932" gradientUnits="userSpaceOnUse">
<stop stopColor="#6F10B9"/>
<stop offset="1" stopColor="#C02A93"/>
</linearGradient>
</defs>
</svg>
);

schedulerSvg.app.eyeSvg = (
<svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.57017 7.71402C9.5168 7.71402 10.2842 6.94663 10.2842 6C10.2842 5.05337 9.5168 4.28597 8.57017 4.28597C7.62354 4.28597 6.85614 5.05337 6.85614 6C6.85614 6.94663 7.62354 7.71402 8.57017 7.71402Z" fill="black"/>
<path d="M8.57017 7.71402C9.5168 7.71402 10.2842 6.94663 10.2842 6C10.2842 5.05337 9.5168 4.28597 8.57017 4.28597C7.62354 4.28597 6.85614 5.05337 6.85614 6C6.85614 6.94663 7.62354 7.71402 8.57017 7.71402Z" fill="url(#paint0_linear_534_316)"/>
<path d="M17.0889 5.70005C16.4144 3.99826 15.2385 2.54179 13.717 1.52392C12.1955 0.506038 10.4005 -0.025125 8.57017 0.000913504C6.7398 -0.025125 4.94479 0.506038 3.42333 1.52392C1.90187 2.54179 0.725894 3.99826 0.051464 5.70005C-0.0171547 5.89413 -0.0171547 6.10587 0.051464 6.29995C0.725894 8.00174 1.90187 9.45821 3.42333 10.4761C4.94479 11.494 6.7398 12.0251 8.57017 11.9991C10.4005 12.0251 12.1955 11.494 13.717 10.4761C15.2385 9.45821 16.4144 8.00174 17.0889 6.29995C17.1575 6.10587 17.1575 5.89413 17.0889 5.70005ZM8.57188 9.42805H8.57017C7.66099 9.4285 6.78887 9.06777 6.14567 8.42521C5.50246 7.78265 5.14086 6.91089 5.1404 6.00171C5.13995 5.09254 5.50068 4.22042 6.14324 3.57721C6.78581 2.93401 7.65756 2.57241 8.56674 2.57195C9.47591 2.57127 10.3481 2.93178 10.9915 3.57418C11.6349 4.21659 11.9967 5.08825 11.9974 5.99743C11.998 6.9066 11.6375 7.77881 10.9951 8.42218C10.3527 9.06555 9.48106 9.42737 8.57188 9.42805Z" fill="black"/>
<path d="M17.0889 5.70005C16.4144 3.99826 15.2385 2.54179 13.717 1.52392C12.1955 0.506038 10.4005 -0.025125 8.57017 0.000913504C6.7398 -0.025125 4.94479 0.506038 3.42333 1.52392C1.90187 2.54179 0.725894 3.99826 0.051464 5.70005C-0.0171547 5.89413 -0.0171547 6.10587 0.051464 6.29995C0.725894 8.00174 1.90187 9.45821 3.42333 10.4761C4.94479 11.494 6.7398 12.0251 8.57017 11.9991C10.4005 12.0251 12.1955 11.494 13.717 10.4761C15.2385 9.45821 16.4144 8.00174 17.0889 6.29995C17.1575 6.10587 17.1575 5.89413 17.0889 5.70005ZM8.57188 9.42805H8.57017C7.66099 9.4285 6.78887 9.06777 6.14567 8.42521C5.50246 7.78265 5.14086 6.91089 5.1404 6.00171C5.13995 5.09254 5.50068 4.22042 6.14324 3.57721C6.78581 2.93401 7.65756 2.57241 8.56674 2.57195C9.47591 2.57127 10.3481 2.93178 10.9915 3.57418C11.6349 4.21659 11.9967 5.08825 11.9974 5.99743C11.998 6.9066 11.6375 7.77881 10.9951 8.42218C10.3527 9.06555 9.48106 9.42737 8.57188 9.42805Z" fill="url(#paint1_linear_534_316)"/>
<defs>
<linearGradient id="paint0_linear_534_316" x1="1.40796" y1="3.57699" x2="19.06" y2="3.93424" gradientUnits="userSpaceOnUse">
<stop stopColor="#6F10B9"/>
<stop offset="1" stopColor="#C02A93"/>
</linearGradient>
<linearGradient id="paint1_linear_534_316" x1="1.40796" y1="3.57699" x2="19.06" y2="3.93424" gradientUnits="userSpaceOnUse">
<stop stopColor="#6F10B9"/>
<stop offset="1" stopColor="#C02A93"/>
</linearGradient>
</defs>
</svg>
);
schedulerSvg.app.filterSvg = (
<svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.3848 0H1.31896C1.06342 0.000201229 0.813441 0.0745694 0.599348 0.214079C0.385255 0.353588 0.216259 0.552241 0.112869 0.785927C0.00947917 1.01961 -0.0238588 1.27828 0.0169012 1.53055C0.0576612 1.78281 0.170767 2.01782 0.34249 2.20706L4.70484 7.0047V11.5294C4.70488 11.6179 4.72984 11.7045 4.77687 11.7794C4.8239 11.8543 4.89108 11.9145 4.97072 11.9529C5.03437 11.9843 5.10446 12.0004 5.17543 12C5.28238 11.9999 5.38612 11.9634 5.46955 11.8965L7.82248 10.0141C7.87751 9.97005 7.92194 9.91419 7.95249 9.85066C7.98303 9.78713 7.99891 9.71755 7.99896 9.64705V7.0047L12.3613 2.20706C12.533 2.01782 12.6461 1.78281 12.6869 1.53055C12.7277 1.27828 12.6943 1.01961 12.5909 0.785927C12.4875 0.552241 12.3185 0.353588 12.1044 0.214079C11.8904 0.0745694 11.6404 0.000201229 11.3848 0Z" fill="#161930"/>
</svg>
);
schedulerSvg.app.addNewSvg = (
  <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.85714 6.85714C7.76646 6.85714 8.63853 6.49592 9.28151 5.85294C9.92449 5.20996 10.2857 4.33789 10.2857 3.42857C10.2857 2.51926 9.92449 1.64719 9.28151 1.00421C8.63853 0.361224 7.76646 0 6.85714 0C5.94783 0 5.07576 0.361224 4.43278 1.00421C3.78979 1.64719 3.42857 2.51926 3.42857 3.42857C3.42857 4.33789 3.78979 5.20996 4.43278 5.85294C5.07576 6.49592 5.94783 6.85714 6.85714 6.85714ZM6.85714 9.14286C8.67577 9.14286 10.4199 9.8653 11.7059 11.1513C12.9918 12.4372 13.7143 14.1814 13.7143 16H0C0 14.1814 0.722447 12.4372 2.00841 11.1513C3.29437 9.8653 5.03852 9.14286 6.85714 9.14286ZM16 4.57143C16 4.26832 15.8796 3.97763 15.6653 3.76331C15.4509 3.54898 15.1602 3.42857 14.8571 3.42857C14.554 3.42857 14.2633 3.54898 14.049 3.76331C13.8347 3.97763 13.7143 4.26832 13.7143 4.57143V5.71429H12.5714C12.2683 5.71429 11.9776 5.83469 11.7633 6.04902C11.549 6.26335 11.4286 6.55404 11.4286 6.85714C11.4286 7.16025 11.549 7.45094 11.7633 7.66527C11.9776 7.87959 12.2683 8 12.5714 8H13.7143V9.14286C13.7143 9.44596 13.8347 9.73665 14.049 9.95098C14.2633 10.1653 14.554 10.2857 14.8571 10.2857C15.1602 10.2857 15.4509 10.1653 15.6653 9.95098C15.8796 9.73665 16 9.44596 16 9.14286V8H17.1429C17.446 8 17.7367 7.87959 17.951 7.66527C18.1653 7.45094 18.2857 7.16025 18.2857 6.85714C18.2857 6.55404 18.1653 6.26335 17.951 6.04902C17.7367 5.83469 17.446 5.71429 17.1429 5.71429H16V4.57143Z" fill="#1E4BA0"/>
  </svg>

  );
  schedulerSvg.app.boldText = (
    <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.1254 7.91831C9.73825 7.35738 9.1388 6.98398 8.32675 6.79812C8.97397 6.5354 9.45288 6.14441 9.76377 5.62507C10.0747 5.10557 10.23 4.51292 10.23 3.84617C10.23 2.57056 9.7478 1.61052 8.78361 0.966383C7.81925 0.322137 6.47733 0 4.75821 0H0V14H5.44332C7.13737 14 8.43781 13.6508 9.34518 12.9519C10.2522 12.2533 10.7059 11.2212 10.7059 9.85584C10.7059 9.12518 10.5122 8.47927 10.1254 7.91831ZM3.22609 2.50001H4.75829C5.51963 2.50001 6.08417 2.63632 6.45235 2.90869C6.82015 3.1812 7.00423 3.61534 7.00423 4.21157C7.00423 4.73725 6.82334 5.13625 6.46174 5.40862C6.10006 5.68113 5.55438 5.82378 4.82481 5.83658H3.22609V2.50001ZM6.98507 11.0913C6.64249 11.3638 6.12858 11.5001 5.44332 11.5001H3.22609V7.99047H5.62433C6.28408 7.99047 6.76148 8.14592 7.0564 8.45671C7.35149 8.76766 7.49906 9.22759 7.49906 9.83661C7.49906 10.4007 7.32756 10.8189 6.98507 11.0913Z" fill="#6B597A"/>
    </svg>
    
      );
  // my library svg

  schedulerSvg.app.remove = (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="15" height="15" x="0" y="0" viewBox="0 0 64 64" ><g transform="matrix(1.1099999999999999,0,0,1.1099999999999999,-3.520738735198975,-3.5194756364822375)"><path d="M4.59 59.41a2 2 0 0 0 2.83 0L32 34.83l24.59 24.58a2 2 0 0 0 2.83-2.83L34.83 32 59.41 7.41a2 2 0 0 0-2.83-2.83L32 29.17 7.41 4.59a2 2 0 0 0-2.82 2.82L29.17 32 4.59 56.59a2 2 0 0 0 0 2.82z" fill="#000000"  data-original="#000000" className=""></path></g></svg>
  );
  schedulerSvg.app.removeWhite = (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="15" height="15" x="0" y="0" viewBox="0 0 64 64" ><g transform="matrix(1.1099999999999999,0,0,1.1099999999999999,-3.520738735198975,-3.5194756364822375)"><path d="M4.59 59.41a2 2 0 0 0 2.83 0L32 34.83l24.59 24.58a2 2 0 0 0 2.83-2.83L34.83 32 59.41 7.41a2 2 0 0 0-2.83-2.83L32 29.17 7.41 4.59a2 2 0 0 0-2.82 2.82L29.17 32 4.59 56.59a2 2 0 0 0 0 2.82z" fill="#ffffff"  data-original="#000000" className=""></path></g></svg>
  );
  schedulerSvg.app.leftArrow = (
    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
    <path d="M6.41495 0.191807L6.80585 0.584883C6.92793 0.708591 6.99518 0.873178 6.99518 1.04908C6.99518 1.22489 6.92793 1.38967 6.80585 1.51338L2.3773 5.99732L6.81076 10.4864C6.93284 10.6099 7 10.7747 7 10.9505C7 11.1263 6.93284 11.2912 6.81076 11.4148L6.42227 11.808C6.16964 12.064 5.75811 12.064 5.50548 11.808L0.207928 6.46318C0.0859463 6.33966 -7.31453e-07 6.17507 -7.39206e-07 5.99771L-7.39295e-07 5.99566C-7.46984e-07 5.81975 0.0860426 5.65517 0.207928 5.53165L5.49112 0.191807C5.6131 0.0680981 5.78056 0.000194619 5.95419 5.92262e-08C6.12791 4.93879e-08 6.29306 0.0680981 6.41495 0.191807Z" fill="#161930"/>
    </svg>
  );
  schedulerSvg.app.Arrow = (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 4.97 8.969" fill="#ffffff">
    <path className="cls-1" d="M1467.33,90.2l3.56,3.761a0.8,0.8,0,0,1,0,1.061l-3.56,3.761a0.688,0.688,0,0,1-.93,0,0.787,0.787,0,0,1-.08-1.061l3.06-3.227-3.06-3.235a0.8,0.8,0,0,1,0-1.061A0.692,0.692,0,0,1,1467.33,90.2Z" transform="translate(-1466.12 -90)"></path>
  </svg>
  );
  schedulerSvg.app.EditDraft = (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="15" height="15" x="0" y="0" viewBox="0 0 492.493 492"><g><path d="M304.14 82.473 33.165 353.469a10.799 10.799 0 0 0-2.816 4.949L.313 478.973a10.716 10.716 0 0 0 2.816 10.136 10.675 10.675 0 0 0 7.527 3.114 10.6 10.6 0 0 0 2.582-.32l120.555-30.04a10.655 10.655 0 0 0 4.95-2.812l271-270.977zM476.875 45.523 446.711 15.36c-20.16-20.16-55.297-20.14-75.434 0l-36.949 36.95 105.598 105.597 36.949-36.949c10.07-10.066 15.617-23.465 15.617-37.715s-5.547-27.648-15.617-37.719zm0 0" fill="#ffffff" data-original="#000000" className=""></path></g></svg>
  );
  schedulerSvg.app.EyeWhiteSvg = (
    <svg viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.7895 0C6.74861 0 2.35818 3.22256 0 8C2.35818 12.7783 6.74861 16 11.7895 16C16.8303 16 21.2208 12.7783 23.5789 8C21.2208 3.22256 16.8303 0 11.7895 0ZM11.7895 13C9.07611 13 6.8771 10.7618 6.8771 8C6.8771 5.23822 9.07611 2.99996 11.7895 2.99996C14.5028 2.99996 16.7019 5.23822 16.7019 8C16.7019 10.7618 14.5028 13 11.7895 13Z"/>
    <path d="M11.7895 11C13.4173 11 14.7368 9.65683 14.7368 8C14.7368 6.34317 13.4173 5.00004 11.7895 5.00004C10.1617 5.00004 8.84211 6.34317 8.84211 8C8.84211 9.65683 10.1617 11 11.7895 11Z"/>
    </svg>

  );



schedulerSvg.app.Comment = (
  <svg xmlns="http://www.w3.org/2000/svg"  width="20" height="20" x="0" y="0" viewBox="0 0 48 48"><g transform="matrix(1.2599999999999991,0,0,1.2599999999999991,-6.239999999999984,-6.75999999999997)"><path d="M16 42a1 1 0 0 1-1-1v-6c-3.86 0-7-3.14-7-7V17c0-3.86 3.14-7 7-7h18c3.86 0 7 3.14 7 7v11c0 3.86-3.14 7-7 7h-7.548a1 1 0 0 0-.625.22l-8.202 6.56A1 1 0 0 1 16 42zm-1-30c-2.757 0-5 2.243-5 5v11c0 2.757 2.243 5 5 5 1.103 0 2 .897 2 2v3.92l6.578-5.262A3.008 3.008 0 0 1 25.452 33H33c2.757 0 5-2.243 5-5V17c0-2.757-2.243-5-5-5z" fill="#1c293b" data-original="#000000" class=""></path></g></svg>
);
schedulerSvg.app.Share = (
  <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none"  fillRule="evenodd">
        <path d="M2.0002379,17.8741567 C2.2118296,13.890249 3.07049967,11.1687045 4.57624811,9.7095232 C6.09779847,8.23502871 8.05625727,7.47461771 10.4516245,7.42829018 L10.4516245,3.32029737 C10.4516245,3.14340202 10.5950265,3 10.7719219,3 L10.7719219,3 C10.976658,3 11.1726536,3.08297419 11.3151566,3.22997683 L17.5169225,9.62756975 C17.9013306,10.0241159 17.8914907,10.6572044 17.4949446,11.0416125 C17.4928309,11.0436615 17.4907082,11.0457011 17.4885766,11.0477313 L11.1160032,17.1170038 C11.0459765,17.1836975 10.9666461,17.239881 10.8804895,17.2837995 L10.7310161,17.3599939 C10.6364778,17.408185 10.5207726,17.3706131 10.4725815,17.2760748 C10.458806,17.2490509 10.4516245,17.2191494 10.4516245,17.188817 L10.4516245,12.8949987 C8.73620915,12.7883774 7.25982301,13.1273968 6.02246607,13.9120569 C4.78510913,14.6967169 3.52608231,16.0593646 2.24538561,18 L2.11958786,18 C2.05358739,18 2.00008345,17.9464961 2.00008345,17.8804956 C2.00008345,17.8783818 2.00013953,17.8762683 2.00025164,17.8741575 Z" id="Path-6" stroke="#1c293b"></path>
        <rect id="Rectangle-Copy-2" stroke-opacity="0" stroke="#1c293b" x="0.5" y="0.5" width="20" height="20"></rect>
    </g>
</svg>
);
schedulerSvg.app.threeDot1 =(
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="18" height="18" x="0" y="0" viewBox="0 0 32 32"><g><path d="M13 16c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3-3 1.346-3 3zM13 26c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3-3 1.346-3 3zM13 6c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3-3 1.346-3 3z" fill="#72778F" opacity="1" data-original="#000000"></path></g></svg>
);
schedulerSvg.app.threeDot = (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="18" height="18" x="0" y="0" viewBox="0 0 426.667 426.667" ><g><circle cx="42.667" cy="213.333" r="42.667" fill="#72778F" opacity="1" data-original="#000000"></circle><circle cx="213.333" cy="213.333" r="42.667" fill="#72778F" opacity="1" data-original="#000000"></circle><circle cx="384" cy="213.333" r="42.667" fill="#72778F" opacity="1" data-original="#000000"></circle></g></svg>
);
schedulerSvg.app.copy = (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="20" height="20" viewBox="0 0 24 24" ><g><path d="M18.548 2H9.452A3.456 3.456 0 0 0 6 5.452V6h-.548A3.456 3.456 0 0 0 2 9.452v9.096A3.456 3.456 0 0 0 5.452 22h9.096c1.748 0 3.182-1.312 3.406-3h.594A3.456 3.456 0 0 0 22 15.548V5.452A3.456 3.456 0 0 0 18.548 2zM20 15.548c0 .8-.651 1.452-1.452 1.452H18V9.452A3.456 3.456 0 0 0 14.548 6H8v-.548C8 4.652 8.651 4 9.452 4h9.096c.8 0 1.452.651 1.452 1.452z" fill="#ffffff" opacity="1" data-original="#000000"></path></g></svg>
);

schedulerSvg.app.reload = (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="200px"
      height="200px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      style={{ marginRight: '-2px', display: 'block', backgroundColor: 'rgb(255, 255, 255)', animationPlayState: 'paused' }}
    >
      <g transform="matrix(1,0,0,1,0,0)" style={{ transform: 'matrix(1, 0, 0, 1, 0, 0)', animationPlayState: 'paused' }}>
        <path
          d="M50 15A35 35 0 1 0 74.74873734152916 25.251262658470843"
          fill="none"
          stroke="#8417af"
          strokeWidth="5"
          style={{ animationPlayState: 'paused' }}
        />
        <path
          d="M49 3L49 27L61 15L49 3"
          fill="#8417af"
          style={{ animationPlayState: 'paused' }}
        />
      </g>
    </svg>
)

export default schedulerSvg;