
import svg from './svg';
import { useEffect, useState } from 'react';
import styles from './widget.module.css'



function WidgetPreview({ combinedProps }) {
    const [show, setShow] = useState(true);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const [showCreateBox, setShowCrateBox] = useState(false)
    const [errors, setErrors] = useState({});

    const[activeDiv,setActiveDiv] = useState('register')
    const[minimize,setMinimize] = useState(false);

    const { buttonText, colorTheme, emailLabel, emailPlaceholder, emptyMess, heading, invalidEmailMess, nameLabel, namePlaceholder, position, subHeading, title, widgetType, projectId } = combinedProps

    useEffect(()=>{
         setName('')
         setEmail('')
    },[show == false])

   
  
    return (
        <div className={styles.pxl_widget_preview_box}>
           <div className={styles.pxl_widget_scroll}>
           <div className={styles.pxl_widget_heading1}>
                <h3 className={styles.ps_heading}>Widget Preview</h3>
            </div>
            <div className={styles.ps_wa_preview + " " + styles.pxl_widget_preview_inner}>
               
                <div className={(widgetType == "bubble") ? (position === "right") ? [styles.psw_widget_wrapper, styles.psw_widget_right, styles.psw_bubble].join(' ') : [styles.psw_widget_wrapper, styles.psw_widget_left, styles.psw_bubble].join(' ') : (position === "right") ? [styles.psw_widget_wrapper, styles.psw_widget_right, styles.psw_button].join(' ') : [styles.psw_widget_wrapper, styles.psw_widget_left, styles.psw_button].join(' ')}>

                    <div className={styles.psw_widget_inner}>
                        <div className={styles.psw_chat_toggle} onClick={() => setShow(!show)}>
                            <span className={styles.psw_chat_bubble_arrow} ></span>
                            {svg.chat}
                            <span>chat with us</span>
                        </div>
                        <div className={styles.psw_chat_box_wrapper} style={{ display: (show) ? "" : "none" }} >
                            <div className={styles.psw_chat_box_inner}>
                                <div className={styles.psw_cb_header} >
                                    <div className={styles.psw_cb_title}>
                                        <h3 name="psw_widget_title">{title}</h3>
                                        <div className={styles.psw_cb_title_actions}>
                                            <div className={styles.psw_cb_minimize_chat} title="Minimize" onClick={()=>{setMinimize(!minimize);setActiveDiv('register')}}></div>
                                            <div className={styles.psw_cb_close_chat} title="Close Chat" onClick={() =>{ setShow(false);setActiveDiv('register');setErrors({})}}><span></span><span></span></div>
                                        </div>
                                    </div>
                                </div>

                                {/* Register Customer  */}
                                <div className={styles.psw_login_box} style={{ display:(minimize) ? activeDiv == 'register' ?  "block" : "none" : ""}}>
                                    <h3 name="psw_heading">{heading}</h3>
                                    <p name="psw_sub_heading">{subHeading}</p>
                                    <div className={styles.psw_input_wrapper}>
                                        <label name="psw_name_label">{nameLabel}</label>
                                        <input type="text" className={styles.psw_input} name="psw_name_input" placeholder={namePlaceholder} value={name} onChange={(e) => setName(e.target.value)} />
                                        {errors.name && <span className={styles.pt_error}>{errors.name}</span>}
                                    </div>
                                    <div className={styles.psw_input_wrapper}>
                                        <label name="psw_email_label">{emailLabel}</label>
                                        <input type="text" className={styles.psw_input} name="psw_email_input" placeholder={emailPlaceholder} value={email} onChange={(e) => setEmail(e.target.value)} />
                                        {errors.email && <span className={styles.pt_error}>{errors.email}</span>}
                                    </div>
                                    <button className={styles.psw_btn + " " + styles.psw_login_btn} name="btn_text" >{buttonText}</button>
                                </div>


                                {/* Create ps Box */}
                                <div className={styles.psw_login_box} style={{ display:(minimize) ? activeDiv == 'tickets' ?  "block" : "none" : "" }} >
                                    <h3 name="psw_heading">Tickets</h3>
                                    <div className={styles.psw_ticket} onClick={()=>setActiveDiv('chat')}>
                                        <div className={styles.tickets}>Test Ticket</div>
                                    </div>
                                    <div className={(showCreateBox) ? styles.create_ticket_hide + " " +  styles.show : styles.create_ticket_hide + " " + styles.hide }>
                                        <h3 name="psw_heading">Create Ticket</h3>
                                        <div className={styles.psw_input_wrapper}>
                                            <label name="psw_name_label">Issue Title</label>
                                            <input type="text" className={styles.psw_input} name="psw_name_input" placeholder="Enter Issue Title"  />
                                        </div>
                                        <div className={styles.psw_input_wrapper}>
                                            <label name="psw_email_label">Description</label>
                                            <textarea rows="3" className={styles.psw_input} name="psw_oc_msg_ph" placeholder="Enter Description"  />
                                      </div>
                                    
                                    <div>
                                        <button className={styles.psw_btn} name="btn_text" onClick={()=>setActiveDiv('chat')}>Create</button>
                                        <button onClick={()=>setShowCrateBox(false)} className={styles.psw_create_ticket_close_btn} style={{display :showCreateBox ? '' : 'none'}}>Cancel</button>
                                   </div>    

                                    </div>
                                    <button onClick={()=>setShowCrateBox(true)} className={styles.psw_btn + " " + styles.psw_create_ticket_btn}  style={{display :showCreateBox ? 'none' : ''}}>Create Ticket</button>
                                
                                </div>

                                {/* Chat Box */}
                                <div className={styles.psw_chat_box} style={{display:minimize ? activeDiv == 'chat' ?  "flex" : "none" : "" }}>
                                    <div className={styles.psw_cb_body}>
                                        <div className={styles.ticket_mess_heading}>
                                            <span onClick={()=>setActiveDiv('tickets')}>{svg.back_arrow}</span><h3> #1 Ticket Subject</h3>
                                        </div>
                                        <div className={styles.psw_cb_msg_wrapper} id="psw_messageBody">
                                            <div className={styles.psw_cb_msg + " " + styles.psw_left}>
                                                <div className={styles.psw_cb_msg_inner}>
                                                    <p className={styles.psw_cb_msg_time}>Customer</p>
                                                    <p className={styles.psw_cb_msg_text}>Hello</p>
                                                </div>
                                            </div>
                                         
                                            <div className={styles.psw_cb_msg + " " + styles.psw_right}>
                                                <div className={styles.psw_cb_msg_inner}>
                                                    <p className={styles.psw_cb_msg_time}>Operator</p>
                                                    <p className={styles.psw_cb_msg_text} >This is the demo message.</p>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    {/* <div className={styles.psw_cb_footer}>
                                        <textarea className={styles.psw_cb_textarea} placeholder="Enter your message"></textarea>
                                        <span className={styles.psw_send_message_btn}>
                                           {svg.mess_send}
                                        </span>
                                    </div> */}
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
           </div>


        </div>
    )
}

export default WidgetPreview