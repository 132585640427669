import { Outlet } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useStore } from "../zustand/store";
import '../../node_modules/react-toastify/dist/ReactToastify.css';
import Cookies from "js-cookie";
import { useLocation, useNavigate } from 'react-router-dom';
import CreateBrandPopUp from "../components/common/CreateBrandPopUp";
import Header from "../components/Header";
import Alert from "../components/Alert"
import TabNavigation from "./TabNavigation"


const Layout = () => {
    const navigate = useNavigate();
    let store = useStore(state => state),
        { token, role, currentBrandId } = store.userData,
        cookieToken = Cookies.get('authToken'),
        authPage = ['/', '/login'],
        currentPath = useLocation().pathname;
    const [createBrandPopUp, setCreateBrandPopUp] = useState(false)

    useEffect(() => {
        if (!['/terms-conditions', '/privacy-policy'].includes(currentPath)) {

        if (currentPath.includes('imagePreview')) {

        } else if (!authPage.includes(currentPath) && !token) {
            navigate('/');
        } else if (token && cookieToken && token != cookieToken) {//token save in Cookie and store but not same
            store.updateStore("userData", {});
            Cookies.remove('authToken');
        } else if (token && token == cookieToken && authPage.includes(currentPath)) {//accessing the auth page in case of already login
            if (role == 'admin') {
                navigate('/admin');
            } else {
                navigate('/dashboard');
            }

        }
    }
    }, []);
    useEffect(() => {
        console.log(token, cookieToken, currentPath)
        if (!['/terms-conditions', '/privacy-policy'].includes(currentPath)) {
            if (token == 'undefined' && cookieToken == 'undefined') {
                navigate(currentPath);
            }
        }
    }, [currentPath])

    useEffect(() => {
        if (role === 'user') {
            if (!currentBrandId) {
                setCreateBrandPopUp(true);
                return false
            }
        }

    }, [role])

    let pathsToShowHeader = ['/scheduler', '/images', '/pixa-support'];

    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = 'https://api.pixasupport.com/project_widget.js';
    //     script.async = true;
    //     document.getElementById('pixasupport').appendChild(script);
    // useEffect(() => {
    //     const script = document.createElement('script');
    //     script.src = 'https://api.pixasupport.com/project_widget.js';
    //     script.async = true;
    //     document.getElementById('pixasupport').appendChild(script);

    //     return () => {
    //       document.getElementById('pixasupport').removeChild(script);
    //     };
    //   }, []);

    return (
        <>
            {cookieToken && pathsToShowHeader.some(path => currentPath.includes(path)) && <Header />}

            <Outlet />
            {/* <ToastContainer /> */}
            <Alert/>
            <TabNavigation />
            {/* <div id="pixasupport" className="f31bd9b73cf5d1454ba8743fc809bb4b9b63a005cb7064e42aa5ec63f4c14ebe"></div> */}

            {createBrandPopUp && <CreateBrandPopUp
                openModal={createBrandPopUp}
                handleClose={() => {
                    setCreateBrandPopUp(false)
                }}
                type={'firstcreate'}
            />}
        </>
    )
};

export default Layout;