import  { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useStore } from "../../zustand/store";

import { Modal } from 'react-bootstrap';
import { hitApi, setMyState, Pagination, commonToast, skeletonLoader, NoDataFoundDataTable, getNameInitials, dateFormatter, teamMemberRestrictionMess, validations } from "../../components/commonAction";
import Select from "react-select";
import svg from "./components/svg";
import makeAnimated from 'react-select/animated';


const animatedComponents = makeAnimated();

const Customers = ({ pageTitle }) => {
   
    let store = useStore(state => state);
    const { role ,scope} = store.userData;

    const [state, setState] = useState({
        data: [],
        limit: 10,
        page: 1,
        keyword: '',
        sort: 'createdAt=-1',
        filterProject: '',
        status: 3,
        totalRecords: '',
        userLoading: false,
        clearKeyword: false,
    });
    const [user, setUser] = useState({
        name: '',
        email: '',
        password: '',
        filterProject: '',
        target: '',
        confirmPass: '',
        status: '',
        isEdit: false
    })

    const [btnLoading, setBtnLoading] = useState(false);
    const [projectList, setProjectList] = useState([]);
    const [selectedProject, setSelectedProject] = useState([])

    useEffect(() => {
        getUserList();
    }, [state.page, state.status, state.filterProject, state.clearKeyword])

    useEffect(() => {
        getProjectList();
    }, [])

    const clearSearch = () => {
        setMyState(setState, { ...state, clearKeyword: true, keyword: '' });
    }

    const getUserList = () => {
        setMyState(setState, { ...state, userLoading: true });
        let { limit, page, keyword, sort, status, filterProject } = state;
        let data = { limit, page: keyword ? 1 : page, searchTerm:keyword, sort, };
        if (status != 3) {
            data['status'] = status;
        }
 
        if(filterProject){
            data['project_id'] = filterProject;
        }

        hitApi({
            url: `pixa-support/api/getCustomers`,
            method: "POST",
            data: data,
            alert: false,
        }, (resp) => {
            setMyState(setState, { ...state, userLoading: false });
            if (resp.status) {
                const response = resp.data;

                setMyState(setState, {
                    ...state,
                    // limit: response.length,
                    data: response,
                    userLoading: false,
                    totalRecords: resp.totalRecords
                });
            }
        });
    }

    const getProjectList = () => {
        hitApi({
            url: `pixa-support/api/getProjects`,
            method: "POST",
            data: {
                page: 1,
                listPerPage: 100,
                searchTerm: ''
            },
            alert: false,
        }, (resp) => {
            if (resp.status === 1) {
                if (resp.data.length > 0) {
                    let projData = resp.data.map((val) => ({ value: val._id, label: val.project_name }));
                    projData.unshift({value:"",label:'Select Project'});
                    setProjectList(projData);
                }
            }
        });
    }

    const emptyUserState = () => {
        setMyState(setUser, {
            name: '', email: '', password: '', target: '', status: '', isEdit: false
        })
    }
    const handlePageChange = (pageNumber) => {
        setMyState(setState, (prev) => ({ ...prev, page: pageNumber }));
    };

    const [showModal, setShowModal] = useState(false);
    const handleClose = () => {
        setShowModal(false);
        setMyState(setUser, {
            name: '', email: '', password: '',  target: '', isEdit: false, confirmPass: ''
        })
    }
    const handleShow = (item) => {
        setShowModal(true)
        if (item) {
            setMyState(setUser, {
                name: item.name, email: item.email, password: '', target: item._id, isEdit: true, status: item.status
            })
            let data = item?.project_id ? item?.project_id.map(val => ({value:val._id,label:val.project_name})) : [];
            setSelectedProject(data);
        }
    };

    const createUser = (e) => {
        e.preventDefault();
 
        if (user.name && user.email && (user.password || user.isEdit) && selectedProject.length > 0) {

            const data = {
                name: user.name,
                email: user.email,
                password: user?.password,
                project_id: selectedProject[0]?.value,
            }
            let isValid = true;
            if (user.isEdit === true) {
                if (user.password === ''){
                    delete data.password;
                }else{
                    isValid = validations( user.password,'password');      
                } 
            }else{
               isValid = validations( user.password,'password');
            }
            if(isValid){
                setBtnLoading(true);
                hitApi({
                    url: user.isEdit ? ('pixa-support/api/createUpdateCustomer?customer_id=' + user.target) : 'pixa-support/api/createUpdateCustomer',
                    method: "POST",
                    data: { ...data }
                }, (resp) => {
                    setBtnLoading(false);
                    if (resp.status) {
                        handleClose();
                        getUserList();
                        emptyUserState();
                    }
                });
            }
          
        } else {
            const emptyField = !user.name ? 'Customer Name' : !user.email ? 'Email' : !user.password ? 'Password' : 'Project';
            commonToast('error', `${emptyField} is required.`);
        }


    }
    const updateCustomerStatus = (id) => {
        if (id) {
            hitApi({
                url:'pixa-support/updateCustomerStatus?customer_id=' + id,
                method: "POST",
                data: { }
            }, (resp) => {
                setBtnLoading(false);
                if (resp.status) {        
                    getUserList();      
                }
            });
           
        }
    }

    const containsSupporProjecttWrite = (role === "teamMember") ? scope?.support ? scope?.support['add project']?.includes("write"): true : true;
    const containsSupportTicketWrite = (role === "teamMember") ? scope?.support ? scope?.support['ticket reply']?.includes("write"): true : true;

   
    let cntStart = ((state.page - 1) * state.limit) + 1, cnt = cntStart;





    return (
        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>
            <div className="pxl_mainWrapper pxl-puxaSupport-customer-wrapper">
                <div className="sch-container-fluid">


                    <div className="pxl-count-cards">
                        <div className="pxl_container">

                            <div className="pxl_grid">
                                <div className="dash_head dash_head_topNone">

                                    <h3>Customers ({state.totalRecords})</h3>

                                    <div className="pxl_filterFeilds cun-users-resheader">
                                        <div className='cun-headreSelect pxl-selectBrand'>
                                            <Select
                                            className="pxl-selectBrand"
                                                value={projectList.filter(option =>
                                                    option.value == state.filterProject)}
                                                options={projectList}
                                                onChange={(e) => setMyState(setState, {
                                                    filterProject: e.value,
                                                    page: 1,

                                                })}
                                            />
                                        </div>

                                        <div className="sch-search-section">
                                            <div className="sch-search-row sch-search-bg-color-white">
                                                <input type="text" value={state.keyword} placeholder="Search Customer" onChange={(e) => setMyState(setState, {
                                                    keyword: e.target.value,
                                                    clearKeyword: false
                                                })}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
                                                            getUserList()
                                                        }
                                                    }} />
                                                <button type="button" className="sch-search-btn">
                                                    {!state.keyword ? <span> <img src="/assets/images/scheduler/search.svg" /> </span> : <span onClick={clearSearch}><img src="/assets/images/scheduler/search.svg" /></span>}

                                                </button>
                                            </div>
                                        </div>
                                        <div className=' cun-socialIcon'>
                                        <button className={`sch-btn-iconLeft ${(role === "teamMember" || role === "client") ? "sch-btn-disbale" : ""}`}  disabled={role === "teamMember" || role === "client"} onClick={() => handleShow()}><span><img src="/assets/images/plus-svg.svg" /></span> Add New Customer </button>
                                        {(role === "teamMember" || role === "client") &&  <div className="cun-show-tooltip"><p>{teamMemberRestrictionMess()}</p></div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="pxl_table table-responsive support_table">
                                    {

                                        <table className="pxl_table">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Project</th>
                                                    <th>Registration Date</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!state.userLoading ?
                                                    state.data.length > 0 ?
                                                        state.data.map((item, index) => {

                                                            return (
                                                                <tr  key={index}>
                                                                    <td><p>{state.page == 1 ? (index + 1 ) :   (index + 1 ) + ((state.page - 1) * state.limit)}</p></td>
                                                                    <td>
                                                                        <div className="tab_name_wrap">
                                                                            <div>
                                                                                {
                                                                                    item.profile_image ? <img src="/images/img1.jpg" /> : <span>{getNameInitials(item.name)}</span>
                                                                                }

                                                                            </div>
                                                                            <div className="pxl-customerName">
                                                                                <p className="pxlText-capital">{item.name}</p>
                                                                            </div>
                                                                        </div></td>
                                                                    <td>
                                                                        <p>{item.email}</p>
                                                                    </td>
                                                                    <td>
                                                                        <div className="pxl_planHolder">
                                                                            {
                                                                                item.project_id ? item.project_id.map((proj, i) => <p key={i} >{proj.project_name}</p>) : " - "
                                                                            }
                                                                        </div>
                                                                       
                                                                    </td>
                                                                    <td>
                                                                        {dateFormatter(item.updatedAt,'MMMM DD, YYYY')}
                                                                    </td>
                                                                    <td>
                                                                        <div className="xs_switch">
                                                                            <input 
                                                                            disabled={role === "teamMember" || role === "client"}
                                                                            style={{cursor: (role === "teamMember" || role === "client" )? 'not-allowed' : 'pointer'}}
                                                                             id={'userChk_' + index}
                                                                             type="checkbox"
                                                                             value={item.status}
                                                                             defaultChecked={item.status === 1 ? true : false}
                                                                             onClick={(e) => updateCustomerStatus(item._id)}
                                                                            /><label htmlFor={'userChk_' + index}><span className="xs_switch_icon"></span>
                                                                                {/* {/ <span className="xs_switch_text">Active</span> /} */}
                                                                            </label></div>
                                                                    </td>

                                                                    <td>
                                                                        <div className="pxl_tableIcon_holder pxl-table-editTooltip  pxl-editReadOnly">
                                                                            <button  className={`pxl_tableIcon cun-iconTooltip ${(role === "teamMember" || role === "client") ? "sch-btn-disbale" : ""}`} disabled={role === "teamMember" || role === "client"}
                                                                            onClick={() => handleShow(item)}
                                                                            >{svg.dt_edit_icon}
                                                                                <div className="cun-show-tooltip">
                                                                                    <p>{(role === "teamMember" || role === "client") ?  teamMemberRestrictionMess() : 'Edit'}</p>
                                                                                </div>
                                                                            </button>
                                                                           
                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            )
                                                        })

                                                        :
                                                        (state.keyword) ? <NoDataFoundDataTable column={8} statement="You don't have customer with this name." /> : <NoDataFoundDataTable column={8} statement="No data found." />
                                                    :
                                                    <tr ><td colSpan={8}>{skeletonLoader('table')}</td></tr>
                                                }




                                            </tbody>
                                        </table>

                                    }
                                </div>
                               

                                <Pagination
                                    type="User"
                                    dataRange={{
                                        start: state.totalRecords ? cntStart : 0,
                                        end: state.totalRecords ? cntStart + state.data.length - 1 : 0,
                                    }}
                                    currentPage={state.page}
                                    totalRecords={state.totalRecords}
                                    perPage={state.limit}
                                    isLoading={state.userLoading}
                                    onClick={(pageNum) => {
                                        setMyState(setState, {
                                            page: pageNum,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Modal
                show={showModal} onHide={handleClose} animation={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv pxl-newCustomer-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {user.isEdit ? "Edit Customer" : "Add New Customer"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="" action="" onSubmit={createUser}>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Customer Name <span className="pxl-star-red">*</span></label>
                            <input type="text" className="form-control" value={user.name} placeholder="Enter Name" onChange={(e) => setMyState(setUser, {
                                name: e.target.value
                            })} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Email Address <span className="pxl-star-red">*</span></label>
                            <input type="email" className="form-control" value={user.email} disabled={user.isEdit} placeholder="Enter Email" onChange={(e) => setMyState(setUser, {
                                email: e.target.value
                            })} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Password {!user.isEdit && <span className="pxl-star-red">*</span>}</label>
                            <input type="password" className="form-control" value={user.password} placeholder="Enter Password" onChange={(e) => setMyState(setUser, {
                                password: e.target.value
                            })} />
                        </div>
                       
                        <div className="form-group">
                            <label htmlFor="exampleFormControlSelect2">Select Project <span className="pxl-star-red">*</span></label>
                            <Select
                            className="pxl-selectBrand"
                                closeMenuOnSelect={true }
                                components={animatedComponents}
                                value={selectedProject}
                                // isMulti
                                options={projectList}
                                onChange={(option) => setSelectedProject([option])}
                            />
                        </div>

                        <button type="submit" className="pxl_btn" disabled={btnLoading}> {btnLoading ? "Processing..." : user.isEdit ? "Update" : 'Submit'}</button>
                    </form>
                </Modal.Body>
              
            </Modal>


        </>
    )
};

export default Customers;
