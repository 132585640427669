import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet';
import Sidebar from '../components/Sidebar';
import CreateBrandPopUp from '../components/common/CreateBrandPopUp';
import { assetsUrlPath, hitApi, setMyState, skeletonLoader, useOutsideClick } from '../components/commonAction';
import Cookies from "js-cookie";
import { useStore } from "../zustand/store";
import { useNavigate } from 'react-router-dom';

import { Tooltip } from "@mui/material";

function Brand({ pageTitle }) {
    const [state, setState] = useState({
        openCreatePopUp: false,
        brandList: [],
        limit: 15,
        page: 1,
        sort: 'createdAt=-1',
        keyword: '',
        isLoading: false,
        totalCount: 0,
        loadData: false,
        isSkeletonLoader:false,
        totalRecord: '',
        editRecords: false
    })
    let store = useStore(state => state);
    let navigate = useNavigate()
    useEffect(() => {
        getBrands();
    }, [])

    const getBrands = (type = null) => {
        // if (state.isLoading) {
        //     return;
        // }
        
        if( type == 'loadMore'){
            setMyState(setState, {...state, isLoading: true,openCreatePopUp:false});
        }else{
            setMyState(setState, {...state, isSkeletonLoader:true,openCreatePopUp:false });
        }

        let { limit, page, keyword, sort } = state,
            cPage = type == 'loadMore' ? page + 1 : 1;
        let data = { limit, page: cPage, sort }
        hitApi({
            url: 'brand/brand-list',
            method: 'GET',
            data: data,
            loading: true,
            alert: false,
        }, (resp, err = null) => {
            if (resp.status) {
                let newList = (type == 'loadMore') ? [...state.brandList, ...resp.data?.records] : resp?.data?.records;
                setMyState(setState, {...state, brandList: newList, totalRecord: resp?.data?.totalRecords, isLoading: false,isSkeletonLoader:false, page: cPage,openCreatePopUp:false,editRecords:false });
            }
        });
    }

    // sub menu delet edit setting
    const [allsubmenu, setallsubmenu] = useState(false);
    const toggleSublist = (id) => {
        setallsubmenu(prevState => ({
            //   ...prevState,
            [id]: !prevState[id]
        }));
    };

    const menuRef = useRef();

    useOutsideClick(menuRef, () => setallsubmenu(false));

    const handleSelectedAction = (id, path) => {
       
        hitApi({
            url: 'brand/change-brand',
            method: 'PATCH',
            data: {
                brandId: id
            },
            alert: false,
        }, (resp, err = null) => {
            if (!err && resp.data) {
                Cookies.set('authToken', resp?.data?.token, { expires: +process.env.REACT_APP_TOKEN_LIFE })
                store.updateStore("userData", { ...store.userData, ...resp?.data });
                navigate(path)
            }
        });
    }

    return (
        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>
            <div className="pxl_mainWrapper">
                <Sidebar />
                <div className="pxl_rightBar_assets pxl-bg-back-color-wrap pxlBrand-mainWrapper-box">
                    {/* Count Card Widgets Section */}
                    <div className="pxl_pageTitle">
                        <h2>{pageTitle}</h2>
                    </div>

                    <div className="pxl-flex-divide pxl-brand-header-bottom">
                        <div className="pxl-brand-record-head">
                            <h5>Brands ({state.totalRecord})</h5>
                        </div>
                        <div className='pxl-brand-btn'>
                            <button className="sch-btn-iconLeft " onClick={() =>{setMyState(setState, { openCreatePopUp: true })}}> <span><img src="/assets/images/plus-svg.svg"/></span> Add New Brand</button>
                        </div>
                    </div>

                    <div className='brand_wrapper'>

                      
                        <div className="pxl-assets-area">

                            <div className=" pxl-brandMain cun-adminPixastockWrap ">

                            {
                            state?.brandList && state.isSkeletonLoader ? 
                            [...Array(15)].map((count,i) => <span key={i}>{skeletonLoader('asset', 1, 268,140,i)}</span>) 
                           : state.brandList?.length > 0 && state?.brandList?.map((item,index) => (
                                    <div className='pxl-vrandBox' key={index}>
                                        <div className='pxl-brand-profile-wrap'>
                                            <div className='pxl-brand-profile'>
                                                <div className='pxl-brandImg'>
                                                    <img src={assetsUrlPath(item?.icon?.original?.path)} />
                                                </div>
                                                <div className='pxl-brandName cun-socialIcon'>
                                                {/* <Tooltip title={item?.title} placement="top" arrow> */}

                                                    <h2>{item?.title}</h2>
                                                    {/* </Tooltip> */}
                                                    <div className='cun-show-tooltip'>
                                                        <p>{item?.title}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`sch-menuSection  ${allsubmenu[item?._id] ? 'sch-menuOpen' : ''}`}>
                                                <div className='pxl-brand-menu'  ref={menuRef} onClick={() => toggleSublist(item?._id)}>
                                                    <img src="/assets/images/three-dot1.png" alt="" />
                                                </div>
                                                <div className="sch-submenubox">
                                                    <ul>
                                                        <li>
                                                            <a onClick={(e) => {
                                                                                setMyState(setState, {
                                                                                    openCreatePopUp: true,
                                                                                    editRecords: item,

                                                                                });
                                                                                setallsubmenu(false);
                                                                            }}>
                                                                <span>
                                                                    <img src="/assets/images/edit-svg.svg" alt="" />
                                                                </span>Edit
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='pxl-brand-bottom-sec'>
                                            <ul>
                                                {/* <li className='cun-socialIcon'>
                                                    <a onClick={()=>handleSelectedAction(item?._id,'/builder')}><img src="/assets/images/builder.png" alt="" /></a>
                                                    <div className="cun-show-tooltip"><p>builder</p></div>
                                                </li> */}
                                                <li className='cun-socialIcon'>
                                                    <a onClick={()=>handleSelectedAction(item?._id,'/scheduler')}><img src="/assets/images/brand-dateSvg.svg" alt="" /></a>
                                                    <div className="cun-show-tooltip"><p>scheduler</p></div>
                                                </li>
                                                <li className='cun-socialIcon'>
                                                    <a onClick={()=>handleSelectedAction(item?._id,'/images')}><img src="/assets/images/brand-imageSvg.svg" alt="" /></a>
                                                    <div className="cun-show-tooltip"><p>images</p></div>
                                                </li>
                                                <li className='cun-socialIcon'>
                                                    <a onClick={()=>handleSelectedAction(item?._id,'/pixa-support')}><img src="/assets/images/brand-supportSvg.svg" alt="" /></a>
                                                    <div className="cun-show-tooltip"><p>pixa support</p></div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                ))
                        }
                               
                           {state.isLoading && [...Array(15)].map((count,i) => <span key={i}>{skeletonLoader('asset', 1, 268,140,i)}</span>) }

                            </div>


                        </div>

                        {
                            state.brandList?.length < state?.totalRecord &&
                            <div className="loadmorebtn">
                                <a
                                    className={`sch-loadmoreBtn ${state?.brandList?.length >= state?.totalRecord ? 'pxl-button-disable' : ''}`}
                                    onClick={() => {
                                        getBrands('loadMore');
                                    }}
                                >{state.isLoading ? <span>Loading...</span> : <span>Load More</span>}</a>
                            </div>
                        }
                    </div>
                </div>




            </div>
            {/* </div > */}

            <CreateBrandPopUp
                openModal={state.openCreatePopUp}
                handleClose={() => setMyState(setState, { openCreatePopUp: false, editRecords: false })}
                callBrand={getBrands}
                type={state.editRecords ? 'edit' : 'create'}
                editRecords={state.editRecords}
            />


        </>
    )
}

export default Brand