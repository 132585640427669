import { useEffect, useState } from "react";
import { commonToast, hitApi } from "../../components/commonAction";
import Select from "react-select";
import { useStore } from "../../zustand/store";

import makeAnimated from 'react-select/animated';
import { useNavigate,Link } from 'react-router-dom';


function SupportSetting({ p_id,s_support,setActiveTabMenu }) {
  const navigate = useNavigate();

  const animatedComponents = makeAnimated();
  const [script, setScript] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [projectList, setProjectList] = useState('');
  const [isError, setIsError] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  let userData = useStore(state => state)['userData'];
  let { role, scope } = userData;
  useEffect(() => {
setSelectedProject(s_support)
  
}, [s_support])

  
  useEffect(() => {
    getProjects()
  }, [])

  const getProjects = () => {
    let data = []

    hitApi({
      url: 'pixa-support/api/getProjects',
      method: "POST",
      data: {
        // page: page,
        // listPerPage: listPerPage,
        // searchTerm: search,
        userId: userData.userId,
        // userId:  '6593b8c8867874b7407d4e67'
      },
      loading: true,
      alert: false
    }, (resp, err = null) => {
      if (resp.status == 1) {
        let data = resp.data.map(item => ({ value: item._id, label: item.project_name }))
        data.unshift({ value: "", label: "Select" })
        setProjectList(data)
        

        // let d1=[]
        // resp.data.records.map((i)=>{
        //  let d2={
        //     value:i._id,
        //     label:i.title
        //   }
        //   d1.push(d2)
        // })
        // setProjectList(d1)
        setIsError(false)
      } else {
        setIsError(true)
      }
    });


  }



  const saveSupportSetting = (e) => {
    e.preventDefault();

    if (!selectedProject[0]?.value) {
      commonToast('error', `Project is required!`);
    } else {
      setBtnLoading(true)
      if (p_id) {
        const formData = new FormData();
        formData.append('target', p_id);
        formData.append('supportId', selectedProject[0].value);
        hitApi({
          url: 'page-builder/update-project',
          method: "PATCH",
          data: formData,
          loading: true,
          alert: true,
          isFormData: true
        }, (resp, err = null) => {
          setBtnLoading(false)
          setTimeout(()=>{
            navigate(`/builder/projects`);
          },1000)
          
          // if (resp.status === 1)
          //  fetchProjectsDetail(resp.data._id);
        });

      }
    }
  }
  const handleCopyScript = () => {
    navigator.clipboard.writeText(script);
    commonToast('success', `Script copied to clipboard!`);

  }

  useEffect(() => {
    document.body.classList.add('pxl-body-white');
    return () => {
      document.body.classList.remove('pxl-body-white');
    };
  }, []);
  // let script = `<div id="pixasupport" className="e0c3920c50ca16cbf1f37d2d2338d0b51a092cfca449f3e1c9d3637995c54097"> <script src="https://api.pixasupport.com/project_widget.js"></script></div>`
  return (
    <div className="sup_proj_setting_form install_script_wrap pxl-supportProject-wrap">
      <h4 className="proj_main_head">Support project</h4>
      {projectList.length !=1 && <div className="pxl-select-projectLabel">
        <label htmlFor="exampleFormControlSelect2">Select Project <span className="pxl-star-red">*</span></label>
      </div>}

      <div className="cun-headreSelect pxl-supportModel">
        {projectList.length  > 1 ? <Select
          closeMenuOnSelect={true}
          components={animatedComponents}
          value={selectedProject ||''}
          options={projectList}
          onChange={(option) => setSelectedProject([option])}
        />:<span>You have not added project in Support yet, feel free to create by clicking here. <Link to={'/pixa-support/projects'}>Pixa support</Link></span>}
        <div className='pxl-supportBtn-assignBack'>
        <button className="pxlCommon-btn"   onClick={() => { setActiveTabMenu('project') }}> Back</button>
          {projectList.length !=1&&<button className="pxlCommon-btn" disabled={btnLoading}  onClick={(e) => { saveSupportSetting(e) }}>  {btnLoading ? "Processing..." : 'Assign '}</button>}
        </div>
      </div>
    </div>
  )
}

export default SupportSetting