import ProfileMain from "../../components/Profile";

const Profile = ({pageTitle}) => {
    return (
        <>
            <div className="pxl_mainWrapper">
                <div className="pxl_rightBar_assets">
                    <ProfileMain />
                </div>
            </div>

        </>
    )
};
export default Profile;