import { Outlet, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { hitApi, setMyState } from "../components/commonAction";
import svg from "../components/svg";
import appConfig from "../config/appConfig";

const EmailVerification = ({ pageTitle }) => {

    let verificationToken = useLocation().pathname.split('/')[2] || null;
    let [verStatus, setVerStatus] = useState('Processing...');

    useEffect(() => {
        if (verificationToken) {
            hitApi({
                url: 'auth/email-verification',
                method: "GET",
                data: { token: verificationToken }
            }, (resp, err = null) => {
                setVerStatus(err ? err : resp?.message);
                if (!err && resp.data) {
                    console.log(resp.data);
                }
            });
        }
    }, []);
    return (
        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>

            <div className='pxl_loginWrapper'>
                <div className='pxl_mainContainer'>
                    <div className='pxl_leftBox' style={{ backgroundImage: "url('/assets/images/background.jpg')" }}>
                        <div className='auth_img_box'>
                            <div className='pxl_loginWrapper_after'>
                                <span className='pxl_loginIcon pxl_moveIcon1'>{svg.app.moveIcon1}</span>
                                <span className='pxl_loginIcon pxl_moveIcon2'>{svg.app.moveIcon2}</span>
                                <span className='pxl_loginIcon pxl_moveIcon3'>{svg.app.moveIcon3}</span>
                                <span className='pxl_loginIcon pxl_moveIcon4'>{svg.app.moveIcon4}</span>
                                <span className='pxl_loginIcon pxl_moveIcon5'>{svg.app.moveIcon5}</span>
                                <span className='pxl_loginIcon pxl_moveIcon6'>{svg.app.moveIcon6}</span>
                            </div>
                            <img alt="" src="/assets/images/loginscreen.png" />
                            <div className='pxl_overlayBox'>
                                <h2>It is a long established fact that a reader will be distracted by the readable content</h2>
                            </div>
                        </div>
                    </div>
                    <div className='pxl_loginForm order-md-2 order-1'>
                        <div className='pxl_rightBox'>
                            <div className='pxl_form pxl_paddT_zero'>
                                <div className="pxl_welcome_title">
                                    <span><img src={appConfig.appLogo} alt="" /></span>
                                    <p>Email verification of <b>pixalab</b></p>
                                </div>

                                <p>{verificationToken ? verStatus : "Invalid URL"} </p>
                                <div className="pxl_auth_link">
                                    <p>Back To <Link href="/login">Login</Link></p>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
};

export default EmailVerification;