import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

let editorStore = (set, get) => ({
    userData: {},
    scheduler: {
        calendarDate: '',
        schedulePost: [],
        resMenuToggle: false,
    },
    updateStore: async (key, data) => {
        set({ [key]: data });
    },

    //editor variables 
    selectCursor: false,
    drawData: { type: "pencil", size: 2, flow: 5, color: "#fffff", opacity: 100, status: "", bgColor: "linear-gradient(0deg,#FFFFFF ,#FFFFFF)" },
    editorData: [],
    filterCanvas: {},
    activetab: "pencil",
    activeElement: {
        id: "",
        element: ""
    },
    undoData: {
        status: false,
        currentPostion: 0,
        data: []
    },
    updateElement  : {
        status : false ,
        action : ""
      },
      layerManagement  :{
        status : false ,
        start : "",
        end : "",
      },
    save : false,
    pageLoading:false,

});

editorStore = persist(editorStore, {
    name: 'pixaLabStorage',
    storage: createJSONStorage(() => localStorage)
});

export const useStore = create(editorStore);