import { Modal } from 'react-bootstrap';
import svg from '../svg';
import React, { useState, useEffect } from 'react';

function ConfirmationPopup({
    shownPopup,
    closePopup,
    title,
    subTitle,
    type,
    headTitle,
    btnLoader,
    removeAction,
    cancelBtn = "Cancel",
    yesBtn = "Delete",
}) {
   
    return (
        <>
            <Modal
                show={shownPopup} onHide={closePopup} animation={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className='pxl-model-header'>
                            {/* <h2 className='modal-title'>Delete {type}  </h2> */}
                            <h2 className='modal-title'>{headTitle ? headTitle : 'Confirmation' }</h2>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='pxl_model_body'>
                         <div className='pxa_confirmation-delet'>
                            <div className='pxa_confirmImg'>
                                <img src='/assets/images/confirmation-delet.png' alt='Alert Img' />
                            </div>
                            <div className='pxa_textContent'>
                                <h4>{title ? title : `Are you sure you want to delete this ${type ? type : 'item'} ?`}</h4>
                                <p>{subTitle ? subTitle : `This ${type ? type : 'item'} will be deleted immediately. you can't undo this action.`}</p>
                                <div className='modalBtn-holder'>
                                    <a className='pxa_btn pxl-cancelBtn' onClick={closePopup}>{cancelBtn}</a>
                                    <button className=' pxl-deleteBtn' disabled={btnLoader} onClick={removeAction}>{btnLoader ?  'Processing...'  : yesBtn}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>

        </>

    )   
}

export default ConfirmationPopup;