import React, { useEffect, useState } from 'react';
import useSticky from './use-sticky';
import { useLocation, Link } from 'react-router-dom';
import './header.css';
import { useStore } from "../zustand/store";

import { teamMemberRestrictionMess, useOutsideClick,assetsUrlPath,getNameInitials } from "../components/commonAction";
import { useRef } from 'react';

import appConfig from '../config/appConfig';
import AppsOption from '../components/common/AppsOption';
import BrandSelect from '../components/common/BrandSelect';
import CreateProjectPopUP from '../pages/pixa-support/components/CreateProjectPopUP';
import { NewDesignPopUp } from '../pages/scheduler/components/SchedulePopUp';
import { useNavigate } from 'react-router-dom';
const Header = () => {

    let store = useStore(state => state);
    let { name, role, scope, profilePic} = store.userData;
        
    const [isActive, setIsActive] = useState(false);
    const [openPopUp, setOpenPopUp] = useState(false);
    const [showNewDesignPopUp, setShowNewDesignPopUp] = useState(false);
    const handleClick = event => {
        setIsActive(current => !current);
    };
    const { headerSticky } = useSticky();
    const [toggleViewMode, setToggleViewMode] = useState(false);
    let appPath = useLocation().pathname.split('/').filter(Boolean)[0];
    let currentPath = useLocation().pathname.replace(`/${appPath}`, '');
    let pixaPath = useLocation().pathname.split('/').filter(Boolean)[1];
    let pixaProjId = useLocation().pathname.split('/').filter(Boolean)[2];
    // let showDesingtab = useLocation().pathname.replace(`/${appPath}/my-designs/`, '');
    // console.log(showDesingtab,'showDesingtab')
    let path = useLocation().pathname;

    useEffect(()=>{
        setToggleViewMode(false);
    },[path])
  


    const navigate = useNavigate();
    const menuRef = useRef();
    useOutsideClick(menuRef, () => {
        setIsActive(false);
    });

    const toggleRef = useRef();
    const sidebar = useRef();

    function useOutside(toggleRef, sidebar, handler) {
        useEffect(
            () => {
                const listener = (event) => {

                    if (!toggleRef.current || toggleRef.current.contains(event.target) || sidebar.current.contains(event.target)) {
                        return;
                    }
                    handler(event);
                };
                document.addEventListener("mousedown", listener);
                document.addEventListener("touchstart", listener);
                return () => {
                    document.removeEventListener("mousedown", listener);
                    document.removeEventListener("touchstart", listener);
                };
            },

            [toggleRef, handler]
        );
    }

    useOutside(toggleRef, sidebar, () => setToggleViewMode(false));

    const containsSchedularWrite = (role === "teamMember") ? scope?.scheduler?.schedule?.includes("write") : (role === "client") ? false : true;
    const containsImagesWrite = (role === "teamMember") ? scope?.images?.images?.includes("write")  :  (role === "client") ? false : true;
    const containsSupporProjecttWrite = (role === "teamMember") ? scope?.support ? scope?.support['add project']?.includes("write") : true : (role === "client") ? false : true;
    const containsSupportTicketWrite = (role === "teamMember") ? scope?.support ? scope?.support['ticket reply']?.includes("write") : true :  (role === "client") ? false : true;
    const containsBuilderWrite = (role === "teamMember") ? scope?.builder?.builder?.includes("write") : (role === "client") ? false : true;

    let pathApp = appPath === 'pixa-support' ? "supportLogo" : `${appPath}Logo`;
    let appLogoUrl = appConfig[pathApp];
   

    return (
        <>
            {/* Header  */}
            <div className='pxl_headerWrapper'>
                <header className={`sch-header-wrapper ${headerSticky && "header-sticky"} ${toggleViewMode ? 'sch-nav-open' : ''}`}>
                    <div className="sch-container-fluid-header">
                        <div className="sch-header-row">
                            {/* Logo  */}
                            <div className="sch-header-col sch-header-col-sm sch-headLogo">
                                <div className="sch-logo">
                                    <Link to={role == "teamMember" ? `/${appPath}` : `/${appPath}`}>

                                    <img src={appLogoUrl} alt="logo" />
                                    </Link>
                                </div>


                            </div>
                            {/* Header Menus */}
                            <div className="sch-header-col sch-header-col-lg innerspan" ref={sidebar}>
                                <div className="sch-main-menu innerspan" >
                                    {/* {
                                        appPath == 'builder' && <ul className="menu innerspan">
                                            {role !== 'teamMember' && <li className={['', '/'].includes(currentPath) ? "active" : ''}>
                                                <Link to={'/builder'}>
                                                    Dashboard
                                                </Link>
                                            </li>}
                                            <li className={currentPath == '/projects' ? "active" : ''}>
                                                <Link to="/builder/projects">
                                                    Projects
                                                </Link>
                                            </li>

                                        </ul>
                                    } */}

                                    {
                                        appPath == 'scheduler' && <ul className="menu innerspan">
                                            {role !== 'teamMember' && <li className={['', '/'].includes(currentPath) ? "active" : ''}>
                                                <Link to={'/scheduler'}>
                                                    Dashboard
                                                </Link>
                                            </li>}
                                            <li className={currentPath == '/calendar' ? "active" : ''}>
                                                <Link to="/scheduler/calendar">
                                                    Calendar
                                                </Link>
                                            </li>
                                            <li className={currentPath == '/draft' ? "active" : ''}>
                                                <Link to="/scheduler/draft">
                                                    Draft {/*<span className='sch_draft_count'>{draftPostCount}</span> */}
                                                </Link>
                                            </li>
                                            <li className={currentPath == '/social-integration' ? "active" : ''}>
                                                <Link to="/scheduler/social-integration">
                                                    Integration
                                                </Link>
                                            </li>

                                        </ul>
                                    }

                                    {
                                        appPath == 'images' && <ul className="menu innerspan">
                                            {role !== 'teamMember' && <li className={['', '/'].includes(currentPath) ? "active" : ''}>
                                                <Link to="/images">
                                                    Dashboard
                                                </Link>
                                            </li>}
                                            <li className={currentPath == '/templates' ? "active" : ''}>
                                                <Link to="/images/templates">
                                                    Templates
                                                </Link>
                                            </li>
                                            <li className={(currentPath == '/my-designs' || currentPath.startsWith('/my-designs/') || currentPath.startsWith('/editor/')) && !currentPath.includes('/my-designs/project') ? "active" : ''}>
                                                <Link to="/images/my-designs">
                                                My Projects
                                                </Link>
                                            </li>
                                            {currentPath.includes('/my-designs/project') ? <li className={(currentPath == `/my-designs/projects/` || currentPath.startsWith('/my-designs/projects/')) ? "active" : ''}>
                                                <a >
                                                My Designs
                                                </a>
                                               
                                            </li>:""}
                                        </ul>
                                    }

                                    {
                                        appPath == 'pixa-support' && <ul className="menu innerspan">
                                            {role !== 'teamMember' && <li className={['', '/'].includes(currentPath) ? "active" : ''}>
                                                <Link to={'/pixa-support'}>
                                                    Dashboard
                                                </Link>
                                            </li>
                                            }
                                            <li className={currentPath == '/projects' || currentPath.startsWith('/projects/') ? "active" : ''}>
                                                <Link to="/pixa-support/projects">
                                                    Projects
                                                </Link>
                                            </li>


                                            <li className={currentPath == '/customers' ? "active" : ''}>
                                                <Link to="/pixa-support/customers">
                                                    Customers
                                                </Link>
                                            </li>
                                            {(role !== "teamMember") && <li className={currentPath == '/settings' ? "active" : ''}>
                                                <Link to="/pixa-support/settings">
                                                    Settings
                                                </Link>
                                            </li>}

                                        </ul>
                                    }

                                </div>
                            </div>
                            {/* Header Buttons */}
                            <div className="sch-header-col sch-header-col-sm sch-headerRight">
                                <div className={`sch-btns-wrap ${toggleViewMode ? 'sch-nav-open' : ''}`}>

                                    <ul>

                                        {/* {appPath == 'builder'  && !currentPath.startsWith('/edit-project') && role !== 'teamMember'&& <li>
                                            <div className='sch-headBtn cun-socialIcon'>
                                            <button onClick={() => setOpenPopUp(true)} className={`sch-btn-iconLeft ${containsBuilderWrite ? "" : "sch-btn-disbale"}`} disabled={!containsBuilderWrite}>
                                                <span>
                                                    <img src="/assets/images/plus-svg.svg" />
                                                </span>
                                                Create Project
                                            </button>
                                            {!containsBuilderWrite && <div className="cun-show-tooltip"><p>{teamMemberRestrictionMess(containsBuilderWrite)}</p></div>}
                                            </div>
                                        </li>} */}

                                        {appPath == 'scheduler' &&  role !== 'teamMember'&&<li>
                                            <div className='sch-headBtn cun-socialIcon'>
                                                <button className={`sch-btn-iconLeft ${containsSchedularWrite ? "" : "sch-btn-disbale"}`} disabled={!containsSchedularWrite}
                                                    onClick={() => { !currentPath.includes('/new-post') && navigate('/scheduler/new-post') }}
                                                >
                                                    <span>
                                                        <img src="/assets/images/plus-svg.svg" />
                                                    </span>
                                                    Create Post
                                                </button>
                                                {!containsSchedularWrite && <div className="cun-show-tooltip"><p>{teamMemberRestrictionMess(containsSchedularWrite)}</p></div>}
                                            </div>

                                        </li>}

                                        {appPath == 'images' && !currentPath.startsWith('/editor/') &&  role !== 'teamMember'&&<li>
                                            <div className='sch-headBtn cun-socialIcon'>
                                                <button className={`sch-btn-iconLeft ${containsImagesWrite ? "" : "sch-btn-disbale"}`} disabled={!containsImagesWrite} onClick={() => setShowNewDesignPopUp(true)} >
                                                    <span>
                                                        <img src="/assets/images/plus-svg.svg" />
                                                    </span>
                                                    New Design
                                                </button>
                                                {!containsImagesWrite && <div className="cun-show-tooltip"><p>{teamMemberRestrictionMess(containsImagesWrite)}</p></div>}
                                            </div>
                                        </li>}

                                        {appPath == 'pixa-support' && pixaPath == 'projects' && !pixaProjId && role !== 'teamMember'&& <li>
                                            <div className='sch-headBtn cun-socialIcon'>
                                                <button className={`sch-btn-iconLeft ${containsSupporProjecttWrite ? "" : "sch-btn-disbale"}`} disabled={!containsSupporProjecttWrite}
                                                    onClick={() => setOpenPopUp(true)} >
                                                    <span>
                                                        <img src="/assets/images/plus-svg.svg" />
                                                    </span>
                                                    New Project
                                                </button>
                                                {!containsSupporProjecttWrite && <div className="cun-show-tooltip"><p>{teamMemberRestrictionMess(containsSupporProjecttWrite)}</p></div>}
                                            </div>
                                        </li>}

                                        {appPath == 'pixa-support' && pixaPath == 'projects' && pixaProjId && role !== 'teamMember'&& <li>
                                            <div className='sch-headBtn cun-socialIcon'>
                                                <button onClick={() => navigate('/pixa-support/create-ticket/' + pixaProjId)}
                                                    className={`sch-btn-iconLeft ${containsSupportTicketWrite ? "" : "sch-btn-disbale"}`} disabled={!containsSupportTicketWrite} >
                                                    <span>
                                                        <img src="/assets/images/plus-svg.svg" />
                                                    </span>
                                                    Create Ticket
                                                </button>
                                                {!containsSupportTicketWrite && <div className="cun-show-tooltip"><p>{teamMemberRestrictionMess(containsSupportTicketWrite)}</p></div>}
                                            </div>
                                        </li>}


                                        <li className='innerspan'>
                                            <a ref={toggleRef} className="sch-menu-btn innerspan" onClick={() => setToggleViewMode(!toggleViewMode)} >
                                                <span className='innerspan'>
                                                    <span className='innerspan'></span>
                                                    <span className='innerspan'></span>
                                                    <span className='innerspan'></span>
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <div className="user-info-wrapper" onClick={handleClick} ref={menuRef}>
                                                <a className="user-info">
                                                   
                                                    {
                                                        profilePic ?   <img src={assetsUrlPath(profilePic)} alt="" className="user-img" />
                                                        :
                                                        <span className='pxl_imgHolder'>{getNameInitials(name)}</span> 
                                                    }
                                                  
                                                    {/* <span className='pxl_imgHolder'>pt</span> */}
                                                    {/* {getNameInitials(name)} */}
                                                    <div className="drop-down-header">
                                                        <h4>Hey, {name?.split(' ')[0]}</h4>
                                                    </div>
                                                </a>
                                                <div className={`user-info-box ${isActive ? 'show' : ''} `}>
                                                    <BrandSelect />
                                                </div>
                                            </div>
                                        </li>
                                        <li> <AppsOption /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                    </div>
                </header>
            </div>

            <CreateProjectPopUP
                openPopUp={openPopUp}
                onClose={() => setOpenPopUp(false)}
                app={appPath}
            />

            <NewDesignPopUp
                shownPopup={showNewDesignPopUp}
                closePopup={() => setShowNewDesignPopUp(false)}

            />

        </>
    );
};

export default Header;