import { useEffect, useRef } from "react"
const Fun=()=>{
    let input=useRef([])
    useEffect(()=>{
        let d1 = document.getElementsByName("a")
        d1=Array.from(d1)
        for(let i of d1)
        {
            input.current.push(i)
        }
    },[])
console.log("inpurt122 ",input.current)
    return(<>
    </>)
}
export default  Fun