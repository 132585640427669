
import { Helmet } from "react-helmet";

import svg from "../components/svg";
import Sidebar from "../components/Sidebar";
import { useEffect, useState } from "react";
import { NoDataFoundDataTable, Pagination, commonToast, hitApi, setMyState, skeletonLoader, validations } from "../components/commonAction";
import { Modal } from 'react-bootstrap';
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { Tooltip } from "@mui/material";


const Client = ({ pageTitle }) => {

    const [state, setState] = useState({
        data: [],
        keys: '',
        limit: 10,
        page: 1,
        keyword: '',
        sort: 'createdAt=-1',
        totalRecords: '',
        isTrash: '',
        userLoading: false,
        clearKeyword: false,
    });
    const [user, setUser] = useState({
        name: '',
        email: '',
        password: '',
        confirmPass: '',
        target: '',
        status: '',
        scope: { "brandId": [] },
        isEdit: false
    })
    const [btnLoading, setBtnLoading] = useState(false);
    const [selectedScope, setSelectedScope] = useState([]);
    const [selectedId, setSelectedId] = useState([]);

    const [objScope, setObjScope] = useState([])

    useEffect(() => {
        getBrandList();

    }, [])
    useEffect(() => {
        getUserList();
    }, [state.page, state.clearKeyword])

    const clearSearch = () => {
        setMyState(setState, { ...state, clearKeyword: true, keyword: '' });
    }

    const getBrandList = () => {
        hitApi({
            url: `brand/brand-list`,
            method: "GET",
            data: {
                limit: 'all'
            }
        }, (resp) => {
            if (resp.status) {
                const response = resp.data.records;
                let d1 = []
                response.forEach(element => {
                    d1.push(
                        { value: element._id, label: element.title }
                    )
                });
                setObjScope(d1)
            }
        });
    }
    const getUserList = () => {
        setMyState(setState, { ...state, userLoading: true });
        let { keys, limit, page, keyword, sort, target, isTrash } = state;
        let data = { keys, limit, page: keyword ? 1 : page, keyword, sort, target, isTrash };
        // if (status != 3) {
        //     data['status'] = status;
        // }

        hitApi({
            url: `client/client-list`,
            method: "GET",
            data: data
        }, (resp) => {
            if (resp.status) {
                const response = resp.data.records;

                setMyState(setState, {
                    ...state,
                    // limit: response.length,
                    data: response,
                    userLoading: false,
                    totalRecords: resp.data.totalRecords
                });
            }
        });
    }

    const emptyUserState = () => {
        setMyState(setUser, {
            name: '', email: '', password: '', target: '', status: '', isEdit: false
        })
    }

    const [showModal, setShowModal] = useState(false);
    const handleClose = () => {
        setShowModal(false);
        setMyState(setUser, {
            name: '', email: '', password: '', target: '', confirmPass: '', isEdit: false
        });
        // setCheckedScope({});
        setSelectedScope([]);
    }


    const createUser = (e) => {
        e.preventDefault();
        if (user.name && user.email && user.password && selectedScope.length > 0) {
            let isValid = validations(user.email, 'email') && validations(user?.password, 'password');

            if (isValid) {
                setBtnLoading(true);
                hitApi({
                    url: 'client/add-client',
                    method: "POST",
                    data: {
                        ...user, scope: {
                            "brandId": selectedScope.map((i) => {
                                return (i.value)
                            })
                        }
                    }
                }, (resp) => {
                    setBtnLoading(false);
                    if (resp.status) {
                        handleClose();
                        getUserList();
                        emptyUserState();
                    }
                });
            }
        } else {
            const emptyField = !user.name ? 'Name' : !user.email ? 'Email' : !user.password ? 'Password' : 'Brand';
            commonToast('error', `${emptyField} is required.`);
        }
    }

    const updateUser = (e) => {
        e.preventDefault();
        let isValid = true;

        if (!((user.password && user.confirmPass) || (!user.password && !user.confirmPass))) {
            commonToast('error', `${!user.password ? 'Password' : 'Confirm Password'} is required.`);
            return false;
        }
        if (selectedScope.length === 0) {
            commonToast('error', `Brand is required.`);
            return false;
        }
        let data = {
            name: user.name, status: user.status, target: user.target, email: user.email, password: user.password,

            scope: {
                "brandId": selectedScope.map((i) => {
                    return (i.value)
                })
            }

        }
        if (!user.name) {
            commonToast('error', `Name is required.`);
            return false;
        }
        if (user.password && user.confirmPass) {
            if (user.password != user.confirmPass) {
                commonToast('error', `Password and confirm password should be same.`);
                return false;
            }
            isValid = validations(user?.password, 'password');
            data['password'] = user.password;
            data['confirmPassword'] = user.confirmPass;
        }
        if (isValid) {
            setBtnLoading(true);
            hitApi({
                url: 'client/update-client',
                method: "PATCH",
                data: data
            }, (resp, err = null) => {
                setBtnLoading(false);
                if (resp.status) {
                    handleClose();
                    getUserList();
                    emptyUserState();
                }
            });
        }
    }

    const updatestatus = (e,item) => {
        e.preventDefault();
        let data = {
            target: item?._id,
            status: e.target.checked ? 1 : 2
        }
        setBtnLoading(true);
        hitApi({
            url: 'client/update-client',
            method: "PATCH",
            data: data
        }, (resp, err = null) => {
            setBtnLoading(false);
            if (resp.status) {
                handleClose();
                getUserList();
                emptyUserState();
            }
        });
    }


    let animatedComponents = makeAnimated();

    let cntStart = ((state.page - 1) * state.limit) + 1, cnt = cntStart;



    const handleShow = (item) => {
        setShowModal(true)
        if (item) {
            setMyState(setUser, {
                name: item.name, email: item.email, password: '', target: item._id, isEdit: true, status: item.status
            });
            let resultArray = [];
            item?.scope?.brandId.map((i) => {
                let matchingObj = objScope.find(obj => obj.value === i);
                if (matchingObj) {
                    resultArray.push(matchingObj);
                }
            });


            setSelectedScope(resultArray);

        }
    };
    return (
        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>
            <div className="pxl_mainWrapper">
                <Sidebar />
                <div className="pxl_rightBar_assets pxl-bg-back-color-wrap pxl-clientMain-wrapper-box">
                    {/* Count Card Widgets Section */}
                    <div className="pxl_pageTitle">
                        <h2>{pageTitle}</h2>
                    </div>

                    <div className="pxl-count-cards">
                        <div className="pxl_container">
                            <div className="pxl_grid">
                                <div className="pxl_tableBox_filter">
                                    <div className="pxl_tableData">
                                        <h5>Clients ({state.totalRecords})</h5>
                                    </div>
                                    <div className="pxl_filterFeilds cun-users-resheader">

                                        <div className="sch-search-section sch-searchButton-wrap">
                                            <div className="sch-search-row sch-search-bg-color-white">
                                                <input type="text" value={state.keyword} placeholder="Search Client"
                                                    onChange={(e) => setMyState(setState, {
                                                        keyword: e.target.value,
                                                        clearKeyword: false
                                                    })}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
                                                            getUserList()
                                                        }
                                                    }}
                                                />
                                                <button type="button" className="sch-search-btn">
                                                    {!state.keyword ? <span> <img src="/assets/images/scheduler/search.svg" /> </span> : <span onClick={clearSearch}><img src="/assets/images/scheduler/search.svg" /></span>}

                                                </button>
                                            </div>
                                            <div className="pxl-addNew-client-btn">
                                                <button className="pxlCommon-btn" onClick={() => handleShow()}>
                                                   Add New Client
                                                </button>
                                            </div>
                                        </div>
                                        

                                    </div>
                                </div>
                                <div className="pxl_table table-responsive">
                                    {/* { */}

                                    <table className="pxl_table">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Brand</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!state.userLoading ?
                                                state.data && state.data.length > 0 ?
                                                    state.data.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td><p>{state.page == 1 ? (index + 1) : (index + 1) + ((state.page - 1) * state.limit)}</p></td>
                                                                <td><p className="pxlName">{item.name}</p></td>
                                                                <td><p>{item.email}</p></td>
                                                                <td>
                                                                    <div className="pxl_planHolder_table">
                                                                        {
                                                                            item?.scope && objScope && objScope.map((key, index) => {

                                                                                if (item?.scope?.brandId.includes(key.value)) {
                                                                                    return (

                                                                                        <span key={index} className="pxl_plans">{key.label}</span>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td>

                                                                    {/* <p className="mr-2">{item.status == 1 ? 'Active' : 'Inactive'}</p> */}
                                                                    <Tooltip title={item?.status === 1 ? 'Active' : 'Inactive'} placement="top" arrow>
                                                                        <div className="xs_switch">
                                                                            <input
                                                                                id={'userChk_' + index}
                                                                                type="checkbox"
                                                                                value={item.status}
                                                                                defaultChecked={item.status === 1 ? true : false}
                                                                                onClick={(e) => { updatestatus(e, item) }}
                                                                            /><label htmlFor={'userChk_' + index}><span className="xs_switch_icon"></span>
                                                                            </label>
                                                                        </div>
                                                                    </Tooltip>

                                                                </td>

                                                                <td>
                                                                    <div className="pxl_tableIcon_holder pxl-clientTooltip-edit">
                                                                        <a href={void (0)} className="pxl_tableIcon cun-iconTooltip" onClick={() => handleShow(item)}>{svg.app.editIcon}
                                                                            <div className="cun-show-tooltip">
                                                                                <p>Edit</p>
                                                                            </div>
                                                                        </a>

                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        )
                                                    })

                                                    :
                                                    (state.keyword) ? <NoDataFoundDataTable column={8} statement="You don't have user with this name." /> : <NoDataFoundDataTable column={8} statement="No user found." />
                                                :
                                                <tr ><td colSpan={8}>{skeletonLoader('table')}</td></tr>
                                            }




                                        </tbody>
                                    </table>

                                    {/* } */}
                                </div>


                                <Pagination
                                    type="User"
                                    dataRange={{
                                        start: state.totalRecords ? cntStart : 0,
                                        end: state.totalRecords ? cntStart + state.data.length - 1 : 0,
                                    }}
                                    currentPage={state.page}
                                    totalRecords={state.totalRecords}
                                    perPage={state.limit}
                                    isLoading={state.userLoading}
                                    onClick={(pageNum) => {
                                        setMyState(setState, {
                                            page: pageNum,
                                        });
                                    }}
                                />
                            </div>


                        </div>
                    </div>
                </div>
            </div>


            <Modal
                show={showModal} onHide={handleClose} animation={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="pxl_modalDv pxl-addNewClient-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {user.isEdit ? "Edit Client" : "Add New Client"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="" action="" onSubmit={user.isEdit ? updateUser : createUser}>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1"> Name <span className="pxl-star-red">*</span></label>
                            <input type="text" className="form-control" value={user.name} placeholder="Enter Name" onChange={(e) => setMyState(setUser, {
                                name: e.target.value
                            })} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Email Address <span className="pxl-star-red">*</span></label>
                            <input type="email" className="form-control" value={user.email} disabled={user.isEdit} placeholder="Enter Email" onChange={(e) => setMyState(setUser, {
                                email: e.target.value
                            })} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Password {user.isEdit ? "" : <span className="pxl-star-red">*</span>}</label>
                            <input type="password" className="form-control" value={user.password} placeholder="Enter Password" onChange={(e) => setMyState(setUser, {
                                password: e.target.value
                            })} />
                        </div>
                        {user.isEdit && <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Confirm Password</label>
                            <input type="password" className="form-control" value={user.confirmPass} placeholder="Enter Confirm Password" onChange={(e) => setMyState(setUser, {
                                confirmPass: e.target.value
                            })} />
                        </div>}
                        <div className="form-group scope pxl-selectBrand">
                            <label htmlFor="exampleFormControlSelect2">Select Brand <span className="pxl-star-red">*</span></label>
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                value={selectedScope}
                                isMulti
                                options={objScope}
                                onChange={(option) => setSelectedScope(option)}
                               
                            />
                        </div>
                        {/* <div className="sch-statusCheckbox sch-relativeClass">
                            {user.isEdit && <div className="sch-custom-check-box">
                                <form>
                                    <div className="form-group">
                                        <input type="checkbox" checked={user.status} className="form-check-input" id="exampleCheck1" onChange={(e) => setMyState(setUser, {
                                            status: e.target.checked
                                        })} />
                                        <label className="form-check-label" htmlFor="exampleCheck1">Status</label>
                                    </div>
                                </form>
                            </div>}
                        </div> */}
                        <button type="submit" className="pxl_btn"> {btnLoading ? "Processing..." : user.isEdit ? "Update" : 'Submit'}</button>
                    </form>
                </Modal.Body>

            </Modal>

        </>
    )
};

export default Client;