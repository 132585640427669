
import {Helmet} from "react-helmet";
import LoginComponent from "../components/LoginComponent";


const Login = ({pageTitle}) => { 
 
    return (
        <>
            <Helmet >
                <title>{pageTitle}</title> 
            </Helmet>
            <div className='pxl_loginWrapper'>
                <div className='pxl_mainContainer'>
                    
                    <div className='pxl_loginForm order-md-2 order-1'>
                         <LoginComponent />
                    </div>

                </div>
            </div>
        </>
    )
};

export default Login;