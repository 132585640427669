import Dashboard from "./index";
import Projects from "./projects";
import appConfig from "./../../config/appConfig";
import {  Routes, Route } from "react-router-dom";
import ProjectSetting from "./components/ProjectSetting";
import PixaSupportSettings from "./components/Settings/PixaSupportSettings";
import Customers from "./Customer";
import TicketList from "./projectTickets/TicketList";
import TicketCreate from "./projectTickets/TicketCreate";
import TicketsReply from "./projectTickets/TicketsReply";


const SchedulerRouter = () => {
   
return (
    <>
       
        <Routes>
            <Route path="/" element={<Dashboard pageTitle={`${appConfig.appName} | Dashboard`} />} />
            <Route path="/projects" element={<Projects pageTitle={`${appConfig.appName} | Projects`} />} />
            <Route path="/projects/:id" element={<TicketList pageTitle={`${appConfig.appName} | Ticktes`} />} />
            <Route path="/projects/:id/tickets/:t_id" element={<TicketsReply pageTitle={`${appConfig.appName} | Ticktes`} />} />
            {/* <Route path="/pixa-support/projects" element={<Projects pageTitle={`${appConfig.appName} | Projects`} />} /> */}
            <Route path="/settings" element={<PixaSupportSettings pageTitle={`${appConfig.appName} | Settings`} />} />
            <Route path="/customers" element={<Customers pageTitle={`${appConfig.appName} | Customers`} />} />
            <Route path="/create-project/:id?" element={<ProjectSetting pageTitle={`${appConfig.appName} | Project`} />} />
            <Route path="/edit-project/:id?" element={<ProjectSetting pageTitle={`${appConfig.appName} | Project`} />} />
            <Route path="/create-ticket/:id" element={<TicketCreate pageTitle={`${appConfig.appName} | Ticket Create`} />} />


        </Routes>
    </>
)
};

export default SchedulerRouter;