import React, { useState } from 'react';
import { useStore } from "../zustand/store";
import { Tab, Tabs } from 'react-bootstrap';
import { hitApi, setMyState, getNameInitials, commonToast,assetsUrlPath } from './commonAction';
import svg from './svg';


function Profile() {
    let store = useStore(state => state),
        { token, role, email, name, profilePic, isVerified } = store.userData;
    let [state, setState] = useState({
        name: name,
        password: '',
        newPassword: '',
        conPassword: '',
        otpValue: '',
        profile: ''
    });

    const [image, setImage] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);

    const url = window.location.href;


    // const handleImageChange = (e) => {
    //     const selectedImage = e.target.files[0];
    //     setMyState(setState, {
    //         profile: e.target.files[0]
    //     })
    //     if (selectedImage) {
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             setImage(reader.result);
    //         };
    //         reader.readAsDataURL(selectedImage);
    //     }
    // };

    const handleChangePassword = (e) => {
        e.preventDefault();
        if (state.conPassword && state.newPassword) {
            if (state.conPassword != state.newPassword) {
            commonToast('error' ,'New password and confirm password should be same.')
                return;
            }
            setBtnLoading(true)
            hitApi({
                url: 'accounts/change-password',
                method: "PATCH",
                data: {
                    // "currentPassword": state.password,
                    "newPassword": state.newPassword,
                    "confirmPassword": state.conPassword
                }
            }, (resp, err = null) => {
                setBtnLoading(false);
                if (resp.status) {
                    setMyState(setState, {
                        newPassword: '',
                        conPassword: ''
                    });
                }
            });
        } else {
            commonToast('error' ,'Fields can not be empty.')
        }
    }


    const verifyAccount = (e) => {
        e.preventDefault();
        if (state.otpValue) {
            setBtnLoading(true);
            hitApi({
                url: 'accounts/account-verification',
                method: "PATCH",
                data: { token: state.otpValue }
            }, (resp, err = null) => {
                setBtnLoading(false)
                if (resp.status) {
                    let newdata = { ...store.userData, isVerified: true };
                    store.updateStore("userData", newdata);
                    setMyState(setState, {
                        otpValue: '',
                    });
                }
            });
        } else {
            commonToast('error' ,'Verification Code is required.')
        }

    }

    const resendOTP = () => {
        setBtnLoading(true)
        hitApi({
            url: 'accounts/re-send-verification',
            method: "GET",
            data: { token: token }
        }, (resp, err = null) => { setBtnLoading(false) });
    }

    const updateProfile = (e) => {
            if (state.profile.type == 'image/jpg' || state.profile.type == 'image/jpeg' || state.profile.type == 'image/png') {
            e.preventDefault();
            const formData = new FormData();
            formData.append('profile', state.profile);
            formData.append('name', state.name);
            setBtnLoading(true);

            hitApi({
                url: 'accounts/update-profile',
                method: "PATCH",
                isFormData: true,
                data: formData
            }, (resp, err) => {
            setBtnLoading(false)
                if (resp.status) {
                    let newdata;
                    if (state.profile) {
                        newdata = { ...store.userData, name: state.name, profilePic: resp?.data?.profilePic };
                    } else {
                        newdata = { ...store.userData, name: state.name };
                    }
                    store.updateStore("userData", newdata);
                }
                else {
                    console.log(err)
                    commonToast('error', { err });

                }
            });
        } else {
            commonToast('error', `Profile pictures must be JPG, PNG, or JPEG files.`);
        } 



    }

    return (
        <>
            <div className="sch-profile-mainWrapper">
                <div className="pxl_profile_wrapperr">
                    <div className="pxl_profile_container">
                        <div className="pxl_user_info_box">
                            <div className="pxl_user_info_box_row">
                                <div className="pxl_user_img">
                                    <span className="pxl_user_img_icon">
                                        {
                                            profilePic ?
                                                <img src={assetsUrlPath(profilePic)} alt="Preview" />
                                                :
                                                getNameInitials(name)
                                        }

                                    </span>
                                    {/* <span className="pxl_edit_icon">
                                    <img src="assets/images/edit-profile-icon.svg" alt="" />
                                    <input type="file" accept="image/*" onChange={handleImageChange} />
                                </span> */}
                                </div>
                                <div className="pxl_user_info">
                                    <h4>{name}</h4>
                                    <p>{email}</p>
                                </div>
                            </div>
                        </div>

                        <div className="pxl_profile_row pxl_tab_style">
                            <Tabs defaultActiveKey={url.endsWith('#verification') ? 'contact' : 'home'}>
                                <Tab eventKey="home" title="Profile Settings">
                                    <div className="pxl_tab_section">
                                        <form onSubmit={updateProfile}>
                                            <div className="cun-account-settingFlex">
                                                <div className="pxl_input_feilds">
                                                    <label>
                                                        Full Name  <span className="pxl-star-red">*</span>
                                                    </label>
                                                    <input id="" type="text" name="" value={state.name} placeholder="Enter Name" onChange={(e) => setMyState(setState, {
                                                        name: e.target.value
                                                    })} />
                                                </div>
                                                <div className="pxl_input_feilds">
                                                    <label>
                                                        Email Address  <span className="pxl-star-red">*</span>
                                                    </label>
                                                    <input readOnly type="email" name="" value={email} placeholder="Enter Email" />
                                                </div>
                                            </div>
                                            <div className="pxl_input_feilds">
                                                <label>
                                                    Upload Profile Image  <span className="pxl-star-red">*</span>
                                                </label>

                                                <div className="pxl-custom-file-upload pxl-upload-file-profile pxl-profile-fileSelect">
                                                    <div className="pxl-custom-file-select">
                                                        <div className="pxl-custom-file-select-button" id="fileName">
                                                            <img src="/assets/images/newpost/file-upload1.svg" alt="" />
                                                        </div>
                                                        <div className="pxl-custom-file-select-name" id="noFile">
                                                            {state.profile ? <span>{state.profile.name}</span> : <span> <b>Upload</b> <span className='pxl-upload-file-text'>or drop your file here</span>  </span>}
                                                        </div>
                                                        <input type="file" name="chooseFile" id="chooseFile" onChange={(e) => {
                                                            setMyState(setState, {
                                                                profile: e.target.files[0]
                                                            })
                                                        }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pxl_profile_field_wrap">
                                                <button type="submit" className="pxl_btn" disabled={btnLoading}>
                                                    {btnLoading ? "Processing..." : "Save Changes"}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </Tab>
                                <Tab eventKey="profile" title="Password Settings">
                                    <div className="pxl_tab_section">
                                        <form onSubmit={handleChangePassword}>
                                            <div className="cun-account-settingFlex">
                                                {/* <div className="pxl_input_feilds">
                                                    <label>Current Password  <span className="pxl-star-red">*</span></label>
                                                    <input id="" type="password" name="" value={state.password} placeholder="Enter Current Password" onChange={(e) => setMyState(setState, {
                                                        password: e.target.value
                                                    })} />
                                                </div> */}
                                                <div className="pxl_input_feilds">
                                                    <label>New Password  <span className="pxl-star-red">*</span></label>
                                                    <input id="" type="password" name="" value={state.newPassword} placeholder="Enter New Password" onChange={(e) => setMyState(setState, {
                                                        newPassword: e.target.value
                                                    })} />
                                                </div>
                                            </div>
                                            <div className="pxl_input_feilds">
                                                <label>Confirm Password  <span className="pxl-star-red">*</span></label>
                                                <input id="" type="password" name="" value={state.conPassword} placeholder="Enter Confirm Password" onChange={(e) => setMyState(setState, {
                                                    conPassword: e.target.value
                                                })} />
                                            </div>
                                            <div className="pxl_profile_field_wrap" disabled={btnLoading}>
                                                <button type="submit" className="pxl_btn" disabled={state.isProcess}>{btnLoading ? "Processing..." : "Save Changes"}</button>
                                            </div>
                                        </form>
                                    </div>
                                </Tab>
                                <Tab eventKey="contact" title="Verify Account">

                                    <div className="pxl_tab_section pxlProfileTab">
                                        {!isVerified ? <form onSubmit={verifyAccount}>
                                            <div className="pxl_title_with_desc">
                                                <h4>
                                                    Verify Your Account
                                                </h4>
                                                <p>We emailed your the six digit code to {email} . Please enter the code below to confirm your email.</p>
                                            </div>
                                            <div className="pxl_input_feilds pxl_input_sm">
                                                <input id="" type="text" name="otp" value={state.otpValue} placeholder="Enter Verification Code" onChange={(e) => setMyState(setState, {
                                                    otpValue: e.target.value
                                                })} />
                                            </div>
                                            <div className="pxl_profile_field_wrap">
                                                <button type="submit" className="pxl_btn" disabled={btnLoading} >
                                                    {btnLoading ? "Processing..." : "Verify Account"}
                                                </button>
                                            </div>
                                            <div className="pxl_input_note">
                                                <p>If you didn’t receive a code !! <a onClick={resendOTP}>RESEND</a></p>
                                            </div>
                                        </form>
                                            :
                                            <div className="pxl_title_with_desc">
                                                <h4> <span className='me-2'>{svg.app.verifyIcon}</span>Your account is verified.</h4>
                                            </div>}
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile