import { Outlet, Link ,useNavigate} from "react-router-dom";
import { Helmet } from "react-helmet";
import Title from "./components/Title";
import './css/editor.css'
import { useEffect } from "react";
import { NoDataFound, assetsUrlPath, dateFormatter, downloadImage, hitApi, skeletonLoader, useOutsideClick, teamMemberRestrictionMess } from "../../components/commonAction";
import { useState } from "react";
import { setMyState } from "../../components/commonAction";
import { NewDesignPopUp } from "../scheduler/components/SchedulePopUp";
import { useRef } from "react";
import svg from "../../components/svg";
import ConfirmationPopup from "../../components/common/ConfirmationPopup";
import appConfig from "../../config/appConfig";
import { useStore } from "../../zustand/store";


const templates = [
    {
        id: 1,
        img: '/assets/images/templates/1.jpg',
    },
    {
        id: 2,
        img: '/assets/images/templates/2.jpg',
    },
    {
        id: 3,
        img: '/assets/images/templates/3.jpg'
    },
    {
        id: 4,
        img: '/assets/images/templates/4.jpg'
    },
    {
        id: 5,
        img: '/assets/images/templates/5.jpg'
    },
]

const posts = [
    {
        id: 1,
        img: '/assets/images/templates/1.jpg',
        date: "",
        title: "established fact that a reader will be distracted by the readable content",
        fb_uri: "",
        twit_uri: "",
        insta_uri: "",
    },
    {
        id: 2,
        img: '/assets/images/templates/2.jpg',
        date: "",
        title: "established fact that a reader will be distracted by the readable content",
        fb_uri: "",
        twit_uri: "",
        insta_uri: "",
    },
    {
        id: 3,
        img: '/assets/images/templates/3.jpg',
        date: "",
        title: "established fact that a reader will be distracted by the readable content",
        fb_uri: "",
        twit_uri: "",
        insta_uri: "",
    },
    {
        id: 4,
        img: '/assets/images/templates/4.jpg',
        date: "",
        title: "established fact that a reader will be distracted by the readable content",
        fb_uri: "",
        twit_uri: "",
        insta_uri: "",
    },
    {
        id: 5,
        img: '/assets/images/templates/5.jpg',
        date: "",
        title: "established fact that a reader will be distracted by the readable content",
        fb_uri: "",
        twit_uri: "",
        insta_uri: "",
    },
]

const Dashboard = ({ pageTitle }) => {

    const [state, setState] = useState({
        limit: 6,
        page: 1,
        sort: 'createdAt=-1',
        keyword: '',
        isLoading: false,
        templateLoad: false,
        featuredload: false,
        scheduledCount: 0,
        isCount: false,
        draftCount: 0,
        designcount: 0,
        loadMoreActive: false,
        isDataload: false,
        totalRecord: '',
        trendinglist: [],
        featuredlist: [],
        moveToPopUp: false,
        folderName: 'Uncategorized',
        showNewDesignPopUp: false,
        useTemplateId: '',
        designlist: [],
        removeMe: false,
        deleteId: '',
        proCount:0,
        projectFolderList:[]

    });
    const navigate = useNavigate();

    let userData = useStore(state => state)['userData'];
    let { role } = userData;

    let allowAccess = (role === "client") ? false : true;

    const getMyDesign = (type) => {
        let { limit, page, sort, keyword } = state;
        let reqData;
        if (type == 'trending') {
            setMyState(setState, { templateLoad: true });
            reqData = { limit, page, sort, isTrending: 1 };
        }
        else {
            setMyState(setState, { featuredload: true });
            reqData = { limit, page, sort, isFeatured: 1 };
        }

        setMyState(setState, { isLoading: true });

        hitApi({
            url: 'image/template/get-image-template',
            method: "GET",
            data: reqData
        }, (resp, err = null) => {

            if (type == 'trending') {
                setMyState(setState, { templateLoad: false });
            }
            else {
                setMyState(setState, { featuredload: false });
            }

            if (resp.status) {
                let newList = resp.data.records;
                if (type == 'trending') {
                    setMyState(setState, { templateLoad: false });
                    setMyState(setState, { trendinglist: newList, totalRecord: resp.data.totalRecords });
                }
                else {
                    setMyState(setState, { featuredload: false });
                    setMyState(setState, { featuredlist: newList, totalRecord: resp.data.totalRecords });
                }
            }

        });
    }
    const getFolderList = () => {
        hitApi({
            url: 'image/folder/get-folders',
            method: "GET",
            data: {
                limit: "all"
            }
        }, (resp, err = null) => {
            if (resp.status) {
                setMyState(setState, { projectFolderList: resp.data.records });

                if (resp.data.records) {

                    let data = resp.data.records.map(val => ({ label: val.title, value: val._id ?? 'uncategorized' }));
                    data.unshift({ label: "Select Project", value: "" });
                    setMyState(setState, { projectFolderList: data });                  
                }
            }
        });
    }

    const getDesign = () => {
        let { limit, page, sort, keyword } = state;
        let reqData = { limit, page, sort };


        setMyState(setState, { isLoading: true });
        hitApi({
            url: 'image/my-designs/get-design',
            method: "GET",
            data: reqData
        }, (resp, err = null) => {
            setMyState(setState, { isLoading: false });

            if (resp) {
                setMyState(setState, { isLoading: false });
                if (resp.status) {
                    let newList = resp.data.records;

                    setMyState(setState, { designlist: newList, totalRecord: resp.data.totalRecords });
                }
            }

        });
    }

    useEffect(() => {
        getMyDesign('trending');
        getMyDesign('featured');
        getDesign();
        fetchCounts();
        getFolderList();
    }, []);

    const fetchCounts = () => {
        setMyState(setState, { isCount: true });
        hitApi({
            url: 'scheduler/dashboard/get-count',
            method: "GET",
            data: {}
        }, (resp, err = null) => {
            setMyState(setState, { isCount: false });
            if (resp.status) {
                setMyState(setState, { draftCount: resp.data.draftcount, designcount: resp.data.designcount, scheduledCount: resp.data.scheduledcount,proCount:resp.data.projectcount });
            }
        });
    }


    useEffect(() => {
        document.body.classList.add('pxl-body-white');
        return () => {
          document.body.classList.remove('pxl-body-white');
        };
      }, []);
    const [allsubmenu, setallsubmenu] = useState(false);
    const toggleSublist = (id) => {
        setallsubmenu(prevState => ({
            //   ...prevState,
            [id]: !prevState[id]
        }));
    };
    // sub menu delet edit setting end

    const closeAllMenu = () => {
        setallsubmenu(false);
        setMyState(setState, { showaction: false })
    }


    const menuRef = useRef();


    useOutsideClick(menuRef, () => closeAllMenu());

    return (
        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>

            {/* Main Wrapper  */}
            <main className="sch-main-wrapper">

                {/* <div className="pxl_sidebarMenu">
                    <button onClick={() => logoutMe()}>Logout</button>
                    <div className="pxl_logo">
                        <a href={()=> false} className="pxl_mainLogo">
                            <img src={appConfig.appLogo} alt="Logo"/>
                        </a>
                    </div>
                </div> */}


                {/* Banner Start  */}
                <div className="sch-banner-wrapper">

                    <div className="sch-container-fluid">
                        <div className="sch-banner-box pxl-dashboardBanner-box">
                            <div className="sch-banner-position-wrap">
                                <img src="/assets/images/banner-pos1.jpg" alt="" className="sch-banner-pos1" />
                                <img src="/assets/images/banner-pos2.jpg" alt="" className="sch-banner-pos2" />
                                <img src="/assets/images/banner-pos3.jpg" alt="" className="sch-banner-pos3" />
                                <img src="/assets/images/banner-pos4.jpg" alt="" className="sch-banner-pos4" />
                                <img src="/assets/images/banner-pos5.jpg" alt="" className="sch-banner-pos5" />
                                <img src="/assets/images/banner-pos6.jpg" alt="" className="sch-banner-pos6" />
                                <img src="/assets/images/banner-pos7.jpg" alt="" className="sch-banner-pos7" />
                                <img src="/assets/images/banner-pos8.jpg" alt="" className="sch-banner-pos8" />
                                <img src="/assets/images/banner-pos9.jpg" alt="" className="sch-banner-pos9" />
                            </div>
                            <div className="sch-banner-content">
                                <h2>Templates for any niche you need</h2>
                                <p>Choose from 12000 templates and bundles</p>
                                <div className={`pxl-banner-create-design-post-btn ${allowAccess ? "" : "pxl-bannerBtn-disable"}`}>
                                    <ul>
                                        <li>
                                           <div className="pxl-banner-btn pxl-create-design-btn">
                                            <button className={`cun-socialIcon ${allowAccess ? "" : "sch-btn-disbale"}`} disabled={!allowAccess} onClick={() => setMyState(setState, { showNewDesignPopUp: true })}>
                                                    <span>
                                                        <img src="/assets/images/scheduler/design-icon.svg" />
                                                    </span>
                                                    New Design
                                                    {!allowAccess && <div className="cun-show-tooltip"><p>{teamMemberRestrictionMess(allowAccess)}</p></div>}
                                                </button>
                                                
                                           </div>
                                        </li>
                                        {/* <li>
                                            <div className="pxl-banner-btn  pxl-create-post-btn">
                                            <button onClick={()=>navigate('/scheduler/new-post')} className={`cun-socialIcon ${allowAccess ? "" : "sch-btn-disbale"}`} disabled={!allowAccess}>
                                                <span>
                                                    <img src="/assets/images/scheduler/post-icon.svg" />
                                                </span>
                                                Create Post
                                                {!allowAccess && <div className="cun-show-tooltip"><p>{teamMemberRestrictionMess(allowAccess)}</p></div>}
                                            </button>
                                          
                                            </div>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Count Card Widgets Section */}
                <div className="sch-count-cards">
                    <div className="sch-container">
                        <div className="sch-grid pxl-dashboard-count-box">
                            <div className="sch-count-widget">
                                <div className="sch-count-icon">
                                    <span>
                                    {svg.TotalDesignCount}
                                        {/* <img src="/assets/images/count/icon1.svg" alt="" /> */}
                                    </span>
                                    {state.isCount ? skeletonLoader('asset', 1, 84, 45, "") : <h3>{state.designcount}</h3>}
                                </div>
                                <p>Total Design Count</p>
                            </div>
                            <div className="sch-count-widget">
                                <div className="sch-count-icon">
                                    <span>
                                        {/* <img src="/assets/images/count/icon2.svg" alt="" /> */}
                                        {svg.TotalProjectCount}
                                    </span>
                                    {state.isCount ? skeletonLoader('asset', 1, 84, 45, "") : <h3>{state.proCount}</h3>}
                                </div>
                                <p>Total Project Count</p>
                            </div>
                            {/* <div className="sch-count-widget">
                                <div className="sch-count-icon">
                                    <span>
                                        <img src="/assets/images/count/icon4.svg" alt="" />
                                    </span>
                                    {state.isCount ? skeletonLoader('asset', 1, 84, 45, "") : <h3>{state.scheduledCount}</h3>}
                                </div>
                                <p>Total Scheduled Posts</p>
                            </div> */}
                            {/* <div className="sch-count-widget">
                                <div className="sch-count-icon">
                                    <span>
                                        <img src="/assets/images/count/icon3.svg" alt="" />
                                    </span>
                                    {state.isCount ? skeletonLoader('asset', 1, 84, 45) : <h3>0</h3>}
                                </div>
                                <p>Total Published Posts</p>
                            </div> */}

                        </div>
                    </div>
                </div>



                {/* Trending Templates Section */}
                <div className="pxl-images-dashboardMain-wrapper">
                    <div className="sch-container-fluid">
                        <div className="pxl-img-dashboard-row-wrap pxl-dashboard-trendingTemplates">
                            <div className="pxl-image-dashboard-heading-flexWrapper">
                                <div className="sch-schedular-heading-name">
                                    <h2 className="sch-font-20-weight-600">Trending Templates</h2>
                                </div>
                                <div className="sch-see-more-link">
                                    <Link to="/images/templates?type=trending">See More<span>
                                        <img src="/assets/images/scheduler/arrow-right.svg" alt="" /></span>
                                    </Link>
                                </div>
                            </div>
                            <div className="sch-section-row">
                                {state.templateLoad ? [...Array(6)]?.map((index) => <div key={index} style={{ marginBottom: '40px' }} > {skeletonLoader('asset', 1, '', 300, "")}</div>)
                                    :
                                    state.trendinglist.length > 0 &&
                                    state.trendinglist.map(image => (
                                        <>
                                            <div key={image.id} className={`sch-template-box ${allowAccess ? "" : "pxl-img-dashboar-readonly"}`}>
                                                <div className="sch-template-img">
                                                    <img src={image?.files?.original?.thumb ? assetsUrlPath(image?.files?.original?.thumb) : "/assets/images/default-draft-img.jpg"} alt="" />
                                                    <div className='sch-templateHover-btn cun-socialIcon'>
                                                        <button className={`sch-edit-temp-btn ${allowAccess ? "" : "sch-btn-disbale"}`} disabled={!allowAccess}
                                                            onClick={() => setMyState(setState, { showNewDesignPopUp: true, useTemplateId: image._id })}>Use This Template</button>
                                                        {!allowAccess && <div className="cun-show-tooltip"><p>{teamMemberRestrictionMess(allowAccess)}</p></div>}
                                                    </div>
                                                </div>
                                                {/* <Link href={void (0)} className="sch-edit-temp-btn" onClick={() => setMyState(setState, { showNewDesignPopUp: true, useTemplateId: image._id })}>Use this template</Link> */}
                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                            {!state.templateLoad && state.trendinglist.length == 0 &&
                                <NoDataFound title={'No templates found.'} changeDesign={true} bgRemove={true} />
                            }
                        </div>
                        <div className="pxl-img-dashboard-row-wrap pxl-dashboard-featuredTemplate">
                            <div className="pxl-image-dashboard-heading-flexWrapper">
                                <div className="sch-schedular-heading-name">
                                    <h2 className="sch-font-20-weight-600">Featured Templates</h2>
                                </div>
                                <div className="sch-see-more-link">
                                    <Link to="/images/templates?type=featured">See More<span>
                                        <img src="/assets/images/scheduler/arrow-right.svg" alt="" /></span>
                                    </Link>
                                </div>
                            </div>

                          
                            <div className="sch-section-row">
                            
                                {state.featuredload ? [...Array(6)].map((index) => <div key={index} style={{ marginBottom: '40px' }}>{skeletonLoader('asset', 1, '', 300, "")}</div>)
                                    :
                                    state.featuredlist.length > 0 &&
                                    state.featuredlist.map((image, index) => (
                                        <div key={index} className={`sch-template-box ${allowAccess ? "" : "pxl-img-dashboar-readonly"}`}>
                                            <div className="sch-template-img">
                                                <img src={image?.files?.original?.thumb ? assetsUrlPath(image?.files?.original?.thumb) : "/assets/images/default-draft-img.jpg"} alt="" />
                                                <div className="sch-templateHover-btn cun-socialIcon">
                                                    <button className={`sch-edit-temp-btn ${allowAccess ? "" : "sch-btn-disbale"}`} disabled={!allowAccess}
                                                        onClick={() => setMyState(setState, { showNewDesignPopUp: true, useTemplateId: image._id })}>Use This Template</button>
                                                    {!allowAccess && <div className="cun-show-tooltip"><p>{teamMemberRestrictionMess(allowAccess)}</p></div>}
                                                </div>
                                            </div>
                                            {/* <Link href={void (0)} className="sch-edit-temp-btn" onClick={() => setMyState(setState, { showNewDesignPopUp: true, useTemplateId: image._id })}>Use this template</Link> */}
                                        </div>
                                    ))
                                }
                            </div>
                            {!state.featuredload && state.featuredlist.length == 0 &&
                                <NoDataFound title={'No templates found.'} changeDesign={true} bgRemove={true} />
                            }
                        </div>
                        <div className="pxl-img-dashboard-row-wrap pxl-dashboard-recentDesign">
                            <div className="pxl-image-dashboard-heading-flexWrapper">
                                <div className="pxl-images-page-heading-name">
                                    <h2 className="sch-font-20-weight-600">Recent Designs</h2>
                                </div>
                                <div className="pxl-see-more-wrap">
                                    <Link to="/images/my-designs/projects/recent">See More<span>
                                        <img src="/assets/images/scheduler/arrow-right.svg" alt="" /></span>
                                    </Link>
                                </div>
                            </div>
                           
                            
                           <div className="sch-uncategorized-mainwrapper-box pxl-mydesign-createProjectBox">
                           <div className="sch-workspace-row">
                                {state.isLoading ? [...Array(6)].map((index) => <div key={index} style={{ marginBottom: '40px' }}> {skeletonLoader('asset', 1, '', 300, "")}</div>) :
                                    state.designlist.length > 0 && state.designlist.map((item, i) => (
                                        <div className="sch-workspace-repear-single" key={i}>
                                            {/* <div className="sch-custom-check-box cun-select sch_hide_checkbox">
                                                <div className="form-group">
                                                    <input type="checkbox" id="singleCheckbox" />
                                                    <label for="singleCheckbox"></label>
                                                </div>
                                            </div> */}
                                            <div className="sch-imagebox">
                                                {item.isProcessing && <div className='pxl-processing-section'><h2>processing...</h2> </div>}
                                                <img src={item?.files?.original?.thumb ? assetsUrlPath(item.files.original.thumb) : '/assets/images/default-draft-img.jpg'} />
                                            </div>
                                            <div className="sch-section-flex">
                                                <div className="sch-workspace-info">
                                                    <h2>{item.title}</h2>
                                                    <h2>{state?.projectFolderList?.map((data)=>{return((item?.folderId==data?.value)?data?.label:(item?.folderId==undefined&&data?.value=='uncategorized')?"Uncategorized":"")})}</h2>
                                                    <h3><img src="/assets/images/scheduler/calendar-svg.svg"></img>
                                                        <span>{dateFormatter(item.createdAt)}</span>
                                                        {/* <span>
                                                            <img src="/assets/images/scheduler/dot.png"></img>    
                                                        </span> */}
                                                    </h3>
                                                </div>

                                            {allowAccess &&  <div className="sch-draft-content">
                                                    <div className={`sch-menuSection  ${allsubmenu[item._id] ? 'sch-menuOpen' : ''}`}>
                                                        <div className='three_dot_bg' id={item._id} onClick={() => toggleSublist(item._id)} ref={menuRef}>
                                                            <img src="/assets/images/three-dot.svg" alt="" />
                                                        </div>
                                                        <div className={`sch-submenubox`}>
                                                            <ul>
                                                                <li>
                                                                    <a href={`/images/editor/${item._id}`}>
                                                                        <span> <img src="/assets/images/edit-svg.svg" alt="" /></span>Edit
                                                                    </a>
                                                                </li>
                                                               
                                                                {(item?.files?.original?.path) && !item.isProcessing ?
                                                                    <li>
                                                                        <a onClick={() => { closeAllMenu(); downloadImage(item?.files?.original?.path) }}>
                                                                            <span><img src="/assets/images/downloadSvg.svg" alt="" /></span>Download
                                                                        </a>
                                                                    </li>
                                                                    : ''}
                                                                     <li>
                                                                    <a onClick={() => { closeAllMenu(); setMyState(setState, { removeMe: true, deleteId: item._id }) }}>
                                                                        <span> <img src="/assets/images/delet-svg.svg" alt="" /></span>Delete
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>}

                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                           </div>

                            {!state.isLoading && state.designlist.length == 0 &&
                                <NoDataFound title={'You have not created any designs yet..'} changeDesign={true} bgRemove={true} />
                            }
                        </div>
                    </div>
                </div>




            </main>


            <NewDesignPopUp
                shownPopup={state.showNewDesignPopUp}
                closePopup={() => setMyState(setState, { showNewDesignPopUp: false })}
                templateId={state.useTemplateId}
            />


            <ConfirmationPopup
                shownPopup={state.removeMe}
                closePopup={() => {
                    setMyState(setState, {
                        removeMe: false,
                        deleteId: ''
                    })
                }}
                type={"design"}
                title={`Do you want to move this design to trash?`}
                subTitle={'Items can be restored from trash in next 15 days.'}
                yesBtn={"Yes, Move"}
                btnLoader={state.isDataload}
                removeAction={() => {
                    let data = {}
                    data['target'] = state.deleteId
                    setMyState(setState, { isDataload: true })

                    hitApi({
                        url: 'image/my-designs/move-to-trash',
                        method: "PATCH",
                        data: data,
                    }, (resp, err = null) => {
                        if (resp.status) {
                            // setMyState(setState, { isLoad: !state.isLoad });
                            getDesign();
                        }
                        setMyState(setState, {
                            removeMe: false,
                            deleteId: '',
                            isDataload: false
                        })
                    })
                }}
            />
        </>
    )
};

export default Dashboard;