

import { useStore } from "../../../zustand/store";
import { useEffect } from "react";
import appConfig from "./../../../config/appConfig";

const HeaderResponsive = (props) => {
    let store = useStore(state => state);
   

    useEffect(() => {
        document.addEventListener('click', (event) => {
            if (!(event.target.matches('.sch-headerResToggle') || event.target.matches('.sch-menu-btn') || event.target.matches('.innerspan') || event.target.matches('.toggle_profile') ) ) {
                store.updateStore("resMenuToggle", false)
            }
        });
    }, [])

    return (
        <>
            <div className={`sch-headerRes-wrapper`}>
                <div className="sch-headerRes-inner">
                    <div className="sch-resLogo">
                        <a>
                            <img src={appConfig.appLogo} alt="" />
                        </a>
                    </div>
                    <div className="sch-headerResToggle" >
                       <ul>
                        <li className='innerspan'>
                        <a onClick={() => store.updateStore("resMenuToggle", !store.resMenuToggle)}  className="sch-menu-btn innerspan" >
                        <span  className='innerspan'><span className='innerspan'></span><span className='innerspan'></span><span className='innerspan'></span></span></a>
                        </li>
                       </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default HeaderResponsive;