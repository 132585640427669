import 'highlight.js/styles/github.css';
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import appConfig from '../../config/appConfig';
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('xml', require('highlight.js/lib/languages/xml'));

function ExampleRequest({data}) {
    let bodyData = {}, 
    headerData = {};
    if(data?.body?.length){
        data?.body?.map(d => {
            bodyData[d.key] = d.description;
        })
    } 

    if(data?.headers?.length){
        data?.headers?.map(d => {
            headerData[d.key] = d.value;
        })
    }
    
    let option = {
        headers:headerData,
        method: data.method,
        body : bodyData
    }

    return (
        <div className='code_Section'>
            {/* <div dangerouslySetInnerHTML={{
                __html: ``}}>
                    
            </div> */}
            <div>
                <pre>
                    <code className="language-javascript hljs">
                        {/* <span className="hljs-keyword">const</span> url = <span className="hljs-keyword">new</span> <span className="hljs-title function_">URL</span>(
                                <span className="hljs-string">"https://bridge.flexifunnels.com/api/autologin"</span>
                            );
                        <br/>

                        <span className="hljs-keyword">const</span> 
                        headers = {
                            <span className="hljs-string">"Content-Type"</span>: <span className="hljs-string">"application/json"</span>,
                            <span className="hljs-string">"Accept"</span>: <span className="hljs-string">"application/json"</span>,
                        };
                        <br/>

                        <span className="hljs-keyword">let</span> body = {
                            <span className="hljs-string">"code"</span>: <span className="hljs-string">"xyza"</span>
                        };
                        <br/>

                        <span className="hljs-title function_">fetch</span>(url, {
                            <span className="hljs-attr">method</span>: <span className="hljs-string">"POST"</span>,
                            headers,
                            <span className="hljs-attr">body</span>: <span className="hljs-title class_">JSON</span>.<span className="hljs-title function_">stringify</span>(body),
                        }).<span className="hljs-title function_">then</span>(<span className="hljs-function"><span className="hljs-params">response</span> =&gt;</span> response.<span className="hljs-title function_">json</span>()); */}
                        {
`fetch('${appConfig.apiUrl+data.url}', ${JSON.stringify(option, null, 2)})
.then(res => res.json())
.then(resp => {
    console.log(resp);
})`
                        }
                    </code>
                </pre>
            </div>
        </div>
    )
}

export default ExampleRequest