
import './App.css';
import Routes from './pages/MainRoutes'
import 'react-loading-skeleton/dist/skeleton.css'
import { useEffect, useState } from 'react';

const ScriptLoader = ({ scriptUrl, onLoad }) => {
    const [scriptLoaded, setScriptLoaded] = useState(false);

    useEffect(() => {
        if (scriptLoaded) return;
      
        const pixasupportElement = document.getElementById('pixasupport');
        if (!pixasupportElement) {
            console.error('Element with id "pixasupport" not found');
            return;
        }

        const script = document.createElement('script');
        script.src = scriptUrl;
        script.async = true;

        const initializeScripts = () => {
            if (typeof onLoad === 'function') {
                onLoad();
            }
        };

        script.onload = () => {
            setScriptLoaded(true);
            if (document.readyState === 'complete' || document.readyState === 'interactive') {
                initializeScripts();
            } else {
                document.addEventListener('DOMContentLoaded', initializeScripts);
            }
        };

        pixasupportElement.appendChild(script);

        return () => {
            if (pixasupportElement.contains(script)) {
                pixasupportElement.removeChild(script);
            }
            document.removeEventListener('DOMContentLoaded', initializeScripts);
        };
    }, [scriptUrl, onLoad, scriptLoaded]);

    return null;
};
function App(props) {

    const handleScriptLoad = () => {

        if (typeof window.pixasupportProject === 'function') {
            const pixasupportElement = document.getElementById('pixasupport');
            if (pixasupportElement) {
                const pixasupportId = pixasupportElement.getAttribute('class') || pixasupportElement.getAttribute('ref');
                if (pixasupportId) {
                    window.pixasupportProject(pixasupportId);
                }
            }
        }
    };

    return (
        <div className="App">

            <Routes />
            <div id="pixasupport" className="f31bd9b73cf5d1454ba8743fc809bb4b9b63a005cb7064e42aa5ec63f4c14ebe"></div>
            <ScriptLoader scriptUrl="https://api.pixasupport.com/project_widget.js" onLoad={handleScriptLoad} />

        </div>
    );
}

export default App;
