import Sidebar from "../components/Sidebar";
import ProfileMain from "../components/Profile";
import {Helmet} from "react-helmet";

const Profile = ({pageTitle}) => {
    return (
        <>
            <div className="pxl_mainWrapper">
                <Helmet >
                    <title>{pageTitle}</title>
                </Helmet>
                <Sidebar/>
                <div className="pxl_rightBar_assets">
                    <ProfileMain />
                </div>
            </div>

        </>
    )
};
export default Profile;