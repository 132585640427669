import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import svg from "./svg";
import { commonToast, hitApi, setMyState, validations } from "./commonAction";
import { useGoogleLogin } from '@react-oauth/google';
import { useStore } from "../zustand/store";
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import appConfig from './../config/appConfig';

import { OldSocialLogin as SocialLogin } from "react-social-login";



const Login = ({ pageTitle }) => {
    let store = useStore(state => state);
    const navigate = useNavigate();



    let [authType, setauthType] = useState('login');
    let [state, setState] = useState({
        name: '',
        email: '',
        password: '',
        isRemember: true,
        isProcess: false,
        loginProcess: false
    });

    useEffect(() => {
        setState({
            name: '',
            email: '',
            password: '',
            isRemember: true,
            isProcess: false
        });
    }, [authType]);


    let manageLogin = (resp) => {
        let authData = resp.data;
        let { token, role, scope, currentBrandId } = authData;
        Cookies.set('authToken', token, { expires: +process.env.REACT_APP_TOKEN_LIFE })


        store.updateStore("userData", authData);
        navigate(role == 'admin' ? "/admin" : '/dashboard');

        //    if(role == 'teamMember'){
        //          navigate(scope.hasOwnProperty("scheduler") ? '/scheduler/calendar' : scope.hasOwnProperty("images") ? '/images/templates' : scope.hasOwnProperty("support") ? '/pixa-support/projects' : '/account-settings#profile' )
        //    }else{
        //     navigate(role == 'admin' ? "/admin" : (resp.isCamp ? '/scheduler/calendar' : '/scheduler/new-post'));
        //    }

    }

    let createAnAccount = (e) => {
        e.preventDefault();
        let { name, email, password } = state;
        if (name.trim() && email && password) {
            let isValid = validations(email, 'email') && validations(password, 'password')
            if (isValid) {
                setMyState(setState, {
                    isProcess: true
                });
                hitApi({
                    url: 'auth/signup',
                    method: "POST",
                    data: { name, email, password }
                }, (resp, err = null) => {
                    if (resp.status) setauthType('login');
                    setMyState(setState, {
                        isProcess: false
                    });

                });
            }

        } else {
            const emptyField = !name ? 'Name' : !email ? 'Email' : !password ? 'Password' : null;
            commonToast('error', `${emptyField} is required.`);
        }
    }

    let loginMe = async (e) => {
        e.preventDefault();

        let { email, password } = state;
        if (email && password) {
            setMyState(setState, {
                loginProcess: true
            })

            hitApi({
                url: 'auth/login',
                method: "POST",
                data: { email, password }
            }, (resp, err = null) => {
                setMyState(setState, {
                    loginProcess: false
                });
                if (!err && resp.data) {
                    manageLogin(resp);
                }
            });
        } else {
            const emptyField = !email ? 'Email' : !password ? 'Password' : null;
            commonToast('error', `${emptyField} is required.`);
        }
    }


    let socialLogin = (params) => {
        setMyState(setState, {
            isProcess: false
        });
        hitApi({
            url: 'auth/social-auth',
            method: "POST",
            data: { ...params }
        }, (resp, err = null) => {
            setMyState(setState, {
                isProcess: false
            });
            if (!err && resp.data) {
                manageLogin(resp);
            }
        });
    }

    const handleSocialLogin = (data) => {
        socialLogin({
            ...data
        });
    };

    const loginWithGoogle = useGoogleLogin({
        onSuccess: async tokenResponse => {
            let { access_token = null } = tokenResponse;
            if (access_token) {

                fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
                    headers: { Authorization: `Bearer ${access_token}` },
                }).then(response => response.json()).then(result => {

                    socialLogin({
                        _profile: {
                            email: result.email,
                            firstName: result.given_name,
                            lastName: result.family_name,
                            name: result.name,
                            profilePicURL: result.picture,
                        },
                        _provider: 'google',
                        _token: {
                            ...tokenResponse
                        }
                    });

                }).catch(error => {
                    console.log('error', error)
                });
            } else {
                console.log('Something went wrong.')
            }

        },
        // flow: 'auth-code',
        flow: 'implicit',
    });


    let forgotPasswordHandle = (e) => {
        e.preventDefault();
        if(!state.email){
            commonToast('error', `Email is required.`);
            return 
        }


        let isValid = validations(state.email, 'email');
        if (isValid) {
            setMyState(setState, {
                isProcess: true,
            });
            hitApi({
                url: 'auth/forgot-password',
                method: "PATCH",
                data: { email: state.email }
            }, (resp, err = null) => {
                setMyState(setState, {
                    isProcess: false,
                });

            });
        }
    }

    return (
        <>
            <div className='pxl_login-common-screen'>
                {['createAccount', 'login'].includes(authType) ?
                    <div className='pxl_leftForm pxl_form'>
                        <span><img src={appConfig.appLogo} alt="" /></span>
                        {
                            authType == 'login' ?
                                <form onSubmit={loginMe}>
                                    <div className='pxl_logo_auth'>
                                        <h3>Use your registered email to <br></br> continue with<span> PixaLab</span> </h3>
                                    </div>

                                    <div className='input_auth_box'>
                                        <div className='pxl_custom_form pxl_login-input'>
                                            <input className='pxl_customInput_auth' type='email' value={state?.email} placeholder='Email' onChange={(e) => setMyState(setState, {
                                                email: e.target.value
                                            })} />
                                            <span className='pxl_inputIcon'>{svg.app.emailIcon}</span>
                                        </div>
                                        <div className='pxl_custom_form pxl_login-input '>
                                            {/* <label>Password</label> */}
                                            <input className='pxl_customInput_auth' type='password' placeholder='Password'  onChange={(e) => setMyState(setState, {
                                                password: e.target.value
                                            })} />
                                            <span className='pxl_inputIcon pxl_inputIcon_password'>{svg.app.passwordIcon}</span>
                                        </div>
                                    </div>
                                    <div className='pxl_checkboxHolder pxl_checkboxHolder-right'>
                                        {/* <div className='pxl_remamberMe checkbox'>
                                            <input type="checkbox" id='remamberMe' defaultChecked={state?.isRemember} onClick={e => setMyState(setState, {  
                                                isRemember : e.target.checked 
                                            })} />
                                            <label htmlFor='remamberMe'>
                                                Remember Me
                                            </label>
                                        </div> */}
                                        <p className="pxl_link" onClick={() => {
                                            setauthType("forgotPassword")
                                        }}>
                                            <a>Forgot Password?</a>
                                        </p>
                                    </div>
                         

                                    <div className='pxl_btnHolder pxl_btnHolder-login'>
                                        <button className='pxl_customBtn pxl_btn' style={{ "width": "100%" }} type='submit' disabled={state?.loginProcess ? true : false}>
                                            {state?.loginProcess ? "Processing..." : "Login Now"}
                                        </button>
                                    </div>
                                </form>
                                :
                                <form onSubmit={createAnAccount}>
                                    <div className='pxl_logo_auth'>
                                        <h3 className="pxl-fill-details-title">Please provide the necessary details below to create your account.</h3>
                                    </div>

                                    <div className='input_auth_box'>
                                        <div className='pxl_custom_form pxl_login-input'>
                                            <input className='pxl_customInput_auth' value={state?.name} placeholder='Name' onChange={(e) => setMyState(setState, {
                                                name: e.target.value
                                            })} />
                                            <span className='pxl_inputIcon'>{svg.app.userIcon}</span>
                                        </div>
                                        <div className='pxl_custom_form pxl_login-input'>
                                            <input className='pxl_customInput_auth' type='email' value={state?.email} placeholder='Email' onChange={(e) => setMyState(setState, {
                                                email: e.target.value
                                            })} />
                                            <span className='pxl_inputIcon'>{svg.app.emailIcon}</span>
                                        </div>
                                        <div className='pxl_custom_form pxl_login-input'>
                                            <input className='pxl_customInput_auth' type='password' placeholder='Password' value={state?.password} onChange={(e) => setMyState(setState, {
                                                password: e.target.value
                                            })} />
                                            <span className='pxl_inputIcon pxl_inputIcon_password'>{svg.app.passwordIcon}</span>
                                        </div>
                                    </div>
                                    <div className='pxl_custom_form pxl_login-input pxl-loginPrivacyPolicy'>
                                        <div className='pxl_remamberMe checkbox'>
                                            <input type="checkbox" id='remamberMe' defaultChecked={state?.isRemember} onClick={e => setMyState(setState, {
                                                isRemember: e.target.checked
                                            })} />
                                            <label htmlFor='remamberMe'>
                                            By creating an account on PixaLab, I hereby accept the <a href="https://www.pixalab.ai/term-and-condition/index.html" target='_blank'>Terms & Conditions</a> and acknowledge the <a href="https://www.pixalab.ai/privacy-policy/index.html" target='_blank'>Privacy Policy</a>.
                                            </label>
                                        </div>

                                    </div>
                                    <div className='pxl_btnHolder pxl_btnHolder-login pxl_btnHolder-login-topspace'>
                                        <button className='pxl_customBtn pxl_btn' style={{ "width": "100%" }} type='submit' disabled={state?.isProcess}>
                                            {state?.isProcess ? "Processing..." : "Create Now"}
                                        </button>
                                    </div>
                                </form>
                        }


                        <div className="pxl-login-or">
                            <p>or</p>
                        </div>
                        <div className="pxl-login-social">
                            <button className='pxl_socialLogin_btn' onClick={() => loginWithGoogle()} disabled={state?.isProcess}>
                                <span><img src='/assets/images/google.png' alt='Facebook Icon' /></span>  {"Continue With Google"}
                            </button>
                            <SocialLogin
                                provider="facebook"
                                appId={process.env.REACT_APP_FACEBOOK_APP_ID ? process.env.REACT_APP_FACEBOOK_APP_ID : ''}
                                callback={handleSocialLogin}
                            >
                                <button className='pxl_socialLogin_btn' disabled={state?.isProcess}>
                                    <span><img src='/assets/images/fb.png' alt='Facebook Icon' /></span> { "Continue With Facebook"}
                                </button>
                            </SocialLogin>

                            {/* <button className='pxl_socialLogin_btn'>
                                <span>{svg.app.twitterCircleIcon}</span> Continue with Twitter
                            </button> */}

                            {/* <button className='pxl_socialLogin_btn' onClick={() => {
                                setauthType("Login")
                            }}>
                                <span>{svg.app.emailCircleIcon}</span> Continue with Email
                            </button> */}
                        </div>
                        <div>
                            {
                                authType == 'login' ?
                                    <div className="pxl_createAccount-sec "><h2>Don't have an account? <span className="pxl_back_login pxl_creteAccount" onClick={() => setauthType("createAccount")} > Create Account</span></h2>
                                    </div>
                                    :

                                    // <div className="pxl_checkboxHolder text-center "><span className="pxl_back_login mx-auto" onClick={() => setauthType("login")} >Back to Login</span>
                                    // </div>
                                    <div className="pxl_createAccount-sec ">
                                        <h2>Already have an account?
                                            <span className="pxl_back_login pxl_creteAccount" onClick={() => {
                                                setauthType("login")
                                            }}> Log in</span>
                                        </h2>
                                    </div>
                            }
                        </div>
                    </div>
                    :
                    authType == 'forgotPassword' &&
                    <div className='pxl_leftForm text-left pxl_form'>
                        <span><img src={process.env.APP_LOGO} alt="" /></span>
                        <div className='pxl_logo_auth'>
                            <span><img src={appConfig.appLogo} alt="" /></span>
                            {/* <h3><a onClick={()=> setauthType('login')}></a> Forgot Password </h3> */}
                        </div>
                        <div className="pxl_logo_auth">
                            <h3>Forgot password? Don't worry.... <br/> Please enter your registered email and we will send you a new one.</h3>

                        </div>
                        <form onSubmit={forgotPasswordHandle}>
                            <div className='input_auth_box'>
                                <div className='pxl_custom_form pxl_login-input'>
                                    {/* <label>Email Address</label> */}
                                    <input className='pxl_customInput_auth' type='email' placeholder='Email' value={state?.email} onChange={(e) => setMyState(setState, {
                                        email: e.target.value
                                    })} />
                                    <span className='pxl_inputIcon'>{svg.app.emailIcon}</span>
                                </div>
                            </div>
                            <div className='pxl_btnHolder pxl_btnHolder-login'>
                                <button className='pxl_customBtn pxl_btn' disabled={state?.isProcess} style={{ "width": "100%" }} type='submit'>{state?.isProcess ? "Processing..." : "Submit"}</button>
                            </div>
                        </form>
                        <div className='fogot-back-login text-center '>
                            <span onClick={() => {
                                setauthType("login")
                            }} className="pxl_back_login">
                                Back to Login
                            </span>
                        </div>
                    </div>
                }

            </div>

        </>
    )
};

export default Login;