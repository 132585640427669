import { useState, useEffect } from 'react'
import { commonToast, hitApi } from '../../../components/commonAction';
import { useStore } from '../../../zustand/store';
import appConfig from '../../../config/appConfig';
import svg from './svg';

function ProjectDomainSetting({ p_id,setActiveTabMenu }) {
    const [activeType, setActiveTab] = useState('Sub Domain');
    let store = useStore(state => state);
    let userData = store.userData
    const [domainRadioActive, setDomainRadioActive] = useState('sub_domain');
    const [domainName, setDomainName] = useState('');
    const [customDomainName, setCustomDomainName] = useState('');
    const [currDomain, setCurrDomain] = useState('');

    const [isVerify, setIsVerify] = useState(true)
    const [isConnected, setIsConnected] = useState(false)
    const [isEdit, setIsEdit] = useState(false)

    const [isError, setIsError] = useState(false);
    const [showPoints, setShowPoints] = useState(true)
    const [showCustomDomIpt, setCustomDomIpt] = useState(true);
    const [isDomainWaiting, setIsDomainWaiting] = useState(true);

    const regDomainName = new RegExp(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/)

    useEffect(() => {
        getDomain()
    }, [])

    const fetchDomain = (method, url, data) => {

        hitApi({
            url: url,
            method: method,
            data: data,
            alert: false
        }, (resp) => {
            if (resp.status === 1) {
                if (resp.data.domain_type) {
                    (resp.data.domain_type == 1) ? setDomainRadioActive('sub_domain') : setDomainRadioActive('custom_domain');
                    if (resp.data.domain_type === 1) {
                        if(resp.data.domain_name) setDomainName(resp.data.domain_name.split('.')[0]);
                        // setIsVerify(true)
                        setIsConnected(false);
                        setCustomDomIpt(true);
                        setCurrDomain(resp.data.domain_name);
                    } else {
                        setCustomDomIpt(false);
                        setCustomDomainName(resp.data.domain_name);
                        setCurrDomain(resp.data.domain_name);
                        if (resp.data.domain_verified) {
                            setIsConnected(true);

                            setIsDomainWaiting(false);
                        } else {

                            setIsConnected(true);

                            setIsDomainWaiting(true);
                        }
                    }
                }
                setIsEdit(false)
                setIsError(false)
            } else {
                setIsError(true);
            }
        });

    }

    const getDomain = () => {
        let url, data;
        // if (domainfor != 'project') {
        url = 'pixa-support/api/getUserDetails?user_id=' + userData.userId;
        fetchDomain('GET', url, '')
        // } else {
        //     url = 'pixa-support/api/project_details';
        //     data = { project_id: p_id, };
        //     if (p_id) fetchDomain('POST', url, data)
        // }
    }


    const addDomain = (e) => {
        e.preventDefault();
        if (activeType === 'Sub Domain') {

            const urlname = new URL(appConfig.appUrl);
            const isDomainName = regDomainName.test(domainName);
            if (!domainName) {
                commonToast('error', `Field can not be empty!`);
                return false;
            } else if (isDomainName) {
                commonToast('error', `Enter valid domain name!`);
                return false;
            } else {
                let url, data;
                // if (domainfor != 'project') {
                // url = 'pixa-support/api/update_agency_domain';
                // data = {
                //     'domain_name': domainName + "." + urlname.hostname,
                //     'domain_type': 1
                // }

                // } else {
                url = 'pixa-support/api/update_Project_Domain';
                data = {
                    'domain_name': domainName + "." + urlname.hostname,
                    'domain_type': 1,
                    project_id: p_id,
                    user_id: userData.userId
                };

                // }
                hitApi({
                    url: url,
                    method: 'POST',
                    data: data,
                    alert: true
                }, (resp) => {
                    if (resp.status === 1) {
                    setActiveTabMenu('mail')


                        getDomain();
                        setCustomDomainName('');
                        setCurrDomain(resp.data.domain_name.split('.')[0])
                    }
                });

            }
        }
        if (activeType === 'Custom Domain') {
            if (currDomain != customDomainName) {
                const customdomainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

                // const isDomainName = validator.isURL(customDomainName, { require_protocol: false });
                const isCusDomainName = customdomainRegex.test(customDomainName);

                if (!customDomainName) {
                    commonToast('error', `Field can not be empty!`);
                    return false;
                    // } 
                    // else if (!isDomainName) {
                    //     commonToast('error', `Enter valid domain name!`);
                    //     return false;
                } else if (!isCusDomainName) {
                    commonToast('error', `Enter valid domain name!`);
                    return false;
                } else {
                    // setShowVerifyTable(true)
                    let url, data;
                    // if (domainfor != 'project') {
                    //     url = 'pixa-support/api/update_agency_domain';
                    //     data = {
                    //         'domain_name': customDomainName,
                    //         'domain_type': 2
                    //     }
                    // } else {
                    url = 'pixa-support/api/update_Project_Domain';
                    data = {
                        'domain_name': customDomainName,
                        'domain_type': 2,
                        project_id: p_id,
                        user_id: userData.userId
                    };

                    // }
                    hitApi({
                        url: url,
                        method: 'POST',
                        data: data,
                        alert: true
                    }, (resp) => {
                        if (resp.status == 1) {
                            setDomainName('')
                            setCustomDomIpt(false)
                            setCurrDomain(resp.data.domain_name)
                            setIsConnected(true);
                            setIsDomainWaiting(true)
                            setActiveTabMenu('mail')


                        }
                    });

                }
            }
        }
    }


    const VerifyCustomDOmain = () => {

        fetch(`https://${customDomainName}/`, { mode: 'no-cors' }).then(async result => {
            let data = { 'custom_domain': customDomainName, };
            // if (domainfor != 'project') {
            //     data["agency_id"] = userData.userId
            // } else {
            data["project_id"] = p_id
            // }
            hitApi({
                url: 'pixa-support/api/Verify_custom_domain',
                method: 'POST',
                data: data,
                alert: true
            }, (resp) => {
                if (resp.status == 1) {
                    setIsConnected(true)
                    setIsDomainWaiting(false)
                }
            });

        }).catch(err => {
            setIsConnected(true)

            setIsDomainWaiting(true)
            commonToast('error', `Domain verification is pending!`);
        })
    }

    const radioBtnSubDomain = () => {

        setDomainRadioActive('sub_domain');
        if (currDomain == customDomainName && customDomainName) {

            setIsConnected(true);
            setCustomDomIpt(false)

        }
    }


    return (


        <div className='proj_domai_set_form'>
            <div className='sup_proj_setting_form'>
                <h4 className='proj_main_head'>Project Domain Settings</h4>
                <div className='mt-3 mb-3'>
                    <div className="cun-radiobox-wrapper">
                        {['Sub Domain', 'Custom Domain'].map((option, i) => (
                            <div key={i} className="cun-custom-radioBtn">
                                <input type="radio" id={option} value={activeType} checked={activeType === option} name={'media_type'} onChange={(e) => setActiveTab(option)} />
                                <label htmlFor={option}>{option}</label>
                            </div>
                        ))
                        }
                    </div>
                </div>

                {(activeType == 'Sub Domain') && <form onSubmit={addDomain}>

                    <div className='domain_wrapper'>
                        <h4>Connected Domain</h4>
                        <p>Please enter your domain above without any www, http or https (for example, mydomain.com OR support.mydomain.com).</p>
                        <div className="pxl_input_feilds">
                            <input type="text" name="" placeholder="support.pixalab.com" value={domainName} onChange={(e) => setDomainName(e.target.value)} />
                        </div>
                        <button className='sch-btn' type='submit' disabled={(currDomain != domainName && domainName) ? false : true}>Save</button>
                    </div></form>}

                {(activeType == 'Custom Domain') &&  <> 
                
                {(showCustomDomIpt) && <form onSubmit={addDomain}>
                    <div className='domain_wrapper'>
                        <h4>Setup Domain</h4>
                        <p>Enter sub domain</p>
                        <div className='custom_domain_inp_wrap'>
                            <div className="pxl_input_feilds">
                                <input type="text" name="" placeholder="Enter Domain" value={customDomainName} onChange={(e) => setCustomDomainName(e.target.value)} />
                            </div>
                            <div className='domain_inp_second_div'>.pixasupport.com</div>
                        </div>

                        <button disabled={(currDomain != customDomainName && customDomainName) ? false : true}  className='sch-btn'>{isEdit ? "Update" : "Add"}</button>
                    </div>
                </form> }
                {isConnected ?
                                <div className="custom_connected_wrap">
                                    <h3 className="section_heading">Connected Domain</h3>
                                    <p className='section_heading_p'>Please enter your domain above without any www, http or https (for example, mydomain.com OR
                                        support.mydomain.com).</p>
                                    <div className="connected_dom_wrap">
                                        {/* <div className="check_section"> */}
                                            <span>{isDomainWaiting ? svg.domain_wait : svg.domain_check}</span>
                                            <h3>{customDomainName}sadsad</h3>
                                        {/* </div> */}

                                    </div>
                                    {isDomainWaiting && <p><b>Note</b> - When you added the above records for the domain, it may take up to 24-48 hours for the change to take effect.</p>}
                                    {isDomainWaiting ? <div className='flex-btn-wrap'><button className="sch-btn" onClick={VerifyCustomDOmain}>Verify</button>
                                    <div className="edit_btn ml_5" onClick={() => { setIsEdit(true); setCustomDomIpt(true); setIsConnected(false) }}>Edit</div>
                                    </div>
                                        : <div className="edit_btn mt-3" onClick={() => { setIsEdit(true); setCustomDomIpt(true); setIsConnected(false) }}>Edit</div>
                                    }
                                </div>
                                 : null}
                
                
                </>}
                
            </div>

        </div>



    )
}

export default ProjectDomainSetting