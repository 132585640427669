import { useState,useEffect } from 'react'
import HomePage from './LandingPage/HomePage';
import CallForAction from './LandingPage/CallForAction';
import { hitApi } from '../../../components/commonAction';
import FeatureSection from './LandingPage/FeatureSection';
import FAQSection from './LandingPage/FAQSection';

function ProjectLandingPage({p_id,setActiveTabMenu}) {
    const [landingPageData, setlandingPageData] = useState({});
    const [isError, setIsError] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    
    // const projectName = useSelector(store => store.common.projectName);
    // const userData = useSelector(store => store.userData);

    useEffect(() => {
        // if (userData.role == 1)
             fetchLandingPageConent();
    }, []);

    const fetchLandingPageConent = (id) => {
        if (id) {
            hitApi({
                url: 'pixa-support/getLandingPage',
                method: "POST",
                data: {
                    project_id: id
                },
                loading: false,
                alert: false,
                
            }, (resp, err = null) => {
                if (resp.status === 1) {
                    setlandingPageData(resp)
                    setIsError(false)
                }else{
                    setIsError(true)
                }
            });
            
        }
    }
    return (
        <div className='sup_proj_setting_form landing_page_sup'>

            <h4 className='proj_main_head'>Landing Page Content</h4>

            <div className='proj_Setting_first_flex'>
                <div className='max-402'>
                   <HomePage p_id={p_id} homeData={landingPageData.homePage} />
                </div>

                <div className='max-402'>
                    <h4>Features Section</h4>
                    <FeatureSection  p_id={p_id}  features={landingPageData.featurePage} />
                </div>

                <div className='max-402'>
                    <h4>FAQ Section</h4>
                    <FAQSection p_id={p_id} faq={landingPageData.FAQ}/>
                </div>

                <div className='max-402'>
                   <CallForAction p_id={p_id} callActions={landingPageData.footerPage}/>
                </div>
            </div>

            <button className="sch-btn mt-3"> {btnLoading ? "Processing..." : 'Save & Continue'}</button>
            <button className="" onClick={()=>setActiveTabMenu('mail')}> Back</button>
           
        </div>
    )
}

export default ProjectLandingPage