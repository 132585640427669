
export const fonts = [
    {
        "value": "Cookie",
        "label": "Cookie"
    },
    {
        "value": "Amita",
        "label": "Amita"
    },
    {
        "value": "Bungee",
        "label": "Bungee"
    },
    {
        "value": "Atomic Age",
        "label": "Atomic Age"
    },
    {
        "value": "Actor",
        "label": "Actor"
    },
    {
        "value": "Bilbo Swash Caps",
        "label": "Bilbo Swash Caps"
    },
    {
        "value": "Anek Odia",
        "label": "Anek Odia"
    },
    {
        "value": "Baloo Tamma 2",
        "label": "Baloo Tamma 2"
    },
    {
        "value": "Aldrich",
        "label": "Aldrich"
    },
    {
        "value": "Bad Script",
        "label": "Bad Script"
    },
    {
        "value": "Bowlby One",
        "label": "Bowlby One"
    },
    {
        "value": "Akronim",
        "label": "Akronim"
    },
    {
        "value": "Albert Sans",
        "label": "Albert Sans"
    },
    {
        "value": "Bevan",
        "label": "Bevan"
    },
    {
        "value": "Big Shoulders Text",
        "label": "Big Shoulders Text"
    },
    {
        "value": "Aclonica",
        "label": "Aclonica"
    },
    {
        "value": "Cagliostro",
        "label": "Cagliostro"
    },
    {
        "value": "Average Sans",
        "label": "Average Sans"
    },
    {
        "value": "Bebas Neue",
        "label": "Bebas Neue"
    },
    {
        "value": "Carrois Gothic",
        "label": "Carrois Gothic"
    },
    {
        "value": "Birthstone",
        "label": "Birthstone"
    },
    {
        "value": "Farsan",
        "label": "Farsan"
    },
    {
        "value": "Adamina",
        "label": "Adamina"
    },
    {
        "value": "Alegreya Sans",
        "label": "Alegreya Sans"
    },
    {
        "value": "Anonymous Pro",
        "label": "Anonymous Pro"
    },
    {
        "value": "Bellota Text",
        "label": "Bellota Text"
    },
    {
        "value": "Blaka",
        "label": "Blaka"
    },
    {
        "value": "Charmonman",
        "label": "Charmonman"
    },
    {
        "value": "Climate Crisis",
        "label": "Climate Crisis"
    },
    {
        "value": "Cuprum",
        "label": "Cuprum"
    },
    {
        "value": "Belgrano",
        "label": "Belgrano"
    },
    {
        "value": "BioRhyme",
        "label": "BioRhyme"
    },
    {
        "value": "Expletus Sans",
        "label": "Expletus Sans"
    },
    {
        "value": "ABeeZee",
        "label": "ABeeZee"
    },
    {
        "value": "Cantata One",
        "label": "Cantata One"
    },
    {
        "value": "Brygada 1918",
        "label": "Brygada 1918"
    },
    {
        "value": "Acme",
        "label": "Acme"
    },
    {
        "value": "Babylonica",
        "label": "Babylonica"
    },
    {
        "value": "Baloo Bhaijaan 2",
        "label": "Baloo Bhaijaan 2"
    },
    {
        "value": "Crushed",
        "label": "Crushed"
    },
    {
        "value": "DM Sans",
        "label": "DM Sans"
    },
    {
        "value": "Cherish",
        "label": "Cherish"
    },
    {
        "value": "Boogaloo",
        "label": "Boogaloo"
    },
    {
        "value": "Englebert",
        "label": "Englebert"
    },
    {
        "value": "Alkalami",
        "label": "Alkalami"
    },
    {
        "value": "Ballet",
        "label": "Ballet"
    },
    {
        "value": "Chelsea Market",
        "label": "Chelsea Market"
    },
    {
        "value": "Black And White Picture",
        "label": "Black And White Picture"
    },
    {
        "value": "Chathura",
        "label": "Chathura"
    },
    {
        "value": "Caveat",
        "label": "Caveat"
    },
    {
        "value": "Allan",
        "label": "Allan"
    },
    {
        "value": "Belleza",
        "label": "Belleza"
    },
    {
        "value": "Big Shoulders Stencil Text",
        "label": "Big Shoulders Stencil Text"
    },
    {
        "value": "Bokor",
        "label": "Bokor"
    },
    {
        "value": "Baloo Da 2",
        "label": "Baloo Da 2"
    },
    {
        "value": "Castoro",
        "label": "Castoro"
    },
    {
        "value": "Clicker Script",
        "label": "Clicker Script"
    },
    {
        "value": "Comforter",
        "label": "Comforter"
    },
    {
        "value": "Alegreya SC",
        "label": "Alegreya SC"
    },
    {
        "value": "Fasthand",
        "label": "Fasthand"
    },
    {
        "value": "Alumni Sans",
        "label": "Alumni Sans"
    },
    {
        "value": "Carter One",
        "label": "Carter One"
    },
    {
        "value": "Averia Libre",
        "label": "Averia Libre"
    },
    {
        "value": "Brawler",
        "label": "Brawler"
    },
    {
        "value": "Athiti",
        "label": "Athiti"
    },
    {
        "value": "Edu TAS Beginner",
        "label": "Edu TAS Beginner"
    },
    {
        "value": "Anek Latin",
        "label": "Anek Latin"
    },
    {
        "value": "Barlow Semi Condensed",
        "label": "Barlow Semi Condensed"
    },
    {
        "value": "Bahianita",
        "label": "Bahianita"
    },
    {
        "value": "Diplomata",
        "label": "Diplomata"
    },
    {
        "value": "Estonia",
        "label": "Estonia"
    },
    {
        "value": "DotGothic16",
        "label": "DotGothic16"
    },
    {
        "value": "Elsie",
        "label": "Elsie"
    },
    {
        "value": "Barlow Condensed",
        "label": "Barlow Condensed"
    },
    {
        "value": "Copse",
        "label": "Copse"
    },
    {
        "value": "Delius",
        "label": "Delius"
    },
    {
        "value": "Cairo",
        "label": "Cairo"
    },
    {
        "value": "Crimson Text",
        "label": "Crimson Text"
    },
    {
        "value": "Days One",
        "label": "Days One"
    },
    {
        "value": "BioRhyme Expanded",
        "label": "BioRhyme Expanded"
    },
    {
        "value": "Bona Nova",
        "label": "Bona Nova"
    },
    {
        "value": "Chewy",
        "label": "Chewy"
    },
    {
        "value": "Aleo",
        "label": "Aleo"
    },
    {
        "value": "Corben",
        "label": "Corben"
    },
    {
        "value": "Covered By Your Grace",
        "label": "Covered By Your Grace"
    },
    {
        "value": "Baloo Bhai 2",
        "label": "Baloo Bhai 2"
    },
    {
        "value": "Arbutus Slab",
        "label": "Arbutus Slab"
    },
    {
        "value": "Baloo Thambi 2",
        "label": "Baloo Thambi 2"
    },
    {
        "value": "Anek Telugu",
        "label": "Anek Telugu"
    },
    {
        "value": "Aubrey",
        "label": "Aubrey"
    },
    {
        "value": "Big Shoulders Inline Text",
        "label": "Big Shoulders Inline Text"
    },
    {
        "value": "Cormorant Infant",
        "label": "Cormorant Infant"
    },
    {
        "value": "Alumni Sans Collegiate One",
        "label": "Alumni Sans Collegiate One"
    },
    {
        "value": "Cinzel",
        "label": "Cinzel"
    },
    {
        "value": "Architects Daughter",
        "label": "Architects Daughter"
    },
    {
        "value": "Aboreto",
        "label": "Aboreto"
    },
    {
        "value": "Carattere",
        "label": "Carattere"
    },
    {
        "value": "Angkor",
        "label": "Angkor"
    },
    {
        "value": "Encode Sans Expanded",
        "label": "Encode Sans Expanded"
    },
    {
        "value": "Capriola",
        "label": "Capriola"
    },
    {
        "value": "Caudex",
        "label": "Caudex"
    },
    {
        "value": "Fascinate",
        "label": "Fascinate"
    },
    {
        "value": "Delius Swash Caps",
        "label": "Delius Swash Caps"
    },
    {
        "value": "Charis SIL",
        "label": "Charis SIL"
    },
    {
        "value": "Dhurjati",
        "label": "Dhurjati"
    },
    {
        "value": "Cormorant Unicase",
        "label": "Cormorant Unicase"
    },
    {
        "value": "Bonheur Royale",
        "label": "Bonheur Royale"
    },
    {
        "value": "Dr Sugiyama",
        "label": "Dr Sugiyama"
    },
    {
        "value": "DM Serif Display",
        "label": "DM Serif Display"
    },
    {
        "value": "Abril Fatface",
        "label": "Abril Fatface"
    },
    {
        "value": "Akshar",
        "label": "Akshar"
    },
    {
        "value": "Aguafina Script",
        "label": "Aguafina Script"
    },
    {
        "value": "Anek Devanagari",
        "label": "Anek Devanagari"
    },
    {
        "value": "Cairo Play",
        "label": "Cairo Play"
    },
    {
        "value": "Averia Sans Libre",
        "label": "Averia Sans Libre"
    },
    {
        "value": "Cantora One",
        "label": "Cantora One"
    },
    {
        "value": "Bungee Inline",
        "label": "Bungee Inline"
    },
    {
        "value": "Crete Round",
        "label": "Crete Round"
    },
    {
        "value": "Bungee Outline",
        "label": "Bungee Outline"
    },
    {
        "value": "DynaPuff",
        "label": "DynaPuff"
    },
    {
        "value": "Alike Angular",
        "label": "Alike Angular"
    },
    {
        "value": "Donegal One",
        "label": "Donegal One"
    },
    {
        "value": "Alex Brush",
        "label": "Alex Brush"
    },
    {
        "value": "Ephesis",
        "label": "Ephesis"
    },
    {
        "value": "Amethysta",
        "label": "Amethysta"
    },
    {
        "value": "Anek Gujarati",
        "label": "Anek Gujarati"
    },
    {
        "value": "Bodoni Moda",
        "label": "Bodoni Moda"
    },
    {
        "value": "Cabin",
        "label": "Cabin"
    },
    {
        "value": "Baloo Chettan 2",
        "label": "Baloo Chettan 2"
    },
    {
        "value": "BenchNine",
        "label": "BenchNine"
    },
    {
        "value": "Autour One",
        "label": "Autour One"
    },
    {
        "value": "Anek Bangla",
        "label": "Anek Bangla"
    },
    {
        "value": "Arimo",
        "label": "Arimo"
    },
    {
        "value": "Bowlby One SC",
        "label": "Bowlby One SC"
    },
    {
        "value": "Fascinate Inline",
        "label": "Fascinate Inline"
    },
    {
        "value": "Bellota",
        "label": "Bellota"
    },
    {
        "value": "Cherry Cream Soda",
        "label": "Cherry Cream Soda"
    },
    {
        "value": "Berkshire Swash",
        "label": "Berkshire Swash"
    },
    {
        "value": "Didact Gothic",
        "label": "Didact Gothic"
    },
    {
        "value": "Big Shoulders Display",
        "label": "Big Shoulders Display"
    },
    {
        "value": "Cherry Swash",
        "label": "Cherry Swash"
    },
    {
        "value": "Crafty Girls",
        "label": "Crafty Girls"
    },
    {
        "value": "Fahkwang",
        "label": "Fahkwang"
    },
    {
        "value": "Condiment",
        "label": "Condiment"
    },
    {
        "value": "Emblema One",
        "label": "Emblema One"
    },
    {
        "value": "Alata",
        "label": "Alata"
    },
    {
        "value": "Dancing Script",
        "label": "Dancing Script"
    },
    {
        "value": "Devonshire",
        "label": "Devonshire"
    },
    {
        "value": "Caladea",
        "label": "Caladea"
    },
    {
        "value": "Cantarell",
        "label": "Cantarell"
    },
    {
        "value": "Encode Sans SC",
        "label": "Encode Sans SC"
    },
    {
        "value": "Doppio One",
        "label": "Doppio One"
    },
    {
        "value": "Asap",
        "label": "Asap"
    },
    {
        "value": "Besley",
        "label": "Besley"
    },
    {
        "value": "Cambay",
        "label": "Cambay"
    },
    {
        "value": "Coming Soon",
        "label": "Coming Soon"
    },
    {
        "value": "Creepster",
        "label": "Creepster"
    },
    {
        "value": "Baloo Bhaina 2",
        "label": "Baloo Bhaina 2"
    },
    {
        "value": "Encode Sans Condensed",
        "label": "Encode Sans Condensed"
    },
    {
        "value": "Cormorant",
        "label": "Cormorant"
    },
    {
        "value": "Faustina",
        "label": "Faustina"
    },
    {
        "value": "Archivo Narrow",
        "label": "Archivo Narrow"
    },
    {
        "value": "Cutive Mono",
        "label": "Cutive Mono"
    },
    {
        "value": "Bitter",
        "label": "Bitter"
    },
    {
        "value": "Cabin Sketch",
        "label": "Cabin Sketch"
    },
    {
        "value": "Are You Serious",
        "label": "Are You Serious"
    },
    {
        "value": "Basic",
        "label": "Basic"
    },
    {
        "value": "Black Han Sans",
        "label": "Black Han Sans"
    },
    {
        "value": "Annie Use Your Telescope",
        "label": "Annie Use Your Telescope"
    },
    {
        "value": "Bayon",
        "label": "Bayon"
    },
    {
        "value": "Anaheim",
        "label": "Anaheim"
    },
    {
        "value": "Chau Philomene One",
        "label": "Chau Philomene One"
    },
    {
        "value": "Buda",
        "label": "Buda"
    },
    {
        "value": "Catamaran",
        "label": "Catamaran"
    },
    {
        "value": "Averia Serif Libre",
        "label": "Averia Serif Libre"
    },
    {
        "value": "Chela One",
        "label": "Chela One"
    },
    {
        "value": "Do Hyeon",
        "label": "Do Hyeon"
    },
    {
        "value": "Amiko",
        "label": "Amiko"
    },
    {
        "value": "Amiri",
        "label": "Amiri"
    },
    {
        "value": "Assistant",
        "label": "Assistant"
    },
    {
        "value": "Amiri Quran",
        "label": "Amiri Quran"
    },
    {
        "value": "Eater",
        "label": "Eater"
    },
    {
        "value": "Courier Prime",
        "label": "Courier Prime"
    },
    {
        "value": "Baumans",
        "label": "Baumans"
    },
    {
        "value": "Chakra Petch",
        "label": "Chakra Petch"
    },
    {
        "value": "Encode Sans Semi Expanded",
        "label": "Encode Sans Semi Expanded"
    },
    {
        "value": "Barrio",
        "label": "Barrio"
    },
    {
        "value": "Dorsa",
        "label": "Dorsa"
    },
    {
        "value": "Edu QLD Beginner",
        "label": "Edu QLD Beginner"
    },
    {
        "value": "Antic Didone",
        "label": "Antic Didone"
    },
    {
        "value": "Archivo Black",
        "label": "Archivo Black"
    },
    {
        "value": "Aref Ruqaa",
        "label": "Aref Ruqaa"
    },
    {
        "value": "Arima Madurai",
        "label": "Arima Madurai"
    },
    {
        "value": "Bungee Hairline",
        "label": "Bungee Hairline"
    },
    {
        "value": "Cardo",
        "label": "Cardo"
    },
    {
        "value": "Federo",
        "label": "Federo"
    },
    {
        "value": "Caesar Dressing",
        "label": "Caesar Dressing"
    },
    {
        "value": "Bentham",
        "label": "Bentham"
    },
    {
        "value": "Dokdo",
        "label": "Dokdo"
    },
    {
        "value": "B612 Mono",
        "label": "B612 Mono"
    },
    {
        "value": "Engagement",
        "label": "Engagement"
    },
    {
        "value": "Advent Pro",
        "label": "Advent Pro"
    },
    {
        "value": "Content",
        "label": "Content"
    },
    {
        "value": "Anybody",
        "label": "Anybody"
    },
    {
        "value": "Archivo",
        "label": "Archivo"
    },
    {
        "value": "Duru Sans",
        "label": "Duru Sans"
    },
    {
        "value": "Anek Gurmukhi",
        "label": "Anek Gurmukhi"
    },
    {
        "value": "Alegreya Sans SC",
        "label": "Alegreya Sans SC"
    },
    {
        "value": "Bangers",
        "label": "Bangers"
    },
    {
        "value": "Anek Kannada",
        "label": "Anek Kannada"
    },
    {
        "value": "Bungee Shade",
        "label": "Bungee Shade"
    },
    {
        "value": "Contrail One",
        "label": "Contrail One"
    },
    {
        "value": "Chivo",
        "label": "Chivo"
    },
    {
        "value": "EB Garamond",
        "label": "EB Garamond"
    },
    {
        "value": "Changa One",
        "label": "Changa One"
    },
    {
        "value": "Edu SA Beginner",
        "label": "Edu SA Beginner"
    },
    {
        "value": "Bai Jamjuree",
        "label": "Bai Jamjuree"
    },
    {
        "value": "Fauna One",
        "label": "Fauna One"
    },
    {
        "value": "Asap Condensed",
        "label": "Asap Condensed"
    },
    {
        "value": "Combo",
        "label": "Combo"
    },
    {
        "value": "Cormorant SC",
        "label": "Cormorant SC"
    },
    {
        "value": "Cute Font",
        "label": "Cute Font"
    },
    {
        "value": "Domine",
        "label": "Domine"
    },
    {
        "value": "Bilbo",
        "label": "Bilbo"
    },
    {
        "value": "Butterfly Kids",
        "label": "Butterfly Kids"
    },
    {
        "value": "Barriecito",
        "label": "Barriecito"
    },
    {
        "value": "El Messiri",
        "label": "El Messiri"
    },
    {
        "value": "Azeret Mono",
        "label": "Azeret Mono"
    },
    {
        "value": "David Libre",
        "label": "David Libre"
    },
    {
        "value": "Economica",
        "label": "Economica"
    },
    {
        "value": "Erica One",
        "label": "Erica One"
    },
    {
        "value": "Andika",
        "label": "Andika"
    },
    {
        "value": "Chango",
        "label": "Chango"
    },
    {
        "value": "Codystar",
        "label": "Codystar"
    },
    {
        "value": "Crimson Pro",
        "label": "Crimson Pro"
    },
    {
        "value": "Akaya Kanadaka",
        "label": "Akaya Kanadaka"
    },
    {
        "value": "Arvo",
        "label": "Arvo"
    },
    {
        "value": "Caramel",
        "label": "Caramel"
    },
    {
        "value": "Big Shoulders Inline Display",
        "label": "Big Shoulders Inline Display"
    },
    {
        "value": "Dekko",
        "label": "Dekko"
    },
    {
        "value": "Almendra Display",
        "label": "Almendra Display"
    },
    {
        "value": "Blaka Ink",
        "label": "Blaka Ink"
    },
    {
        "value": "Amatic SC",
        "label": "Amatic SC"
    },
    {
        "value": "Alexandria",
        "label": "Alexandria"
    },
    {
        "value": "Comforter Brush",
        "label": "Comforter Brush"
    },
    {
        "value": "Almendra",
        "label": "Almendra"
    },
    {
        "value": "Antonio",
        "label": "Antonio"
    },
    {
        "value": "Carrois Gothic SC",
        "label": "Carrois Gothic SC"
    },
    {
        "value": "Coda Caption",
        "label": "Coda Caption"
    },
    {
        "value": "Commissioner",
        "label": "Commissioner"
    },
    {
        "value": "Balsamiq Sans",
        "label": "Balsamiq Sans"
    },
    {
        "value": "Bahiana",
        "label": "Bahiana"
    },
    {
        "value": "Beth Ellen",
        "label": "Beth Ellen"
    },
    {
        "value": "Denk One",
        "label": "Denk One"
    },
    {
        "value": "Encode Sans",
        "label": "Encode Sans"
    },
    {
        "value": "Andada Pro",
        "label": "Andada Pro"
    },
    {
        "value": "Big Shoulders Stencil Display",
        "label": "Big Shoulders Stencil Display"
    },
    {
        "value": "Bellefair",
        "label": "Bellefair"
    },
    {
        "value": "Averia Gruesa Libre",
        "label": "Averia Gruesa Libre"
    },
    {
        "value": "Chicle",
        "label": "Chicle"
    },
    {
        "value": "Arsenal",
        "label": "Arsenal"
    },
    {
        "value": "BIZ UDPGothic",
        "label": "BIZ UDPGothic"
    },
    {
        "value": "Calistoga",
        "label": "Calistoga"
    },
    {
        "value": "Calligraffitti",
        "label": "Calligraffitti"
    },
    {
        "value": "Cutive",
        "label": "Cutive"
    },
    {
        "value": "Euphoria Script",
        "label": "Euphoria Script"
    },
    {
        "value": "Elsie Swash Caps",
        "label": "Elsie Swash Caps"
    },
    {
        "value": "Allerta",
        "label": "Allerta"
    },
    {
        "value": "Convergence",
        "label": "Convergence"
    },
    {
        "value": "Candal",
        "label": "Candal"
    },
    {
        "value": "Chenla",
        "label": "Chenla"
    },
    {
        "value": "Dongle",
        "label": "Dongle"
    },
    {
        "value": "Antic Slab",
        "label": "Antic Slab"
    },
    {
        "value": "DM Mono",
        "label": "DM Mono"
    },
    {
        "value": "Ewert",
        "label": "Ewert"
    },
    {
        "value": "Anek Tamil",
        "label": "Anek Tamil"
    },
    {
        "value": "Cabin Condensed",
        "label": "Cabin Condensed"
    },
    {
        "value": "Caveat Brush",
        "label": "Caveat Brush"
    },
    {
        "value": "Electrolize",
        "label": "Electrolize"
    },
    {
        "value": "Akaya Telivigala",
        "label": "Akaya Telivigala"
    },
    {
        "value": "Alegreya",
        "label": "Alegreya"
    },
    {
        "value": "Battambang",
        "label": "Battambang"
    },
    {
        "value": "Explora",
        "label": "Explora"
    },
    {
        "value": "Federant",
        "label": "Federant"
    },
    {
        "value": "Artifika",
        "label": "Artifika"
    },
    {
        "value": "Anek Malayalam",
        "label": "Anek Malayalam"
    },
    {
        "value": "Blaka Hollow",
        "label": "Blaka Hollow"
    },
    {
        "value": "Enriqueta",
        "label": "Enriqueta"
    },
    {
        "value": "Arizonia",
        "label": "Arizonia"
    },
    {
        "value": "East Sea Dokdo",
        "label": "East Sea Dokdo"
    },
    {
        "value": "Farro",
        "label": "Farro"
    },
    {
        "value": "Charm",
        "label": "Charm"
    },
    {
        "value": "Darker Grotesque",
        "label": "Darker Grotesque"
    },
    {
        "value": "Edu VIC WA NT Beginner",
        "label": "Edu VIC WA NT Beginner"
    },
    {
        "value": "BhuTuka Expanded One",
        "label": "BhuTuka Expanded One"
    },
    {
        "value": "Bubblegum Sans",
        "label": "Bubblegum Sans"
    },
    {
        "value": "Alike",
        "label": "Alike"
    },
    {
        "value": "Be Vietnam Pro",
        "label": "Be Vietnam Pro"
    },
    {
        "value": "Coiny",
        "label": "Coiny"
    },
    {
        "value": "Arya",
        "label": "Arya"
    },
    {
        "value": "Benne",
        "label": "Benne"
    },
    {
        "value": "Dela Gothic One",
        "label": "Dela Gothic One"
    },
    {
        "value": "Bree Serif",
        "label": "Bree Serif"
    },
    {
        "value": "Croissant One",
        "label": "Croissant One"
    },
    {
        "value": "Emilys Candy",
        "label": "Emilys Candy"
    },
    {
        "value": "Baskervville",
        "label": "Baskervville"
    },
    {
        "value": "Aladin",
        "label": "Aladin"
    },
    {
        "value": "Baloo Tammudu 2",
        "label": "Baloo Tammudu 2"
    },
    {
        "value": "Blinker",
        "label": "Blinker"
    },
    {
        "value": "Edu NSW ACT Foundation",
        "label": "Edu NSW ACT Foundation"
    },
    {
        "value": "Damion",
        "label": "Damion"
    },
    {
        "value": "Allura",
        "label": "Allura"
    },
    {
        "value": "Armata",
        "label": "Armata"
    },
    {
        "value": "Audiowide",
        "label": "Audiowide"
    },
    {
        "value": "Carme",
        "label": "Carme"
    },
    {
        "value": "Changa",
        "label": "Changa"
    },
    {
        "value": "Epilogue",
        "label": "Epilogue"
    },
    {
        "value": "Anton",
        "label": "Anton"
    },
    {
        "value": "Coustard",
        "label": "Coustard"
    },
    {
        "value": "Abel",
        "label": "Abel"
    },
    {
        "value": "Della Respira",
        "label": "Della Respira"
    },
    {
        "value": "Eagle Lake",
        "label": "Eagle Lake"
    },
    {
        "value": "Cinzel Decorative",
        "label": "Cinzel Decorative"
    },
    {
        "value": "Dangrek",
        "label": "Dangrek"
    },
    {
        "value": "Arbutus",
        "label": "Arbutus"
    },
    {
        "value": "Diplomata SC",
        "label": "Diplomata SC"
    },
    {
        "value": "Aref Ruqaa Ink",
        "label": "Aref Ruqaa Ink"
    },
    {
        "value": "Ceviche One",
        "label": "Ceviche One"
    },
    {
        "value": "Cousine",
        "label": "Cousine"
    },
    {
        "value": "Alatsi",
        "label": "Alatsi"
    },
    {
        "value": "Alumni Sans Pinstripe",
        "label": "Alumni Sans Pinstripe"
    },
    {
        "value": "BIZ UDGothic",
        "label": "BIZ UDGothic"
    },
    {
        "value": "Alfa Slab One",
        "label": "Alfa Slab One"
    },
    {
        "value": "Cambo",
        "label": "Cambo"
    },
    {
        "value": "Courgette",
        "label": "Courgette"
    },
    {
        "value": "Almarai",
        "label": "Almarai"
    },
    {
        "value": "Asul",
        "label": "Asul"
    },
    {
        "value": "Amarante",
        "label": "Amarante"
    },
    {
        "value": "Alice",
        "label": "Alice"
    },
    {
        "value": "Cedarville Cursive",
        "label": "Cedarville Cursive"
    },
    {
        "value": "Comic Neue",
        "label": "Comic Neue"
    },
    {
        "value": "Biryani",
        "label": "Biryani"
    },
    {
        "value": "Concert One",
        "label": "Concert One"
    },
    {
        "value": "Antic",
        "label": "Antic"
    },
    {
        "value": "Beau Rivage",
        "label": "Beau Rivage"
    },
    {
        "value": "Asar",
        "label": "Asar"
    },
    {
        "value": "Alef",
        "label": "Alef"
    },
    {
        "value": "Chilanka",
        "label": "Chilanka"
    },
    {
        "value": "Abhaya Libre",
        "label": "Abhaya Libre"
    },
    {
        "value": "BIZ UDPMincho",
        "label": "BIZ UDPMincho"
    },
    {
        "value": "Astloch",
        "label": "Astloch"
    },
    {
        "value": "Bigelow Rules",
        "label": "Bigelow Rules"
    },
    {
        "value": "Bungee Spice",
        "label": "Bungee Spice"
    },
    {
        "value": "Dosis",
        "label": "Dosis"
    },
    {
        "value": "Bubbler One",
        "label": "Bubbler One"
    },
    {
        "value": "Dawning of a New Day",
        "label": "Dawning of a New Day"
    },
    {
        "value": "Amaranth",
        "label": "Amaranth"
    },
    {
        "value": "Barlow",
        "label": "Barlow"
    },
    {
        "value": "Bigshot One",
        "label": "Bigshot One"
    },
    {
        "value": "Comfortaa",
        "label": "Comfortaa"
    },
    {
        "value": "Cormorant Garamond",
        "label": "Cormorant Garamond"
    },
    {
        "value": "Alumni Sans Inline One",
        "label": "Alumni Sans Inline One"
    },
    {
        "value": "Asset",
        "label": "Asset"
    },
    {
        "value": "Chivo Mono",
        "label": "Chivo Mono"
    },
    {
        "value": "Exo",
        "label": "Exo"
    },
    {
        "value": "Atkinson Hyperlegible",
        "label": "Atkinson Hyperlegible"
    },
    {
        "value": "Balthazar",
        "label": "Balthazar"
    },
    {
        "value": "Butcherman",
        "label": "Butcherman"
    },
    {
        "value": "DM Serif Text",
        "label": "DM Serif Text"
    },
    {
        "value": "Esteban",
        "label": "Esteban"
    },
    {
        "value": "Felipa",
        "label": "Felipa"
    },
    {
        "value": "Arapey",
        "label": "Arapey"
    },
    {
        "value": "Bakbak One",
        "label": "Bakbak One"
    },
    {
        "value": "Buenard",
        "label": "Buenard"
    },
    {
        "value": "Allerta Stencil",
        "label": "Allerta Stencil"
    },
    {
        "value": "Baloo Paaji 2",
        "label": "Baloo Paaji 2"
    },
    {
        "value": "Black Ops One",
        "label": "Black Ops One"
    },
    {
        "value": "Encode Sans Semi Condensed",
        "label": "Encode Sans Semi Condensed"
    },
    {
        "value": "Fanwood Text",
        "label": "Fanwood Text"
    },
    {
        "value": "Dynalight",
        "label": "Dynalight"
    },
    {
        "value": "Familjen Grotesk",
        "label": "Familjen Grotesk"
    },
    {
        "value": "Exo 2",
        "label": "Exo 2"
    },
    {
        "value": "Faster One",
        "label": "Faster One"
    },
    {
        "value": "Baloo 2",
        "label": "Baloo 2"
    },
    {
        "value": "Abyssinica SIL",
        "label": "Abyssinica SIL"
    },
    {
        "value": "Almendra SC",
        "label": "Almendra SC"
    },
    {
        "value": "Birthstone Bounce",
        "label": "Birthstone Bounce"
    },
    {
        "value": "Allison",
        "label": "Allison"
    },
    {
        "value": "Corinthia",
        "label": "Corinthia"
    },
    {
        "value": "Eczar",
        "label": "Eczar"
    },
    {
        "value": "B612",
        "label": "B612"
    },
    {
        "value": "Chonburi",
        "label": "Chonburi"
    },
    {
        "value": "Average",
        "label": "Average"
    },
    {
        "value": "Arima",
        "label": "Arima"
    },
    {
        "value": "Atma",
        "label": "Atma"
    },
    {
        "value": "Coda",
        "label": "Coda"
    },
    {
        "value": "Cormorant Upright",
        "label": "Cormorant Upright"
    },
    {
        "value": "Delius Unicase",
        "label": "Delius Unicase"
    },
    {
        "value": "Bonbon",
        "label": "Bonbon"
    },
    {
        "value": "BIZ UDMincho",
        "label": "BIZ UDMincho"
    },
    {
        "value": "Forum",
        "label": "Forum"
    },
    {
        "value": "Fira Sans Extra Condensed",
        "label": "Fira Sans Extra Condensed"
    },
    {
        "value": "Material Icons Outlined",
        "label": "Material Icons Outlined"
    },
    {
        "value": "Modern Antiqua",
        "label": "Modern Antiqua"
    },
    {
        "value": "Fira Mono",
        "label": "Fira Mono"
    },
    {
        "value": "Gowun Batang",
        "label": "Gowun Batang"
    },
    {
        "value": "Griffy",
        "label": "Griffy"
    },
    {
        "value": "IM Fell DW Pica",
        "label": "IM Fell DW Pica"
    },
    {
        "value": "Lakki Reddy",
        "label": "Lakki Reddy"
    },
    {
        "value": "Geo",
        "label": "Geo"
    },
    {
        "value": "Manuale",
        "label": "Manuale"
    },
    {
        "value": "Mako",
        "label": "Mako"
    },
    {
        "value": "Glass Antiqua",
        "label": "Glass Antiqua"
    },
    {
        "value": "Mohave",
        "label": "Mohave"
    },
    {
        "value": "Gaegu",
        "label": "Gaegu"
    },
    {
        "value": "Francois One",
        "label": "Francois One"
    },
    {
        "value": "Love Ya Like A Sister",
        "label": "Love Ya Like A Sister"
    },
    {
        "value": "Geostar Fill",
        "label": "Geostar Fill"
    },
    {
        "value": "Libre Barcode EAN13 Text",
        "label": "Libre Barcode EAN13 Text"
    },
    {
        "value": "Gentium Book Plus",
        "label": "Gentium Book Plus"
    },
    {
        "value": "Libre Barcode 39 Text",
        "label": "Libre Barcode 39 Text"
    },
    {
        "value": "Inspiration",
        "label": "Inspiration"
    },
    {
        "value": "Noto Sans Coptic",
        "label": "Noto Sans Coptic"
    },
    {
        "value": "Mr De Haviland",
        "label": "Mr De Haviland"
    },
    {
        "value": "Nixie One",
        "label": "Nixie One"
    },
    {
        "value": "Noto Sans Adlam",
        "label": "Noto Sans Adlam"
    },
    {
        "value": "Noto Sans Mono",
        "label": "Noto Sans Mono"
    },
    {
        "value": "Noto Sans Phoenician",
        "label": "Noto Sans Phoenician"
    },
    {
        "value": "Noto Sans Meetei Mayek",
        "label": "Noto Sans Meetei Mayek"
    },
    {
        "value": "Finlandica",
        "label": "Finlandica"
    },
    {
        "value": "Josefin Sans",
        "label": "Josefin Sans"
    },
    {
        "value": "Gabriela",
        "label": "Gabriela"
    },
    {
        "value": "Licorice",
        "label": "Licorice"
    },
    {
        "value": "Khand",
        "label": "Khand"
    },
    {
        "value": "Merriweather",
        "label": "Merriweather"
    },
    {
        "value": "Kings",
        "label": "Kings"
    },
    {
        "value": "Inika",
        "label": "Inika"
    },
    {
        "value": "Kelly Slab",
        "label": "Kelly Slab"
    },
    {
        "value": "Flow Circular",
        "label": "Flow Circular"
    },
    {
        "value": "Macondo",
        "label": "Macondo"
    },
    {
        "value": "Mansalva",
        "label": "Mansalva"
    },
    {
        "value": "Grandstander",
        "label": "Grandstander"
    },
    {
        "value": "Lemonada",
        "label": "Lemonada"
    },
    {
        "value": "Just Me Again Down Here",
        "label": "Just Me Again Down Here"
    },
    {
        "value": "Kufam",
        "label": "Kufam"
    },
    {
        "value": "Material Symbols Outlined",
        "label": "Material Symbols Outlined"
    },
    {
        "value": "Gilda Display",
        "label": "Gilda Display"
    },
    {
        "value": "Hanalei",
        "label": "Hanalei"
    },
    {
        "value": "Mochiy Pop P One",
        "label": "Mochiy Pop P One"
    },
    {
        "value": "Gulzar",
        "label": "Gulzar"
    },
    {
        "value": "Megrim",
        "label": "Megrim"
    },
    {
        "value": "IM Fell Great Primer SC",
        "label": "IM Fell Great Primer SC"
    },
    {
        "value": "Karma",
        "label": "Karma"
    },
    {
        "value": "Hind Madurai",
        "label": "Hind Madurai"
    },
    {
        "value": "Jua",
        "label": "Jua"
    },
    {
        "value": "MonteCarlo",
        "label": "MonteCarlo"
    },
    {
        "value": "Lexend Zetta",
        "label": "Lexend Zetta"
    },
    {
        "value": "Monoton",
        "label": "Monoton"
    },
    {
        "value": "Moo Lah Lah",
        "label": "Moo Lah Lah"
    },
    {
        "value": "Neuton",
        "label": "Neuton"
    },
    {
        "value": "Noto Sans Linear A",
        "label": "Noto Sans Linear A"
    },
    {
        "value": "Noto Sans Syriac",
        "label": "Noto Sans Syriac"
    },
    {
        "value": "Noto Rashi Hebrew",
        "label": "Noto Rashi Hebrew"
    },
    {
        "value": "Mr Dafoe",
        "label": "Mr Dafoe"
    },
    {
        "value": "Noto Sans Gunjala Gondi",
        "label": "Noto Sans Gunjala Gondi"
    },
    {
        "value": "Noto Sans Cherokee",
        "label": "Noto Sans Cherokee"
    },
    {
        "value": "Noto Sans Khojki",
        "label": "Noto Sans Khojki"
    },
    {
        "value": "Noto Sans JP",
        "label": "Noto Sans JP"
    },
    {
        "value": "Noto Sans Pau Cin Hau",
        "label": "Noto Sans Pau Cin Hau"
    },
    {
        "value": "Nosifer",
        "label": "Nosifer"
    },
    {
        "value": "Nothing You Could Do",
        "label": "Nothing You Could Do"
    },
    {
        "value": "Ledger",
        "label": "Ledger"
    },
    {
        "value": "Miriam Libre",
        "label": "Miriam Libre"
    },
    {
        "value": "Marcellus",
        "label": "Marcellus"
    },
    {
        "value": "Fuggles",
        "label": "Fuggles"
    },
    {
        "value": "Krub",
        "label": "Krub"
    },
    {
        "value": "Mate",
        "label": "Mate"
    },
    {
        "value": "Hanalei Fill",
        "label": "Hanalei Fill"
    },
    {
        "value": "Lexend",
        "label": "Lexend"
    },
    {
        "value": "Kavivanar",
        "label": "Kavivanar"
    },
    {
        "value": "Lexend Tera",
        "label": "Lexend Tera"
    },
    {
        "value": "Fira Sans",
        "label": "Fira Sans"
    },
    {
        "value": "Graduate",
        "label": "Graduate"
    },
    {
        "value": "Martian Mono",
        "label": "Martian Mono"
    },
    {
        "value": "Give You Glory",
        "label": "Give You Glory"
    },
    {
        "value": "Monda",
        "label": "Monda"
    },
    {
        "value": "Grey Qo",
        "label": "Grey Qo"
    },
    {
        "value": "IBM Plex Sans Thai Looped",
        "label": "IBM Plex Sans Thai Looped"
    },
    {
        "value": "Lily Script One",
        "label": "Lily Script One"
    },
    {
        "value": "Material Icons Sharp",
        "label": "Material Icons Sharp"
    },
    {
        "value": "Khula",
        "label": "Khula"
    },
    {
        "value": "Mukta Mahee",
        "label": "Mukta Mahee"
    },
    {
        "value": "Ms Madi",
        "label": "Ms Madi"
    },
    {
        "value": "Noto Sans Glagolitic",
        "label": "Noto Sans Glagolitic"
    },
    {
        "value": "Noto Sans Marchen",
        "label": "Noto Sans Marchen"
    },
    {
        "value": "Noto Sans Syloti Nagri",
        "label": "Noto Sans Syloti Nagri"
    },
    {
        "value": "Noto Sans Indic Siyaq Numbers",
        "label": "Noto Sans Indic Siyaq Numbers"
    },
    {
        "value": "Noto Sans Pahawh Hmong",
        "label": "Noto Sans Pahawh Hmong"
    },
    {
        "value": "Noticia Text",
        "label": "Noticia Text"
    },
    {
        "value": "Noto Sans NKo",
        "label": "Noto Sans NKo"
    },
    {
        "value": "Mukta",
        "label": "Mukta"
    },
    {
        "value": "Noto Sans SignWriting",
        "label": "Noto Sans SignWriting"
    },
    {
        "value": "Noto Sans Siddham",
        "label": "Noto Sans Siddham"
    },
    {
        "value": "New Tegomin",
        "label": "New Tegomin"
    },
    {
        "value": "Norican",
        "label": "Norican"
    },
    {
        "value": "Noto Sans Gujarati",
        "label": "Noto Sans Gujarati"
    },
    {
        "value": "Noto Sans Bhaiksuki",
        "label": "Noto Sans Bhaiksuki"
    },
    {
        "value": "Fraunces",
        "label": "Fraunces"
    },
    {
        "value": "Kurale",
        "label": "Kurale"
    },
    {
        "value": "Libre Baskerville",
        "label": "Libre Baskerville"
    },
    {
        "value": "Koh Santepheap",
        "label": "Koh Santepheap"
    },
    {
        "value": "Georama",
        "label": "Georama"
    },
    {
        "value": "Luckiest Guy",
        "label": "Luckiest Guy"
    },
    {
        "value": "Langar",
        "label": "Langar"
    },
    {
        "value": "Material Symbols Rounded",
        "label": "Material Symbols Rounded"
    },
    {
        "value": "Material Icons",
        "label": "Material Icons"
    },
    {
        "value": "Meddon",
        "label": "Meddon"
    },
    {
        "value": "Fenix",
        "label": "Fenix"
    },
    {
        "value": "Macondo Swash Caps",
        "label": "Macondo Swash Caps"
    },
    {
        "value": "Irish Grover",
        "label": "Irish Grover"
    },
    {
        "value": "Jim Nightshade",
        "label": "Jim Nightshade"
    },
    {
        "value": "Fondamento",
        "label": "Fondamento"
    },
    {
        "value": "Lexend Deca",
        "label": "Lexend Deca"
    },
    {
        "value": "Inria Sans",
        "label": "Inria Sans"
    },
    {
        "value": "Jacques Francois",
        "label": "Jacques Francois"
    },
    {
        "value": "Montaga",
        "label": "Montaga"
    },
    {
        "value": "Noto Sans Cypriot",
        "label": "Noto Sans Cypriot"
    },
    {
        "value": "News Cycle",
        "label": "News Cycle"
    },
    {
        "value": "Niconne",
        "label": "Niconne"
    },
    {
        "value": "Nanum Brush Script",
        "label": "Nanum Brush Script"
    },
    {
        "value": "Noto Sans Adlam Unjoined",
        "label": "Noto Sans Adlam Unjoined"
    },
    {
        "value": "Mrs Sheppards",
        "label": "Mrs Sheppards"
    },
    {
        "value": "Noto Sans Gothic",
        "label": "Noto Sans Gothic"
    },
    {
        "value": "NTR",
        "label": "NTR"
    },
    {
        "value": "Noto Sans Old South Arabian",
        "label": "Noto Sans Old South Arabian"
    },
    {
        "value": "Niramit",
        "label": "Niramit"
    },
    {
        "value": "Noto Sans Kharoshthi",
        "label": "Noto Sans Kharoshthi"
    },
    {
        "value": "Noto Sans Mayan Numerals",
        "label": "Noto Sans Mayan Numerals"
    },
    {
        "value": "Noto Sans Tifinagh",
        "label": "Noto Sans Tifinagh"
    },
    {
        "value": "Gurajada",
        "label": "Gurajada"
    },
    {
        "value": "Kumar One",
        "label": "Kumar One"
    },
    {
        "value": "Jockey One",
        "label": "Jockey One"
    },
    {
        "value": "Frank Ruhl Libre",
        "label": "Frank Ruhl Libre"
    },
    {
        "value": "Merienda One",
        "label": "Merienda One"
    },
    {
        "value": "Freckle Face",
        "label": "Freckle Face"
    },
    {
        "value": "Karantina",
        "label": "Karantina"
    },
    {
        "value": "League Spartan",
        "label": "League Spartan"
    },
    {
        "value": "Gwendolyn",
        "label": "Gwendolyn"
    },
    {
        "value": "Hind",
        "label": "Hind"
    },
    {
        "value": "Margarine",
        "label": "Margarine"
    },
    {
        "value": "Finger Paint",
        "label": "Finger Paint"
    },
    {
        "value": "Metal Mania",
        "label": "Metal Mania"
    },
    {
        "value": "Freehand",
        "label": "Freehand"
    },
    {
        "value": "Galada",
        "label": "Galada"
    },
    {
        "value": "Grechen Fuemen",
        "label": "Grechen Fuemen"
    },
    {
        "value": "Jura",
        "label": "Jura"
    },
    {
        "value": "Kiwi Maru",
        "label": "Kiwi Maru"
    },
    {
        "value": "Klee One",
        "label": "Klee One"
    },
    {
        "value": "Gorditas",
        "label": "Gorditas"
    },
    {
        "value": "Liu Jian Mao Cao",
        "label": "Liu Jian Mao Cao"
    },
    {
        "value": "IBM Plex Sans Devanagari",
        "label": "IBM Plex Sans Devanagari"
    },
    {
        "value": "Lemon",
        "label": "Lemon"
    },
    {
        "value": "IBM Plex Sans",
        "label": "IBM Plex Sans"
    },
    {
        "value": "Lobster Two",
        "label": "Lobster Two"
    },
    {
        "value": "Londrina Shadow",
        "label": "Londrina Shadow"
    },
    {
        "value": "Indie Flower",
        "label": "Indie Flower"
    },
    {
        "value": "Noto Sans",
        "label": "Noto Sans"
    },
    {
        "value": "Noto Sans Takri",
        "label": "Noto Sans Takri"
    },
    {
        "value": "Noto Sans Sharada",
        "label": "Noto Sans Sharada"
    },
    {
        "value": "Mukta Vaani",
        "label": "Mukta Vaani"
    },
    {
        "value": "Noto Sans Cuneiform",
        "label": "Noto Sans Cuneiform"
    },
    {
        "value": "Noto Sans Avestan",
        "label": "Noto Sans Avestan"
    },
    {
        "value": "Noto Sans Math",
        "label": "Noto Sans Math"
    },
    {
        "value": "Noto Sans Sogdian",
        "label": "Noto Sans Sogdian"
    },
    {
        "value": "Moon Dance",
        "label": "Moon Dance"
    },
    {
        "value": "Mystery Quest",
        "label": "Mystery Quest"
    },
    {
        "value": "Noto Sans Osmanya",
        "label": "Noto Sans Osmanya"
    },
    {
        "value": "Nerko One",
        "label": "Nerko One"
    },
    {
        "value": "Noto Sans Tamil",
        "label": "Noto Sans Tamil"
    },
    {
        "value": "Jost",
        "label": "Jost"
    },
    {
        "value": "Itim",
        "label": "Itim"
    },
    {
        "value": "Gentium Book Basic",
        "label": "Gentium Book Basic"
    },
    {
        "value": "IM Fell French Canon SC",
        "label": "IM Fell French Canon SC"
    },
    {
        "value": "Lexend Peta",
        "label": "Lexend Peta"
    },
    {
        "value": "Mingzat",
        "label": "Mingzat"
    },
    {
        "value": "Kosugi",
        "label": "Kosugi"
    },
    {
        "value": "Josefin Slab",
        "label": "Josefin Slab"
    },
    {
        "value": "Kaisei Tokumin",
        "label": "Kaisei Tokumin"
    },
    {
        "value": "Gloria Hallelujah",
        "label": "Gloria Hallelujah"
    },
    {
        "value": "Merriweather Sans",
        "label": "Merriweather Sans"
    },
    {
        "value": "Kaisei Opti",
        "label": "Kaisei Opti"
    },
    {
        "value": "Montagu Slab",
        "label": "Montagu Slab"
    },
    {
        "value": "Iceberg",
        "label": "Iceberg"
    },
    {
        "value": "Montez",
        "label": "Montez"
    },
    {
        "value": "Kanit",
        "label": "Kanit"
    },
    {
        "value": "Material Icons Two Tone",
        "label": "Material Icons Two Tone"
    },
    {
        "value": "MedievalSharp",
        "label": "MedievalSharp"
    },
    {
        "value": "Londrina Solid",
        "label": "Londrina Solid"
    },
    {
        "value": "Gelasio",
        "label": "Gelasio"
    },
    {
        "value": "Great Vibes",
        "label": "Great Vibes"
    },
    {
        "value": "Keania One",
        "label": "Keania One"
    },
    {
        "value": "IBM Plex Sans Arabic",
        "label": "IBM Plex Sans Arabic"
    },
    {
        "value": "Lobster",
        "label": "Lobster"
    },
    {
        "value": "Life Savers",
        "label": "Life Savers"
    },
    {
        "value": "Kosugi Maru",
        "label": "Kosugi Maru"
    },
    {
        "value": "JetBrains Mono",
        "label": "JetBrains Mono"
    },
    {
        "value": "Fresca",
        "label": "Fresca"
    },
    {
        "value": "My Soul",
        "label": "My Soul"
    },
    {
        "value": "Gothic A1",
        "label": "Gothic A1"
    },
    {
        "value": "Headland One",
        "label": "Headland One"
    },
    {
        "value": "Noto Sans Malayalam",
        "label": "Noto Sans Malayalam"
    },
    {
        "value": "Noto Sans Rejang",
        "label": "Noto Sans Rejang"
    },
    {
        "value": "Kodchasan",
        "label": "Kodchasan"
    },
    {
        "value": "Noto Sans Runic",
        "label": "Noto Sans Runic"
    },
    {
        "value": "Genos",
        "label": "Genos"
    },
    {
        "value": "Halant",
        "label": "Halant"
    },
    {
        "value": "Noto Sans Anatolian Hieroglyphs",
        "label": "Noto Sans Anatolian Hieroglyphs"
    },
    {
        "value": "Mr Bedfort",
        "label": "Mr Bedfort"
    },
    {
        "value": "Henny Penny",
        "label": "Henny Penny"
    },
    {
        "value": "Homemade Apple",
        "label": "Homemade Apple"
    },
    {
        "value": "Mynerve",
        "label": "Mynerve"
    },
    {
        "value": "Noto Sans Lao",
        "label": "Noto Sans Lao"
    },
    {
        "value": "Italianno",
        "label": "Italianno"
    },
    {
        "value": "Fira Code",
        "label": "Fira Code"
    },
    {
        "value": "Noto Sans Palmyrene",
        "label": "Noto Sans Palmyrene"
    },
    {
        "value": "Geostar",
        "label": "Geostar"
    },
    {
        "value": "Noto Sans Miao",
        "label": "Noto Sans Miao"
    },
    {
        "value": "Hurricane",
        "label": "Hurricane"
    },
    {
        "value": "Murecho",
        "label": "Murecho"
    },
    {
        "value": "Kantumruy Pro",
        "label": "Kantumruy Pro"
    },
    {
        "value": "Noto Sans Ol Chiki",
        "label": "Noto Sans Ol Chiki"
    },
    {
        "value": "Kotta One",
        "label": "Kotta One"
    },
    {
        "value": "Noto Sans Armenian",
        "label": "Noto Sans Armenian"
    },
    {
        "value": "Montserrat Alternates",
        "label": "Montserrat Alternates"
    },
    {
        "value": "Markazi Text",
        "label": "Markazi Text"
    },
    {
        "value": "Girassol",
        "label": "Girassol"
    },
    {
        "value": "Noto Sans Masaram Gondi",
        "label": "Noto Sans Masaram Gondi"
    },
    {
        "value": "Notable",
        "label": "Notable"
    },
    {
        "value": "Galdeano",
        "label": "Galdeano"
    },
    {
        "value": "Kameron",
        "label": "Kameron"
    },
    {
        "value": "Noto Sans HK",
        "label": "Noto Sans HK"
    },
    {
        "value": "Mukta Malar",
        "label": "Mukta Malar"
    },
    {
        "value": "Metrophobic",
        "label": "Metrophobic"
    },
    {
        "value": "Marmelad",
        "label": "Marmelad"
    },
    {
        "value": "Noto Sans Mahajani",
        "label": "Noto Sans Mahajani"
    },
    {
        "value": "Miniver",
        "label": "Miniver"
    },
    {
        "value": "Nanum Gothic Coding",
        "label": "Nanum Gothic Coding"
    },
    {
        "value": "Noto Emoji",
        "label": "Noto Emoji"
    },
    {
        "value": "Noto Sans Mro",
        "label": "Noto Sans Mro"
    },
    {
        "value": "Noto Sans Old Hungarian",
        "label": "Noto Sans Old Hungarian"
    },
    {
        "value": "Nanum Gothic",
        "label": "Nanum Gothic"
    },
    {
        "value": "Noto Sans Lao Looped",
        "label": "Noto Sans Lao Looped"
    },
    {
        "value": "Nabla",
        "label": "Nabla"
    },
    {
        "value": "Neonderthaw",
        "label": "Neonderthaw"
    },
    {
        "value": "Noto Sans Hanifi Rohingya",
        "label": "Noto Sans Hanifi Rohingya"
    },
    {
        "value": "Noto Sans Old Italic",
        "label": "Noto Sans Old Italic"
    },
    {
        "value": "Noto Sans Buhid",
        "label": "Noto Sans Buhid"
    },
    {
        "value": "Noto Sans Sora Sompeng",
        "label": "Noto Sans Sora Sompeng"
    },
    {
        "value": "Noto Sans Mende Kikakui",
        "label": "Noto Sans Mende Kikakui"
    },
    {
        "value": "Noto Sans Multani",
        "label": "Noto Sans Multani"
    },
    {
        "value": "Kaisei Decol",
        "label": "Kaisei Decol"
    },
    {
        "value": "Galindo",
        "label": "Galindo"
    },
    {
        "value": "Goudy Bookletter 1911",
        "label": "Goudy Bookletter 1911"
    },
    {
        "value": "Hanuman",
        "label": "Hanuman"
    },
    {
        "value": "Mea Culpa",
        "label": "Mea Culpa"
    },
    {
        "value": "Fredoka",
        "label": "Fredoka"
    },
    {
        "value": "Fruktur",
        "label": "Fruktur"
    },
    {
        "value": "IM Fell Double Pica SC",
        "label": "IM Fell Double Pica SC"
    },
    {
        "value": "Imprima",
        "label": "Imprima"
    },
    {
        "value": "Marvel",
        "label": "Marvel"
    },
    {
        "value": "Fontdiner Swanky",
        "label": "Fontdiner Swanky"
    },
    {
        "value": "Flamenco",
        "label": "Flamenco"
    },
    {
        "value": "Gotu",
        "label": "Gotu"
    },
    {
        "value": "Kavoon",
        "label": "Kavoon"
    },
    {
        "value": "Gafata",
        "label": "Gafata"
    },
    {
        "value": "Jacques Francois Shadow",
        "label": "Jacques Francois Shadow"
    },
    {
        "value": "Gruppo",
        "label": "Gruppo"
    },
    {
        "value": "Gideon Roman",
        "label": "Gideon Roman"
    },
    {
        "value": "Material Symbols Sharp",
        "label": "Material Symbols Sharp"
    },
    {
        "value": "IBM Plex Sans Condensed",
        "label": "IBM Plex Sans Condensed"
    },
    {
        "value": "Lateef",
        "label": "Lateef"
    },
    {
        "value": "M PLUS 1p",
        "label": "M PLUS 1p"
    },
    {
        "value": "Molle",
        "label": "Molle"
    },
    {
        "value": "Hachi Maru Pop",
        "label": "Hachi Maru Pop"
    },
    {
        "value": "McLaren",
        "label": "McLaren"
    },
    {
        "value": "Moulpali",
        "label": "Moulpali"
    },
    {
        "value": "Noto Sans Tirhuta",
        "label": "Noto Sans Tirhuta"
    },
    {
        "value": "Noto Sans Elymaic",
        "label": "Noto Sans Elymaic"
    },
    {
        "value": "Nanum Pen Script",
        "label": "Nanum Pen Script"
    },
    {
        "value": "Noto Sans Elbasan",
        "label": "Noto Sans Elbasan"
    },
    {
        "value": "Noto Sans Chakma",
        "label": "Noto Sans Chakma"
    },
    {
        "value": "Noto Sans Nushu",
        "label": "Noto Sans Nushu"
    },
    {
        "value": "Noto Sans Egyptian Hieroglyphs",
        "label": "Noto Sans Egyptian Hieroglyphs"
    },
    {
        "value": "Noto Sans Caucasian Albanian",
        "label": "Noto Sans Caucasian Albanian"
    },
    {
        "value": "Noto Sans Grantha",
        "label": "Noto Sans Grantha"
    },
    {
        "value": "Nanum Myeongjo",
        "label": "Nanum Myeongjo"
    },
    {
        "value": "Noto Sans KR",
        "label": "Noto Sans KR"
    },
    {
        "value": "Noto Sans Ugaritic",
        "label": "Noto Sans Ugaritic"
    },
    {
        "value": "Montserrat Subrayada",
        "label": "Montserrat Subrayada"
    },
    {
        "value": "Noto Sans Thaana",
        "label": "Noto Sans Thaana"
    },
    {
        "value": "Noto Sans Tai Le",
        "label": "Noto Sans Tai Le"
    },
    {
        "value": "Habibi",
        "label": "Habibi"
    },
    {
        "value": "League Gothic",
        "label": "League Gothic"
    },
    {
        "value": "Herr Von Muellerhoff",
        "label": "Herr Von Muellerhoff"
    },
    {
        "value": "Manrope",
        "label": "Manrope"
    },
    {
        "value": "Lekton",
        "label": "Lekton"
    },
    {
        "value": "Hammersmith One",
        "label": "Hammersmith One"
    },
    {
        "value": "Just Another Hand",
        "label": "Just Another Hand"
    },
    {
        "value": "Ingrid Darling",
        "label": "Ingrid Darling"
    },
    {
        "value": "Handlee",
        "label": "Handlee"
    },
    {
        "value": "Kenia",
        "label": "Kenia"
    },
    {
        "value": "IBM Plex Sans JP",
        "label": "IBM Plex Sans JP"
    },
    {
        "value": "Grenze",
        "label": "Grenze"
    },
    {
        "value": "Heebo",
        "label": "Heebo"
    },
    {
        "value": "Inconsolata",
        "label": "Inconsolata"
    },
    {
        "value": "Maven Pro",
        "label": "Maven Pro"
    },
    {
        "value": "Festive",
        "label": "Festive"
    },
    {
        "value": "Kirang Haerang",
        "label": "Kirang Haerang"
    },
    {
        "value": "Modak",
        "label": "Modak"
    },
    {
        "value": "Miltonian Tattoo",
        "label": "Miltonian Tattoo"
    },
    {
        "value": "Major Mono Display",
        "label": "Major Mono Display"
    },
    {
        "value": "Marko One",
        "label": "Marko One"
    },
    {
        "value": "IM Fell French Canon",
        "label": "IM Fell French Canon"
    },
    {
        "value": "Libre Barcode 128 Text",
        "label": "Libre Barcode 128 Text"
    },
    {
        "value": "Harmattan",
        "label": "Harmattan"
    },
    {
        "value": "Junge",
        "label": "Junge"
    },
    {
        "value": "Laila",
        "label": "Laila"
    },
    {
        "value": "Maiden Orange",
        "label": "Maiden Orange"
    },
    {
        "value": "Fredericka the Great",
        "label": "Fredericka the Great"
    },
    {
        "value": "Hi Melody",
        "label": "Hi Melody"
    },
    {
        "value": "M PLUS 1",
        "label": "M PLUS 1"
    },
    {
        "value": "Marhey",
        "label": "Marhey"
    },
    {
        "value": "Mirza",
        "label": "Mirza"
    },
    {
        "value": "Metamorphous",
        "label": "Metamorphous"
    },
    {
        "value": "Mrs Saint Delafield",
        "label": "Mrs Saint Delafield"
    },
    {
        "value": "Noto Sans Bengali",
        "label": "Noto Sans Bengali"
    },
    {
        "value": "Noto Kufi Arabic",
        "label": "Noto Kufi Arabic"
    },
    {
        "value": "Noto Sans Cham",
        "label": "Noto Sans Cham"
    },
    {
        "value": "Noto Sans Javanese",
        "label": "Noto Sans Javanese"
    },
    {
        "value": "Noto Sans Lepcha",
        "label": "Noto Sans Lepcha"
    },
    {
        "value": "Noto Sans Thai",
        "label": "Noto Sans Thai"
    },
    {
        "value": "Noto Sans Tai Viet",
        "label": "Noto Sans Tai Viet"
    },
    {
        "value": "Noto Sans Saurashtra",
        "label": "Noto Sans Saurashtra"
    },
    {
        "value": "MuseoModerno",
        "label": "MuseoModerno"
    },
    {
        "value": "Noto Sans Telugu",
        "label": "Noto Sans Telugu"
    },
    {
        "value": "Noto Sans Phags Pa",
        "label": "Noto Sans Phags Pa"
    },
    {
        "value": "Noto Sans Tagalog",
        "label": "Noto Sans Tagalog"
    },
    {
        "value": "Noto Sans Lisu",
        "label": "Noto Sans Lisu"
    },
    {
        "value": "Noto Sans SC",
        "label": "Noto Sans SC"
    },
    {
        "value": "Fira Sans Condensed",
        "label": "Fira Sans Condensed"
    },
    {
        "value": "Fjalla One",
        "label": "Fjalla One"
    },
    {
        "value": "Fredoka One",
        "label": "Fredoka One"
    },
    {
        "value": "Ibarra Real Nova",
        "label": "Ibarra Real Nova"
    },
    {
        "value": "Imperial Script",
        "label": "Imperial Script"
    },
    {
        "value": "Libre Barcode 39",
        "label": "Libre Barcode 39"
    },
    {
        "value": "Gayathri",
        "label": "Gayathri"
    },
    {
        "value": "Hubballi",
        "label": "Hubballi"
    },
    {
        "value": "IM Fell English",
        "label": "IM Fell English"
    },
    {
        "value": "Meie Script",
        "label": "Meie Script"
    },
    {
        "value": "Homenaje",
        "label": "Homenaje"
    },
    {
        "value": "Julius Sans One",
        "label": "Julius Sans One"
    },
    {
        "value": "Lora",
        "label": "Lora"
    },
    {
        "value": "Germania One",
        "label": "Germania One"
    },
    {
        "value": "Lexend Giga",
        "label": "Lexend Giga"
    },
    {
        "value": "Hind Vadodara",
        "label": "Hind Vadodara"
    },
    {
        "value": "Milonga",
        "label": "Milonga"
    },
    {
        "value": "IBM Plex Sans KR",
        "label": "IBM Plex Sans KR"
    },
    {
        "value": "Lancelot",
        "label": "Lancelot"
    },
    {
        "value": "Lilita One",
        "label": "Lilita One"
    },
    {
        "value": "Judson",
        "label": "Judson"
    },
    {
        "value": "Inder",
        "label": "Inder"
    },
    {
        "value": "Mina",
        "label": "Mina"
    },
    {
        "value": "Jomolhari",
        "label": "Jomolhari"
    },
    {
        "value": "Goldman",
        "label": "Goldman"
    },
    {
        "value": "Gudea",
        "label": "Gudea"
    },
    {
        "value": "Libre Bodoni",
        "label": "Libre Bodoni"
    },
    {
        "value": "Manjari",
        "label": "Manjari"
    },
    {
        "value": "IM Fell Great Primer",
        "label": "IM Fell Great Primer"
    },
    {
        "value": "Mouse Memoirs",
        "label": "Mouse Memoirs"
    },
    {
        "value": "Noto Sans Hatran",
        "label": "Noto Sans Hatran"
    },
    {
        "value": "Noto Sans Tangsa",
        "label": "Noto Sans Tangsa"
    },
    {
        "value": "Noto Sans Deseret",
        "label": "Noto Sans Deseret"
    },
    {
        "value": "Noto Sans Khudawadi",
        "label": "Noto Sans Khudawadi"
    },
    {
        "value": "Noto Sans Lydian",
        "label": "Noto Sans Lydian"
    },
    {
        "value": "Neucha",
        "label": "Neucha"
    },
    {
        "value": "Noto Naskh Arabic",
        "label": "Noto Naskh Arabic"
    },
    {
        "value": "Noto Sans Mongolian",
        "label": "Noto Sans Mongolian"
    },
    {
        "value": "Noto Sans New Tai Lue",
        "label": "Noto Sans New Tai Lue"
    },
    {
        "value": "Kaushan Script",
        "label": "Kaushan Script"
    },
    {
        "value": "Kranky",
        "label": "Kranky"
    },
    {
        "value": "Montserrat",
        "label": "Montserrat"
    },
    {
        "value": "IM Fell English SC",
        "label": "IM Fell English SC"
    },
    {
        "value": "Gravitas One",
        "label": "Gravitas One"
    },
    {
        "value": "Libre Barcode 39 Extended",
        "label": "Libre Barcode 39 Extended"
    },
    {
        "value": "Magra",
        "label": "Magra"
    },
    {
        "value": "Island Moments",
        "label": "Island Moments"
    },
    {
        "value": "Koulen",
        "label": "Koulen"
    },
    {
        "value": "League Script",
        "label": "League Script"
    },
    {
        "value": "Fjord One",
        "label": "Fjord One"
    },
    {
        "value": "Hina Mincho",
        "label": "Hina Mincho"
    },
    {
        "value": "Libre Caslon Display",
        "label": "Libre Caslon Display"
    },
    {
        "value": "Flow Block",
        "label": "Flow Block"
    },
    {
        "value": "Julee",
        "label": "Julee"
    },
    {
        "value": "Mali",
        "label": "Mali"
    },
    {
        "value": "Krona One",
        "label": "Krona One"
    },
    {
        "value": "Meow Script",
        "label": "Meow Script"
    },
    {
        "value": "Frijole",
        "label": "Frijole"
    },
    {
        "value": "Grape Nuts",
        "label": "Grape Nuts"
    },
    {
        "value": "Hind Siliguri",
        "label": "Hind Siliguri"
    },
    {
        "value": "Long Cang",
        "label": "Long Cang"
    },
    {
        "value": "Fuzzy Bubbles",
        "label": "Fuzzy Bubbles"
    },
    {
        "value": "Gajraj One",
        "label": "Gajraj One"
    },
    {
        "value": "Hahmlet",
        "label": "Hahmlet"
    },
    {
        "value": "Kulim Park",
        "label": "Kulim Park"
    },
    {
        "value": "Lexend Exa",
        "label": "Lexend Exa"
    },
    {
        "value": "Medula One",
        "label": "Medula One"
    },
    {
        "value": "Glegoo",
        "label": "Glegoo"
    },
    {
        "value": "IBM Plex Sans Thai",
        "label": "IBM Plex Sans Thai"
    },
    {
        "value": "Gupter",
        "label": "Gupter"
    },
    {
        "value": "Noto Sans Ogham",
        "label": "Noto Sans Ogham"
    },
    {
        "value": "Noto Sans Tai Tham",
        "label": "Noto Sans Tai Tham"
    },
    {
        "value": "Noto Sans Myanmar",
        "label": "Noto Sans Myanmar"
    },
    {
        "value": "Noto Sans Bassa Vah",
        "label": "Noto Sans Bassa Vah"
    },
    {
        "value": "Noto Music",
        "label": "Noto Music"
    },
    {
        "value": "Noto Sans Old Permic",
        "label": "Noto Sans Old Permic"
    },
    {
        "value": "Noto Sans Buginese",
        "label": "Noto Sans Buginese"
    },
    {
        "value": "Noto Sans Newa",
        "label": "Noto Sans Newa"
    },
    {
        "value": "Noto Sans Symbols",
        "label": "Noto Sans Symbols"
    },
    {
        "value": "IBM Plex Mono",
        "label": "IBM Plex Mono"
    },
    {
        "value": "Libre Caslon Text",
        "label": "Libre Caslon Text"
    },
    {
        "value": "Mandali",
        "label": "Mandali"
    },
    {
        "value": "Miltonian",
        "label": "Miltonian"
    },
    {
        "value": "Gantari",
        "label": "Gantari"
    },
    {
        "value": "Grenze Gotisch",
        "label": "Grenze Gotisch"
    },
    {
        "value": "Hanken Grotesk",
        "label": "Hanken Grotesk"
    },
    {
        "value": "Inter",
        "label": "Inter"
    },
    {
        "value": "Lavishly Yours",
        "label": "Lavishly Yours"
    },
    {
        "value": "Kadwa",
        "label": "Kadwa"
    },
    {
        "value": "Libre Barcode 39 Extended Text",
        "label": "Libre Barcode 39 Extended Text"
    },
    {
        "value": "Goblin One",
        "label": "Goblin One"
    },
    {
        "value": "Khmer",
        "label": "Khmer"
    },
    {
        "value": "GFS Neohellenic",
        "label": "GFS Neohellenic"
    },
    {
        "value": "Flow Rounded",
        "label": "Flow Rounded"
    },
    {
        "value": "Kumar One Outline",
        "label": "Kumar One Outline"
    },
    {
        "value": "Londrina Outline",
        "label": "Londrina Outline"
    },
    {
        "value": "Maitree",
        "label": "Maitree"
    },
    {
        "value": "IBM Plex Sans Hebrew",
        "label": "IBM Plex Sans Hebrew"
    },
    {
        "value": "Kaisei HarunoUmi",
        "label": "Kaisei HarunoUmi"
    },
    {
        "value": "Lalezar",
        "label": "Lalezar"
    },
    {
        "value": "Glory",
        "label": "Glory"
    },
    {
        "value": "Noto Sans Brahmi",
        "label": "Noto Sans Brahmi"
    },
    {
        "value": "Noto Sans Old Persian",
        "label": "Noto Sans Old Persian"
    },
    {
        "value": "Noto Sans Meroitic",
        "label": "Noto Sans Meroitic"
    },
    {
        "value": "Noto Sans Gurmukhi",
        "label": "Noto Sans Gurmukhi"
    },
    {
        "value": "Noto Sans Soyombo",
        "label": "Noto Sans Soyombo"
    },
    {
        "value": "Newsreader",
        "label": "Newsreader"
    },
    {
        "value": "Nobile",
        "label": "Nobile"
    },
    {
        "value": "Noto Sans Manichaean",
        "label": "Noto Sans Manichaean"
    },
    {
        "value": "Noto Color Emoji",
        "label": "Noto Color Emoji"
    },
    {
        "value": "Noto Sans Old Sogdian",
        "label": "Noto Sans Old Sogdian"
    },
    {
        "value": "Noto Sans Psalter Pahlavi",
        "label": "Noto Sans Psalter Pahlavi"
    },
    {
        "value": "Knewave",
        "label": "Knewave"
    },
    {
        "value": "Kolker Brush",
        "label": "Kolker Brush"
    },
    {
        "value": "Grand Hotel",
        "label": "Grand Hotel"
    },
    {
        "value": "Martel",
        "label": "Martel"
    },
    {
        "value": "Gemunu Libre",
        "label": "Gemunu Libre"
    },
    {
        "value": "Literata",
        "label": "Literata"
    },
    {
        "value": "Fragment Mono",
        "label": "Fragment Mono"
    },
    {
        "value": "Mallanna",
        "label": "Mallanna"
    },
    {
        "value": "Kantumruy",
        "label": "Kantumruy"
    },
    {
        "value": "Lusitana",
        "label": "Lusitana"
    },
    {
        "value": "Figtree",
        "label": "Figtree"
    },
    {
        "value": "Joan",
        "label": "Joan"
    },
    {
        "value": "KoHo",
        "label": "KoHo"
    },
    {
        "value": "Gentium Plus",
        "label": "Gentium Plus"
    },
    {
        "value": "Joti One",
        "label": "Joti One"
    },
    {
        "value": "Livvic",
        "label": "Livvic"
    },
    {
        "value": "Luxurious Roman",
        "label": "Luxurious Roman"
    },
    {
        "value": "Labrada",
        "label": "Labrada"
    },
    {
        "value": "Lacquer",
        "label": "Lacquer"
    },
    {
        "value": "Gluten",
        "label": "Gluten"
    },
    {
        "value": "Hind Guntur",
        "label": "Hind Guntur"
    },
    {
        "value": "K2D",
        "label": "K2D"
    },
    {
        "value": "La Belle Aurore",
        "label": "La Belle Aurore"
    },
    {
        "value": "Golos Text",
        "label": "Golos Text"
    },
    {
        "value": "Mitr",
        "label": "Mitr"
    },
    {
        "value": "Jomhuria",
        "label": "Jomhuria"
    },
    {
        "value": "Kite One",
        "label": "Kite One"
    },
    {
        "value": "Noto Sans Bamum",
        "label": "Noto Sans Bamum"
    },
    {
        "value": "Noto Sans Sundanese",
        "label": "Noto Sans Sundanese"
    },
    {
        "value": "Noto Sans Khmer",
        "label": "Noto Sans Khmer"
    },
    {
        "value": "Noto Sans Linear B",
        "label": "Noto Sans Linear B"
    },
    {
        "value": "Noto Sans Batak",
        "label": "Noto Sans Batak"
    },
    {
        "value": "Noto Sans Carian",
        "label": "Noto Sans Carian"
    },
    {
        "value": "Noto Sans Hanunoo",
        "label": "Noto Sans Hanunoo"
    },
    {
        "value": "Noto Sans Old North Arabian",
        "label": "Noto Sans Old North Arabian"
    },
    {
        "value": "Noto Sans Mandaic",
        "label": "Noto Sans Mandaic"
    },
    {
        "value": "Noto Sans Nabataean",
        "label": "Noto Sans Nabataean"
    },
    {
        "value": "Noto Sans Tagbanwa",
        "label": "Noto Sans Tagbanwa"
    },
    {
        "value": "Mulish",
        "label": "Mulish"
    },
    {
        "value": "Noto Sans TC",
        "label": "Noto Sans TC"
    },
    {
        "value": "Noto Sans Samaritan",
        "label": "Noto Sans Samaritan"
    },
    {
        "value": "Noto Sans Duployan",
        "label": "Noto Sans Duployan"
    },
    {
        "value": "Fleur De Leah",
        "label": "Fleur De Leah"
    },
    {
        "value": "Limelight",
        "label": "Limelight"
    },
    {
        "value": "Mogra",
        "label": "Mogra"
    },
    {
        "value": "Holtwood One SC",
        "label": "Holtwood One SC"
    },
    {
        "value": "Gugi",
        "label": "Gugi"
    },
    {
        "value": "Lovers Quarrel",
        "label": "Lovers Quarrel"
    },
    {
        "value": "IM Fell Double Pica",
        "label": "IM Fell Double Pica"
    },
    {
        "value": "Kalam",
        "label": "Kalam"
    },
    {
        "value": "Linden Hill",
        "label": "Linden Hill"
    },
    {
        "value": "Kreon",
        "label": "Kreon"
    },
    {
        "value": "Martel Sans",
        "label": "Martel Sans"
    },
    {
        "value": "Merienda",
        "label": "Merienda"
    },
    {
        "value": "Kumbh Sans",
        "label": "Kumbh Sans"
    },
    {
        "value": "M PLUS Code Latin",
        "label": "M PLUS Code Latin"
    },
    {
        "value": "Kdam Thmor Pro",
        "label": "Kdam Thmor Pro"
    },
    {
        "value": "Marck Script",
        "label": "Marck Script"
    },
    {
        "value": "Miss Fajardose",
        "label": "Miss Fajardose"
    },
    {
        "value": "Jolly Lodger",
        "label": "Jolly Lodger"
    },
    {
        "value": "Londrina Sketch",
        "label": "Londrina Sketch"
    },
    {
        "value": "Italiana",
        "label": "Italiana"
    },
    {
        "value": "Ma Shan Zheng",
        "label": "Ma Shan Zheng"
    },
    {
        "value": "Mochiy Pop One",
        "label": "Mochiy Pop One"
    },
    {
        "value": "Noto Sans Osage",
        "label": "Noto Sans Osage"
    },
    {
        "value": "Noto Sans Hebrew",
        "label": "Noto Sans Hebrew"
    },
    {
        "value": "Mountains of Christmas",
        "label": "Mountains of Christmas"
    },
    {
        "value": "Noto Sans Imperial Aramaic",
        "label": "Noto Sans Imperial Aramaic"
    },
    {
        "value": "Noto Sans Inscriptional Pahlavi",
        "label": "Noto Sans Inscriptional Pahlavi"
    },
    {
        "value": "Nokora",
        "label": "Nokora"
    },
    {
        "value": "Noto Sans Lycian",
        "label": "Noto Sans Lycian"
    },
    {
        "value": "M PLUS 2",
        "label": "M PLUS 2"
    },
    {
        "value": "Gowun Dodum",
        "label": "Gowun Dodum"
    },
    {
        "value": "Inria Serif",
        "label": "Inria Serif"
    },
    {
        "value": "Jaldi",
        "label": "Jaldi"
    },
    {
        "value": "Leckerli One",
        "label": "Leckerli One"
    },
    {
        "value": "M PLUS Rounded 1c",
        "label": "M PLUS Rounded 1c"
    },
    {
        "value": "Libre Franklin",
        "label": "Libre Franklin"
    },
    {
        "value": "Metal",
        "label": "Metal"
    },
    {
        "value": "Monofett",
        "label": "Monofett"
    },
    {
        "value": "Gamja Flower",
        "label": "Gamja Flower"
    },
    {
        "value": "Iceland",
        "label": "Iceland"
    },
    {
        "value": "Monsieur La Doulaise",
        "label": "Monsieur La Doulaise"
    },
    {
        "value": "Istok Web",
        "label": "Istok Web"
    },
    {
        "value": "Gochi Hand",
        "label": "Gochi Hand"
    },
    {
        "value": "Inter Tight",
        "label": "Inter Tight"
    },
    {
        "value": "Lustria",
        "label": "Lustria"
    },
    {
        "value": "M PLUS 1 Code",
        "label": "M PLUS 1 Code"
    },
    {
        "value": "Happy Monkey",
        "label": "Happy Monkey"
    },
    {
        "value": "Gidugu",
        "label": "Gidugu"
    },
    {
        "value": "Noto Sans Ethiopic",
        "label": "Noto Sans Ethiopic"
    },
    {
        "value": "Noto Sans Kaithi",
        "label": "Noto Sans Kaithi"
    },
    {
        "value": "Noto Sans Tamil Supplement",
        "label": "Noto Sans Tamil Supplement"
    },
    {
        "value": "Noto Sans Kannada",
        "label": "Noto Sans Kannada"
    },
    {
        "value": "New Rocker",
        "label": "New Rocker"
    },
    {
        "value": "Noto Sans Devanagari",
        "label": "Noto Sans Devanagari"
    },
    {
        "value": "Noto Sans Thai Looped",
        "label": "Noto Sans Thai Looped"
    },
    {
        "value": "Noto Sans Medefaidrin",
        "label": "Noto Sans Medefaidrin"
    },
    {
        "value": "Moul",
        "label": "Moul"
    },
    {
        "value": "Noto Sans Canadian Aboriginal",
        "label": "Noto Sans Canadian Aboriginal"
    },
    {
        "value": "Noto Sans Display",
        "label": "Noto Sans Display"
    },
    {
        "value": "Imbue",
        "label": "Imbue"
    },
    {
        "value": "Katibeh",
        "label": "Katibeh"
    },
    {
        "value": "Lato",
        "label": "Lato"
    },
    {
        "value": "Mate SC",
        "label": "Mate SC"
    },
    {
        "value": "Gloock",
        "label": "Gloock"
    },
    {
        "value": "Flavors",
        "label": "Flavors"
    },
    {
        "value": "Lexend Mega",
        "label": "Lexend Mega"
    },
    {
        "value": "GFS Didot",
        "label": "GFS Didot"
    },
    {
        "value": "IM Fell DW Pica SC",
        "label": "IM Fell DW Pica SC"
    },
    {
        "value": "Loved by the King",
        "label": "Loved by the King"
    },
    {
        "value": "Fugaz One",
        "label": "Fugaz One"
    },
    {
        "value": "Hepta Slab",
        "label": "Hepta Slab"
    },
    {
        "value": "Karla",
        "label": "Karla"
    },
    {
        "value": "Kristi",
        "label": "Kristi"
    },
    {
        "value": "Michroma",
        "label": "Michroma"
    },
    {
        "value": "Libre Barcode 128",
        "label": "Libre Barcode 128"
    },
    {
        "value": "Marcellus SC",
        "label": "Marcellus SC"
    },
    {
        "value": "Molengo",
        "label": "Molengo"
    },
    {
        "value": "Luxurious Script",
        "label": "Luxurious Script"
    },
    {
        "value": "Meera Inimai",
        "label": "Meera Inimai"
    },
    {
        "value": "Inknut Antiqua",
        "label": "Inknut Antiqua"
    },
    {
        "value": "Love Light",
        "label": "Love Light"
    },
    {
        "value": "IBM Plex Serif",
        "label": "IBM Plex Serif"
    },
    {
        "value": "Mada",
        "label": "Mada"
    },
    {
        "value": "Noto Sans Old Turkic",
        "label": "Noto Sans Old Turkic"
    },
    {
        "value": "Noto Sans Symbols 2",
        "label": "Noto Sans Symbols 2"
    },
    {
        "value": "Noto Sans Arabic",
        "label": "Noto Sans Arabic"
    },
    {
        "value": "Noto Sans Sinhala",
        "label": "Noto Sans Sinhala"
    },
    {
        "value": "Noto Nastaliq Urdu",
        "label": "Noto Nastaliq Urdu"
    },
    {
        "value": "Noto Sans Kayah Li",
        "label": "Noto Sans Kayah Li"
    },
    {
        "value": "Noto Sans Balinese",
        "label": "Noto Sans Balinese"
    },
    {
        "value": "Noto Sans Oriya",
        "label": "Noto Sans Oriya"
    },
    {
        "value": "Noto Sans Georgian",
        "label": "Noto Sans Georgian"
    },
    {
        "value": "Noto Sans Inscriptional Parthian",
        "label": "Noto Sans Inscriptional Parthian"
    },
    {
        "value": "Noto Sans Shavian",
        "label": "Noto Sans Shavian"
    },
    {
        "value": "Noto Sans Limbu",
        "label": "Noto Sans Limbu"
    },
    {
        "value": "Noto Sans Modi",
        "label": "Noto Sans Modi"
    },
    {
        "value": "Noto Sans Vai",
        "label": "Noto Sans Vai"
    },
    {
        "value": "Noto Serif Sinhala",
        "label": "Noto Serif Sinhala"
    },
    {
        "value": "Tiro Bangla",
        "label": "Tiro Bangla"
    },
    {
        "value": "Noto Traditional Nushu",
        "label": "Noto Traditional Nushu"
    },
    {
        "value": "ZCOOL KuaiLe",
        "label": "ZCOOL KuaiLe"
    },
    {
        "value": "PT Mono",
        "label": "PT Mono"
    },
    {
        "value": "Oswald",
        "label": "Oswald"
    },
    {
        "value": "Noto Serif Oriya",
        "label": "Noto Serif Oriya"
    },
    {
        "value": "Patua One",
        "label": "Patua One"
    },
    {
        "value": "Rochester",
        "label": "Rochester"
    },
    {
        "value": "Redacted Script",
        "label": "Redacted Script"
    },
    {
        "value": "Sriracha",
        "label": "Sriracha"
    },
    {
        "value": "Strait",
        "label": "Strait"
    },
    {
        "value": "Press Start 2P",
        "label": "Press Start 2P"
    },
    {
        "value": "Radio Canada",
        "label": "Radio Canada"
    },
    {
        "value": "Sassy Frass",
        "label": "Sassy Frass"
    },
    {
        "value": "Tiro Kannada",
        "label": "Tiro Kannada"
    },
    {
        "value": "Oi",
        "label": "Oi"
    },
    {
        "value": "Syne Tactile",
        "label": "Syne Tactile"
    },
    {
        "value": "Pavanam",
        "label": "Pavanam"
    },
    {
        "value": "Teko",
        "label": "Teko"
    },
    {
        "value": "Xanh Mono",
        "label": "Xanh Mono"
    },
    {
        "value": "Red Hat Display",
        "label": "Red Hat Display"
    },
    {
        "value": "Roboto Mono",
        "label": "Roboto Mono"
    },
    {
        "value": "Rubik 80s Fade",
        "label": "Rubik 80s Fade"
    },
    {
        "value": "Sahitya",
        "label": "Sahitya"
    },
    {
        "value": "Slabo 27px",
        "label": "Slabo 27px"
    },
    {
        "value": "Qwigley",
        "label": "Qwigley"
    },
    {
        "value": "Stalinist One",
        "label": "Stalinist One"
    },
    {
        "value": "Rock Salt",
        "label": "Rock Salt"
    },
    {
        "value": "Six Caps",
        "label": "Six Caps"
    },
    {
        "value": "Vesper Libre",
        "label": "Vesper Libre"
    },
    {
        "value": "Vibur",
        "label": "Vibur"
    },
    {
        "value": "Noto Sans Zanabazar Square",
        "label": "Noto Sans Zanabazar Square"
    },
    {
        "value": "Preahvihear",
        "label": "Preahvihear"
    },
    {
        "value": "Tiro Devanagari Hindi",
        "label": "Tiro Devanagari Hindi"
    },
    {
        "value": "Waiting for the Sunrise",
        "label": "Waiting for the Sunrise"
    },
    {
        "value": "ZCOOL XiaoWei",
        "label": "ZCOOL XiaoWei"
    },
    {
        "value": "Yuji Mai",
        "label": "Yuji Mai"
    },
    {
        "value": "Ovo",
        "label": "Ovo"
    },
    {
        "value": "Nova Script",
        "label": "Nova Script"
    },
    {
        "value": "Paprika",
        "label": "Paprika"
    },
    {
        "value": "Redacted",
        "label": "Redacted"
    },
    {
        "value": "Yaldevi",
        "label": "Yaldevi"
    },
    {
        "value": "Orienta",
        "label": "Orienta"
    },
    {
        "value": "Raleway",
        "label": "Raleway"
    },
    {
        "value": "Rubik",
        "label": "Rubik"
    },
    {
        "value": "Noto Serif Ahom",
        "label": "Noto Serif Ahom"
    },
    {
        "value": "Suez One",
        "label": "Suez One"
    },
    {
        "value": "Zen Tokyo Zoo",
        "label": "Zen Tokyo Zoo"
    },
    {
        "value": "Orelega One",
        "label": "Orelega One"
    },
    {
        "value": "Readex Pro",
        "label": "Readex Pro"
    },
    {
        "value": "Ruthie",
        "label": "Ruthie"
    },
    {
        "value": "Voltaire",
        "label": "Voltaire"
    },
    {
        "value": "Sree Krushnadevaraya",
        "label": "Sree Krushnadevaraya"
    },
    {
        "value": "Ropa Sans",
        "label": "Ropa Sans"
    },
    {
        "value": "Taprom",
        "label": "Taprom"
    },
    {
        "value": "Shippori Mincho",
        "label": "Shippori Mincho"
    },
    {
        "value": "Tiro Devanagari Sanskrit",
        "label": "Tiro Devanagari Sanskrit"
    },
    {
        "value": "Noto Serif Thai",
        "label": "Noto Serif Thai"
    },
    {
        "value": "Yeon Sung",
        "label": "Yeon Sung"
    },
    {
        "value": "Oldenburg",
        "label": "Oldenburg"
    },
    {
        "value": "Playball",
        "label": "Playball"
    },
    {
        "value": "Oleo Script",
        "label": "Oleo Script"
    },
    {
        "value": "Righteous",
        "label": "Righteous"
    },
    {
        "value": "Rubik Maze",
        "label": "Rubik Maze"
    },
    {
        "value": "Quattrocento Sans",
        "label": "Quattrocento Sans"
    },
    {
        "value": "Share",
        "label": "Share"
    },
    {
        "value": "Overpass Mono",
        "label": "Overpass Mono"
    },
    {
        "value": "Slackey",
        "label": "Slackey"
    },
    {
        "value": "Philosopher",
        "label": "Philosopher"
    },
    {
        "value": "Oxanium",
        "label": "Oxanium"
    },
    {
        "value": "Rouge Script",
        "label": "Rouge Script"
    },
    {
        "value": "Rajdhani",
        "label": "Rajdhani"
    },
    {
        "value": "Ramaraja",
        "label": "Ramaraja"
    },
    {
        "value": "Play",
        "label": "Play"
    },
    {
        "value": "Reem Kufi",
        "label": "Reem Kufi"
    },
    {
        "value": "Single Day",
        "label": "Single Day"
    },
    {
        "value": "Tienne",
        "label": "Tienne"
    },
    {
        "value": "Rubik Gemstones",
        "label": "Rubik Gemstones"
    },
    {
        "value": "Smooch",
        "label": "Smooch"
    },
    {
        "value": "Open Sans",
        "label": "Open Sans"
    },
    {
        "value": "Nova Cut",
        "label": "Nova Cut"
    },
    {
        "value": "Rancho",
        "label": "Rancho"
    },
    {
        "value": "Syne",
        "label": "Syne"
    },
    {
        "value": "Ravi Prakash",
        "label": "Ravi Prakash"
    },
    {
        "value": "Sumana",
        "label": "Sumana"
    },
    {
        "value": "Sanchez",
        "label": "Sanchez"
    },
    {
        "value": "Smythe",
        "label": "Smythe"
    },
    {
        "value": "Stick",
        "label": "Stick"
    },
    {
        "value": "UnifrakturMaguntia",
        "label": "UnifrakturMaguntia"
    },
    {
        "value": "Noto Serif KR",
        "label": "Noto Serif KR"
    },
    {
        "value": "Redressed",
        "label": "Redressed"
    },
    {
        "value": "Solway",
        "label": "Solway"
    },
    {
        "value": "Wallpoet",
        "label": "Wallpoet"
    },
    {
        "value": "Rubik Spray Paint",
        "label": "Rubik Spray Paint"
    },
    {
        "value": "Palanquin Dark",
        "label": "Palanquin Dark"
    },
    {
        "value": "Sintony",
        "label": "Sintony"
    },
    {
        "value": "Passion One",
        "label": "Passion One"
    },
    {
        "value": "Sofia",
        "label": "Sofia"
    },
    {
        "value": "ZCOOL QingKe HuangYou",
        "label": "ZCOOL QingKe HuangYou"
    },
    {
        "value": "Noto Serif Khojki",
        "label": "Noto Serif Khojki"
    },
    {
        "value": "Nova Flat",
        "label": "Nova Flat"
    },
    {
        "value": "Nunito",
        "label": "Nunito"
    },
    {
        "value": "Offside",
        "label": "Offside"
    },
    {
        "value": "Questrial",
        "label": "Questrial"
    },
    {
        "value": "Stylish",
        "label": "Stylish"
    },
    {
        "value": "Padyakke Expanded One",
        "label": "Padyakke Expanded One"
    },
    {
        "value": "Smooch Sans",
        "label": "Smooch Sans"
    },
    {
        "value": "Texturina",
        "label": "Texturina"
    },
    {
        "value": "Radley",
        "label": "Radley"
    },
    {
        "value": "Rosario",
        "label": "Rosario"
    },
    {
        "value": "Rum Raisin",
        "label": "Rum Raisin"
    },
    {
        "value": "Varela",
        "label": "Varela"
    },
    {
        "value": "Yanone Kaffeesatz",
        "label": "Yanone Kaffeesatz"
    },
    {
        "value": "Siemreap",
        "label": "Siemreap"
    },
    {
        "value": "Tourney",
        "label": "Tourney"
    },
    {
        "value": "Noto Serif TC",
        "label": "Noto Serif TC"
    },
    {
        "value": "Sail",
        "label": "Sail"
    },
    {
        "value": "Pacifico",
        "label": "Pacifico"
    },
    {
        "value": "Roboto Flex",
        "label": "Roboto Flex"
    },
    {
        "value": "Shojumaru",
        "label": "Shojumaru"
    },
    {
        "value": "Yuji Boku",
        "label": "Yuji Boku"
    },
    {
        "value": "Ubuntu Condensed",
        "label": "Ubuntu Condensed"
    },
    {
        "value": "Noto Serif Ethiopic",
        "label": "Noto Serif Ethiopic"
    },
    {
        "value": "PT Serif Caption",
        "label": "PT Serif Caption"
    },
    {
        "value": "Padauk",
        "label": "Padauk"
    },
    {
        "value": "Ruslan Display",
        "label": "Ruslan Display"
    },
    {
        "value": "Swanky and Moo Moo",
        "label": "Swanky and Moo Moo"
    },
    {
        "value": "Plaster",
        "label": "Plaster"
    },
    {
        "value": "Sofadi One",
        "label": "Sofadi One"
    },
    {
        "value": "Tajawal",
        "label": "Tajawal"
    },
    {
        "value": "Yusei Magic",
        "label": "Yusei Magic"
    },
    {
        "value": "Noto Serif Devanagari",
        "label": "Noto Serif Devanagari"
    },
    {
        "value": "Noto Serif Gurmukhi",
        "label": "Noto Serif Gurmukhi"
    },
    {
        "value": "Noto Serif Tangut",
        "label": "Noto Serif Tangut"
    },
    {
        "value": "Over the Rainbow",
        "label": "Over the Rainbow"
    },
    {
        "value": "Sevillana",
        "label": "Sevillana"
    },
    {
        "value": "Song Myung",
        "label": "Song Myung"
    },
    {
        "value": "Prompt",
        "label": "Prompt"
    },
    {
        "value": "Quintessential",
        "label": "Quintessential"
    },
    {
        "value": "Water Brush",
        "label": "Water Brush"
    },
    {
        "value": "Varta",
        "label": "Varta"
    },
    {
        "value": "Nuosu SIL",
        "label": "Nuosu SIL"
    },
    {
        "value": "Rozha One",
        "label": "Rozha One"
    },
    {
        "value": "Outfit",
        "label": "Outfit"
    },
    {
        "value": "Unica One",
        "label": "Unica One"
    },
    {
        "value": "Sarabun",
        "label": "Sarabun"
    },
    {
        "value": "Viaoda Libre",
        "label": "Viaoda Libre"
    },
    {
        "value": "Zhi Mang Xing",
        "label": "Zhi Mang Xing"
    },
    {
        "value": "Purple Purse",
        "label": "Purple Purse"
    },
    {
        "value": "Source Serif Pro",
        "label": "Source Serif Pro"
    },
    {
        "value": "Zilla Slab",
        "label": "Zilla Slab"
    },
    {
        "value": "Noto Serif HK",
        "label": "Noto Serif HK"
    },
    {
        "value": "Noto Sans Yi",
        "label": "Noto Sans Yi"
    },
    {
        "value": "Poller One",
        "label": "Poller One"
    },
    {
        "value": "Rubik Glitch",
        "label": "Rubik Glitch"
    },
    {
        "value": "Sigmar One",
        "label": "Sigmar One"
    },
    {
        "value": "Tiro Gurmukhi",
        "label": "Tiro Gurmukhi"
    },
    {
        "value": "Passions Conflict",
        "label": "Passions Conflict"
    },
    {
        "value": "Spicy Rice",
        "label": "Spicy Rice"
    },
    {
        "value": "Noto Serif Yezidi",
        "label": "Noto Serif Yezidi"
    },
    {
        "value": "Noto Serif NP Hmong",
        "label": "Noto Serif NP Hmong"
    },
    {
        "value": "Pangolin",
        "label": "Pangolin"
    },
    {
        "value": "Spectral SC",
        "label": "Spectral SC"
    },
    {
        "value": "Unna",
        "label": "Unna"
    },
    {
        "value": "Red Hat Text",
        "label": "Red Hat Text"
    },
    {
        "value": "Rampart One",
        "label": "Rampart One"
    },
    {
        "value": "Seymour One",
        "label": "Seymour One"
    },
    {
        "value": "Pinyon Script",
        "label": "Pinyon Script"
    },
    {
        "value": "The Girl Next Door",
        "label": "The Girl Next Door"
    },
    {
        "value": "Vollkorn SC",
        "label": "Vollkorn SC"
    },
    {
        "value": "Playfair Display SC",
        "label": "Playfair Display SC"
    },
    {
        "value": "Rye",
        "label": "Rye"
    },
    {
        "value": "Schoolbell",
        "label": "Schoolbell"
    },
    {
        "value": "Tiro Telugu",
        "label": "Tiro Telugu"
    },
    {
        "value": "Volkhov",
        "label": "Volkhov"
    },
    {
        "value": "Zen Maru Gothic",
        "label": "Zen Maru Gothic"
    },
    {
        "value": "Noto Serif Malayalam",
        "label": "Noto Serif Malayalam"
    },
    {
        "value": "Patrick Hand",
        "label": "Patrick Hand"
    },
    {
        "value": "Sura",
        "label": "Sura"
    },
    {
        "value": "Yuji Syuku",
        "label": "Yuji Syuku"
    },
    {
        "value": "Nunito Sans",
        "label": "Nunito Sans"
    },
    {
        "value": "Public Sans",
        "label": "Public Sans"
    },
    {
        "value": "Noto Serif Armenian",
        "label": "Noto Serif Armenian"
    },
    {
        "value": "Pathway Gothic One",
        "label": "Pathway Gothic One"
    },
    {
        "value": "Noto Serif Gujarati",
        "label": "Noto Serif Gujarati"
    },
    {
        "value": "Ranga",
        "label": "Ranga"
    },
    {
        "value": "Rubik Beastly",
        "label": "Rubik Beastly"
    },
    {
        "value": "Poly",
        "label": "Poly"
    },
    {
        "value": "Sen",
        "label": "Sen"
    },
    {
        "value": "Stint Ultra Expanded",
        "label": "Stint Ultra Expanded"
    },
    {
        "value": "Old Standard TT",
        "label": "Old Standard TT"
    },
    {
        "value": "Quattrocento",
        "label": "Quattrocento"
    },
    {
        "value": "Roboto Condensed",
        "label": "Roboto Condensed"
    },
    {
        "value": "Sawarabi Gothic",
        "label": "Sawarabi Gothic"
    },
    {
        "value": "Source Sans 3",
        "label": "Source Sans 3"
    },
    {
        "value": "Source Serif 4",
        "label": "Source Serif 4"
    },
    {
        "value": "Tapestry",
        "label": "Tapestry"
    },
    {
        "value": "Zeyada",
        "label": "Zeyada"
    },
    {
        "value": "Twinkle Star",
        "label": "Twinkle Star"
    },
    {
        "value": "Walter Turncoat",
        "label": "Walter Turncoat"
    },
    {
        "value": "Noto Serif Bengali",
        "label": "Noto Serif Bengali"
    },
    {
        "value": "Noto Serif Tamil",
        "label": "Noto Serif Tamil"
    },
    {
        "value": "Spirax",
        "label": "Spirax"
    },
    {
        "value": "Telex",
        "label": "Telex"
    },
    {
        "value": "Piazzolla",
        "label": "Piazzolla"
    },
    {
        "value": "Rosarivo",
        "label": "Rosarivo"
    },
    {
        "value": "Podkova",
        "label": "Podkova"
    },
    {
        "value": "Reenie Beanie",
        "label": "Reenie Beanie"
    },
    {
        "value": "Ruda",
        "label": "Ruda"
    },
    {
        "value": "Trochut",
        "label": "Trochut"
    },
    {
        "value": "Oregano",
        "label": "Oregano"
    },
    {
        "value": "Permanent Marker",
        "label": "Permanent Marker"
    },
    {
        "value": "Varela Round",
        "label": "Varela Round"
    },
    {
        "value": "Pragati Narrow",
        "label": "Pragati Narrow"
    },
    {
        "value": "Stoke",
        "label": "Stoke"
    },
    {
        "value": "Trirong",
        "label": "Trirong"
    },
    {
        "value": "Tenor Sans",
        "label": "Tenor Sans"
    },
    {
        "value": "Rasa",
        "label": "Rasa"
    },
    {
        "value": "Tiro Devanagari Marathi",
        "label": "Tiro Devanagari Marathi"
    },
    {
        "value": "Noto Serif Telugu",
        "label": "Noto Serif Telugu"
    },
    {
        "value": "Romanesco",
        "label": "Romanesco"
    },
    {
        "value": "Shippori Mincho B1",
        "label": "Shippori Mincho B1"
    },
    {
        "value": "Noto Serif",
        "label": "Noto Serif"
    },
    {
        "value": "Sora",
        "label": "Sora"
    },
    {
        "value": "Saira Stencil One",
        "label": "Saira Stencil One"
    },
    {
        "value": "UnifrakturCook",
        "label": "UnifrakturCook"
    },
    {
        "value": "Noto Sans Warang Citi",
        "label": "Noto Sans Warang Citi"
    },
    {
        "value": "Signika",
        "label": "Signika"
    },
    {
        "value": "Noto Serif Khmer",
        "label": "Noto Serif Khmer"
    },
    {
        "value": "Simonetta",
        "label": "Simonetta"
    },
    {
        "value": "Saira Condensed",
        "label": "Saira Condensed"
    },
    {
        "value": "Short Stack",
        "label": "Short Stack"
    },
    {
        "value": "Spinnaker",
        "label": "Spinnaker"
    },
    {
        "value": "Squada One",
        "label": "Squada One"
    },
    {
        "value": "Yomogi",
        "label": "Yomogi"
    },
    {
        "value": "Rhodium Libre",
        "label": "Rhodium Libre"
    },
    {
        "value": "Rubik Iso",
        "label": "Rubik Iso"
    },
    {
        "value": "Rubik Storm",
        "label": "Rubik Storm"
    },
    {
        "value": "Unbounded",
        "label": "Unbounded"
    },
    {
        "value": "Nova Mono",
        "label": "Nova Mono"
    },
    {
        "value": "Prociono",
        "label": "Prociono"
    },
    {
        "value": "Shanti",
        "label": "Shanti"
    },
    {
        "value": "Sniglet",
        "label": "Sniglet"
    },
    {
        "value": "Pontano Sans",
        "label": "Pontano Sans"
    },
    {
        "value": "Sedgwick Ave",
        "label": "Sedgwick Ave"
    },
    {
        "value": "Qahiri",
        "label": "Qahiri"
    },
    {
        "value": "Road Rage",
        "label": "Road Rage"
    },
    {
        "value": "Trispace",
        "label": "Trispace"
    },
    {
        "value": "Uchen",
        "label": "Uchen"
    },
    {
        "value": "Wellfleet",
        "label": "Wellfleet"
    },
    {
        "value": "Ole",
        "label": "Ole"
    },
    {
        "value": "Viga",
        "label": "Viga"
    },
    {
        "value": "Noto Serif JP",
        "label": "Noto Serif JP"
    },
    {
        "value": "Noto Serif Hebrew",
        "label": "Noto Serif Hebrew"
    },
    {
        "value": "Yrsa",
        "label": "Yrsa"
    },
    {
        "value": "Saira",
        "label": "Saira"
    },
    {
        "value": "VT323",
        "label": "VT323"
    },
    {
        "value": "PT Sans",
        "label": "PT Sans"
    },
    {
        "value": "Qwitcher Grypen",
        "label": "Qwitcher Grypen"
    },
    {
        "value": "Shadows Into Light",
        "label": "Shadows Into Light"
    },
    {
        "value": "Srisakdi",
        "label": "Srisakdi"
    },
    {
        "value": "Rubik Marker Hatch",
        "label": "Rubik Marker Hatch"
    },
    {
        "value": "Satisfy",
        "label": "Satisfy"
    },
    {
        "value": "Tauri",
        "label": "Tauri"
    },
    {
        "value": "Paytone One",
        "label": "Paytone One"
    },
    {
        "value": "Saira Extra Condensed",
        "label": "Saira Extra Condensed"
    },
    {
        "value": "Odor Mean Chey",
        "label": "Odor Mean Chey"
    },
    {
        "value": "Petemoss",
        "label": "Petemoss"
    },
    {
        "value": "Source Sans Pro",
        "label": "Source Sans Pro"
    },
    {
        "value": "Rokkitt",
        "label": "Rokkitt"
    },
    {
        "value": "Signika Negative",
        "label": "Signika Negative"
    },
    {
        "value": "Work Sans",
        "label": "Work Sans"
    },
    {
        "value": "Yantramanav",
        "label": "Yantramanav"
    },
    {
        "value": "Snowburst One",
        "label": "Snowburst One"
    },
    {
        "value": "Petit Formal Script",
        "label": "Petit Formal Script"
    },
    {
        "value": "Reem Kufi Fun",
        "label": "Reem Kufi Fun"
    },
    {
        "value": "Slabo 13px",
        "label": "Slabo 13px"
    },
    {
        "value": "Stick No Bills",
        "label": "Stick No Bills"
    },
    {
        "value": "Prosto One",
        "label": "Prosto One"
    },
    {
        "value": "Tenali Ramakrishna",
        "label": "Tenali Ramakrishna"
    },
    {
        "value": "Palanquin",
        "label": "Palanquin"
    },
    {
        "value": "Sofia Sans Condensed",
        "label": "Sofia Sans Condensed"
    },
    {
        "value": "Suwannaphum",
        "label": "Suwannaphum"
    },
    {
        "value": "Trykker",
        "label": "Trykker"
    },
    {
        "value": "Passero One",
        "label": "Passero One"
    },
    {
        "value": "Praise",
        "label": "Praise"
    },
    {
        "value": "Shadows Into Light Two",
        "label": "Shadows Into Light Two"
    },
    {
        "value": "Racing Sans One",
        "label": "Racing Sans One"
    },
    {
        "value": "Ribeye Marrow",
        "label": "Ribeye Marrow"
    },
    {
        "value": "Shrikhand",
        "label": "Shrikhand"
    },
    {
        "value": "Splash",
        "label": "Splash"
    },
    {
        "value": "Rubik Burned",
        "label": "Rubik Burned"
    },
    {
        "value": "Rubik Distressed",
        "label": "Rubik Distressed"
    },
    {
        "value": "Reem Kufi Ink",
        "label": "Reem Kufi Ink"
    },
    {
        "value": "Rubik Moonrocks",
        "label": "Rubik Moonrocks"
    },
    {
        "value": "Sunshiney",
        "label": "Sunshiney"
    },
    {
        "value": "Zen Antique",
        "label": "Zen Antique"
    },
    {
        "value": "Puppies Play",
        "label": "Puppies Play"
    },
    {
        "value": "Scheherazade New",
        "label": "Scheherazade New"
    },
    {
        "value": "Sofia Sans Semi Condensed",
        "label": "Sofia Sans Semi Condensed"
    },
    {
        "value": "Train One",
        "label": "Train One"
    },
    {
        "value": "Noto Serif Balinese",
        "label": "Noto Serif Balinese"
    },
    {
        "value": "Plus Jakarta Sans",
        "label": "Plus Jakarta Sans"
    },
    {
        "value": "Port Lligat Slab",
        "label": "Port Lligat Slab"
    },
    {
        "value": "Tomorrow",
        "label": "Tomorrow"
    },
    {
        "value": "Sarala",
        "label": "Sarala"
    },
    {
        "value": "Skranji",
        "label": "Skranji"
    },
    {
        "value": "Noto Serif Kannada",
        "label": "Noto Serif Kannada"
    },
    {
        "value": "Peddana",
        "label": "Peddana"
    },
    {
        "value": "Raleway Dots",
        "label": "Raleway Dots"
    },
    {
        "value": "Rubik Dirt",
        "label": "Rubik Dirt"
    },
    {
        "value": "Poppins",
        "label": "Poppins"
    },
    {
        "value": "Yatra One",
        "label": "Yatra One"
    },
    {
        "value": "Playfair Display",
        "label": "Playfair Display"
    },
    {
        "value": "Quando",
        "label": "Quando"
    },
    {
        "value": "Source Code Pro",
        "label": "Source Code Pro"
    },
    {
        "value": "Taviraj",
        "label": "Taviraj"
    },
    {
        "value": "Poiret One",
        "label": "Poiret One"
    },
    {
        "value": "Pridi",
        "label": "Pridi"
    },
    {
        "value": "Ribeye",
        "label": "Ribeye"
    },
    {
        "value": "Sedgwick Ave Display",
        "label": "Sedgwick Ave Display"
    },
    {
        "value": "Yesteryear",
        "label": "Yesteryear"
    },
    {
        "value": "PT Sans Caption",
        "label": "PT Sans Caption"
    },
    {
        "value": "Vazirmatn",
        "label": "Vazirmatn"
    },
    {
        "value": "Poor Story",
        "label": "Poor Story"
    },
    {
        "value": "WindSong",
        "label": "WindSong"
    },
    {
        "value": "Port Lligat Sans",
        "label": "Port Lligat Sans"
    },
    {
        "value": "Zen Loop",
        "label": "Zen Loop"
    },
    {
        "value": "Noto Serif Tibetan",
        "label": "Noto Serif Tibetan"
    },
    {
        "value": "Piedra",
        "label": "Piedra"
    },
    {
        "value": "Ruge Boogie",
        "label": "Ruge Boogie"
    },
    {
        "value": "Tulpen One",
        "label": "Tulpen One"
    },
    {
        "value": "Oleo Script Swash Caps",
        "label": "Oleo Script Swash Caps"
    },
    {
        "value": "Phudu",
        "label": "Phudu"
    },
    {
        "value": "Noto Serif Grantha",
        "label": "Noto Serif Grantha"
    },
    {
        "value": "Smokum",
        "label": "Smokum"
    },
    {
        "value": "Oxygen Mono",
        "label": "Oxygen Mono"
    },
    {
        "value": "Suravaram",
        "label": "Suravaram"
    },
    {
        "value": "Whisper",
        "label": "Whisper"
    },
    {
        "value": "Recursive",
        "label": "Recursive"
    },
    {
        "value": "Send Flowers",
        "label": "Send Flowers"
    },
    {
        "value": "Rowdies",
        "label": "Rowdies"
    },
    {
        "value": "Sansita Swashed",
        "label": "Sansita Swashed"
    },
    {
        "value": "Syne Mono",
        "label": "Syne Mono"
    },
    {
        "value": "Roboto",
        "label": "Roboto"
    },
    {
        "value": "Underdog",
        "label": "Underdog"
    },
    {
        "value": "Shantell Sans",
        "label": "Shantell Sans"
    },
    {
        "value": "Orbitron",
        "label": "Orbitron"
    },
    {
        "value": "Numans",
        "label": "Numans"
    },
    {
        "value": "Overpass",
        "label": "Overpass"
    },
    {
        "value": "Reggae One",
        "label": "Reggae One"
    },
    {
        "value": "Rubik Bubbles",
        "label": "Rubik Bubbles"
    },
    {
        "value": "Seaweed Script",
        "label": "Seaweed Script"
    },
    {
        "value": "Vast Shadow",
        "label": "Vast Shadow"
    },
    {
        "value": "Patrick Hand SC",
        "label": "Patrick Hand SC"
    },
    {
        "value": "Updock",
        "label": "Updock"
    },
    {
        "value": "Nova Oval",
        "label": "Nova Oval"
    },
    {
        "value": "Quicksand",
        "label": "Quicksand"
    },
    {
        "value": "Solitreo",
        "label": "Solitreo"
    },
    {
        "value": "Spline Sans Mono",
        "label": "Spline Sans Mono"
    },
    {
        "value": "Shippori Antique",
        "label": "Shippori Antique"
    },
    {
        "value": "Tai Heritage Pro",
        "label": "Tai Heritage Pro"
    },
    {
        "value": "Pirata One",
        "label": "Pirata One"
    },
    {
        "value": "Sunflower",
        "label": "Sunflower"
    },
    {
        "value": "Parisienne",
        "label": "Parisienne"
    },
    {
        "value": "Scope One",
        "label": "Scope One"
    },
    {
        "value": "Zen Antique Soft",
        "label": "Zen Antique Soft"
    },
    {
        "value": "Noto Serif Display",
        "label": "Noto Serif Display"
    },
    {
        "value": "PT Serif",
        "label": "PT Serif"
    },
    {
        "value": "Petrona",
        "label": "Petrona"
    },
    {
        "value": "Ramabhadra",
        "label": "Ramabhadra"
    },
    {
        "value": "Rammetto One",
        "label": "Rammetto One"
    },
    {
        "value": "Noto Sans Wancho",
        "label": "Noto Sans Wancho"
    },
    {
        "value": "Original Surfer",
        "label": "Original Surfer"
    },
    {
        "value": "Revalia",
        "label": "Revalia"
    },
    {
        "value": "Thasadith",
        "label": "Thasadith"
    },
    {
        "value": "Truculenta",
        "label": "Truculenta"
    },
    {
        "value": "Wire One",
        "label": "Wire One"
    },
    {
        "value": "Noto Serif Georgian",
        "label": "Noto Serif Georgian"
    },
    {
        "value": "Ruluko",
        "label": "Ruluko"
    },
    {
        "value": "Stalemate",
        "label": "Stalemate"
    },
    {
        "value": "Waterfall",
        "label": "Waterfall"
    },
    {
        "value": "Noto Serif SC",
        "label": "Noto Serif SC"
    },
    {
        "value": "Peralta",
        "label": "Peralta"
    },
    {
        "value": "Zen Kaku Gothic Antique",
        "label": "Zen Kaku Gothic Antique"
    },
    {
        "value": "Noto Serif Lao",
        "label": "Noto Serif Lao"
    },
    {
        "value": "Turret Road",
        "label": "Turret Road"
    },
    {
        "value": "Shippori Antique B1",
        "label": "Shippori Antique B1"
    },
    {
        "value": "Vujahday Script",
        "label": "Vujahday Script"
    },
    {
        "value": "Quantico",
        "label": "Quantico"
    },
    {
        "value": "Zen Kaku Gothic New",
        "label": "Zen Kaku Gothic New"
    },
    {
        "value": "Noto Serif Dogra",
        "label": "Noto Serif Dogra"
    },
    {
        "value": "Sacramento",
        "label": "Sacramento"
    },
    {
        "value": "Proza Libre",
        "label": "Proza Libre"
    },
    {
        "value": "Nova Slim",
        "label": "Nova Slim"
    },
    {
        "value": "Snippet",
        "label": "Snippet"
    },
    {
        "value": "Ultra",
        "label": "Ultra"
    },
    {
        "value": "Rationale",
        "label": "Rationale"
    },
    {
        "value": "Sarina",
        "label": "Sarina"
    },
    {
        "value": "Rubik Puddles",
        "label": "Rubik Puddles"
    },
    {
        "value": "Rubik Wet Paint",
        "label": "Rubik Wet Paint"
    },
    {
        "value": "Wendy One",
        "label": "Wendy One"
    },
    {
        "value": "Rubik Vinyl",
        "label": "Rubik Vinyl"
    },
    {
        "value": "Space Grotesk",
        "label": "Space Grotesk"
    },
    {
        "value": "Pattaya",
        "label": "Pattaya"
    },
    {
        "value": "Red Hat Mono",
        "label": "Red Hat Mono"
    },
    {
        "value": "Oranienbaum",
        "label": "Oranienbaum"
    },
    {
        "value": "Trade Winds",
        "label": "Trade Winds"
    },
    {
        "value": "Yellowtail",
        "label": "Yellowtail"
    },
    {
        "value": "Odibee Sans",
        "label": "Odibee Sans"
    },
    {
        "value": "Russo One",
        "label": "Russo One"
    },
    {
        "value": "Sarpanch",
        "label": "Sarpanch"
    },
    {
        "value": "Pompiere",
        "label": "Pompiere"
    },
    {
        "value": "Syncopate",
        "label": "Syncopate"
    },
    {
        "value": "Risque",
        "label": "Risque"
    },
    {
        "value": "Scada",
        "label": "Scada"
    },
    {
        "value": "The Nautigal",
        "label": "The Nautigal"
    },
    {
        "value": "Vollkorn",
        "label": "Vollkorn"
    },
    {
        "value": "Stint Ultra Condensed",
        "label": "Stint Ultra Condensed"
    },
    {
        "value": "Warnes",
        "label": "Warnes"
    },
    {
        "value": "Zen Kurenaido",
        "label": "Zen Kurenaido"
    },
    {
        "value": "Saira Semi Condensed",
        "label": "Saira Semi Condensed"
    },
    {
        "value": "Rufina",
        "label": "Rufina"
    },
    {
        "value": "Sansita",
        "label": "Sansita"
    },
    {
        "value": "Sawarabi Mincho",
        "label": "Sawarabi Mincho"
    },
    {
        "value": "Roboto Serif",
        "label": "Roboto Serif"
    },
    {
        "value": "Staatliches",
        "label": "Staatliches"
    },
    {
        "value": "Oxygen",
        "label": "Oxygen"
    },
    {
        "value": "Urbanist",
        "label": "Urbanist"
    },
    {
        "value": "Shalimar",
        "label": "Shalimar"
    },
    {
        "value": "Tangerine",
        "label": "Tangerine"
    },
    {
        "value": "Ubuntu",
        "label": "Ubuntu"
    },
    {
        "value": "Potta One",
        "label": "Potta One"
    },
    {
        "value": "Sofia Sans",
        "label": "Sofia Sans"
    },
    {
        "value": "Trocchi",
        "label": "Trocchi"
    },
    {
        "value": "Ubuntu Mono",
        "label": "Ubuntu Mono"
    },
    {
        "value": "Square Peg",
        "label": "Square Peg"
    },
    {
        "value": "Suranna",
        "label": "Suranna"
    },
    {
        "value": "Tillana",
        "label": "Tillana"
    },
    {
        "value": "Vampiro One",
        "label": "Vampiro One"
    },
    {
        "value": "Rakkas",
        "label": "Rakkas"
    },
    {
        "value": "Uncial Antiqua",
        "label": "Uncial Antiqua"
    },
    {
        "value": "Sorts Mill Goudy",
        "label": "Sorts Mill Goudy"
    },
    {
        "value": "Timmana",
        "label": "Timmana"
    },
    {
        "value": "Zilla Slab Highlight",
        "label": "Zilla Slab Highlight"
    },
    {
        "value": "Rambla",
        "label": "Rambla"
    },
    {
        "value": "Secular One",
        "label": "Secular One"
    },
    {
        "value": "Supermercado One",
        "label": "Supermercado One"
    },
    {
        "value": "Voces",
        "label": "Voces"
    },
    {
        "value": "Vibes",
        "label": "Vibes"
    },
    {
        "value": "Silkscreen",
        "label": "Silkscreen"
    },
    {
        "value": "Sirin Stencil",
        "label": "Sirin Stencil"
    },
    {
        "value": "PT Sans Narrow",
        "label": "PT Sans Narrow"
    },
    {
        "value": "Special Elite",
        "label": "Special Elite"
    },
    {
        "value": "Text Me One",
        "label": "Text Me One"
    },
    {
        "value": "Nova Round",
        "label": "Nova Round"
    },
    {
        "value": "Spline Sans",
        "label": "Spline Sans"
    },
    {
        "value": "Stardos Stencil",
        "label": "Stardos Stencil"
    },
    {
        "value": "RocknRoll One",
        "label": "RocknRoll One"
    },
    {
        "value": "Princess Sofia",
        "label": "Princess Sofia"
    },
    {
        "value": "Sono",
        "label": "Sono"
    },
    {
        "value": "Unlock",
        "label": "Unlock"
    },
    {
        "value": "Yeseva One",
        "label": "Yeseva One"
    },
    {
        "value": "Zen Dots",
        "label": "Zen Dots"
    },
    {
        "value": "Sancreek",
        "label": "Sancreek"
    },
    {
        "value": "Style Script",
        "label": "Style Script"
    },
    {
        "value": "Rubik Mono One",
        "label": "Rubik Mono One"
    },
    {
        "value": "Spectral",
        "label": "Spectral"
    },
    {
        "value": "Puritan",
        "label": "Puritan"
    },
    {
        "value": "Vidaloka",
        "label": "Vidaloka"
    },
    {
        "value": "Share Tech",
        "label": "Share Tech"
    },
    {
        "value": "Noto Serif Toto",
        "label": "Noto Serif Toto"
    },
    {
        "value": "Overlock",
        "label": "Overlock"
    },
    {
        "value": "Roboto Slab",
        "label": "Roboto Slab"
    },
    {
        "value": "Sue Ellen Francisco",
        "label": "Sue Ellen Francisco"
    },
    {
        "value": "Noto Serif Myanmar",
        "label": "Noto Serif Myanmar"
    },
    {
        "value": "Unkempt",
        "label": "Unkempt"
    },
    {
        "value": "Tinos",
        "label": "Tinos"
    },
    {
        "value": "Red Rose",
        "label": "Red Rose"
    },
    {
        "value": "STIX Two Text",
        "label": "STIX Two Text"
    },
    {
        "value": "Titan One",
        "label": "Titan One"
    },
    {
        "value": "Rubik Microbe",
        "label": "Rubik Microbe"
    },
    {
        "value": "Sonsie One",
        "label": "Sonsie One"
    },
    {
        "value": "Share Tech Mono",
        "label": "Share Tech Mono"
    },
    {
        "value": "Ranchers",
        "label": "Ranchers"
    },
    {
        "value": "Prata",
        "label": "Prata"
    },
    {
        "value": "Space Mono",
        "label": "Space Mono"
    },
    {
        "value": "Overlock SC",
        "label": "Overlock SC"
    },
    {
        "value": "Salsa",
        "label": "Salsa"
    },
    {
        "value": "Tiro Tamil",
        "label": "Tiro Tamil"
    },
    {
        "value": "Zen Old Mincho",
        "label": "Zen Old Mincho"
    },
    {
        "value": "Sulphur Point",
        "label": "Sulphur Point"
    },
    {
        "value": "Nova Square",
        "label": "Nova Square"
    },
    {
        "value": "Titillium Web",
        "label": "Titillium Web"
    },
    {
        "value": "Sofia Sans Extra Condensed",
        "label": "Sofia Sans Extra Condensed"
    },
    {
        "value": "Oooh Baby",
        "label": "Oooh Baby"
    }
]