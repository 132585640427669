import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import { setMyState,NoDataFound } from "../../components/commonAction";
import { hitApi, hexToRgbA } from "../../components/commonAction";
import { skeletonLoader, useOutsideClick } from "../../components/commonAction";
import { useRef } from "react";
import svg from "./components/svg";
import { useStore } from "../../../src/zustand/store";


// let data = [ 
//     {id:1,count:262,type:'Resolved'},
//     {id:2,count:262,type:'Closed'},
//     {id:3,count:262,type:'Waiting'},
//     {id:4,count:262,type:'Pending'},
//     {id:5,count:262,type:'Open'},
// ]

const Dashboard = ({ pageTitle }) => {
    const [data, setData] = useState([])
    const [state, setState] = useState({

        scheduledCount: 0,
        draftCount: 0,
        designcount: 0,
        isLoading: false,
        isCount: false,
    });
    const [allsubmenu, setallsubmenu] = useState(false);
    let store = useStore(state => state);

    let { userId, role } = store.userData;

    const fetchCounts = () => {
        setMyState(setState, { isCount: true, });

        hitApi({
            url: 'pixa-support/api/getAgent_analytics',
            method: "POST",
            data: { userId: userId },
            alert: false
        }, (resp, err = null) => {
            if (resp.status) {
                setData(resp.data)
                setMyState(setState, {isLoading:resp.data.length, draftCount: resp.data.draftcount, designcount: resp.data.designcount, scheduledCount: resp.data.scheduledcount });
            }

            setMyState(setState, { isCount: false });
        });
    }

    useEffect(() => {
        fetchCounts()
    }, [])


    const menuRef = useRef();
    useOutsideClick(menuRef, () => setallsubmenu(false));

    return (
        <>
            <Helmet >
                <title>{pageTitle}</title>
            </Helmet>


            <main className="sup_dash_wrap">

                <div className="dash_head">
                    <h3>Dashboard ({data.length}) </h3>
                </div>
                <div className="dash_grid">
                    {
                        state.isCount && [...Array(8)].map((i, index) => <div key={index}>{skeletonLoader('asset', 1,'100%', 165)}</div>)
                    }
                    {
                        data.map((val, i) => <div className="single_dash_box" key={i}>
                            <div
                                className={`dah_color_box }`}
                                style={{ color: val._id.color, borderColor: val._id.color, backgroundColor: hexToRgbA(val._id.color, 0.1) }}

                            >
                                <svg width="54" height="37" viewBox="0 0 54 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M51.8002 6.9805L45.0632 15.1253C44.4377 15.8814 43.2257 15.6617 42.9055 14.7341V14.7341C42.4907 13.5323 40.7581 13.6349 40.4881 14.8773L40.1124 16.606C39.6986 18.5099 36.9531 18.4195 36.6653 16.4926L34.8905 4.60669C34.5633 2.41565 31.4455 2.3029 30.9609 4.46458L28.8797 13.7484C28.4237 15.7826 25.549 15.8494 24.999 13.8385L24.0561 10.3909C23.5091 8.3909 20.655 8.44214 20.1801 10.4604L18.6767 16.8505L15.1288 32.3198L14.7979 33.2773C14.0953 35.31 11.1184 34.9529 10.9165 32.8117L9.30263 15.6978C9.14188 13.9931 6.91652 13.4543 5.99377 14.8966V14.8966C5.15658 16.2052 3.16627 15.9102 2.74457 14.4151L2.20017 12.4849" stroke={val._id.color} strokeWidth="4" />
                                </svg>
                            </div>
                            <div>
                                <h4>{val.status.count}</h4>
                                <p>{val._id.name}</p>
                            </div>
                        </div>)
                    }

                </div>
                    {state.isLoading ==0 && !state.isCount&&
                        <NoDataFound title={'No data found.'}  changeDesign={true} bgRemove={true}   />
                    }


            </main>
        </>
    )
};

export default Dashboard;