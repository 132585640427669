import { useRef, useState } from 'react'
import svg from '../svg'
import { useOutsideClick } from '../commonAction';
import { useStore } from '../../zustand/store';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

function AppsOption() {
  const [openAppmenu, setOpenAppMenu] = useState(false);
  let store = useStore(state => state);
  let { name, role, scope } = store.userData;
  const navigate = useNavigate();
  let appPath = useLocation().pathname.split('/').filter(Boolean)[0];
  const menuRef = useRef();
  useOutsideClick(menuRef, () => {
    setOpenAppMenu(false);
  });

  const optionsData = [
    // { value: 'dashboard', label: 'Dashboard', icon: 'dashboard', url: 'builder' },
    // { value: 'builder', label: 'Builder', icon: 'builderApp', url: 'builder' },
    { value: 'scheduler', label: 'Scheduler', icon: 'schedularApp', url: 'scheduler' },
    { value: 'images', label: 'Images', icon: 'imagesApp', url: 'images' },
    { value: 'support', label: 'PixaSupport', icon: 'supportApp', url: 'pixa-support' },
  ]

  const options = (role == "teamMember") ? optionsData.filter(item => scope.hasOwnProperty(item.value)) : optionsData;

  const handleSelectedAction = (val) => {
    
     if (val == 'images') {
      role === 'teamMember' ? navigate('/images/templates') : navigate('/images');
    } else if (val == 'scheduler') {
      role === 'teamMember' ? navigate('scheduler') : navigate('/scheduler');
    } else if (val == 'support') {
      role === 'teamMember' ? navigate('/pixa-support/projects') : navigate('/pixa-support');
    } 
    // else if (val == 'builder') {
    //   role === 'teamMember' ? navigate('/builder') : navigate('/builder/projects');
    // }
  }

  return (
    <>
      <div className={`header_nine_dot_wrapper`} ref={menuRef} onClick={() => setOpenAppMenu(cur => !cur)}>
        <div className='menu_dot'>
          <img src="/assets/images/menuDotSvg.svg" alt="" />
        </div>

        <div className={`app_dropdown_wrapper ${openAppmenu ? 'show' : ''}`}>
          <div className='app_drop_grid'>
            <ul>
              <li >
                      <Link to='/dashboard'>
                      <div className={`li_first_div app_color${5} `} >
                      <div className='pxl-headerbox1'>
                        <div className='app_icon_wrap '>
                          {svg.app.dashboardIcon}
                        </div>
                        <div className='app_label'>Dashboard</div>
                      </div>
                      <div className='pxl-headerBox1-arrow'>
                        {svg.app.arrowRight}
                      </div>
                    </div>
                    </Link>
                  </li>
              {
                options.map((val, index) => (
                  <li key={index}>
                    <div className={`li_first_div app_color${index} ${appPath === val.url ? "pxl-app-active" : ''}`} onClick={() => handleSelectedAction(val.value)}>
                      <div className='pxl-headerbox1'>
                        <div className='app_icon_wrap '>
                          {svg.app[val.icon]}
                        </div>
                        <div className='app_label'>{val.label} </div>
                      </div>
                      <div className='pxl-headerBox1-arrow'>
                        {svg.app.arrowRight}
                      </div>
                    </div>
                  </li>
                ))
              }

            </ul>

          </div>

        </div>

      </div>

    </>
  )
}

export default AppsOption