import { useStore } from "../zustand/store";

const PrivateRoute = ({ children, allowedRoles  }) => {
    let { role } = useStore(state => state.userData);
    console.log("{role",role)
    console.log("{role",allowedRoles)
    // if (allowedRoles?.map(i=>i==role)) {
        if (allowedRoles.includes(role)) {
        return (
          <>
            {children}
          </>
        );
      } else {
        // window.location.href='/dashboard' ;
      }
};

export default PrivateRoute;