import {  Routes, Route, useLocation } from "react-router-dom";

import Dashboard from "./index";
import Projects from "./projects";
import MyDesigns from "./myDesigns";
import NewDesign from "./new-design";
import Templates from "./templates";
import appConfig from "./../../config/appConfig";
import './../scheduler/css/scheduler.css';

const ImageRouter = () => {
    
    if(useLocation().pathname.includes('/images')) {
        require('./../scheduler/css/scheduler.css');
    }
 
    return (
        <>
           
            <Routes>
                <Route path="/" element={<Dashboard pageTitle={`${appConfig.appName} | Image Dashboard`} />} />                
                <Route path="/my-designs" element={<Projects pageTitle={`${appConfig.appName} | My Designs`} />} />
                <Route path="/templates" element={<Templates pageTitle={`${appConfig.appName} | Templates`} />} />
                <Route path="/my-designs/projects" element={<MyDesigns pageTitle={`${appConfig.appName} | My Designs`} />} />
                <Route path="/my-designs/projects/:id" element={<MyDesigns pageTitle={`${appConfig.appName} | My Designs`} />} />
                <Route path="/editor/:id" element={<NewDesign pageTitle={`${appConfig.appName} | Image Editor`} />} />
             
            </Routes>
        </>
    )
};

export default ImageRouter;