import React from 'react'
import { useState,useEffect } from 'react';
import { useStore } from '../../../../zustand/store';
import { initManageElement,
  removeElement,
  updateElementData,
  addNewElement, } from '../editor/manageElement';

import svg from '../editorSvg';


const Tool = () => {
  let store = useStore((state) => state);
  let undoData = useStore((state) => state.undoData);
  let editorData = useStore((state) => state.editorData);
  let activeElement = useStore((state) => state.activeElement);
  let ImageData = editorData.find((d1) => d1.id == activeElement?.id);
  initManageElement(editorData, store.updateStore);


  let updateImage = (key, value) => {
      let mainIndex = ImageData.id;
      if(key=="opacity"){
          value =value/100
      }
      updateElementData({
          index: mainIndex,
          key,
          value,
      });
  };

  return (
      <>
          <div className='cun_editor_tool_box_flex'>
              
                 
                  <div className={"".concat((activeElement.element == "image" || activeElement.element == "textbox" || activeElement.element == "draw") ? "cun_editor_overlaynone" :"cun_editor_tool_disable_overlay")}>
                  <div className='image_editor_range'>
                      <label className='cun_image_editor_tool_label'>Opacity </label>
                      <div className="range">
                          <input type="range"  value={ImageData?.opacity *100} min="0" max="100"  onChange={(e) => {
                             
                               updateImage("opacity", e.target.value)
                          }} style={{ backgroundSize: `${ImageData?.opacity * 100 }% 100%` }} />

                      </div>
                      <div className='cun_color_picker_toggle'>
                          <h6>{ ImageData?.opacity ? parseInt(ImageData?.opacity * 100) : 0}</h6>
                      </div>
                  </div>
              
              <div className='d-flex justify-content-between px_image_editor_main_box '>
                  <div className='d-flex justify-content-between align-items-center'>
                      {/* {activeElement.element &&
                      <> */}
                          <label className='cun_image_editor_tool_label'>Alignment </label>
                          <div className="ewp_input ewp_input_size">
                              <select    className="subcribe_site" name="" onChange={(e) => {
                                  if (e.target.value == "right") {
                                      updateImage("right", true)
                                  } else if (e.target.value == "buttom") {
                                      updateImage("top", 0)

                                  } else if (e.target.value == "top") {
                                      updateImage("buttom", true)
                                  } else if(e.target.value == "center"){
                                      updateImage("center", true)
                                  } else  if(e.target.value == "left"){
                                    updateImage("left", true)
                                }
                              }}>
                                  <option value="0.2" disabled=""> Select </option>
                                  <option value="right"> Right</option>
                                  <option value="buttom"> Top</option>
                                  <option value="top"> Bottom</option>
                                  <option value="center"> Center</option>
                                  <option value="left"> Left</option>
                              </select>
                          </div>
                      {/* </>} */}

                    
                  </div>
              </div>
              {activeElement.element != "draw" &&
                  <div className='d-flex justify-content-md-between justify-content-center  '>
                      <div className='d-flex justify-content-between align-items-center cunFlipSection'>
                          <label className='cun_image_editor_tool_label'>Flip </label>

                          <button
                              className="cun_image_creator_text_btn "
                              id="FlipX"
                              onClick={() => {
                                  updateImage("flipX", !ImageData?.flipX)
                              }}>
                              {svg.app.text_editor_flipX}
                              <span className="cun_tooltipEle">Flip X</span>
                          </button>
                          <button
                              className="cun_image_creator_text_btn "
                              id="FlipY"
                              onClick={() => {
                                  updateImage("flipY", !ImageData?.flipY)
                              }}>
                              {svg.app.text_editor_flipY}
                              <span className="cun_tooltipEle">Flip Y</span>
                          </button>

                      </div>
                  </div>
              }
              <div className='d-flex justify-content-md-between justify-content-center  '>
                  <div className='d-flex justify-content-between align-items-center'>

                      <button
                          className="cun_image_creator_text_btn "
                          id="sendToFront"
                          onClick={() => {
                              store.updateStore("updateElement", {
                                  status: true,
                                  action: "front",
                              });
                          }}
                      >
                          {svg.app.sendToFront}
                          <span className="cun_tooltipEle">Send To Front</span>
                      </button>

                      <button
                          className="cun_image_creator_text_btn "
                          id="sendToBack"
                          onClick={() => {
                              store.updateStore("updateElement", {
                                  status: true,
                                  action: "back",
                              });
                          }}
                      >
                          {svg.app.sendToBack}
                          <span className="cun_tooltipEle">Send To Back</span>
                      </button>

                      <button
                          className="cun_image_creator_text_btn "
                          id="Delete"
                          onClick={() => {
                              store.updateStore("updateElement", {
                                  status: true,
                                  action: "delete",
                              });
                          }}
                      >
                          {svg.app.deleteIcon_fill}
                          <span className="cun_tooltipEle">Delete</span>
                      </button>
                      <button
                          className="cun_image_creator_text_btn "
                          id="duplicateIcon"
                          onClick={() => {
                              store.updateStore("updateElement", {
                                  status: true,
                                  action: "clone",
                              });
                          }}
                      >
                          {svg.app.duplicateIcon}
                          <span className="cun_tooltipEle">Duplicate</span>
                      </button>
                      {activeElement.mask && 
                      <button
                          className="cun_image_creator_text_btn "
                          id="detachIcon"
                          onClick={() => {
                             let b1= document.getElementById("deattach")
                             if(b1){
                              b1.click()
                             }
                              
                          }}
                      >
                          {svg.app.Detach_icon}
                          <span className="cun_tooltipEle">Detach</span>
                      </button>
                          }

                  </div>
              </div>

                  </div>


            {undoData.data.length > 0 &&
              <div className='d-flex justify-content-md-between justify-content-center'>
                    <div className='d-flex justify-content-between align-items-center'>
                    {(undoData.currentPostion > 1 && undoData.data[undoData.currentPostion - 1] != undefined) && (
                    <button onClick={() => {
                        let data = { ...undoData };
                        data.status = true;
                        data.currentPostion = data.currentPostion - 1;
                        store.updateStore("activeElement", {
                          id: "",
                          element: ""
                        })
                        store.updateStore("undoData", data);
                      }}
                          className="cun_image_creator_text_btn ">
                           {svg.app.undoSvg}
                          <span className="cun_tooltipEle">Undo</span>
                      </button>
                    )}
                      {(undoData.currentPostion < 10 && undoData.data[undoData.currentPostion] != undefined) && (
                      <button onClick={() => {
                        let data = { ...undoData };
                        data.status = true;
                        data.currentPostion = data.currentPostion + 1;
                        store.updateStore("activeElement", {
                          id: "",
                          element: ""
                        })
                        store.updateStore("undoData", data);

                      }}
                          className="cun_image_creator_text_btn ">
                          {svg.app.redoSvg}
                          <span className="cun_tooltipEle">Redo</span>
                      </button>
                      )}

                    </div>
              </div>
                }
          </div>
      </>
  )
}

export default Tool