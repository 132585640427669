
import LeftLayout from './LeftLayout'
import MiddleSection from './MiddleSection'
import appConfig from '../../config/appConfig'
import React, { useState, useEffect } from "react";
import { hitApi  } from "../../components/commonAction";

function APIEndPoint() {

    const [apiData, setApiData] = useState([]);
    const[mobileToggle,setMobileToggle] =useState(false);
    const[filterData,setFilterData] = useState([]);

    useEffect(() => {
        getAPIDoc()
    }, []);

    const getAPIDoc = (searchKeyword) =>{
        let data = {};
        if(searchKeyword)  data.keyword = searchKeyword;
        hitApi({
            url: `api-documentation/get-api-details`,
            method: "GET",
            data : data
        }, (resp) => {
            if(resp.status){
                const response = resp.data;
                setFilterData(response);
                setApiData(response);
            }
        });
    }
    
    function filterParentData(data, keyword) {
        return data.filter(item => {
            return [item.title, ...(item.folders || []).map(folder => folder.title), ...(item.apis || []).map(api => api.title)]
              .some(title => title && title.toLowerCase().includes(keyword.toLowerCase()));
          });
      }

    const handleFilter = (keyword) =>{
        let filterData =  filterParentData(apiData,keyword)
        setFilterData(filterData);
    }

    return (
        <div>
            <div className='api_mobile_header_wrap'>
                <div className="api_logo_wrap">
                    <a className="pxl_mainLogo" onClick={() =>setMobileToggle(false)}>
                        <img src={appConfig.appLogo} alt="Logo" width={'165px'} />
                    </a>
                </div>
                <div className="sch-headerResToggle" >
                    <ul>

                        <li className='innerspan'>
                        <a  onClick={() =>setMobileToggle(!mobileToggle)}  className="sch-menu-btn" >
                        <span  className='innerspan'><span className='innerspan'></span><span className='innerspan'></span><span className='innerspan'></span></span></a>
                        </li>
                    </ul>
                        
                 </div>
           
            </div>
            <div className={`api_Leftsection   ${mobileToggle ? 'show_folder_sidebar' : ''}`}>
                <LeftLayout data={filterData} handleFilter={handleFilter}/>
            </div>
            <div className='api_page_wrapper'>
                <div className='api_content_main_heading ' id={"1"}>Introduction</div>

                <div className='api_highLight_area'>Base URl - {appConfig.apiUrl}</div>


                {/* <div className='api_content_main_heading'>Hrasdysagdyusad</div> */}

                <div className='api_para_text'>
                    <div className='api_details'>This documentation aims to provide all the information you need to work with our API.</div>
                </div>

                <div className='api_content_main_heading file-section' id={"2"}>Authenticating requests</div>
                <div className='api_para_text'>
                    <div className='api_details'>To authenticate requests, include an Authorization header with the value "Bearer {'BEARER_TOKEN'}".</div>
                    <div className='api_details'>All authenticated endpoints are marked with a requires authentication badge in the documentation below.</div>
                    <div className='api_details'>You can retrieve your token by using the api/login endpoint.</div>
                </div>

                {
                    apiData.map((val, i) => <div key={i}>
                        <div className='api_content_main_heading file-section' id={val.folderId} >{val.title}</div>
                            <MiddleSection data={val.folders} apiData={val.apis} />
                        </div>)
                }

            </div>
        </div>
    )
}

export default APIEndPoint