

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './components/header.css';
import './responsive.css'
import './calendar.css'
import App from './App';
import ReactDOM from "react-dom/client";


export default function Index() {
    return (
        <App />
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Index />);
