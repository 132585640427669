import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Dashboard from "./index";
import Sidebar from "../../components/Sidebar"; 
import Users from "./users"; 
import Templates from "./templates"; 
import PixaStock from "./pixaStock"; 
import ApiDocumentation from "./apiDocumentation"; 
import Profile from "./profile"; 
import NotFound from "../404";
import NewDesign from "../images/new-design";
import appConfig from "../../config/appConfig";


const AdminRouter = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<><Sidebar /><Dashboard pageTitle={`${appConfig.appName} | Admin Dashboard`} /></>} />
                <Route path="/dashboard" element={<><Sidebar /><Dashboard pageTitle={`${appConfig.appName} | Admin Dashboard`} /></>} />
                <Route path="/api-documentations" element={<><Sidebar /><ApiDocumentation pageTitle={`${appConfig.appName} | API Documentations`} /></>} />
                <Route path="/users" element={<><Sidebar /><Users pageTitle={`${appConfig.appName} | Users`} /></>} />
                <Route path="/image-template" element={<><Sidebar /><Templates pageTitle={`${appConfig.appName} | Templates`} /></>} />
                <Route path="/pixa-stock" element={<><Sidebar /><PixaStock pageTitle={`${appConfig.appName} | Pixa Stock`} /></>} />
                <Route path="/account-settings" element={<><Sidebar /><Profile pageTitle={`${appConfig.appName} | Profile`} /></>} />
                <Route path="/image-template/:id" element={<NewDesign pageTitle={`${appConfig.appName} | Templates`} />} />
                <Route path="/*" element={<NotFound pageTitle={`${appConfig.appName} | 404`} />} />
            </Routes>
        </>
    )
};

export default AdminRouter;