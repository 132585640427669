import { Routes, Route, useLocation } from "react-router-dom";


import Dashboard from "./index";
import NewPost from "./newPost";
import Calendar from "./calendar";
import Draft from "./draft";
import SchedulePost from "./schedule-post";
import appConfig from "./../../config/appConfig";
import Social from '../../pages/Social'
import './css/scheduler.css';

const SchedulerRouter = () => {
    
    if(useLocation().pathname.includes('/scheduler')) {
        require('./css/scheduler.css');
    }
 
    return (
        <>
           
            <Routes>
                <Route path="/" element={<Dashboard pageTitle={`${appConfig.appName} | Dashboard`} />} />
                <Route path="/new-post" element={<NewPost pageTitle={`${appConfig.appName} | Create New Post`} />} />
                <Route path="/edit-draft/:id" element={<NewPost pageTitle={`${appConfig.appName} | Draft`} />} />
                <Route path="/edit-schedule-post/:id" element={<NewPost pageTitle={`${appConfig.appName} | Schedule Post`} />} />
                <Route path="/calendar" element={<Calendar pageTitle={`${appConfig.appName} | Calendar`} />} />
                <Route path="/draft" element={<Draft pageTitle={`${appConfig.appName} | Draft`} />} />
                <Route path="/schedule-post" element={<SchedulePost pageTitle={`${appConfig.appName} | Schedule Post`} />} />
                <Route path="/social-integration" element={<Social pageTitle={`${appConfig.appName} | Social Accounts`}/>}/>

            </Routes>
        </>
    )
};

export default SchedulerRouter;